import React from 'react';
import { useTranslation } from 'react-i18next';

import CrowdSvg from '@shared/FarmAboutCodeSteps/icons/crowd.svg?react';
import LaptopSvg from '@shared/FarmAboutCodeSteps/icons/laptop.svg?react';
import MoneyBagSvg from '@shared/FarmAboutCodeSteps/icons/money-bag.svg?react';
import { Steps } from '@shared/index';

export const FarmCodeResultSteps = () => {
  const { t } = useTranslation();

  return (
    <Steps>
      <Steps.Item Icon={CrowdSvg} title={t('Share your code with friends and followers')} withArrow={false} />
      <Steps.Item Icon={LaptopSvg} title={t('Users get up to %(span)s25%%%(span_end)s discount on their purchases')} />
      <Steps.Item Icon={MoneyBagSvg} title={t('You get 15%% of the mining income from their Pool Miners')} />
    </Steps>
  );
};
