import cn from 'classnames';
import { useEffect } from 'react';

import { useModal, Modal } from '@shared/Modal';

import AnimLoader from './loader-anim.svg?react';

import styles from './ModalLoader.module.scss';

export default function ModalLoader({ isShow, className }: { isShow: boolean; className?: string }) {
  const { opened, onOpen, onClose } = useModal(isShow);

  useEffect(() => {
    if (isShow) onOpen();
    else onClose();
  }, [isShow]);

  return (
    <Modal.Layout isOpen={opened} onClose={() => {}}>
      <Modal.Content>
        <div className={cn(styles.block, className)}>
          <AnimLoader />
        </div>
      </Modal.Content>
    </Modal.Layout>
  );
}
