import { PropsWithChildren } from 'react';

import { ParallaxBackground } from '@shared/index';

import styles from './Container.module.scss';

export type ContainerProps = PropsWithChildren<{
  className?: string;
  Icon?: React.ReactNode;
  title?: string | React.ReactNode;
  text?: string | React.ReactNode;
  innerTop?: number;
  withParallax?: boolean;
  parallaxClassName?: string;
  dark?: boolean;
}>;

export default function Container({
  className,
  Icon,
  title,
  text,
  children,
  innerTop,
  withParallax,
  parallaxClassName,
  dark,
}: ContainerProps) {
  return (
    <div
      className={`${styles.container} ${className} ${dark && styles.container__dark}`}
      style={{ paddingTop: innerTop }}
    >
      {withParallax && <ParallaxBackground className={parallaxClassName} />}
      {Icon && <div className={styles.icon}>{Icon}</div>}
      {title && <div className={styles.title}>{title}</div>}
      {text && <div className={styles.text}>{text}</div>}
      {children}
    </div>
  );
}
