import cn from 'classnames';
import { useCallback, useEffect, useState } from 'react';

import ScrollToTopIcon from '../../assets/scroll-top-top.svg?react';

import styles from './ScrollToTop.module.scss';

export const ScrollToTop = () => {
  const [isScrollToTopVisible, setScrollToTopVisible] = useState(window.scrollY > 100);
  const scrollToTop = useCallback(() => window.scrollTo({ top: 0, behavior: 'smooth' }), []);
  const onScroll = useCallback(() => {
    setScrollToTopVisible(window.scrollY > 100);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [onScroll]);
  return (
    <div className={cn(styles.scrollToTop, isScrollToTopVisible && styles.scrollToTopShow)} onClick={scrollToTop}>
      <ScrollToTopIcon />
    </div>
  );
};
