export enum ValidationKey {
  RQ = 'rq', // Is the field required?
  MNC = 'mnc', // Minimum characters
  MNW = 'mnw', // Minimum words
  MNN = 'mnn', // Minimum number
  MND = 'mnd', // Minimum date
  MNT = 'mnt', // Minimum time
  MNS = 'mns', // Minimum number of choice selections
  MXC = 'mxc', // Maximum characters
  MXW = 'mxw', // Maximum words
  MXN = 'mxn', // Maximum number
  MXD = 'mxd', // Maximum date
  MXT = 'mxt', // Maximum time
  MXS = 'mxs', // Maximum number of choice selections
  WH = 'wh', // Whether it is whole number only
  CH = 'ch', // ID of required choice
  RGX = 'rgx', // Regex. Two keys: "p" (required) - pattern, "m" (opt) - error message
}
export interface Validation {
  [ValidationKey.RQ]?: boolean;
  [ValidationKey.MNC]?: number | null;
  [ValidationKey.MNW]?: number | null;
  [ValidationKey.MNN]?: number | null;
  [ValidationKey.MND]?: string | null;
  [ValidationKey.MNT]?: string | null;
  [ValidationKey.MNS]?: number | null;
  [ValidationKey.MXC]?: number | null;
  [ValidationKey.MXW]?: number | null;
  [ValidationKey.MXN]?: number | null;
  [ValidationKey.MXD]?: string | null;
  [ValidationKey.MXT]?: string | null;
  [ValidationKey.MXS]?: number | null;
  [ValidationKey.WH]?: boolean | null;
  [ValidationKey.CH]?: string | null;
  [ValidationKey.RGX]?: { p: string; m?: string }; // Regex pattern and optional error message
}

export type Choice = {
  id: string;
  label: string;
  other: boolean;
};
export enum QuestionViewType {
  CHECKBOX_MULTIPLE = 'cm', // Check box list
  CHECKBOX_SINGLE = 'cs', // Radio buttons
  TEXT_EMAIL = 'te', // Text (Email)
  TEXT_SHORT = 'ts', // Text (Short)
  TEXT_LONG = 'tl', // Text (Long)
}
export type Question = {
  id: string;
  type: QuestionViewType;
  label: string;
  description?: string;
  choices?: Choice[];
  validation?: Validation;
};

export type FormTemplate = {
  questions: Question[];
};
export type AnswerItem = {
  choices?: Array<string>;
  text?: string;
  other: boolean;
};
