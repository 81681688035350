import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { PRODUCT_KIND } from '@utils/checkPathname';
import { product } from '@utils/productName';

import { FarmSubheader } from '@pages/headersComponents/FarmSubheader';

import { useGeneratePostModal } from '@hooks/useGeneratePostModal';

import { Root } from '@service/reducers';
import { selectUserId } from '@service/selectors/selectors';

import { HelmetHead } from '../headersComponents/HelmetHead';
import { LinkInfoView } from './view/FarmLinkInfoView';

export default function FarmLinkInfo() {
  const id = useSelector((store: Root) => selectUserId(store));
  const { t } = useTranslation();
  const { handleGeneratePostClick } = useGeneratePostModal(PRODUCT_KIND.Farm, { initialLink: Number(id) });

  return (
    <>
      <HelmetHead title={t('Link to share')} product={product.farm} />
      <FarmSubheader />
      <LinkInfoView id={Number(id)} onGeneratePost={handleGeneratePostClick} />
    </>
  );
}
