import { useTranslation } from 'react-i18next';

import { ParallaxBackground } from '@shared/index';

import styles from './Banner.module.scss';

export default function Banner() {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.content + ' animate__animated'} data-anim="fadeIn">
        <ParallaxBackground className={styles.parallax} />
        {t('ct-affiliate_about_text-edits.orange.All_prizes_will_be_a')}
      </div>
    </div>
  );
}
