import { Span } from '@shared/Graph/Tabs';

import { AffiliateGroup, Discreteness, Product } from '@backend/JsonRpcApi';
import { rpc } from '@backend/Rpc';

import { AppDispatch } from 'src/main.tsx';

import {
  BROWSER_INSTALLS_SUCCESS,
  BROWSER_LINKS_ERROR,
  BROWSER_LINKS_REQUEST,
  BROWSER_LINKS_SUCCESS,
  BROWSER_MINERS_SUCCESS,
  BROWSER_OPENS_SUCCESS,
} from '../constant';

const updateTimestamp = <T extends { timestamp: number }>(arr: T[]): T[] =>
  arr.map((item) => ({ ...item, timestamp: item.timestamp * 1000 }));
export function getBrowserStatsOpens(period_start: string, period_end: string, discreteness?: Discreteness) {
  return async function (dispatch: AppDispatch) {
    try {
      const opens = await rpc.transmit('statistics.v2.clicks', {
        start_date: period_start,
        end_date: period_end,
        discreteness: discreteness || Span.Day,
        product_ids: [Product.CT],
      });

      const updateOpens = opens
        .map((item) => ({ timestamp: item.timestamp * 1000, account_id: +item.advert_id, value: item.value }))
        .sort((a, b) => a.timestamp - b.timestamp);
      dispatch({ type: BROWSER_OPENS_SUCCESS, opens: updateOpens });
    } catch (error) {
      console.error('Error fetching browser stats opens:', error);
    }
  };
}

export function getBrowserStatsInstalls(period_start: string, period_end: string, discreteness?: Discreteness) {
  return async function (dispatch: AppDispatch) {
    try {
      const installs = await rpc.transmit('statistics.referrals', {
        start_date: period_start,
        end_date: period_end,
        discreteness: discreteness || Span.Day,
      });
      dispatch({
        type: BROWSER_INSTALLS_SUCCESS,
        installs: updateTimestamp(installs.items).sort((a, b) => a.timestamp - b.timestamp),
      });
    } catch (error) {
      console.error('Error fetching browser stats installs:', error);
    }
  };
}

export function getBrowserStatsMiners(period_start: string, period_end: string, discreteness?: Discreteness) {
  return async function (dispatch: AppDispatch) {
    try {
      const miners = await rpc.transmit('statistics.referrals.payments', {
        start_date: period_start,
        end_date: period_end,
        group: AffiliateGroup.Browser,
        discreteness: discreteness || Span.Day,
      });

      dispatch({
        type: BROWSER_MINERS_SUCCESS,
        miners: miners.items
          .map((item) => ({
            ...item,
            timestamp: item.timestamp! * 1000,
            value: item.users,
          }))
          .sort((a, b) => a.timestamp - b.timestamp),
      });
    } catch (error) {
      console.error('Error fetching browser stats miners:', error);
    }
  };
}
export default function getBrowserStatsLinks(
  period_start: string,
  period_end: string,
  discretenessOpens?: Discreteness,
  discretenessLinks?: Discreteness,
  discretenessMiner?: Discreteness
) {
  return async function (dispatch: AppDispatch) {
    try {
      dispatch({ type: BROWSER_LINKS_REQUEST });
      getBrowserStatsOpens(period_start, period_end, discretenessOpens)(dispatch);
      getBrowserStatsInstalls(period_start, period_end, discretenessLinks)(dispatch);
      getBrowserStatsMiners(period_start, period_end, discretenessMiner)(dispatch);
      dispatch({ type: BROWSER_LINKS_SUCCESS });
    } catch (e) {
      dispatch({ type: BROWSER_LINKS_ERROR });
      console.log(e);
    }
  };
}
