import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Wallet from '@assets/colored/icon-wallet.svg?react';
import src from '@assets/wallet-modal.png';
import srcSet from '@assets/wallet-modal@2x.png';

import { externalLinks } from '@utils/externalLinks';

import { Button, ButtonVariant } from '@shared/index';
import { Modal } from '@shared/Modal';

import styles from './ModalPromo.module.scss';

export type ModalWalletProps = {
  onClose: () => void;
  link?: string;
};

export function ModalWallet({ onClose, link }: ModalWalletProps) {
  const { t } = useTranslation();
  const handleCreate = useCallback(() => {
    window.open(link || externalLinks.ncw);
    onClose();
  }, [onClose, link]);

  return (
    <Modal.Layout onClose={onClose} isOpen>
      <Modal.Content>
        <Modal.Body>
          <Modal.Image>
            <div className={styles.imageWrapper}>
              <picture>
                <source srcSet={srcSet} media="(max-width: 767px)" />
                <img src={src} alt={t('PopUp1.Title')} className={styles.image} />
              </picture>
            </div>
          </Modal.Image>
          <Modal.TitleDescription
            title={
              <a href={externalLinks.ncw} target="_blank" className={styles.title} rel="noreferrer">
                {t('PopUp1.Title')}
              </a>
            }
            description={t('PopUp1.Description')}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button type={'button'} onClick={handleCreate} Icon={<Wallet />} variant={ButtonVariant.Primary}>
            {t('CREATE WALLET')}
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Layout>
  );
}
