import BrowserGraph from '@pages/BrowserDashboard/view/components/BrowserGraph';
import { ActivationsCard } from '@pages/FarmDashboard/view/components';

import { Container } from '@shared/index';

import ClicksGraph from './components/ClicksGraph/ClicksGraph';
import CommonMobControls from './components/CommonMobControls/CommonMobControls';
import { EarningsGraph } from './components/EarningsGraph/EarningsGraph';
import MinersGraph from './components/MinersGraph/MinersGraph';

const CommonStatisticsView: React.FC = () => {
  return (
    <Container vertical={20}>
      <CommonMobControls />
      <ClicksGraph />
      <ActivationsCard />
      <BrowserGraph type="install" />
      <MinersGraph />
      <EarningsGraph />
    </Container>
  );
};

export default CommonStatisticsView;
