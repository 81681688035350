import cn from 'classnames';
import { ComponentType, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ArrowRightSvg } from '@assets/colorless';
import CloseSVG from '@assets/colorless/cross.svg?react';

import {
  februaryPromoDesktopBackground,
  februaryPromoMobileBackground,
} from '@shared/BannerVariants/FebruaryPromo/assets';
import {
  FEBRUARY_PROMO_BANNER_ID,
  FEBRUARY_PROMO_INFO_URL,
  getIsFebruaryPromo,
} from '@shared/BannerVariants/FebruaryPromo/FebruaryPromo.helpers';
import { useContainerWidth } from '@shared/BannerVariants/hooks';
import { Button, ButtonVariant } from '@shared/Button/Button';

import styles from './FebruaryPromo.module.scss';

type FebruaryPromoWrapperProps = {
  onCloseBanner: () => void;
  offsetBottom?: number;
  offsetTop?: number;
};

const FebruaryPromoBannerContent = ({ onCloseBanner, offsetBottom, offsetTop }: FebruaryPromoWrapperProps) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const containerWidth = useContainerWidth(containerRef);
  const isCompact = (containerWidth ?? 0) <= 768;

  const onClickCloseBanner = () => {
    const timestamp = Date.now();
    localStorage.setItem(FEBRUARY_PROMO_BANNER_ID, timestamp.toString());
    onCloseBanner();
  };

  return (
    <>
      <div
        ref={containerRef}
        className={cn(styles.banner, isCompact && styles.responsiveBanner)}
        style={{ marginBottom: offsetBottom, marginTop: offsetTop }}
      >
        <button className={styles.close} onClick={onClickCloseBanner}>
          <CloseSVG color={'#B3B3B3'} />
        </button>
        <div className={styles.background}>
          <img src={isCompact ? februaryPromoMobileBackground : februaryPromoDesktopBackground} alt="banner" />
        </div>
        <div className={styles.content}>
          <div className={styles.texts}>
            <h3 className={styles.title}>{t('Camp.Modal.Title')}</h3>
            <p className={styles.description}>
              <Trans i18nKey={'Camp.Modal.Descr'} components={{ b: <b /> }} />
            </p>
          </div>
          <div className={styles.buttons}>
            <Button
              type="link"
              target="_blank"
              external
              to={FEBRUARY_PROMO_INFO_URL}
              variant={ButtonVariant.Primary}
              Icon={<ArrowRightSvg />}
              iconPosition="right"
              size="medium"
              className={styles.button}
            >
              {t('Camp.Modal.Btn')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export const withFebruaryPromoWrapper = <
  P extends {
    offsetBottom?: number;
    offsetTop?: number;
  }
>(
  WrappedComponent: ComponentType<P>
) => {
  const FebruaryPromoHOC: React.FC<P> = (props) => {
    const [isFebruaryPromoBannerVisible, setIsFebruaryPromoBannerVisible] = useState<boolean>(getIsFebruaryPromo);
    if (!isFebruaryPromoBannerVisible) return <WrappedComponent {...props} />;

    return (
      <FebruaryPromoBannerContent
        onCloseBanner={() => setIsFebruaryPromoBannerVisible(false)}
        offsetBottom={props.offsetBottom}
        offsetTop={props.offsetTop}
      />
    );
  };

  return FebruaryPromoHOC;
};
