import { useSelector } from 'react-redux';

import { ColorMap } from '@utils/colors';

import { Root } from '@service/reducers';

import Balance, { BalanceVariant } from '../../../../../components/Balance';

import styles from './BalanceInfo.module.scss';

export default function BalanceInfo() {
  const {
    user,
    system: { backgroundColor },
  } = useSelector((store: Root) => store);

  if (!user.hasRequested) {
    return null;
  }
  return (
    <div className={styles.container} style={{ backgroundColor: ColorMap[backgroundColor] }}>
      <div className={styles.inner}>
        <Balance variant={BalanceVariant.HSH} />
      </div>
    </div>
  );
}
