import { MAX_PROMO_IMAGES } from '@shared/PromoImagesSection/PromoImagesSection';

import { browserTexts, browserTitles } from '@constants/promo/browserTexts';
import { farmText, farmTitles } from '@constants/promo/farmTexts';
import { poolTexts, poolTitles } from '@constants/promo/poolTexts';

import { PRODUCT_KIND } from './checkPathname';
import { createLinkToPromo } from './createLinkToPromo';
import { range } from './range';

export const getRandomPromoText = (productKind: PRODUCT_KIND) => {
  let arr: string[] = [];
  switch (productKind) {
    case PRODUCT_KIND.Farm:
      arr = farmText;
      break;
    case PRODUCT_KIND.FarmPro:
      arr = farmText;
      break;
    case PRODUCT_KIND.Pool:
      arr = poolTexts;
      break;
    case PRODUCT_KIND.Browser:
      arr = browserTexts;
      break;
    default:
      break;
  }

  return arr[Math.floor(Math.random() * arr.length)];
};

export const getRandomPromoTitles = (productKind: PRODUCT_KIND) => {
  let arr: string[] = [];
  switch (productKind) {
    case PRODUCT_KIND.Farm:
      arr = farmTitles;
      break;
    case PRODUCT_KIND.FarmPro:
      arr = farmTitles;
      break;
    case PRODUCT_KIND.Pool:
      arr = poolTitles;
      break;
    case PRODUCT_KIND.Browser:
      arr = browserTitles;
      break;
    default:
      break;
  }
  return arr[Math.floor(Math.random() * arr.length)];
};

export const getRandomPromoImage = (productKind: PRODUCT_KIND) => {
  switch (productKind) {
    case PRODUCT_KIND.Farm:
    case PRODUCT_KIND.FarmPro:
    case PRODUCT_KIND.Pool:
    case PRODUCT_KIND.Browser: {
      const promoLink = createLinkToPromo(productKind, 'landscape', genRandomNumber(range(MAX_PROMO_IMAGES)));
      return promoLink;
    }
    default:
      return '';
  }
};

const genRandomNumber = <T>(arr: T[]): T => arr[Math.floor(Math.random() * arr.length)];
