import cls from 'classnames';
import { differenceInDays } from 'date-fns';
import React, { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';

import CloseSVG from '@assets/colorless/cross.svg?react';

import { withFebruaryPromoWrapper } from './FebruaryPromo';

import styles from './BannerVariants.module.scss';

export type BannerVariantsProps = {
  Media?: ReactNode;
  title?: string;
  description: string;
  Button?: ReactNode;
  className?: string;
  variant?: 'default' | 'outlined' | 'vertical';
  offsetBottom?: number;
  offsetTop?: number;
  id?: string;
};

export const BannerVariants: FC<BannerVariantsProps> = withFebruaryPromoWrapper((props) => {
  const { title, description, Media, Button, variant = 'default', className, id } = props;
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (id) {
      const date = localStorage.getItem(id);
      if (date && differenceInDays(new Date().getTime(), +date) == 0) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    } else {
      setIsVisible(true);
    }
  }, []);

  const handleClose = useCallback(() => {
    setIsVisible((prev) => !prev);
    if (id) localStorage.setItem(id, new Date().getTime().toString());
  }, []);

  const classNames = useMemo(
    () =>
      cls({
        [styles[variant]]: true,
      }),
    [variant]
  );

  const MediaComponent = <div className={cls([styles.media])}>{Media}</div>;
  const titleComponent = title && <h3 className={cls([styles.title])}>{title}</h3>;

  if (!isVisible) return null;

  return (
    <div
      className={cls([styles.banner, classNames, className])}
      style={{ marginBottom: props.offsetBottom, marginTop: props.offsetTop }}
    >
      <button className={styles.close} onClick={handleClose}>
        <CloseSVG color={'#B3B3B3'} />
      </button>
      <div className={styles.content}>
        {MediaComponent}
        <div className={styles.texts}>
          {titleComponent}
          <p className={styles.description}>
            <Trans i18nKey={description} components={{ b: <b /> }} />
          </p>
        </div>
      </div>
      <div className={styles.buttons}>{Button}</div>
    </div>
  );
});
