import { FC } from 'react';

import { Container } from '@shared/Container/Container';

import { SuspendController } from './components/SuspendController';

import styles from './TotalSuspendView.module.scss';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const TotalSuspendView: FC<{ suspendConfig: Record<string, any> }> = ({ suspendConfig }) => {
  return (
    <div className={styles.suspend}>
      <div className={styles.suspend__decoration} />
      <Container>
        <SuspendController suspendConfig={suspendConfig} />
      </Container>
    </div>
  );
};
