import { useTranslation } from 'react-i18next';

import { MagicSvg } from '@assets/colorless';

import { PRODUCT_KIND } from '@utils/checkPathname';

import { useGeneratePostModal } from '@hooks/useGeneratePostModal';

import { Button } from '@shared/Button/Button';

import styles from './Top.module.scss';

type ProductFooterProps = {
  productKind: PRODUCT_KIND;
};

export default function ProductFooter({ productKind }: ProductFooterProps) {
  const { t } = useTranslation();
  const { handleGeneratePostClick } = useGeneratePostModal(productKind);
  return (
    <Button size="medium" Icon={<MagicSvg />} type="button" className={styles.btn} onClick={handleGeneratePostClick}>
      {t('GeneratePost.button')}
    </Button>
  );
}
