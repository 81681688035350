import React, { FC, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { SET_TITLE_PAGE } from '@service/constant';

export type HelmetHeadProps = {
  title?: string;
  product?: string;
  link?: string;
};

export const HelmetHead: FC<HelmetHeadProps> = (props) => {
  const { title, product, link } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch({ type: SET_TITLE_PAGE, title });
  }, [title]);

  return (
    <Helmet>
      <title>
        {title
          ? product
            ? `${title} | ${product} - CryptoTab Affiliate`
            : `${title} | CryptoTab Affiliate`
          : 'CryptoTab Affiliate'}
      </title>
      <meta property="og:type" content="website" />
      <meta property="og:url" content={`https://cryptobrowser.site/${link || 'app/about'}`} />
      <meta
        property="og:image"
        content="https://cdn.cryptobrowser.site/images/aff-og-image/ct-nft_affiliate_og-img@2x.jpg"
      />
      <meta property="og:title" content={t('about.meta.title')} />
      <meta property="og:description" content={t('about.meta.description')} />
      <meta property="og:site_name" content="CryptoTab Affiliate" />
    </Helmet>
  );
};
