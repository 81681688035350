import React, { useState, useEffect, FC } from 'react';

import { LoadSpinner } from '@shared/Loader';

import FormNavigation from './FormNavigation';
import Question from './Question';
import { AnswerItem, Question as QuestionType } from './types';
import { showToast, ToastKind } from '../../../../components/Toast/Toast';
import { useFormAPI } from '../services/useFormAPI';
import { ErrorMap, Validator } from '../services/utils';

import styles from './SuspendCard.module.scss';
const findQuestionIndex = (
  questions: QuestionType[],
  savedAnswers: Record<string, AnswerItem>,
  validator: typeof Validator
): number => {
  let index = 0;
  const keys = Object.keys(savedAnswers);

  for (let i = 0; i < questions.length; i++) {
    const question = questions[i];
    const answer = savedAnswers[question.id];

    const validationError = validator.validate(answer?.text || answer?.choices || '', question.validation || {});

    if (validationError) {
      return i;
    }

    if (keys.includes(question.id)) {
      index = i;
    }
  }

  return index;
};
type FormContainerProps = {
  handleSubmintForm: () => void;
};
const FormContainer: FC<FormContainerProps> = ({ handleSubmintForm }) => {
  const { fetchTemplate, submitAnswers } = useFormAPI();
  const [questions, setQuestions] = useState<QuestionType[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [answers, setAnswers] = useState<Record<string, AnswerItem>>({});
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const template = await fetchTemplate();
        setQuestions(template.questions);

        const savedAnswers = localStorage.getItem('answers');
        if (savedAnswers) {
          const parsedAnswers = JSON.parse(savedAnswers);
          setAnswers(parsedAnswers);
          const index = findQuestionIndex(template.questions, parsedAnswers, Validator);
          setCurrentIndex(index);
        }
      } catch {
        showToast(ErrorMap.default, ToastKind.Error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const handleSubmit = async () => {
    try {
      await submitAnswers(answers);
      localStorage.removeItem('answers');
      localStorage.removeItem('suspendCardFormState');
      handleSubmintForm();
    } catch (error) {
      console.log(error);
      showToast(ErrorMap.default, ToastKind.Error);
    }
  };

  const handleNext = () => {
    const currentQuestion = questions[currentIndex];
    const currentAnswer = answers[currentQuestion.id];

    const validationError = Validator.validate(
      currentAnswer?.text || currentAnswer?.choices || '',
      currentQuestion.validation || {}
    );

    if (validationError) {
      setError(validationError);
      return;
    }

    setError(null);
    setCurrentIndex((i) => i + 1);
  };

  const handlePrevious = () => {
    setError(null);
    setCurrentIndex((i) => i - 1);
  };

  const handleAnswerChange = (id: string, answer: AnswerItem) => {
    setAnswers((prev) => {
      const updatedAnswers = { ...prev, [id]: answer };
      localStorage.setItem('answers', JSON.stringify(updatedAnswers));
      return updatedAnswers;
    });
    setError(null);
  };
  if (isLoading) return <LoadSpinner />;
  if (!questions.length && !isLoading)
    return (
      <div className={styles.wrapper}>
        <p style={{ color: 'red' }}>{ErrorMap.default} </p>
      </div>
    );

  return (
    <>
      <Question
        key={questions[currentIndex].id}
        question={questions[currentIndex]}
        onChange={handleAnswerChange}
        answer={answers[questions[currentIndex]?.id]}
      />
      {error && (
        <div className={styles.wrapper} style={{ marginBottom: '20px' }}>
          <p style={{ color: 'red' }}>{error}</p>
        </div>
      )}
      <FormNavigation
        currentIndex={currentIndex}
        total={questions.length}
        onNext={handleNext}
        onPrevious={handlePrevious}
        onSubmit={handleSubmit}
        isLoading={isLoading}
      />
    </>
  );
};

export default FormContainer;
