import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Refresh from '@assets/refresh.svg?react';

import { Button, ButtonVariant } from '@shared/Button/Button';
import { Tooltip } from '@shared/Tooltip/Toolkit';

import { RefreshContent } from '../GeneratePostModal';

import styles from './GeneratePostModalBody.module.scss';

type GeneratePostModalBodyProps = {
  title: string;
  text: string;
  image: string;
  onRefresh: (evt: React.MouseEvent<HTMLButtonElement>) => void;
  onChange?: (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  refLink?: string;
};

export const GeneratePostModalBody = (props: GeneratePostModalBodyProps) => {
  const { t } = useTranslation();
  const { title, text, image, onRefresh, onChange } = props;

  return (
    <div className={styles.root}>
      <p className={styles.subtitle}>{t('Share_block.Description')}</p>
      <div className={styles.content}>
        <div className={styles.texts}>
          <div className={styles.contentWrapper}>
            <input
              name={RefreshContent.Title}
              className={styles.input}
              type="text"
              placeholder="Enter your message"
              value={title}
              onChange={onChange}
            />
            <Tooltip text={t('tip.title')}>
              <Button
                name={RefreshContent.Title}
                type="button"
                onClick={onRefresh}
                Icon={<Refresh />}
                variant={ButtonVariant.Text}
                onlyIcon
                className={classNames(styles.refresh)}
              />
            </Tooltip>
          </div>
          <div className={classNames(styles.fullWrapper, styles.contentWrapper)}>
            <textarea className={styles.textarea} name={RefreshContent.Text} value={text} onChange={onChange} />
            <Tooltip text={t('tip.text')}>
              <Button
                name={RefreshContent.Text}
                type="button"
                onClick={onRefresh}
                Icon={<Refresh />}
                variant={ButtonVariant.Text}
                onlyIcon
                className={classNames(styles.refresh, styles.refreshBottom)}
              />
            </Tooltip>
          </div>
        </div>
        <div className={classNames(styles.fullWrapper, styles.contentWrapper)}>
          <img className={styles.image} src={image} alt={title} />
          <Tooltip text={t('tip.image')} className={classNames(styles.tooltip, styles.imageTooltip)}>
            <Button
              type="button"
              name={RefreshContent.Image}
              onClick={onRefresh}
              Icon={<Refresh />}
              onlyIcon
              className={classNames(styles.refresh, styles.refreshTop)}
            />
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
