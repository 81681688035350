import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PRODUCT_KIND } from '@utils/checkPathname';

import CommonLinksView from '@pages/CommonLinks/view/CommonLinksView';
import { AllSubheader } from '@pages/headersComponents/AllSubheader';
import { HelmetHead } from '@pages/headersComponents/HelmetHead';

import { useGeneratePostModal } from '@hooks/useGeneratePostModal';

import getAggregatedFarmData from '@service/actions/getAggregatedFarmData';
import getBalances from '@service/actions/getBalances';
import getCommonLinksInfo from '@service/actions/getCommonLinksInfo';
import { Root } from '@service/reducers';
import { LinkAcc, LinkInfo, rootBalanceType } from '@service/reducers/balanceReducer';
import { selectUser } from '@service/selectors/selectors';

import { farmLinks } from '@shared/FarmPromoCodes/FarmPromoCodes';
import { FarmPromoTabs } from '@shared/FarmPromoCodes/FarmPromoCodesTabs';
import { Span } from '@shared/Graph/Tabs';

export type CommonLinkType = LinkInfo & {
  link_id: number;
  comment?: string;
  open: number;
  link: string;
  install: number;
  active: number;
  revenue: number;
};

export type CommonLinksInfo = {
  farm: LinkAcc[];
  browser: LinkAcc[];
  ct_pool: LinkAcc[];
  count: number;
};

export default function CommonLinks() {
  const dispatch = useDispatch();

  const balance = useSelector((store: Root) => store.balance);
  const user = useSelector((store: Root) => selectUser(store));

  useEffect(() => {
    !balance.get_balance && dispatch(getBalances());
  }, [dispatch, balance.get_balance]);

  const store = useSelector((store: Root) => store.browserGraph);
  const commonStore = useSelector((store: Root) => store);
  useEffect(() => {
    dispatch(getCommonLinksInfo(store.period_start, store.period_end));
    commonStore.user.id !== 0 &&
      dispatch(getAggregatedFarmData(Span.Day, commonStore.user.id, store.period_start, store.period_end));
  }, [dispatch, store.period_start, store.period_end]);

  const links = useSelector((store: { balance: rootBalanceType }) => store.balance.links);

  const count = useMemo(() => balance.links.filter((i) => i.type !== 'farm').length * 2 + 1, [balance.links]);

  const useCommonLinkMock = (): CommonLinksInfo => {
    return {
      farm: [
        {
          id: user.id,
          name: user.id,
          comment: null,
          is_banned: false,
          type: null,
          url: farmLinks[FarmPromoTabs.Usually],
        },
      ] as unknown as LinkAcc[],
      browser: links,
      ct_pool: links,
      count,
    };
  };

  const linksInfo = useCommonLinkMock();

  const { handleGeneratePostClick } = useGeneratePostModal(PRODUCT_KIND.Browser, { timeoutDelay: 2000 });

  const handleRefresh = useCallback(() => {
    dispatch(getBalances());
    dispatch(getCommonLinksInfo(store.period_start, store.period_end));
    dispatch(getAggregatedFarmData(Span.Day, commonStore.user.id, store.period_start, store.period_end));
  }, [dispatch, store.period_start, store.period_end]);
  const isLoading = !store.stats_links_get && !balance.get_balance;

  return (
    <>
      <HelmetHead title="Link and codes" />
      <AllSubheader />
      <CommonLinksView
        linksInfo={linksInfo}
        onLinkCreate={handleGeneratePostClick}
        isLoading={isLoading}
        handleRefresh={handleRefresh}
      />
    </>
  );
}
