import cn from 'classnames';
import React, { FC, HTMLAttributes, PropsWithChildren } from 'react';

import { StepChevronSvg } from '@assets/colored';

import styles from './Steps.module.scss';

type StepsComponent = FC<PropsWithChildren> & {
  Item: FC<FarmAboutCodeStepProps>;
};

export const Steps: StepsComponent = ({ children }) => {
  return <div className={styles.steps}>{children}</div>;
};

type FarmAboutCodeStepProps = HTMLAttributes<HTMLDivElement> & {
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  title: string | JSX.Element;
  withArrow?: boolean;
  dark?: boolean;
  className?: string;
  arrowAnimClassName?: string;
};

const Step: FC<FarmAboutCodeStepProps> = (props) => {
  const { Icon, title, dark, className } = props;
  return (
    <div className={cn(styles.step, dark && styles.step__dark, className)} data-anim="fadeInUp">
      <Icon />
      <h5 className={styles.step__title}>{title}</h5>
    </div>
  );
};

const StepItem: FC<FarmAboutCodeStepProps> = (props) => {
  const { withArrow = true, arrowAnimClassName, ...rest } = props;

  return (
    <>
      {withArrow && <StepChevronSvg className={cn(styles.steps__arrow, arrowAnimClassName)} data-anim="fadeInDown" />}
      <Step {...rest} />
    </>
  );
};

Steps.Item = StepItem;
