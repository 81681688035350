import { doEmptyLevels, Item, searchItem } from '@utils/browserLevelsUtils';
import checkE from '@utils/checkE';

import { Span } from '@shared/Graph/Tabs';

import { AffiliateGroup, Discreteness, JsonRpcApi, Product } from '@backend/JsonRpcApi';
import { rpc } from '@backend/Rpc';

import { AppDispatch } from 'src/main.tsx';

import {
  BROWSER_CHANGE_TABLE_INSTALLS,
  BROWSER_CHANGE_TABLE_MINERS,
  BROWSER_CHANGE_TABLE_OPENS,
  BROWSER_EARNING_SUCCESS,
  BROWSER_EARNING_TOTAL,
  BROWSER_INSTALLS_SUCCESS,
  BROWSER_LINKS_ERROR,
  BROWSER_LINKS_REQUEST,
  BROWSER_LINKS_SUCCESS,
  BROWSER_MINERS_SUCCESS,
  BROWSER_OPENS_SUCCESS,
  GET_BROWSER_LEVELS,
} from '../constant';

const updateTimestamp = <T extends { timestamp: number }>(arr: T[]): T[] =>
  arr.map((item) => ({ ...item, timestamp: item.timestamp * 1000 }));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fetchAndProcessData = async (rpcMethod: keyof JsonRpcApi, params: object, mapFunc: (item: any) => any) => {
  const response = await rpc.transmit(rpcMethod, params);
  // @ts-expect-error TODO: fix this
  const data = response.items ? response.items : response;
  const modifiedItems = updateTimestamp(data)
    .map(mapFunc)
    .sort((a, b) => a.timestamp - b.timestamp);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const unmodifiedItems: any[] = updateTimestamp(data).sort((a, b) => a.timestamp - b.timestamp);
  return { modifiedItems, unmodifiedItems };
};
export default function getBrowserLinkDetailInfo(
  period_start: string,
  period_end: string,
  opensDiscreteness: Discreteness,
  installsDiscreteness: Discreteness,
  minersDiscreteness: Discreteness,
  earningsDiscreteness: Discreteness,
  updateDate: boolean,
  updateOpensDiscreteness: boolean,
  updateInstallsDiscreteness: boolean,
  updateMinersDiscreteness: boolean,
  updateEarningsDiscreteness: boolean
) {
  return async function (dispatch: AppDispatch) {
    try {
      const mockLevels = new Array(10).fill({ ref: 0, mined: 0 });
      if (updateDate) {
        dispatch({ type: BROWSER_LINKS_REQUEST });

        // ---------- opens ----------
        const opensData = await fetchAndProcessData(
          'statistics.v2.clicks',
          { start_date: period_start, end_date: period_end, discreteness: Span.Day, product_ids: [Product.CT] },
          (item) => ({ timestamp: item.timestamp, account_id: +item.advert_id, value: item.value })
        );
        let graphOpens = opensData;
        if (opensDiscreteness !== Span.Day) {
          graphOpens = await fetchAndProcessData(
            'statistics.v2.clicks',
            {
              start_date: period_start,
              end_date: period_end,
              discreteness: opensDiscreteness,
              product_ids: [Product.CT],
            },
            (item) => ({ timestamp: item.timestamp, account_id: +item.advert_id, value: item.value })
          );
        }
        dispatch({ type: BROWSER_OPENS_SUCCESS, opens: graphOpens.modifiedItems });
        dispatch({ type: BROWSER_CHANGE_TABLE_OPENS, payload: opensData.modifiedItems });

        // ---------- installs ----------
        const installsData = await fetchAndProcessData(
          'statistics.referrals',
          { start_date: period_start, end_date: period_end, discreteness: Span.Day },
          (item) => ({ ...item, timestamp: item.timestamp })
        );
        let graphInstalls = installsData;
        if (installsDiscreteness !== Span.Day) {
          graphInstalls = await fetchAndProcessData(
            'statistics.referrals',
            { start_date: period_start, end_date: period_end, discreteness: installsDiscreteness },
            (item) => ({ ...item, timestamp: item.timestamp })
          );
        }
        dispatch({ type: BROWSER_INSTALLS_SUCCESS, installs: graphInstalls.modifiedItems });
        dispatch({ type: BROWSER_CHANGE_TABLE_INSTALLS, payload: installsData.modifiedItems });

        // ---------- miners ----------
        const minersData = await fetchAndProcessData(
          'statistics.referrals.payments',
          { start_date: period_start, end_date: period_end, group: AffiliateGroup.Browser, discreteness: Span.Day },
          (item) => ({ ...item, timestamp: item.timestamp, value: item.users })
        );
        let graphMiners = minersData;
        if (minersDiscreteness !== Span.Day) {
          graphMiners = await fetchAndProcessData(
            'statistics.referrals.payments',
            {
              start_date: period_start,
              end_date: period_end,
              group: AffiliateGroup.Browser,
              discreteness: minersDiscreteness,
            },
            (item) => ({ ...item, timestamp: item.timestamp, value: item.users })
          );
        }
        dispatch({ type: BROWSER_MINERS_SUCCESS, miners: graphMiners.modifiedItems });
        dispatch({ type: BROWSER_CHANGE_TABLE_MINERS, payload: minersData.modifiedItems });

        // ---------- earnings ----------

        let graphEarnings = graphMiners;
        if (earningsDiscreteness !== Span.Day) {
          graphEarnings = await fetchAndProcessData(
            'statistics.referrals.payments',
            {
              start_date: period_start,
              end_date: period_end,
              group: AffiliateGroup.Browser,
              discreteness: earningsDiscreteness,
            },
            (item) => ({ timestamp: item.timestamp ? item.timestamp : undefined, value: +checkE(item.amount) })
          );
        }
        const earningTotal = checkE(graphEarnings.unmodifiedItems.reduce((acc, item) => acc + item.amount, 0));
        dispatch({ type: BROWSER_EARNING_TOTAL, earnings: earningTotal });
        dispatch({ type: BROWSER_EARNING_SUCCESS, earnings: graphEarnings.modifiedItems });

        const levels: Record<string, Item[]> = {};
        const total: Item[] = [...mockLevels];

        installsData.modifiedItems.forEach((item) => {
          const levelIndex = Math.max(1, Math.min(item.lvl, 10)) - 1;
          if (total[levelIndex]) total[levelIndex].ref += item.value;
        });

        minersData.modifiedItems.forEach((item) => {
          const levelIndex = Math.max(1, Math.min(item.lvl, 10)) - 1;
          if (total[levelIndex]) total[levelIndex].mined += item.value;
        });

        doEmptyLevels(installsData.modifiedItems, levels);
        doEmptyLevels(minersData.modifiedItems, levels);

        Object.keys(levels).forEach((item) => {
          for (let lvl = 1; lvl <= 10; lvl++) {
            levels[item][lvl - 1] = {
              ref: searchItem(item, lvl, installsData.modifiedItems),
              mined: searchItem(item, lvl, minersData.modifiedItems),
            };
          }
        });
        levels.total = total;

        dispatch({ type: BROWSER_LINKS_SUCCESS });
        dispatch({ type: GET_BROWSER_LEVELS, levels });
      } else {
        // Если updateDate false, обрабатываем только те части, которые требуют обновления
        if (updateOpensDiscreteness) {
          const opensData = await fetchAndProcessData(
            'statistics.v2.clicks',
            {
              start_date: period_start,
              end_date: period_end,
              discreteness: opensDiscreteness,
              product_ids: [Product.CT],
            },
            (item) => ({ timestamp: item.timestamp, account_id: +item.advert_id, value: item.value })
          );
          dispatch({ type: BROWSER_OPENS_SUCCESS, opens: opensData.modifiedItems });
        }
        if (updateInstallsDiscreteness) {
          const installsData = await fetchAndProcessData(
            'statistics.referrals',
            { start_date: period_start, end_date: period_end, discreteness: installsDiscreteness },
            (item) => ({ ...item, timestamp: item.timestamp })
          );
          dispatch({ type: BROWSER_INSTALLS_SUCCESS, installs: installsData.modifiedItems });
        }
        if (updateMinersDiscreteness) {
          const minersData = await fetchAndProcessData(
            'statistics.referrals.payments',
            { start_date: period_start, end_date: period_end, group: AffiliateGroup.Browser, minersDiscreteness },
            (item) => ({ ...item, timestamp: item.timestamp, value: item.users })
          );
          dispatch({ type: BROWSER_MINERS_SUCCESS, miners: minersData.modifiedItems });
        }
        if (updateEarningsDiscreteness) {
          const earningsData = await fetchAndProcessData(
            'statistics.referrals.payments',
            {
              start_date: period_start,
              end_date: period_end,
              group: AffiliateGroup.Browser,
              discreteness: earningsDiscreteness,
            },
            (item) => ({ timestamp: item.timestamp ? item.timestamp : undefined, value: +checkE(item.amount) })
          );
          const earningTotal = checkE(earningsData.unmodifiedItems.reduce((acc, item) => acc + item.amount, 0));
          dispatch({ type: BROWSER_EARNING_TOTAL, earnings: earningTotal });
          dispatch({ type: BROWSER_EARNING_SUCCESS, earnings: earningsData.modifiedItems });
        }
      }
    } catch (e) {
      dispatch({ type: BROWSER_LINKS_ERROR });
      console.error(e);
    }
  };
}
