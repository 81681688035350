export const externalLinks = {
  ncw: 'https://ncwallet.net',
  ncwTry: 'https://ncwallet.net/new',
  fee: 'https://jochen-hoenicke.de/queue/#BTC%20(default%20mempool),2w,fee',
  news: 'https://cryptobrowser.site/news',
  faq: 'https://cryptobrowser.site/faq',
  ctFarm: 'https://app.cryptotab.farm/dashboard',
  ncWallet: 'https://app.ncwallet.net',
  lite: 'https://play.app.goo.gl/?link=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dlite.cryptotab.android%26referrer%3Dutm_source%253Daffiliate%2526utm_medium%253Dfooter%2526utm_content%253Dfb66e86c-e1ee-459a-95d5-840713a32812',
  pro: 'https://play.app.goo.gl/?link=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dpro.cryptotab.android%26referrer%3Dutm_source%253Dlanding%2526utm_medium%253Dfooter%2526utm_content%253Dfb66e86c-e1ee-459a-95d5-840713a32812',
  max: 'https://play.app.goo.gl/?link=https%3A//play.google.com/store/apps/details%3Fid%3Dmax.cryptotab.android%26referrer%3Dutm_source%253Dlanding%2526utm_medium%253Dfooter%2526utm_content%253Dfb66e86c-e1ee-459a-95d5-840713a32812',
  terms: `https://cryptobrowser.site/terms`,
  farm: 'https://cryptotab.farm',
  ctb: 'https://cryptobrowser.site/',
  store: 'https://market.ctnft.net',
  click: 'https://cb.click/',
  start: 'https://start.cryptobrowser.site/',
  vpn: 'https://ct-vpn.com/landing_full/',
  nft: 'https://ctnft.net',
  tags: 'https://ctags.app',
  deepFarm:
    'https://cryptofarm.page.link/?link=https%3A%2F%2Fapp.cryptotab.farm%2Fcheckauth&apn=com.cryptofarm&amv=0&ibi=com.cryptofarm&imv=0&isi=1532369824',
  deepFarmPro:
    'https://cryptofarm.page.link/?link=https%3A%2F%2Fapp.cryptotabfarm.com%2F%3Fref%3D.add_generate...&apn=com.ctfarm',
};
