import { useTranslation } from 'react-i18next';

import ErrorImg from '@assets/colored/error-page.svg?react';
import Refresh from '@assets/refresh.svg?react';

import { Button, Card, Container } from '@shared/index';

import styles from './ErrorPageView.module.scss';
export const ErrorPageView = () => {
  const { t } = useTranslation();
  const onClick = () => {
    window.location.reload();
  };
  return (
    <Container vertical={'auto'} horizontal={'auto'} className={styles.container}>
      <Card className={styles.card}>
        <ErrorImg className={styles.icon} />
        <div className={styles.body}>
          <div className={styles.text}>
            <h1 className={styles.heading}>{t('error505.Title')}</h1>
            <p>{t('error505.Description')}</p>
            <Button type="button" className={styles.btn} size={'medium'} Icon={<Refresh />} onClick={onClick}>
              {t('reload.page')}
            </Button>
          </div>
        </div>
      </Card>
    </Container>
  );
};
