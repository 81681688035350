import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { HelmetHead } from '@pages/headersComponents/HelmetHead';

import checkUser from '@service/actions/checkUser';
import { getAuthTokens } from '@service/actions/getAuthTokens';
import getBrowserLand from '@service/actions/getBrowserLand';
import getNFTPromo from '@service/actions/getNFTPromo';
import { getPoolLandings } from '@service/actions/getPoolLandings';
import getPromos from '@service/actions/getPromos';
import { rootBrowserPromo } from '@service/reducers/browserPromoReducer';
import { rootNFTPromo } from '@service/reducers/nftPromoReaducer';
import { PoolLandsState } from '@service/reducers/poolLandingsReducer';
import { rootPromoType } from '@service/reducers/promoReducer';
import { rootUser } from '@service/reducers/userReducer';

import EcoSystemView from './view/EcoSystemView';

export default function EcoSystem() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const store = useSelector(
    (store: {
      user: rootUser;
      promo: rootPromoType;
      browserPromo: rootBrowserPromo;
      nftPromo: rootNFTPromo;
      poolLandings: PoolLandsState;
    }) => store
  );
  useEffect(() => {
    if (!store.user.tokens?.accessToken) {
      dispatch(getAuthTokens(true));
    }
  }, [store.user.tokens]);

  useEffect(() => {
    if (store.user.tokens && !store.user.hasRequested) {
      dispatch(checkUser(true));
    }
    if (store.user.hasRequested) {
      !store.promo.promo_get && !store.promo.promo_request && dispatch(getPromos());
      !store.nftPromo.promo_get && !store.nftPromo.promo_request && dispatch(getNFTPromo());
      !store.browserPromo.land_get && !store.browserPromo.land_request && dispatch(getBrowserLand());
      !store.poolLandings.poolLandsGet && !store.poolLandings.poolLandsGet && dispatch(getPoolLandings());
    }
  }, [dispatch, store.user.tokens, store.user.hasRequested]);

  return (
    <>
      <HelmetHead title={t('Overview')} />
      <EcoSystemView />
    </>
  );
}
