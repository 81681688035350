import { Validation } from '../components/types';

export class Validator {
  static validate(value: string | string[], rules: Validation): string {
    const textValue = Array.isArray(value) ? value.join(' ') : value;

    if (rules.rq && (!value || textValue.trim().length === 0)) {
      return 'This field is required.';
    }

    if (rules.mnc && textValue.length < rules.mnc) {
      return `Minimum ${rules.mnc} characters required.`;
    }
    if (rules.mxc && textValue.length > rules.mxc) {
      return `Maximum ${rules.mxc} characters allowed.`;
    }

    const wordCount = textValue.trim().split(/\s+/).length;
    if (rules.mnw && wordCount < rules.mnw) {
      return `Minimum ${rules.mnw} words required.`;
    }
    if (rules.mxw && wordCount > rules.mxw) {
      return `Maximum ${rules.mxw} words allowed.`;
    }

    const numericValue = parseFloat(textValue);
    if (!isNaN(numericValue)) {
      if (rules.mnn && numericValue < rules.mnn) {
        return `Value must be at least ${rules.mnn}.`;
      }
      if (rules.mxn && numericValue > rules.mxn) {
        return `Value must be no more than ${rules.mxn}.`;
      }
    }

    if (rules.mnd || rules.mxd) {
      const dateValue = new Date(textValue);
      if (rules.mnd && dateValue < new Date(rules.mnd)) {
        return `Date must be on or after ${rules.mnd}.`;
      }
      if (rules.mxd && dateValue > new Date(rules.mxd)) {
        return `Date must be on or before ${rules.mxd}.`;
      }
    }

    if (rules.mnt || rules.mxt) {
      const [hours, minutes] = textValue.split(':').map(Number);
      const timeInMinutes = hours * 60 + minutes;
      const [minHours, minMinutes] = rules.mnt?.split(':').map(Number) || [];
      const minTimeInMinutes = (minHours || 0) * 60 + (minMinutes || 0);
      const [maxHours, maxMinutes] = rules.mxt?.split(':').map(Number) || [];
      const maxTimeInMinutes = (maxHours || 0) * 60 + (maxMinutes || 0);

      if (rules.mnt && timeInMinutes < minTimeInMinutes) {
        return `Time must be on or after ${rules.mnt}.`;
      }
      if (rules.mxt && timeInMinutes > maxTimeInMinutes) {
        return `Time must be on or before ${rules.mxt}.`;
      }
    }

    if (Array.isArray(value)) {
      if (rules.mns && value.length < rules.mns) {
        return `Select at least ${rules.mns} options.`;
      }
      if (rules.mxs && value.length > rules.mxs) {
        return `Select no more than ${rules.mxs} options.`;
      }
    }

    if (rules.rgx) {
      const flags = 's';
      const regex = new RegExp(rules.rgx.p, flags);

      if (!regex.test(textValue)) {
        return rules.rgx.m || 'Invalid format.';
      }
    }
    // if (isOther) {
    //   if (!otherText || otherText.trim().length < 1) {
    //     return `You selected 'Other'. Please specify your answer in the text field.`;
    //   }
    // }
    return '';
  }
}
const emailRegexp = new RegExp(/^([A-Za-z0-9_.-])+@([A-Za-z0-9_.-])+\.[A-Za-z]{2,4}$/);

export const validateEmail = (email: string): boolean => {
  return emailRegexp.test(email);
};
export const ErrorMap: Record<string, string> = {
  bad_email: 'Incorrect email, please enter another and try again',
  default: 'Something went wrong, please try again',
};
