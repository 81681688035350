import { ReactNode } from 'react';

import { AllSvg, CtpLogoSvg } from '@assets/colored';
import { CtSmLogoSvg, FarmSmLogoSvg } from '@assets/index';

import {
  BROWSER_ABOUT,
  BROWSER_DASH,
  BROWSER_LINKS,
  BROWSER_PROMO,
  BROWSER_STATISTIC,
  COMMON_ABOUT,
  FARM_ABOUT,
  FARM_ABOUT_PRO,
  FARM_DASH,
  FARM_LINK_INFO,
  FARM_PROMO,
  FARM_PROMO_PRO,
  FARM_STATISTIC,
  MAIN_LINK_URL,
  MAIN_PROMO_URL,
  MAIN_STATISTIC_URL,
  MAIN_URL,
  POOL_ABOUT,
  POOL_DASH,
  POOL_LINKS,
  POOL_PROMO,
  POOL_STATISTIC,
} from '@utils/links';

export type MobHeaderState = {
  urls: string[];
  baseUrl: string;
  Icon?: ReactNode;
  text: string;
  next: number;
  prev: number;
};
export const mobConfig = [
  {
    urls: [MAIN_URL, MAIN_PROMO_URL, MAIN_STATISTIC_URL, MAIN_LINK_URL, COMMON_ABOUT],
    baseUrl: '/app',
    text: 'All',
    Icon: <AllSvg />,
    next: 1,
    prev: 3,
  },
  {
    urls: [BROWSER_DASH, BROWSER_PROMO, BROWSER_LINKS, BROWSER_ABOUT, BROWSER_STATISTIC],
    baseUrl: '/browser',
    Icon: <CtSmLogoSvg />,
    text: 'CT BROWSER',
    next: 2,
    prev: 0,
  },
  {
    urls: [FARM_DASH, FARM_ABOUT, FARM_ABOUT_PRO, FARM_PROMO, FARM_PROMO_PRO, FARM_STATISTIC, FARM_LINK_INFO],
    baseUrl: '/farm',
    Icon: <FarmSmLogoSvg />,
    text: 'CT FARM',
    next: 3,
    prev: 1,
  },
  {
    urls: [POOL_STATISTIC, POOL_ABOUT, POOL_PROMO, POOL_DASH, POOL_LINKS],
    baseUrl: '/pool',
    Icon: <CtpLogoSvg />,
    text: 'CT POOL',
    next: 0,
    prev: 2,
  },
];
