/* eslint-disable @typescript-eslint/no-explicit-any */
import { JsonRpc, OPEN } from './JsonRpc';
import { JsonRpcApi, JsonRpcApiMethods } from './JsonRpcApi';
import { debug, error } from '../utils/log';

export type Backend = JsonRpc<JsonRpcApi, JsonRpcApiMethods>;

let backend: Backend;

function onRpcOpen(listener: (open: boolean) => void) {
  backend.on('open', async () => {
    debug('rpc', 'open');
    listener(true);
  });
  backend.on('error', async (e) => {
    error('rpc error:', e);
  });
  backend.on('close', async () => {
    debug('rpc', 'close');
    listener(false);
  });
}

export interface Rpc {
  readonly open: boolean;
  readonly auth: boolean;
  readonly interceptors: Backend['interceptors'];

  init(backend: Backend): Promise<void>;

  reopen(sec?: number): Promise<void>;

  authenticate(): void;

  event(name: any, data?: Record<string, unknown>): void;

  close(): Promise<void>;

  status: Backend['status'];

  transmit: Backend['transmit'];
  onmethod: Backend['onmethod'];
  offmethod: Backend['offmethod'];
  on: Backend['on'];
  off: Backend['off'];
}

class DefaultRpc implements Rpc {
  open = false;
  auth = false;
  onErrorCallback = () => undefined;
  refreshUser = () => undefined;
  // will be set in init before app starts
  interceptors!: Backend['interceptors'];

  async init(b: Backend) {
    backend = b;
    this.interceptors = backend.interceptors;
    onRpcOpen((open) => {
      this.open = open;
      if (!open) {
        this.auth = false;
        setTimeout(() => this.reopen(1 + Math.random()), 1000);
      }
    });
    await this.reopen();
  }

  async reopen(sec?: number): Promise<void> {
    if (backend.status !== OPEN) {
      debug('rpc', `connecting ${sec}`);
      try {
        await backend.open();
      } catch {
        const s = sec ?? 1;
        let delay = s * (1 + Math.random());
        if (delay > 60) {
          delay = 60 - s * Math.random();
        }
        await new Promise((resolve) => setTimeout(resolve, s * 1000));
        return this.reopen(delay);
      }
    }
  }

  authenticate(): void {
    this.auth = true;
  }

  async event(_name: string, _data?: Record<string, unknown>): Promise<void> {
    await this.reopen();
    // backend.transmit('analytics.event', { event: name, data }).catch(error)
  }

  async transmit(method: any, params: any): Promise<any> {
    return await backend.transmit(method, params);
  }

  onmethod(method: any, callback: any) {
    backend.onmethod(method, callback);
  }

  offmethod(method: any, callback: any) {
    backend.offmethod(method, callback);
  }

  on(type: any, callback: any) {
    backend.on(type, callback);
  }

  off(type: any, callback: any) {
    backend.off(type, callback);
  }

  async close() {
    await backend.close();
  }

  get status() {
    return backend.status;
  }
}

const defaultRpc = new DefaultRpc();
export const rpc: Rpc = defaultRpc;
