import i18next from 'i18next';
import { useRef, useState } from 'react';

import useClickOutside from '@hooks/useClickOutside';

import DeIcon from './falgs/de.svg';
import EnIcon from './falgs/en.svg';
import EsIcon from './falgs/es.svg';
import FrIcon from './falgs/fr.svg';
import ItIcon from './falgs/it.svg';
import PtIcon from './falgs/pt.svg';
import RuIcon from './falgs/ru.svg';

import styles from './Lang.module.scss';
export { default as EnSvg } from './falgs/en.svg?react';
export { default as RuSvg } from './falgs/ru.svg?react';
export { default as DeSvg } from './falgs/de.svg?react';
export { default as EsSvg } from './falgs/es.svg?react';
export { default as FrSvg } from './falgs/fr.svg?react';
export { default as ItSvg } from './falgs/it.svg?react';
export { default as PtSvg } from './falgs/pt.svg?react';
export type LangProps = {
  footer?: boolean;
  forMobile?: boolean;
};

export type AppLang = 'en' | 'ru' | 'it' | 'pt' | 'fr' | 'de' | 'es';
export default function Lang({ footer, forMobile }: LangProps) {
  const [show, setIsShow] = useState(false);
  const [activeCode, setActiveCode] = useState(
    languages.find((lang) => lang.code === i18next.language) || languages[0]
  );
  const ref = useRef<HTMLDivElement>(null);
  const onClose = () => setIsShow(false);
  useClickOutside(ref, onClose, show);
  i18next.on('languageChanged', (lang) => {
    document.documentElement.lang = lang;
    window.localStorage.setItem('lang', lang);
    setActiveCode(languages.find((it) => it.code === lang) || languages[0]);
  });
  const handleCLick = (lang: LangType) => {
    i18next.changeLanguage(lang.code);
    setIsShow(false);
  };
  return (
    <div
      className={`${styles.lang} ${show && styles.opened} ${footer && styles.footer} ${forMobile && styles.forMobile}`}
      ref={ref}
    >
      <div className={styles.lang__current} onClick={() => setIsShow(!show)}>
        <img src={activeCode?.icon} alt="lang" className="lang__img" />
        {activeCode?.code}
      </div>
      <div className={styles.lang__list}>
        {languages.map((lang: LangType) => (
          <div className={styles.lang__item} key={lang.code} onClick={() => handleCLick(lang)}>
            <img src={lang.icon} alt="lang.code" />
            {lang.code}
          </div>
        ))}
      </div>
    </div>
  );
}

type LangType = { code: string; icon: string };

const languages = [
  {
    code: 'en',
    icon: EnIcon,
  },
  {
    code: 'ru',
    icon: RuIcon,
  },
  {
    code: 'de',
    icon: DeIcon,
  },
  {
    code: 'es',
    icon: EsIcon,
  },
  {
    code: 'fr',
    icon: FrIcon,
  },
  {
    code: 'it',
    icon: ItIcon,
  },
  {
    code: 'pt',
    icon: PtIcon,
  },
];
