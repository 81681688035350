import checkE from '@utils/checkE';

import { POOL_CHANGE_EARNINGS_TOTAL, POOL_STATISTIC_UPDATE } from '@service/constant';
import { PoolStatisticsResponse } from '@service/reducers/poolStatisticReducer';

import { Span } from '@shared/Graph/Tabs';

import { AffiliateGroup, ApiStatisticsRequest, Discreteness, Product } from '@backend/JsonRpcApi';
import { rpc } from '@backend/Rpc';

import { AppDispatch } from 'src/main.tsx';

type getGraphDataArguments = 'link' | 'registration' | 'purchased' | 'earnings';

type PoolApiStatisticsRequest = ApiStatisticsRequest & { group: AffiliateGroup.Pool };

export default function getPoolGraphData(
  type: getGraphDataArguments,
  period_start: string,
  period_end: string,
  discreteness: Discreteness = Span.Day
) {
  return async function (dispatch: AppDispatch) {
    const defaultParams = {
      start_date: period_start,
      end_date: period_end,
      discreteness: discreteness,
    };
    const params: PoolApiStatisticsRequest = {
      ...defaultParams,
      group: AffiliateGroup.Pool,
    };

    const update = (dataType: string, data: PoolStatisticsResponse[]) => {
      dispatch({
        type: POOL_STATISTIC_UPDATE,
        dataType,
        data,
      });
    };

    switch (type) {
      case 'link': {
        rpc.transmit('statistics.v2.clicks', { ...defaultParams, product_ids: [Product.Pool] }).then((res) =>
          update(
            'link',
            res.map((item) => ({
              timestamp: item.timestamp ? item.timestamp * 1000 : undefined,
              account_id: item.advert_id,
              value: item.value,
            }))
          )
        );
        break;
      }
      case 'registration': {
        rpc.transmit('statistics.referrals', defaultParams).then((res) =>
          update(
            'registration',
            res.items.map((item) => ({
              timestamp: item.timestamp ? item.timestamp * 1000 : undefined,
              account_id: item.account_id,
              value: item.value,
            }))
          )
        );
        break;
      }
      case 'purchased': {
        rpc.transmit('statistics.pool_miners', params).then((res) =>
          update(
            'purchased',
            res.map((item) => ({
              timestamp: item.timestamp ? item.timestamp * 1000 : undefined,
              account_id: item.parent_id,
              value: item.count,
            }))
          )
        );

        break;
      }
      case 'earnings': {
        rpc.transmit('statistics.referrals.payments', params).then((res) => {
          update(
            'earnings',
            res.items.map((item) => ({
              timestamp: item.timestamp ? item.timestamp * 1000 : undefined,
              account_id: item.account_id,
              value: parseFloat(item.amount.toFixed(12)),
            }))
          );
          const earningTotal = checkE([...res.items].reduce((acc, item) => acc + item.amount, 0));
          dispatch({ type: POOL_CHANGE_EARNINGS_TOTAL, earningsTotal: earningTotal });
        });
        break;
      }
    }
  };
}
