import BrowserGraph from '@pages/BrowserDashboard/view/components/BrowserGraph';
import MinersGraph from '@pages/BrowserDashboard/view/components/MinersGraph/MinersGraph';

import CommonMobControls from '@shared/Graph/CommonMobControls/CommonMobControls';
import { Container } from '@shared/index';

import { EarningsGraph } from './components/EarningsGraph/EarningsGraph';

export default function BrowserStatisticsView() {
  return (
    <Container vertical={20}>
      <CommonMobControls />
      <BrowserGraph type="open" />
      <BrowserGraph type="install" />
      <MinersGraph />
      <EarningsGraph />
    </Container>
  );
}
