import cls from 'classnames';
import { useCallback, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { CopySvg, CopySuccess } from '@assets/colorless';

import styles from './PromoCard.module.scss';
export type CopyButtonProps = {
  copyText: string;
  noBackground?: boolean;
  className?: string;
};

export default function CopyButton(props: CopyButtonProps) {
  const { copyText, noBackground, className } = props;
  const [check, setCheck] = useState(false);
  const handleClick = useCallback(() => {
    setCheck(true);
    setTimeout(() => setCheck(false), 2000);
  }, []);
  const Mods = cls(
    {
      [styles.copy]: true,
      [styles.copy__check]: check,
      [styles.copy__no]: noBackground,
    },
    className
  );
  return (
    <CopyToClipboard text={copyText} onCopy={handleClick}>
      <div className={Mods}>{check ? <CopySuccess color="white" /> : <CopySvg color="white" />}</div>
    </CopyToClipboard>
  );
}
