import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { MAIN_URL } from '@utils/links';

export const useGoBack = () => {
  const history = useHistory();
  const location = useLocation();

  const goBack = useCallback(() => {
    if (location.key) {
      history.goBack();
    } else {
      history.push(MAIN_URL);
    }
  }, [history]);

  return { goBack };
};
