import Box from './components/Box/Box';
import List from './components/List/List';
import TopInfo from './components/TopInfo/TopInfo';

export default function EcoSystemView() {
  return (
    <Box>
      <TopInfo />
      <List />
    </Box>
  );
}
