import cls from 'classnames';
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ArrowBottomSmSvg, ShareSvg } from '@assets/colorless';

import useClickOutside from '@hooks/useClickOutside';

import { Button } from '@shared/Button/Button';

import { CopyText } from '../CopyText/CopyText';
import { ShareBlock } from '../ShareBlock/ShareBlock';

import styles from './TextCard.module.scss';

export type TextCardProps = {
  text: string;
  currentLinkId?: number;
  productKind?: string;
  handleCopy?: (e: React.ClipboardEvent<HTMLDivElement>) => void;
  url?: string;
  textForCopy?: string;
  replaceContent?: (text: string) => string;
};

const MAX_LENGTH = 320;

export function TextCard({
  text,
  currentLinkId,
  productKind,
  handleCopy,
  url,
  textForCopy,
  replaceContent,
}: TextCardProps) {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const finalText = useMemo(() => {
    return replaceContent ? replaceContent(t(text)) : t(text);
  }, [replaceContent, text, t]);
  return (
    <div className={`${styles.container} ${show && styles.container__show}`} onCopy={handleCopy}>
      <div className={styles.body}>
        <div className={styles.text}>
          <div
            className={styles.text}
            dangerouslySetInnerHTML={{
              __html: finalText
                ? (show ? finalText : finalText.slice(0, MAX_LENGTH) + '...').replace(
                    /{{ref_link}}/g,
                    `${productKind}${currentLinkId}`
                  )
                : finalText,
            }}
          ></div>
        </div>
        {finalText.length > MAX_LENGTH && (
          <div className={styles.more} onClick={() => setShow(!show)}>
            <span>{!show ? t('Learn more') : t('Show Less second')}</span>
            <ArrowBottomSmSvg className={cls([show && styles.active])} />
          </div>
        )}
      </div>
      <div className={styles.row}>
        <Share text={textForCopy ?? finalText} url={url} />
        <CopyText isBtn text={finalText} textForCopy={textForCopy} />
      </div>
    </div>
  );
}

const Share = ({ text, textForCopy, url }: { text: string; textForCopy?: string; url?: string }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);
  useClickOutside(ref, () => setShow(false), show);

  return (
    <Button
      ref={ref}
      type="button"
      size="medium"
      className={styles.share}
      Icon={<ShareSvg />}
      onClick={() => setShow((prev) => !prev)}
    >
      {t('share')}
      {show && (
        <div className={styles.share__list} id="commonAboutShare">
          <ShareBlock msg={textForCopy ?? t(text)} size="l" limit={20} url={url} />
        </div>
      )}
    </Button>
  );
};
