import { useCallback, useState } from 'react';

import { useLockBodyScroll } from '@hooks/useLockBodyScroll';

export function useModal(defaultOpened = false) {
  const [opened, toggleOpened] = useState(defaultOpened);
  const { setLocked } = useLockBodyScroll(defaultOpened);

  const onOpen = useCallback(() => {
    setTimeout(() => {
      toggleOpened(true);
      setLocked(true);
    }, 0);
  }, [opened]);

  const onClose = useCallback(() => {
    toggleOpened(false);
    setLocked(false);
  }, [opened]);

  return {
    opened,
    onOpen,
    onClose,
  };
}
