import React from 'react';
import { useSelector } from 'react-redux';

import { Root } from '@service/reducers';

import DesktopFooter from './DesktopFooter';
import MobileFooter from './MobileFooter';

export default function Footer() {
  const { isMobile } = useSelector((root: Root) => root.system);

  if (isMobile) {
    return <MobileFooter />;
  }
  return <DesktopFooter />;
}
