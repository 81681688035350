import dayjs from 'dayjs';

import { Span } from '@shared/Graph/Tabs';

import { Discreteness } from '@backend/JsonRpcApi';

import {
  BROWSER_INSTALLS_SUCCESS,
  BROWSER_LINKS_ERROR,
  BROWSER_LINKS_REQUEST,
  BROWSER_LINKS_SUCCESS,
  BROWSER_MINERS_SUCCESS,
  BROWSER_OPENS_SUCCESS,
  GET_BROWSER_LEVELS,
  SET_BROWSER_PERIOD,
  BROWSER_CHANGE_DISCREETNESS_OPENS,
  BROWSER_CHANGE_DISCREETNESS_INSTALLS,
  BROWSER_CHANGE_DISCREETNESS_MINERS,
  BROWSER_CHANGE_DISCREETNESS_EARNING,
  BROWSER_EARNING_SUCCESS,
  BROWSER_EARNING_TOTAL,
  BROWSER_CHANGE_TABLE_INSTALLS,
  BROWSER_CHANGE_TABLE_OPENS,
  BROWSER_CHANGE_TABLE_MINERS,
} from '../constant';

const initialState = {
  period_start: dayjs(new Date()).add(-1, 'month').format('YYYY-MM-DD'),
  period_end: dayjs(new Date()).format('YYYY-MM-DD'),

  stats_links: [],
  stats_links_request: false,
  stats_links_error: false,
  stats_links_get: false,

  installsDiscreteness: Span.Day,
  opensDiscreteness: Span.Day,
  minersDiscreteness: Span.Day,
  earningsDiscreteness: Span.Day,
  earningTotal: 0,
  downloads: [],
  installs: [],
  opens: [],
  miners: [],
  earnings: [],
  tableInstalls: [],
  tableOpens: [],
  tableMiners: [],
  levels: {},
  get_levels: false,
};

type ActionType = {
  type: string;
  period_start?: string;
  period_end?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  levels?: any;
  miners?: BrowserMinerResp[];
  opens?: BrowserShortResp[];
  downloads?: BrowserResp[];
  installs?: BrowserShortResp[];
  earnings?: EarningsStatisticsResponse[];
  earningTotal?: number;
  span: Discreteness;
  payload?: BrowserMinerResp[] | BrowserShortResp[];
};

export type BrowserResp = {
  timestamp?: number;
  account_id: number;
  lvl: number;
  value: number;
};

export type BrowserShortResp = {
  timestamp: number;
  account_id: number;
  value: number;
};

export type BrowserMinerResp = BrowserResp & { amount: number };

export type EarningsStatisticsResponse = {
  timestamp: number | undefined;
  value: number;
};

export type BrowserMiners = {
  link_id: number;
  timestamp: number;
  level_1: number;
  level_2: number;
  level_3: number;
  level_4: number;
  level_5: number;
  level_6: number;
  level_7: number;
  level_8: number;
  level_9: number;
  level_10: number;
  value: number;
};

export type rootBrowserGraph = Omit<
  typeof initialState,
  'miners' | 'opens' | 'installs' | 'downloads' | 'levels' | 'earnings' | 'tableInstalls' | 'tableOpens' | 'tableMiners'
> & {
  miners: BrowserMinerResp[];
  opens: BrowserShortResp[];
  installs: BrowserResp[];
  earnings: EarningsStatisticsResponse[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  levels: any;
  tableInstalls: BrowserResp[];
  tableOpens: BrowserShortResp[];
  tableMiners: BrowserMinerResp[];
};

export default function browserStateReducer(state = initialState, action: ActionType) {
  switch (action.type) {
    case SET_BROWSER_PERIOD: {
      return {
        ...state,

        period_start: action.period_start,
        period_end: action.period_end,
        stats_links_get: false,
        graph_links_get: false,
        miners_get: false,
      };
    }
    case BROWSER_LINKS_REQUEST: {
      return {
        ...state,
        stats_links_get: false,
        stats_links_request: true,
        stats_links_error: false,
        get_levels: false,
      };
    }
    case BROWSER_LINKS_ERROR: {
      return {
        ...state,
        stats_links_request: false,
        stats_links_error: true,
      };
    }
    case BROWSER_LINKS_SUCCESS: {
      return {
        ...state,
        stats_links_request: false,
        stats_links_error: false,
        stats_links_get: true,
      };
    }

    case BROWSER_MINERS_SUCCESS: {
      return {
        ...state,
        miners: action.miners,
      };
    }

    case BROWSER_OPENS_SUCCESS: {
      return {
        ...state,
        opens: action.opens,
      };
    }
    case BROWSER_INSTALLS_SUCCESS: {
      return {
        ...state,
        installs: action.installs,
      };
    }
    case GET_BROWSER_LEVELS: {
      return {
        ...state,
        levels: action.levels,
        get_levels: true,
      };
    }
    case BROWSER_CHANGE_DISCREETNESS_OPENS: {
      return {
        ...state,
        opensDiscreteness: action.span,
        stats_links_get: false,
        graph_links_get: false,
        miners_get: false,
      };
    }
    case BROWSER_CHANGE_DISCREETNESS_INSTALLS: {
      return {
        ...state,
        installsDiscreteness: action.span,
        stats_links_get: false,
        graph_links_get: false,
        miners_get: false,
      };
    }
    case BROWSER_CHANGE_TABLE_INSTALLS: {
      return {
        ...state,
        tableInstalls: action.payload,
      };
    }
    case BROWSER_CHANGE_TABLE_OPENS: {
      return {
        ...state,
        tableOpens: action.payload,
      };
    }
    case BROWSER_CHANGE_TABLE_MINERS: {
      return {
        ...state,
        tableMiners: action.payload,
      };
    }
    case BROWSER_CHANGE_DISCREETNESS_MINERS: {
      return {
        ...state,
        minersDiscreteness: action.span,
        stats_links_get: false,
        graph_links_get: false,
        miners_get: false,
      };
    }
    case BROWSER_CHANGE_DISCREETNESS_EARNING: {
      return {
        ...state,
        earningsDiscreteness: action.span,
      };
    }
    case BROWSER_EARNING_TOTAL: {
      return {
        ...state,
        earningTotal: action.earnings,
      };
    }
    case BROWSER_EARNING_SUCCESS: {
      return {
        ...state,
        earnings: action.earnings,
      };
    }

    default:
      return state;
  }
}
