import Lottie from 'lottie-react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { logo, logo_dark } from '@utils/animation';
import { COMMON_ABOUT } from '@utils/links';

import { Root } from '@service/reducers';

import CommonNavMenu from './CommonNavMenu';
import TabsButtons from '../Subheader/TabsButtons';

import styles from './Header.module.scss';

export default function DesktopHeader() {
  const location = useLocation();
  const { isDarkTheme } = useSelector((store: Root) => store.system);
  return (
    <div className={` ${location.pathname === COMMON_ABOUT && styles.container__sticky} ${styles.container}`}>
      <Link to={{ pathname: COMMON_ABOUT }} className={styles.logo__link}>
        <Lottie animationData={isDarkTheme ? logo_dark : logo} loop={false} className={styles.logo} />
      </Link>
      <TabsButtons isLight />
      <CommonNavMenu />
    </div>
  );
}
