import { useTranslation } from 'react-i18next';

import { DownloadIcon } from '@assets/colorless';

import styles from './CardImage.module.scss';
export type CardImageProps = {
  src: string;
  active: string;
};

export function CardImage({ src, active }: CardImageProps) {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <img
        src={src}
        className={`${styles.img} ${styles[active]}`}
        style={{ animation: 'fadeEffect 1s' }}
        loading={'lazy'}
      />
      <a href={src} target="_blank" download className={styles.hover} rel="noreferrer">
        <DownloadIcon className={styles.icon} />
        {t('Download image')}
      </a>
    </div>
  );
}
