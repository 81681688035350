import dayjs from 'dayjs';

import checkE from '@utils/checkE';

import { LinkAcc } from '@service/reducers/balanceReducer';

import { rpc } from '@backend/Rpc';

import { AppDispatch } from 'src/main.tsx';

import {
  BALANCE_ERROR,
  BALANCE_REQUEST,
  BALANCE_SUCCESS,
  GET_AFF_BALANCE,
  GET_ALL_BALANCE,
  GET_ALL_LINKS_INFO,
  GET_CURRENCIES,
  GET_FARM_BALACNE,
  GET_HSH_BALANCE,
  GET_NFT_BALANCE,
  GET_POOL_BALANCE,
} from '../constant';

export default function getBalances() {
  return async function (dispatch: AppDispatch) {
    try {
      dispatch({ type: BALANCE_REQUEST });
      const linksMap = new Map<number, LinkAcc>();
      const links = await rpc.transmit('users.me.links', {});
      const info = await rpc.transmit('users.me.links.balances', {});
      links.forEach((l) => {
        linksMap.set(l.id, l as LinkAcc);
      });
      const balanceHSH = await rpc.transmit('wallets.balance', {});
      const balancePendHSH = await rpc.transmit('wallets.pending.balance', {});

      const currencies = await rpc.transmit('currencies', {});

      if (info.length > 0) {
        const balanceTemplate = {
          affBalance: {
            mined: 0,
            referral: 0,
            total: 0,
            balance: 0,
            locked: 0,
          },
          farmBalance: {
            mined: 0,
            referral: 0,
            total: 0,
            balance: 0,
            locked: 0,
          },
          nftBalance: {
            total: 0,
            balance: 0,
            locked: 0,
          },
          poolBalance: {
            pool: 0,
            nft: 0,
            balance: 0,
            locked: 0,
            total: 0,
            affiliate: 0,
          },
        };

        const commonBalanceInfo = info.reduce(
          (acc, item) => ({
            affBalance: {
              mined: Number(acc.affBalance.mined) + Number(item.ct?.earnings?.mined || 0),
              referral: Number(acc.affBalance.referral) + Number(item.ct?.earnings?.ref || 0),
              total: 0,
              locked: Number(acc.affBalance.locked) + Number(item.ct?.locked || 0),
              balance: Number(acc.affBalance.balance) + Number(item.ct?.total || 0),
            },
            farmBalance: {
              mined: Number(acc.farmBalance.mined) + Number(item.farm?.earnings?.mined || 0),
              referral: Number(acc.farmBalance.referral) + Number(item.farm?.earnings?.ref || 0),
              total: 0,
              locked: Number(acc.farmBalance.locked) + Number(item.farm?.locked || 0),
              balance: Number(acc.farmBalance.balance) + Number(item.farm?.total || 0),
            },
            nftBalance: {
              total: Number(acc.nftBalance.total) + Number(item.nft?.earnings?.ref || 0),
              balance: Number(acc.nftBalance.balance) + Number(item.nft?.total || 0),
              locked: Number(acc.nftBalance.locked) + Number(item.nft?.locked || 0),
            },
            poolBalance: {
              pool: Number(acc.poolBalance.pool) + Number(item.ct_pool?.earnings?.mined || 0),
              nft: Number(acc.poolBalance.nft) + Number(item.ct_nft?.earnings?.mined || 0),
              affiliate:
                Number(acc.poolBalance.affiliate) +
                Number(item.ct_nft?.earnings?.ref || 0) +
                Number(item.ct_pool?.earnings?.ref || 0) +
                Number(item.eggs?.earnings?.ref || 0),
              total:
                Number(acc.poolBalance.total) +
                Number(item.ct_nft?.earnings?.mined || 0) +
                Number(item.ct_nft?.earnings?.ref || 0) +
                Number(item.ct_pool?.earnings?.ref || 0) +
                +Number(item.ct_pool?.earnings?.mined || 0) +
                Number(item.eggs?.earnings?.ref || 0),
              balance:
                Number(acc.poolBalance.balance) +
                Number(item.ct_pool?.total || 0) +
                Number(item.ct_nft?.total || 0) +
                Number(item.eggs?.total || 0),
              locked:
                Number(acc.poolBalance.locked) +
                Number(item.ct_nft?.locked || 0) +
                Number(item.ct_pool?.locked || 0) +
                Number(item.eggs?.locked || 0),
            },
          }),
          balanceTemplate
        );

        dispatch({
          type: GET_AFF_BALANCE,
          balance: {
            mined: checkE(commonBalanceInfo.affBalance.mined),
            referral: checkE(commonBalanceInfo.affBalance.referral),
            total: checkE(commonBalanceInfo.affBalance.mined + commonBalanceInfo.affBalance.referral),
            balance: checkE(commonBalanceInfo.affBalance.balance),
          },
        });
        dispatch({
          type: GET_FARM_BALACNE,
          balance: {
            mined: checkE(commonBalanceInfo.farmBalance.mined),
            referral: checkE(commonBalanceInfo.farmBalance.referral),
            total: checkE(commonBalanceInfo.farmBalance.mined + commonBalanceInfo.farmBalance.referral),
            balance: checkE(commonBalanceInfo.farmBalance.balance),
          },
        });
        dispatch({
          type: GET_NFT_BALANCE,
          nftBalance: {
            total: checkE(commonBalanceInfo.nftBalance.total),
            balance: checkE(commonBalanceInfo.nftBalance.balance),
          },
        });
        dispatch({
          type: GET_ALL_BALANCE,
          balance: {
            mined: checkE(
              commonBalanceInfo.farmBalance.mined +
                commonBalanceInfo.affBalance.mined +
                commonBalanceInfo.poolBalance.pool +
                commonBalanceInfo.poolBalance.nft
            ),
            referral: checkE(
              commonBalanceInfo.farmBalance.referral +
                commonBalanceInfo.affBalance.referral +
                commonBalanceInfo.poolBalance.affiliate
            ),
            total: checkE(
              commonBalanceInfo.affBalance.mined +
                commonBalanceInfo.affBalance.referral +
                commonBalanceInfo.farmBalance.mined +
                commonBalanceInfo.farmBalance.referral +
                commonBalanceInfo.nftBalance.total +
                commonBalanceInfo.poolBalance.total
            ),
            balance: checkE(
              commonBalanceInfo.affBalance.balance +
                commonBalanceInfo.farmBalance.balance +
                commonBalanceInfo.nftBalance.balance +
                commonBalanceInfo.poolBalance.balance
            ),
            wihdraw_balance: checkE(
              commonBalanceInfo.affBalance.balance +
                commonBalanceInfo.farmBalance.balance +
                commonBalanceInfo.nftBalance.balance +
                commonBalanceInfo.poolBalance.balance -
                commonBalanceInfo.affBalance.locked -
                commonBalanceInfo.farmBalance.locked -
                commonBalanceInfo.nftBalance.locked -
                commonBalanceInfo.poolBalance.locked
            ),
          },
        });
        dispatch({
          type: GET_POOL_BALANCE,
          balance: {
            nft: checkE(commonBalanceInfo.poolBalance.nft),
            pool: checkE(commonBalanceInfo.poolBalance.pool),
            affiliate: checkE(commonBalanceInfo.poolBalance.affiliate),
            balance: checkE(commonBalanceInfo.poolBalance.balance),
          },
        });
      }
      dispatch({
        type: GET_ALL_LINKS_INFO,
        allLinksInfo: info,
        linksMap,
      });

      dispatch({
        type: GET_CURRENCIES,
        currencies,
      });

      const commonBalanceHSH = balanceHSH?.find((el) => el.group === 'hsh') || {
        total: 0,
        accrued: 0,
      };
      let nextPaymentPending;

      if (balancePendHSH.earliest_payment_at) {
        const start = +dayjs();
        const end = +dayjs(balancePendHSH.earliest_payment_at * 1000);
        const diff = end - start;

        const days = Math.floor(diff / 86400000);
        const hours = Math.floor((diff - days * 86400000) / 3600000);
        const mins = (diff - days * 86400000 - hours * 3600000) / 60000;

        nextPaymentPending = { days, hours, mins: mins < 1 ? Math.ceil(mins) : Math.floor(mins) };
      }

      dispatch({
        type: GET_HSH_BALANCE,
        hshBalance: {
          current: Number(+commonBalanceHSH.total + +(balancePendHSH?.income || 0) - +(balancePendHSH?.outgoing || 0)),
          accrued: Number(
            +commonBalanceHSH.accrued + +(balancePendHSH?.income || 0) - +(balancePendHSH?.outgoing || 0)
          ),
          income: Number(balancePendHSH?.income || 0),
          outgoing: Number(balancePendHSH?.outgoing || 0),
          nextPayment: nextPaymentPending,
        },
      });

      dispatch({
        type: BALANCE_SUCCESS,
        links: links.filter((item) => !item.is_banned),
      });
    } catch (e) {
      dispatch({ type: BALANCE_ERROR });
      console.log(e);
    }
  };
}
