export const FEBRUARY_PROMO_INFO_URL = 'https://cryptobrowser.site/promo/campfire?utm_source=cta&utm_medium=bnrcamp';
export const FEBRUARY_PROMO_END_DATE = '2025-04-15T00:00:00.000Z';
export const FEBRUARY_PROMO_BANNER_ID = 'FebruaryPromoBannerClosedAt';

export const getIsFebruaryPromo = (): boolean => {
  const isFebruaryPromoClosedAt = localStorage.getItem(FEBRUARY_PROMO_BANNER_ID);

  if (isFebruaryPromoClosedAt) return false;

  const currentDate = new Date();
  const februaryPromoDate = new Date(FEBRUARY_PROMO_END_DATE);
  const isFebruaryPromo = currentDate < februaryPromoDate;

  if (!isFebruaryPromo) {
    console.warn('FebruaryPromo is over, please remove the FebruaryPromo banner', 'color: red; font-size: 20px;');
  }

  return isFebruaryPromo;
};
