import dayjs from 'dayjs';

import { rpc } from '@backend/Rpc';

import { AppDispatch } from 'src/main.tsx';

import { NEWS_ERROR, NEWS_REQUEST, NEWS_SUCCESS } from '../constant';

export default function getNews(lang: string) {
  return async function (dispatch: AppDispatch) {
    try {
      dispatch({ type: NEWS_REQUEST });
      const tips = await rpc.transmit('content.pages', {
        type: 'news',
        language: lang,
        limit: 500,
        offset: 0,
      });
      const digest = await rpc.transmit('content.pages', {
        type: 'digest',
        language: lang,
        limit: 500,
        offset: 0,
      });

      const tipsItems = tips?.items ?? [];
      const digestItems = digest?.items ?? [];
      const news = [
        ...tipsItems.map((i) => ({
          ...i,
          type: 'tips',
          url: getDigestUrlByPlacing(i.placing),
        })),
        ...digestItems.map((i) => ({
          ...i,
          type: 'digest',
          url: getDigestUrlByPlacing(i.placing),
        })),
      ].sort((a, b) => (dayjs(a.published_at).valueOf() > dayjs(b.published_at).valueOf() ? -1 : 1));
      dispatch({ type: NEWS_SUCCESS, news, lang });
    } catch (e) {
      console.log(e);
      dispatch({ type: NEWS_ERROR });
    }
  };
}

const newsOriginMap = {
  cryptobrowser: 'https://cryptobrowser.site/news/',
  ctnft: 'https://ctnft.net/news/',
  ctfarm_pro: 'https://cryptotabfarm.com/news/',
  ctfarm: 'https://cryptotab.farm/news/',
  ctnft_industry_news: 'https://ctnft.net/digest/',
  profarm_industry_news: 'https://cryptotabfarm.com/news/',
  farm_industry_news: 'https://cryptotab.farm/news/',
  browser_industry_news: 'https://cryptobrowser.site/digest/',
  industry_news: 'https://cryptobrowser.site/digest/',
};

const getDigestUrlByPlacing = (placing: string[]) => {
  if (!placing) {
    return newsOriginMap.cryptobrowser;
  }
  if (placing.length === 1) {
    return newsOriginMap[placing[0] as keyof typeof newsOriginMap];
  } else {
    const filteredPlacing = placing.filter((i) => i !== 'industry_news');
    return newsOriginMap[filteredPlacing[0] as keyof typeof newsOriginMap];
  }
};
