/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  GET_POOL_LEVELS,
  POOL_LINKS_ERROR,
  POOL_LINKS_REQUEST,
  POOL_LINKS_SUCCESS,
  POOL_INSTALLS_SUCCESS,
  POOL_MINERS_SUCCESS,
  POOL_OPENS_SUCCESS,
  POOL_STATISTIC_UPDATE,
  SET_POOL_PERIOD,
  POOL_CHANGE_DISCREETNESS_LINKS,
  POOL_CHANGE_DISCREETNESS_REGISTRATION,
  POOL_CHANGE_DISCREETNESS_PURCHASED,
  POOL_CHANGE_DISCREETNESS_EARNINGS,
  POOL_CHANGE_EARNINGS_TOTAL,
} from '@service/constant';

import { Span } from '@shared/Graph/Tabs';

import { Discreteness } from '@backend/JsonRpcApi';

export type PoolStatisticsResponse = {
  timestamp: number | undefined;
  account_id: string | number;
  value: number;
};
type DataType = 'link' | 'registration' | 'purchased' | 'earnings';
export type PoolDataType = Record<DataType, PoolStatisticsResponse[]>;
export type rootPoolStatisticState = {
  data: PoolDataType;
  stats_links_request: boolean;
  stats_links_error: boolean;
  stats_links_get: boolean;
  downloads: any;
  installs: any;
  opens: any;
  miners: any;
  levels: any;

  get_levels: boolean;
  linksDiscreteness: Discreteness;
  registrationDiscreteness: Discreteness;
  purchasedDiscreteness: Discreteness;
  earningsDiscreteness: Discreteness;
  earningsTotal: number;
};

const initialState: rootPoolStatisticState = {
  data: {
    link: [],
    registration: [],
    purchased: [],
    earnings: [],
  },
  stats_links_request: false,
  stats_links_error: false,
  stats_links_get: false,
  downloads: [],
  installs: [],
  opens: [],
  miners: [],
  levels: {},
  get_levels: false,

  linksDiscreteness: Span.Day,
  registrationDiscreteness: Span.Day,
  purchasedDiscreteness: Span.Day,
  earningsDiscreteness: Span.Day,
  earningsTotal: 0,
};

type Action = {
  type: string;
  dataType: DataType;
  data: PoolStatisticsResponse[];
  period_start?: string;
  period_end?: string;
  levels?: any;
  miners?: PoolMinerResp[];
  opens?: PoolShortResp[];
  downloads?: PoolResp[];
  installs?: PoolShortResp[];
  payload?: PoolShortResp[] | PoolResp[];
  span: Discreteness;
  earningsTotal?: number;
};

export type PoolResp = {
  timestamp?: number;
  account_id: number;
  lvl: number;
  value: number;
};

export type PoolShortResp = {
  timestamp: number;
  account_id: number;
  value: number;
};

export type PoolMinerResp = PoolResp & { amount: number };

export default function poolStatisticReducer(state = initialState, action: Action) {
  switch (action.type) {
    case POOL_STATISTIC_UPDATE: {
      const oldData = state.data || {};
      return {
        ...state,
        data: {
          ...oldData,
          [action.dataType]: action.data,
        },
      };
    }
    case SET_POOL_PERIOD: {
      return {
        ...state,
        period_start: action.period_start,
        period_end: action.period_end,
        stats_links_get: false,
        get_levels: false,
      };
    }
    case POOL_LINKS_REQUEST: {
      return {
        ...state,
        stats_links_get: false,
        stats_links_request: true,
        stats_links_error: false,
      };
    }
    case POOL_LINKS_ERROR: {
      return {
        ...state,
        stats_links_request: false,
        stats_links_error: true,
      };
    }
    case POOL_LINKS_SUCCESS: {
      return {
        ...state,
        stats_links_request: false,
        stats_links_error: false,
        stats_links_get: true,
      };
    }
    case POOL_MINERS_SUCCESS: {
      return {
        ...state,
        miners: action.miners,
      };
    }

    case POOL_OPENS_SUCCESS: {
      return {
        ...state,
        opens: action.opens,
      };
    }
    case POOL_INSTALLS_SUCCESS: {
      return {
        ...state,
        installs: action.installs,
      };
    }
    case GET_POOL_LEVELS: {
      return {
        ...state,
        levels: action.levels,
        get_levels: true,
      };
    }
    case POOL_CHANGE_DISCREETNESS_LINKS: {
      return {
        ...state,
        linksDiscreteness: action.span,
      };
    }
    case POOL_CHANGE_DISCREETNESS_REGISTRATION: {
      return {
        ...state,
        registrationDiscreteness: action.span,
      };
    }
    case POOL_CHANGE_DISCREETNESS_PURCHASED: {
      return {
        ...state,
        purchasedDiscreteness: action.span,
      };
    }
    case POOL_CHANGE_DISCREETNESS_EARNINGS: {
      return {
        ...state,
        earningsDiscreteness: action.span,
      };
    }
    case POOL_CHANGE_EARNINGS_TOTAL: {
      return {
        ...state,
        earningsTotal: action.earningsTotal,
      };
    }

    default:
      return state;
  }
}
