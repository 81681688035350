import cn from 'classnames';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { BtcDecorImg, BtcDecorRetinaImg, HshDecorImg, HshDecorRetinaImg } from '@assets/img';

import { WithdrawalSwitchTabs } from '@pages/Withdrawal/view/WithdrawalSwitchTabs';

import { useBreakpoints } from '@hooks/useBreakpoints';

import { Card, Container } from '@shared/index';
import { LoadSpinner } from '@shared/Loader';
import { Pagination } from '@shared/Pagination/Pagination';

import { Withdrawal } from '@backend/JsonRpcApi';

import { BalanceTable } from './components/BalanceTable/BalanceTable';
import HistoryBanner from './components/HistoryBanner/HistoryBanner';
import { ModalNetworkError } from './components/Modal/ModalNetworkError';

import styles from './BrowserBalanceView.module.scss';

export type BrowserBalanceViewProps = {
  items: Withdrawal[];
  index: number;
  perPage: number;
  limit: number;
  isNextWithdrawalsLoading: boolean;
  isBitcoin: boolean;
  setIsBitcoin: (val: boolean) => void;
  hasRequested: boolean;
  handlePageChange: (page: number) => void;
  currentPage: number;
  totalPage: number;
  isLoading: boolean;
  handleCloseModal: () => void;
  showModal: boolean;
  isEmpty: boolean;
};

export const BrowserBalanceView: FC<BrowserBalanceViewProps> = (props) => {
  const {
    items,
    limit,
    perPage,
    isNextWithdrawalsLoading,
    isBitcoin,
    setIsBitcoin,
    hasRequested,
    handlePageChange,
    currentPage,
    totalPage,
    isLoading,
    handleCloseModal,
    showModal,
    isEmpty,
  } = props;

  const { isMobile } = useBreakpoints();
  const paginationButtonsMax = isMobile ? 2 : 3;

  const { t } = useTranslation();

  useEffect(() => {
    const storyElement = document.getElementById('story');
    if (storyElement) {
      const { top } = storyElement.getBoundingClientRect();
      window.scrollTo(0, top - 180);
    }
  }, []);

  return (
    <>
      <div className={styles.browserBalance}>
        <div className={styles.browserBalance__decoration}>
          {isBitcoin ? (
            <img
              src={BtcDecorImg}
              srcSet={BtcDecorRetinaImg + ' 2x'}
              className={styles.decoration__img}
              alt="background"
            />
          ) : (
            <img
              src={HshDecorImg}
              srcSet={HshDecorRetinaImg + ' 2x'}
              className={styles.decoration__img}
              alt="background"
            />
          )}
        </div>
        <Container>
          <Card
            className={cn(styles.card, totalPage === 0 || (!(limit > perPage) && styles.cardNoPagination))}
            headerClass={styles.card_header}
            footerClass={styles.card_footer}
            TitleElement={<CardTitle title={t('Payment History')} isBitcoin={isBitcoin} setIsBitcoin={setIsBitcoin} />}
            id="story"
            footer={
              !isMobile &&
              limit > perPage && (
                <Pagination
                  paginationButtonsMax={paginationButtonsMax}
                  perPage={perPage}
                  index={currentPage}
                  isNextWithdrawalsLoading={isNextWithdrawalsLoading}
                  totalPage={totalPage}
                  handleChangePage={handlePageChange}
                  limit={limit}
                />
              )
            }
          >
            {!hasRequested ? (
              <div className={styles.browserBalance__card__loader}>
                <LoadSpinner />
              </div>
            ) : (
              <BalanceTable
                history={items}
                isBitcoin={isBitcoin}
                perPage={perPage}
                currentPage={currentPage}
                isEmpty={isEmpty}
              />
            )}
            {hasRequested && isLoading && isMobile && (
              <div className={styles.browserBalance__card__loader}>
                <LoadSpinner />
              </div>
            )}
          </Card>
          {items.length === 0 && hasRequested && <HistoryBanner />}
        </Container>
      </div>
      {showModal && <ModalNetworkError onClose={handleCloseModal} />}
    </>
  );
};

const CardTitle = ({
  title,
  isBitcoin,
  setIsBitcoin,
}: {
  title: string;
  isBitcoin: boolean;
  setIsBitcoin: (val: boolean) => void;
}) => {
  return (
    <div className={styles.browserBalance__cardHeader}>
      <h2 className={styles.browserBalance__cardTitle}>{title}</h2>
      <WithdrawalSwitchTabs
        className={styles.browserBalance__cardSwitch}
        isBitcoin={isBitcoin}
        setIsBitcoin={setIsBitcoin}
      />
    </div>
  );
};
