import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { product } from '@utils/productName';

import { useNewsLoad } from '@hooks/useNewsLoad';

import getBrowserDashboardInfo from '@service/actions/getBrowserDashboardInfo';
import { Root } from '@service/reducers';

import { BrowserSubheader } from '../headersComponents/BrowserSubheader';
import { HelmetHead } from '../headersComponents/HelmetHead';
import { BrowserDashboardView } from './view/BrowserDashboardView';

export default function BrowserDashboard() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const earningsDiscreteness = useSelector((store: Root) => store.browserGraph.earningsDiscreteness);
  const { period_start, period_end } = useSelector((store: Root) => store.browserGraph);
  const isFirst = useRef(true);
  useEffect(() => {
    !isFirst.current && dispatch(getBrowserDashboardInfo(period_start, period_end, earningsDiscreteness, false));
  }, [dispatch, earningsDiscreteness]);
  useEffect(() => {
    if (isFirst.current) {
      isFirst.current = false;
    }
    dispatch(getBrowserDashboardInfo(period_start, period_end, earningsDiscreteness, true));
  }, [dispatch, period_start, period_end]);

  useNewsLoad();

  return (
    <>
      <HelmetHead title={t('Dashboard')} product={product.ct} />
      <BrowserSubheader />
      <BrowserDashboardView />
    </>
  );
}
