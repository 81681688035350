import { HTMLAttributes } from 'react';

import { default as NcwLogoImg } from './ncw-logo.png';
export { default as BtcDecorImg } from './btc-decor.png';
export { default as BtcDecorRetinaImg } from './btc-decor@2x.png';
export { default as HshDecorImg } from './hsh-decor.png';
export { default as HshDecorRetinaImg } from './hsh-decor@2x.png';
export { default as EggsImg } from './eggs-img.png';

export const NcwIcon = (props: HTMLAttributes<HTMLImageElement>) => (
  <img src={NcwLogoImg} width={20} height={20} {...props} alt="NCW Logo" />
);
