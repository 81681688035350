import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import farmIcon2000 from '@assets/farm-pro-icon-2000.png';
import farmIcon500 from '@assets/farm-pro-icon-500.png';
import farmIcon from '@assets/farm-pro-icon.svg';
import logo2000 from '@assets/farm-pro-logo-2000.png';
import logo500 from '@assets/farm-pro-logo-500.png';
import whiteLogo from '@assets/farm-pro-logo-white.svg';
import logo from '@assets/farm-pro-logo.svg';
import ui2x from '@assets/farm-pro-ui-preview-retina.png';
import ui from '@assets/farm-pro-ui-preview.png';

import { Root } from '@service/reducers';

import { PromoSection, BrandCard, BrandContainer } from '@shared/index';

import archives from '../../../../../archives/ct-farm-pro_ui-screens.zip';

const imgIconStyle = {
  width: 80,
  height: 80,
};

const imgLogostyle = {
  width: 205,
  height: 40,
};

export default function BrandBlock() {
  const { t } = useTranslation();
  const { isDarkTheme } = useSelector((store: Root) => store.system);
  return (
    <PromoSection
      title={t('Brand resources')}
      text={t('Copy icon, logo, interface screenshots and create your unique promotional content')}
    >
      <BrandContainer>
        <BrandCard
          title={t('Product Icon')}
          src={farmIcon}
          imgStyle={imgIconStyle}
          list={[
            { res: '(.png)', title: t('500px'), href: farmIcon500 },
            { res: '(.png)', title: t('2000px'), href: farmIcon2000 },
            { res: '(.svg)', title: t('Vector'), href: farmIcon },
          ]}
        />
        <BrandCard
          title={t('Product Logo')}
          src={isDarkTheme ? whiteLogo : logo}
          imgStyle={imgLogostyle}
          list={[
            { res: '(.png)', title: t('500px'), href: logo500 },
            { res: '(.png)', title: t('2000px'), href: logo2000 },
            { res: '(.svg)', title: t('Vector'), href: logo },
          ]}
        />
        <BrandCard
          title={t('UI Screens')}
          src={ui}
          srcSet={ui2x}
          list={[{ res: '(.zip)', title: t('Download UI Screens'), href: archives }]}
        />
      </BrandContainer>
    </PromoSection>
  );
}
