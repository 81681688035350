import { product } from '@utils/productName';

import { HelmetHead } from '@pages/headersComponents/HelmetHead';
import PoolSubheader from '@pages/headersComponents/PoolSubheader';
import PoolStatisticView from '@pages/PoolStatistic/view/PoolStatisticView';

export default function PoolStatistic() {
  return (
    <>
      <HelmetHead title={'Statistic'} product={product.pool} />
      <PoolSubheader />
      <PoolStatisticView />
    </>
  );
}
