import { useTranslation } from 'react-i18next';

import CommonPromoView from '@pages/CommonPromo/view/CommonPromoView';
import { AllSubheader } from '@pages/headersComponents/AllSubheader';
import { HelmetHead } from '@pages/headersComponents/HelmetHead';

export default function CommonPromo() {
  const { t } = useTranslation();
  return (
    <>
      <HelmetHead title={t('Promo')} />
      <AllSubheader />
      <CommonPromoView />
    </>
  );
}
