import { WithdrawalWallets, Withdrawal } from '@backend/JsonRpcApi';

const getNumberFromStatus = (status: string) => {
  switch (status) {
    case 'created':
      return 1;
    case 'canceled':
      return 3;
    case 'rejected':
      return 4;
    case 'pending':
      return 7;
    case 'completed':
      return 9;
    case 'confirmed':
      return 2;
    case 'sent':
      return 6;
    default:
      return 8;
  }
};

export const updateDataToBTC = (item: WithdrawalWallets): Withdrawal => {
  return {
    id: item.id,
    request_date: new Date(item.created_at).getTime(),
    address: item.to_address,
    amount: `${item.amount}`,
    state: getNumberFromStatus(item.status),
    type: 'hsh',
    transaction_id: item.txid || '',
    payment_service: 'ncw',
    meta: item.meta,
  };
};
