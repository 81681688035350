import { useEffect } from 'react';

import { BROWSER_BANNED, SUPPORT } from '@utils/links';

import { InterceptorErrorListener } from '@backend/JsonRpcInterceptor';
import { rpc } from '@backend/Rpc';

export const useBannedInterceptor = () => {
  useEffect(() => {
    const shouldSkipHandling =
      window.location.pathname.includes(BROWSER_BANNED) || window.location.pathname.includes(SUPPORT);

    if (shouldSkipHandling) return;

    const handler: InterceptorErrorListener = (error) => {
      if (error.message === 'User banned') {
        window.location.href = BROWSER_BANNED;
      }
    };

    const unsubscribe = rpc.interceptors.error.use(handler);

    return () => {
      unsubscribe();
    };
  }, []);
};
