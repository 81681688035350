import { useTranslation } from 'react-i18next';

import { WithdrawStatus } from '@utils/transactionStatus';

import { mapStatus } from '@pages/BrowserBalance/view/components/mapStatus';

import CheckGreenSvg from '../assets/check-green.svg?react';
import CheckSvg from '../assets/check.svg?react';
import CrossCircleSvg from '../assets/cross-circle.svg?react';
import EmailConfirmSvg from '../assets/email-confirm.svg?react';
import TimeSvg from '../assets/time.svg?react';

export const WithdrawalNote = ({ status }: { status: number }) => {
  const { t } = useTranslation();
  const [, description] = mapStatus(status, t);

  switch (status) {
    case WithdrawStatus.created:
      return (
        <>
          <EmailConfirmSvg />
          <span>{t('Confirm_Email')}</span>
        </>
      );
    case WithdrawStatus.confirmed:
    case WithdrawStatus.reconfirmed:
      return (
        <>
          <TimeSvg />
          <span>{t('Wait_confirm')}</span>
        </>
      );
    case WithdrawStatus.approved:
      return (
        <>
          <CheckSvg />
          <span>{description}</span>
        </>
      );
    case WithdrawStatus.sent:
    case WithdrawStatus.processed:
      return (
        <>
          <TimeSvg />
          <span>{description}</span>
        </>
      );
    case WithdrawStatus.complete: {
      return (
        <>
          <CheckGreenSvg />
          <span>{description}</span>
        </>
      );
    }
    default:
      return (
        <>
          <CrossCircleSvg />
          {description}
        </>
      );
  }
};
