import cn from 'classnames';
import React from 'react';

import CopyButton from '@shared/PromoCard/CopyButton';

import styles from './TextArea.module.scss';

interface TextAreaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  value: string;
  label?: string;
  onChange?: (evt: React.ChangeEvent<HTMLTextAreaElement>) => void;
  className?: string;
  withCopy?: boolean;
}

export default function TextArea(props: TextAreaProps) {
  const { value, onChange, label, className, withCopy, ...rest } = props;

  return (
    <div className={cn(styles.container, className)}>
      {label && <div className={styles.label}>{label}</div>}
      <textarea className={styles.textArea} value={value} onChange={onChange} {...rest} />
      {withCopy && <CopyButton noBackground copyText={value} className={styles.copy} />}
    </div>
  );
}
