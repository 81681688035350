import { useTranslation } from 'react-i18next';

import Landings from '@utils/FarmLand.json';

import { SmallList } from '@shared/SmallList/SmallList';

import styles from './TabBanners.module.scss';

export type TabBannersProps = {
  setPromo: (val: string) => void;
  setLand: (val: string) => void;
  promoAll: string[];
};

export default function TabBanners({ setPromo, setLand, promoAll }: TabBannersProps) {
  const { t } = useTranslation();

  return (
    <div className={styles.tab}>
      <div className={styles.tab__title}>{t('Link Settings')}</div>
      <div className={styles.tab__list}>
        <SmallList
          title={t('Promo code')}
          List={
            <>
              {promoAll?.map((item) => (
                <div key={item} className={styles.tab__promo} onClick={() => setPromo(item)}>
                  {item}
                </div>
              ))}
            </>
          }
        />
      </div>
      <div className={styles.tab__list}>
        <SmallList
          title={t('Landing ID')}
          List={
            <>
              {Landings.farm?.map((item) => (
                <div key={item.id} onClick={() => setLand(item.url)} className={styles.tab__land}>
                  <img src={item.image} alt="img" />
                  <span>{item.name}</span>
                </div>
              ))}
            </>
          }
        />
      </div>
    </div>
  );
}
