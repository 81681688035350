import cls from 'classnames';
import { HTMLAttributes } from 'react';

import { ArrowLeftSmSvg } from '@assets/colorless';

import { useGoBack } from '@hooks/useGoBack';

import styles from './BackButton.module.scss';

export function BackButton(props: HTMLAttributes<HTMLButtonElement>) {
  const { goBack } = useGoBack();
  return (
    <button type="button" {...props} onClick={goBack} className={cls(styles.back, props.className)}>
      <ArrowLeftSmSvg />
      {props.children}
    </button>
  );
}
