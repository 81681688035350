import React, { ChangeEvent, FC, FormEvent, useCallback, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import Arrow from '@assets/colorless/arrowLeft.svg?react';
import Check from '@assets/colorless/check.svg?react';

import { captcha } from '@utils/captcha';

import { Button, ButtonVariant } from '@shared/index';

import { FormStateDate } from '../../FormStateData';
import { SupportError, SupportErrorVariant } from '../../SupportError';
import { validateAccountId, validateDate, validateEmail } from '../../validators';
import SupportInput, { SupportInputVariant } from '../component/SupportInput';

import styles from './SupportFormExtended.module.scss';

type SupportFormProps = {
  email: string;
  onSubmit: (formState: FormStateDate) => void;
  onBack: () => void;
  isSubmitting?: boolean;
};

export const SupportFormExtended: FC<SupportFormProps> = (props) => {
  const { email, onSubmit, onBack, isSubmitting } = props;

  const googleRe = useRef<ReCAPTCHA>(null);

  const [formState, setFormState] = useState<FormStateDate>({
    email,
    message: '',
    id: '',
    date: '',
    attachments: [],
  });

  const [error, setError] = useState<SupportError>({
    error: '',
    variant: SupportErrorVariant.Default,
  });

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    setFormState((prev) => ({
      ...prev,
      [name]: name === 'email' ? value.trim() : value,
    }));
  }, []);

  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();

      if (!googleRe.current?.getValue()) {
        setError({
          error: 'Enter captcha',
          variant: SupportErrorVariant.Captcha,
        });
        return null;
      }

      const { isValid: isEmailValid, error: emailError } = validateEmail(formState.email);
      if (!isEmailValid) {
        return setError(emailError!);
      }

      const { isValid: isIdValid, error: idError } = validateAccountId(formState.id!);
      if (!isIdValid) {
        return setError(idError!);
      }

      const { isValid: isDateValid, error: dateError } = validateDate(formState.date!);
      if (!isDateValid) {
        return setError(dateError!);
      }

      const finalFormState = {
        ...formState,
        message: `Account ID: ${formState.id}\nLast entrance date: ${formState.date}\nComment: ${formState.message}`,
      };

      onSubmit(finalFormState);
    },
    [onSubmit, formState]
  );

  return (
    <div className={styles.container}>
      <div className={styles.decoration}></div>
      <form className={styles.card} onSubmit={handleSubmit}>
        <div className={styles.title}>Ask Support</div>
        <div className={styles.body}>
          <SupportInput
            label="Email:*"
            variant={SupportInputVariant.Email}
            name={'email'}
            value={formState.email}
            onChange={handleChange}
            error={error}
          />

          <SupportInput
            label="Please, write you previous account ID:*"
            variant={SupportInputVariant.AccountId}
            name={'id'}
            value={formState.id!}
            onChange={handleChange}
            error={error}
          />

          <SupportInput
            label="Last entrance date:*"
            variant={SupportInputVariant.Date}
            name={'date'}
            value={formState.date!}
            onChange={handleChange}
            error={error}
          />

          <SupportInput
            label="Comment:*"
            variant={SupportInputVariant.Text}
            name={'message'}
            value={formState.message}
            onChange={handleChange}
          />

          <ReCAPTCHA sitekey={captcha} ref={googleRe} className={styles.captcha} />
          <div className={styles.error}>{error.variant === SupportErrorVariant.Captcha && error.error}</div>
        </div>
        <div className={styles.buttons}>
          <Button onClick={onBack} type="button" Icon={<Arrow />} size="medium">
            BACK
          </Button>
          <Button
            type="button"
            variant={ButtonVariant.Primary}
            size="medium"
            Icon={<Check color="#fff" />}
            disabled={isSubmitting}
          >
            SUBMIT
          </Button>
        </div>
      </form>
    </div>
  );
};
