import { useTranslation } from 'react-i18next';

import { externalLinks } from '@utils/externalLinks';

import { useBreakpoints } from '@hooks/useBreakpoints';

import { Card } from '@shared/Card/Card';
import { Picture } from '@shared/Picture/Picture';
import { StoreButton, StoreButtonKind } from '@shared/StoreButton/StoreButton';

import styles from './FarmAboutAccess.module.scss';

export const FarmAboutAccess = () => {
  const { t } = useTranslation();
  const { isMobile } = useBreakpoints();
  return (
    <Card className={styles.card}>
      <div className={`${styles.farmAboutAccess}`}>
        <div className={styles.farmAboutAccess__content}>
          <h2 className={styles.farmAboutAccess__title}>{t('Access your farm from mobile device')}</h2>
          <p className={styles.farmAboutAccess__description}>
            {t(
              'Control your mining computers from anywhere right on your mobile device screen. Get instant access to scheduling, speed adjustment, up-to-date stats, and withdrawal requests right from your smartphone or tablet.'
            )}
          </p>

          <div className={styles.farmAboutAccess__btn_group}>
            <StoreButton
              link={'https://play.google.com/store/apps/details?id=com.cryptofarm'}
              kind={StoreButtonKind.PlayMarketFull}
              target={'_blank'}
            />
            <StoreButton
              kind={StoreButtonKind.AppStoreFull}
              link={'https://apps.apple.com/app/cryptotab-farm/id1633191121'}
              target={'_blank'}
            />
            <StoreButton
              kind={StoreButtonKind.Web}
              link={externalLinks.ctFarm}
              text={isMobile ? 'Web version' : ''}
              target={'_blank'}
            />
          </div>
        </div>
        <div className={styles.farmAboutAccess__image}>
          <Picture
            imageDesktop={'https://cdn.cryptobrowser.site/images/affiliate-redesign/ct-farm/ctfarm-access.png'}
            imageDesktop2x={'https://cdn.cryptobrowser.site/images/affiliate-redesign/ct-farm/ctfarm-access@2x.png'}
            imageMob={'https://cdn.cryptobrowser.site/images/affiliate-redesign/ct-farm/ctfarm-access-mob.png'}
            imageMob2x={'https://cdn.cryptobrowser.site/images/affiliate-redesign/ct-farm/ctfarm-access-mob@2x.png'}
            breakPoint={'600px'}
          ></Picture>
        </div>
      </div>
    </Card>
  );
};
