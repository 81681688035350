import React, { CSSProperties, FC, HTMLAttributes, PropsWithChildren, useMemo } from 'react';

import styles from './Container.module.scss';

export type ContainerProps = PropsWithChildren<
  HTMLAttributes<HTMLDivElement> & {
    vertical?: number | 'auto';
    horizontal?: number | 'auto';
    marginTop?: number | 'auto';
    marginBottom?: number | 'auto';
  }
>;

const toCssValue = (value: number | 'auto' | undefined) => {
  if (value === undefined) return undefined;
  return typeof value === 'number' && value >= 0 ? `${value}px` : value;
};

export const Container: FC<ContainerProps> = ({ children, ...props }) => {
  const { vertical = 0, horizontal = 'auto', marginTop, marginBottom, className = '', ...rest } = props;

  const inlineStyles = useMemo((): CSSProperties => {
    return {
      marginInline: toCssValue(horizontal),
      marginBlock: toCssValue(vertical),
      marginTop: toCssValue(marginTop),
      marginBottom: toCssValue(marginBottom),
    };
  }, [horizontal, vertical, marginTop, marginBottom]);

  return (
    <div className={`${styles.container} ${className}`} style={inlineStyles} {...rest}>
      {children}
    </div>
  );
};
