import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

import { FARM_GENERATE_CODE } from '@utils/links';
import { product } from '@utils/productName';

import { FarmProSubheader } from '@pages/headersComponents/FarmProSubheader';

import usePromoLoad from '@hooks/usePromoLoad';

import Loader from '@shared/Loader';

import { HelmetHead } from '../headersComponents/HelmetHead';
import { FarmPromoView } from './view/FarmPromoView';

export const FarmProPromo = () => {
  const { t } = useTranslation();
  const { promo } = usePromoLoad();
  const list = useMemo(() => promo.promos.map((_) => _.code), [promo.promos]);

  if (!promo.promo_get) {
    return <Loader />;
  }

  if (promo.promo_get && promo.promos.length === 0) {
    return <Redirect to={FARM_GENERATE_CODE} />;
  }

  return (
    <>
      <HelmetHead title={t('Promo')} product={product.farm} />
      <FarmProSubheader />
      <FarmPromoView list={list} />
    </>
  );
};
