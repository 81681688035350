import classNames from 'classnames';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Withdrawal } from '../../../../../backend/JsonRpcApi';
import { History } from '../History/History';

import styles from './BalanceTable.module.scss';

export type BalanceTableProps = {
  history: Withdrawal[];
  isBitcoin: boolean;
  perPage: number;
  currentPage: number;
  isEmpty: boolean;
};

export const BalanceTable: FC<BalanceTableProps> = (props) => {
  const { history, isBitcoin, perPage, currentPage, isEmpty } = props;
  const { t } = useTranslation();

  return (
    <div
      className={classNames(styles.table_container, {
        [styles.table_container]: isEmpty,
      })}
    >
      <div
        className={classNames(styles.balanceTable, {
          [styles.balanceTable__empty]: isEmpty,
        })}
      >
        <div
          className={classNames(styles.balanceTable__header, {
            [styles.balanceTable__header_empty]: isEmpty,
          })}
        >
          <div className={styles.balanceTable__header__cell}>{t('Address')}</div>
          <div className={styles.balanceTable__header__cell}>{t('Status')}</div>
          <div className={styles.balanceTable__header__cell}>{t('Amount')}</div>
        </div>
        <div className={styles.balanceTable__body}>
          <History
            history={history}
            isBitcoin={isBitcoin}
            perPage={perPage}
            isEmpty={isEmpty}
            currentPage={currentPage}
          />
        </div>
      </div>
    </div>
  );
};
