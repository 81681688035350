import { useTranslation } from 'react-i18next';

import { customYformat } from '@utils/graph';

import PoolStatisticGraph from '@pages/PoolStatistic/view/components/PoolStatisticGraph';

import { LinkAcc } from '@service/reducers/balanceReducer';

import { Container } from '@shared/index';
import { LinkCard } from '@shared/LinkCard/LinkCard';
import { PoolMined } from '@shared/PoolMined/PoolMined';

export type LinkInfoViewProps = {
  link: LinkAcc;
  onSave: (link: LinkAcc) => Promise<void>;
  id: number;
  url: string;
  onGeneratePost: () => void;
};

export function LinkInfoView({ id, url, link, onSave, onGeneratePost }: LinkInfoViewProps) {
  const { t } = useTranslation();
  return (
    <Container vertical={20}>
      <LinkCard isHideGoBack link={link} onSave={onSave} url={url} onGeneratePost={onGeneratePost} />
      <PoolMined id={id} />
      <PoolStatisticGraph
        idName={'open'}
        id={`${id}`}
        title={t('Link Opens')}
        type={'link'}
        tooltip={t('Linkclicks.tooltip')}
      />
      <PoolStatisticGraph
        id={`${id}`}
        title={t('Registrations')}
        type={'registration'}
        tooltip={t('Registrations.tooltip')}
        idName={'install'}
      />
      <PoolStatisticGraph
        title={t('Pool Miners Purchased')}
        type={'purchased'}
        tooltip={t('PoolMinersPurchased.tooltip')}
        id={`${id}`}
        idName={'miner'}
      />
      <PoolStatisticGraph
        title={t('Earnings')}
        type={'earnings'}
        tooltip={t('Earnings.tooltip')}
        yFormat={customYformat}
        id={`${id}`}
      />
    </Container>
  );
}
