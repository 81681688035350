import cn from 'classnames';

import styles from './ShareBlock.module.scss';

type LandIdItemProps = {
  onClick?: () => void;
  className?: string;
  link: { id: string | number; name?: string | null; comment?: string | null };
};

export const LandIdItem = ({ className, onClick, link }: LandIdItemProps) => (
  <div className={cn(className, styles.landLink)} onClick={onClick}>
    <div className={styles.landLink__top}>
      <div className={styles.landLink__id}>{link.id}</div>
      {link.name ? <div className={styles.landLink__name}>{link.name}</div> : null}
    </div>
    {link.comment ? <div className={styles.landLink__comment}>{link.comment}</div> : null}
  </div>
);
