import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import CoinSvg from '@assets/coin.svg?react';

import useIntlFormatter from '@utils/useIntlFormatter';

import styles from '@pages/BrowserDashboard/view/components/BrowserGraph.module.scss';
import IdList from '@pages/BrowserDashboard/view/components/IdList/IdList';

import {
  POOL_CHANGE_DISCREETNESS_EARNINGS,
  POOL_CHANGE_DISCREETNESS_LINKS,
  POOL_CHANGE_DISCREETNESS_PURCHASED,
  POOL_CHANGE_DISCREETNESS_REGISTRATION,
  SET_BROWSER_PERIOD,
} from '@service/constant';
import { Root } from '@service/reducers';
import { PoolShortResp, PoolStatisticsResponse } from '@service/reducers/poolStatisticReducer';

import GraphComponent from '@shared/Graph/GraphComponent/GraphComponent';
import { Span } from '@shared/Graph/Tabs';
export type StatisticAction = {
  path: string;
  title: string;
};

export type PoolStatisticGraphProps = {
  title: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yFormat?: (v: any) => string;
  type: 'link' | 'registration' | 'purchased' | 'earnings';
  actions?: StatisticAction[];
  tooltip?: string;
  id?: string;
  idName?: string;
};

const DEFAULT_ID = 'All Link IDs';

const span_start_timestamp = (span: Span, timestamp: string): string => {
  if (span === Span.Month) {
    return dayjs(timestamp).startOf('month').toString();
  } else if (span === Span.Week) {
    return dayjs(timestamp).startOf('week').toString();
  }
  return dayjs(timestamp).toString();
};

const convertToShortResp = (data: PoolStatisticsResponse[]): PoolShortResp[] => {
  return data
    .filter((item): item is PoolShortResp => item.timestamp !== undefined)
    .map((item) => ({
      ...item,
      timestamp: item.timestamp as number,
    }));
};

function filterData<P>(setGraphData: (val: P[]) => void, data: P[], id: string) {
  if (id === DEFAULT_ID) {
    setGraphData(data);
  } else {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newData = data?.filter((item: any) => +item.account_id === +id);
    setGraphData(newData);
  }
}

const useDiscretenessAndActionType = (type: 'link' | 'registration' | 'purchased' | 'earnings') => {
  const dispatch = useDispatch();
  const span = useSelector((store: Root) => {
    switch (type) {
      case 'link':
        return store.poolStatistic.linksDiscreteness;
      case 'registration':
        return store.poolStatistic.registrationDiscreteness;
      case 'purchased':
        return store.poolStatistic.purchasedDiscreteness;
      case 'earnings':
        return store.poolStatistic.earningsDiscreteness;
      default:
        return store.poolStatistic.linksDiscreteness;
    }
  });

  const actionType = useMemo(() => {
    switch (type) {
      case 'link':
        return POOL_CHANGE_DISCREETNESS_LINKS;
      case 'registration':
        return POOL_CHANGE_DISCREETNESS_REGISTRATION;
      case 'purchased':
        return POOL_CHANGE_DISCREETNESS_PURCHASED;
      case 'earnings':
        return POOL_CHANGE_DISCREETNESS_EARNINGS;
      default:
        return POOL_CHANGE_DISCREETNESS_LINKS;
    }
  }, [type]);

  const handleChangeDiscreetness = useCallback(
    (span: Span) => {
      dispatch({
        type: actionType,
        span,
      });
    },
    [dispatch, actionType]
  );

  return { span, handleChangeDiscreetness };
};

export default function PoolStatisticGraph({
  title,
  yFormat,
  type,
  actions,
  tooltip,
  id,
  idName,
}: PoolStatisticGraphProps) {
  const { t } = useTranslation();
  const { intFormatter } = useIntlFormatter();
  const poolGraph = useSelector((store: Root) => store.poolStatistic);
  const graphPeriod = useSelector((store: Root) => ({
    start: store.browserGraph.period_start,
    end: store.browserGraph.period_end,
  }));
  const linksDiscreteness = useSelector((store: Root) => store.poolStatistic.linksDiscreteness);
  const registrationDiscreteness = useSelector((store: Root) => store.poolStatistic.registrationDiscreteness);
  const purchasedDiscreteness = useSelector((store: Root) => store.poolStatistic.purchasedDiscreteness);
  const earningsDiscreteness = useSelector((store: Root) => store.poolStatistic.earningsDiscreteness);
  const earningTotal = useSelector((store: Root) => store.poolStatistic.earningsTotal);
  const period =
    type === 'link'
      ? (linksDiscreteness as Span)
      : type === 'registration'
      ? (registrationDiscreteness as Span)
      : type === 'purchased'
      ? (purchasedDiscreteness as Span)
      : type === 'earnings'
      ? (earningsDiscreteness as Span)
      : (linksDiscreteness as Span);
  const dispatch = useDispatch();
  const onPeriodChange = useCallback(
    (period: { period_start: string; period_end: string }) => {
      dispatch({
        type: SET_BROWSER_PERIOD,
        ...period,
      });
    },
    [dispatch]
  );

  const data = useMemo(
    () => ({
      link: poolGraph.data.link,
      registration: poolGraph.data.registration,
      purchased: poolGraph.data.purchased,
      earnings: poolGraph.data.earnings,
    }),
    [poolGraph]
  );

  const [active, setActive] = useState(`${id ? id : DEFAULT_ID}`);
  const [graphData, setGraphData] = useState<PoolStatisticsResponse[]>([]);

  const { span, handleChangeDiscreetness } = useDiscretenessAndActionType(type);

  const handleChangeId = useCallback(
    (val: string) => {
      setActive(val);
      filterData(setGraphData, graphData, val);
    },
    [graphData]
  );

  useEffect(() => {
    if (data && data[type]) {
      setGraphData(data[type] || []);
    }
  }, [data, type]);

  useEffect(() => {
    !poolGraph.stats_links_get && setGraphData(data[type]);
    const temp = convertToShortResp(data[type]);
    filterData(setGraphData, temp, active);
  }, [active, poolGraph.stats_links_get, data, type, period]);

  const total = useMemo(() => {
    if (type === 'earnings') {
      const totalEarning = +earningTotal ? `${intFormatter(earningTotal)} BTC` : undefined;
      return totalEarning;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return graphData.reduce((acc: number, item: any) => acc + item.value, 0);
  }, [type, graphData, earningTotal, intFormatter]);
  const icon = useMemo(() => {
    return type === 'earnings' ? <CoinSvg width={32} height={32} style={{ marginRight: '10px' }} /> : null;
  }, [type]);
  return (
    <GraphComponent
      total={total}
      id={idName}
      TotalIcon={icon}
      SwitchElem={
        <IdList
          id={!!id && id + ''}
          active={t(active)}
          containerClass={styles.list__container}
          setActive={handleChangeId}
        />
      }
      onPeriodChange={onPeriodChange}
      period={{
        start: span_start_timestamp(span as Span, graphPeriod.start),
        end: graphPeriod.end,
      }}
      type={'value'}
      onSpanChanged={handleChangeDiscreetness}
      span={span as Span}
      title={title}
      data={graphData}
      actions={actions}
      tooltip={tooltip}
      yFormat={yFormat}
    />
  );
}
