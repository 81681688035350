import { useFarmRespInfo } from '@hooks/useFarmRespInfo';

import { Span } from '@shared/Graph/Tabs';

import { paymentType } from '@backend/JsonRpcApi';
import { rpc } from '@backend/Rpc';

import { AppDispatch } from 'src/main.tsx';

import { GET_AGGREGATED_FARM_DATA } from '../constant';

export default function getAggregatedFarmData(
  period: Span,
  userId: number,
  period_start: string,
  period_end: string,
  paymentType: paymentType = 3
) {
  return async function (dispatch: AppDispatch) {
    const respInfo = useFarmRespInfo(period);

    const resp = await rpc.transmit('farm.get_aggregate_activity_statistics', {
      farm_ids: [userId],
      start_time: `${period_start}`,
      end_time: `${period_end}`,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      discreteness: `${respInfo.discreteness}` as any,
      payment_type_ids: [paymentType],
    });
    dispatch({
      type: GET_AGGREGATED_FARM_DATA,
      stats: resp.items.map((item) => ({
        workers_count: item.workers_count,
        farm_id: item.farm_id,
        value: (Number(item.amount) / 100000000).toFixed(4),
      })),
    });
  };
}
