import { Container } from '@shared/index';

import { LinkEditCard, LinkEditCardProps } from './LinkEditCard';

import styles from './LinkEditCard.module.scss';

export type LinkEditViewProps = LinkEditCardProps;

export function LinkEditView({ linkName, linkComment, onBack, onSave }: LinkEditViewProps) {
  return (
    <Container vertical={20} className={styles.container}>
      <LinkEditCard linkName={linkName} linkComment={linkComment} onBack={onBack} onSave={onSave} />
    </Container>
  );
}
