import cls from 'classnames';
import { PropsWithChildren, ReactNode, useState } from 'react';

import { CircleArrowSvg } from '@assets/colorless';

import styles from './Toggle.module.scss';

export type ToggleProps = PropsWithChildren<{
  Icon: ReactNode;
  title: string;
}>;

export default function Toggle(props: ToggleProps) {
  const { Icon, title, children } = props;
  const [show, setShow] = useState(false);
  return (
    <div className={cls([styles.container, show && styles.container__active])}>
      <div className={styles.top} onClick={() => setShow((prev) => !prev)}>
        {Icon}
        <div className={styles.title}>{title}</div>
        <CircleArrowSvg className={styles.arrow} />
      </div>
      <div className={styles.body}>
        <div>{children}</div>
      </div>
    </div>
  );
}
