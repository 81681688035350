import checkE from '@utils/checkE';

import { BROWSER_MINERS_SUCCESS, BROWSER_EARNING_TOTAL, BROWSER_EARNING_SUCCESS } from '@service/constant';

import { Span } from '@shared/Graph/Tabs';

import { AffiliateGroup, Discreteness, ReferralPaymentsResponse } from '@backend/JsonRpcApi';
import { rpc } from '@backend/Rpc';

import { AppDispatch } from 'src/main.tsx';

export default function getBrowserStatsPayments(
  period_start: string,
  period_end: string,
  discretenessMiners: Discreteness = Span.Day,
  discretenessEarnings: Discreteness = Span.Day,
  isDiscretenessMinersChange: boolean,
  isDateChange: boolean
) {
  const params = (discreteness: Discreteness) => ({
    start_date: period_start,
    end_date: period_end,
    group: AffiliateGroup.Browser,
    discreteness,
  });

  const handleDispatch = (
    dispatch: AppDispatch,
    data: ReferralPaymentsResponse,
    isMiners: boolean,
    isEarnings: boolean
  ) => {
    const minersData = data.items
      .map((item) => ({
        ...item,
        timestamp: item.timestamp! * 1000,
        value: item.users,
      }))
      .sort((a, b) => a.timestamp - b.timestamp);

    isMiners && dispatch({ type: BROWSER_MINERS_SUCCESS, miners: minersData });
    if (isEarnings) {
      const earningsData = data.items.map((item) => ({
        timestamp: item.timestamp ? item.timestamp * 1000 : undefined,
        value: +checkE(item.amount),
      }));
      const earningTotal = checkE(data.items.reduce((acc, item) => acc + item.amount, 0));
      dispatch({ type: BROWSER_EARNING_TOTAL, earnings: earningTotal });
      dispatch({ type: BROWSER_EARNING_SUCCESS, earnings: earningsData });
    }
  };

  return async function (dispatch: AppDispatch) {
    try {
      let data: ReferralPaymentsResponse;
      if (isDateChange) {
        if (discretenessMiners === discretenessEarnings) {
          data = await rpc.transmit('statistics.referrals.payments', params(discretenessMiners));
          handleDispatch(dispatch, data, true, true);
        } else {
          const [dataMiners, dataEarnings] = await Promise.all([
            rpc.transmit('statistics.referrals.payments', params(discretenessMiners)),
            rpc.transmit('statistics.referrals.payments', params(discretenessEarnings)),
          ]);
          handleDispatch(dispatch, dataMiners, true, false);
          handleDispatch(dispatch, dataEarnings, false, true);
        }
      } else {
        data = await rpc.transmit(
          'statistics.referrals.payments',
          params(isDiscretenessMinersChange ? discretenessMiners : discretenessEarnings)
        );
        if (isDiscretenessMinersChange) {
          handleDispatch(dispatch, data, true, false);
        } else {
          handleDispatch(dispatch, data, false, true);
        }
      }
    } catch (e) {
      console.error('Error fetching browser stats payments:', e);
    }
  };
}
