import { FC, useEffect, useState } from 'react';

import { SupportErrorVariant } from '@pages/Support/SupportError';

import { LoadSpinner } from '@shared/Loader';

import { showToast, ToastKind } from '../../../../components/Toast/Toast';
import FormContainer from '../components/FormContainer';
import { SuspendCard } from '../components/SuspendCard';
import { createDispute, fetchDisputeStatus, RequestDisputStatus } from '../services/api';
import { ErrorMap } from '../services/utils';

import styles from '../components/SuspendCard.module.scss';

type SuspendControllerProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  suspendConfig: Record<string, any>;
};

export type SupportError = {
  error: string;
  variant: SupportErrorVariant;
};

enum DisputStatus {
  baseForm,
  dynamicForm,
  wait,
}
export const SuspendController: FC<SuspendControllerProps> = ({ suspendConfig }) => {
  const { title, description } = suspendConfig;
  const [isLoading, setIsLoading] = useState(true);
  const [formStatus, setFormStatus] = useState<DisputStatus>(DisputStatus.dynamicForm);

  useEffect(() => {
    fetchDisputeStatus(setIsLoading)
      .then((response) => {
        if (!response?.status) return setFormStatus(DisputStatus.baseForm);
        if (response.status === RequestDisputStatus.issue_created) {
          setFormStatus(DisputStatus.dynamicForm);
        } else {
          setFormStatus(DisputStatus.wait);
        }
      })
      .catch(() => {
        showToast(ErrorMap.default, ToastKind.Error);
      })
      .finally(() => setIsLoading(false));
  }, []);
  const handleSubmintForm = () => {
    setFormStatus(DisputStatus.wait);
  };

  const openDispute = async (email: string, message: string) => {
    try {
      await createDispute(setIsLoading, {
        contact_email: email,
        situation_description: message,
      });
      localStorage.removeItem('suspendCardFormState');
      setFormStatus(DisputStatus.dynamicForm);
    } catch (error) {
      if (error instanceof Error) {
        const text = ErrorMap[error.message] || ErrorMap.default;
        return showToast(text, ToastKind.Error);
      }

      showToast(ErrorMap.default, ToastKind.Error);
    }
  };
  if (isLoading) {
    return (
      <div className={`${styles.suspendCard} ${styles.wait}`}>
        <LoadSpinner />
      </div>
    );
  }
  if (formStatus === DisputStatus.wait) {
    return (
      <div className={`${styles.suspendCard} ${styles.wait}`}>
        <h2>Your application is being reviewed, please wait</h2>
      </div>
    );
  }
  return (
    <div className={styles.suspendCard}>
      <h2 className={styles.suspendCard__title}>{title}</h2>
      <div className={styles.wrapper}>
        <p className={styles.suspendCard__description}>{description}</p>
      </div>
      {formStatus === DisputStatus.baseForm && (
        <SuspendCard suspendConfig={suspendConfig} openDispute={openDispute} isLoading={isLoading} />
      )}
      {formStatus === DisputStatus.dynamicForm && <FormContainer handleSubmintForm={handleSubmintForm} />}
    </div>
  );
};
