import { PRODUCT_KIND } from '@utils/checkPathname';
import FarmLands from '@utils/FarmLand.json';

import { Root } from '@service/reducers';
import { BrowserPromoLand } from '@service/reducers/browserPromoReducer';

export const selectGetBalance = (store: Root) => store.balance.get_balance;
export const selectStatsLinksGet = (store: Root) => store.browserGraph.stats_links_get;
export const selectGetLevels = (store: Root) => store.browserGraph.get_levels;
export const selectLandGet = (store: Root) => store.browserPromo.land_get;
export const selectBalance = (store: Root) => store.balance;
export const selectRate = (store: Root) => store.rate;
export const selectPromo = (store: Root) => store.promo;
export const selectPoolLandings = (store: Root) => store.poolLandings;
export const selectFarmGraph = (store: Root) => store.farmGraph;
export const selectUser = (store: Root) => store.user;
export const selectBanners = (store: Root) => store.banners;
export const selectBrowserGraph = (store: Root) => store.browserGraph;
export const selectWithdrawals = (store: Root) => store.withdrawals;
export const selectWithdrawal = (store: Root) => store.withdrawal;
export const selectBrowserPromo = (store: Root) => store.browserPromo;
export const selectNews = (store: Root) => store.news;
export const selectNftPromo = (store: Root) => store.nftPromo;
export const selectNftStats = (store: Root) => store.nftStats;
export const selectNetworkAlert = (store: Root) => store.networkAlert;
export const selectPoolStatistic = (store: Root) => store.poolStatistic;
export const selectSystem = (store: Root) => store.system;
export const selectMainStats = (store: Root) => store.mainStats;
export const selectUserId = (store: Root) => store.user.id;

export const selectProductLandings = (state: Root, productKind: PRODUCT_KIND): BrowserPromoLand[] => {
  switch (productKind) {
    case PRODUCT_KIND.Farm:
      return FarmLands.farm as unknown as BrowserPromoLand[];
    case PRODUCT_KIND.Browser:
      return state.browserPromo.land;
    case PRODUCT_KIND.Pool:
      return state.poolLandings.lands;
    case PRODUCT_KIND.Nft:
      return [];
    case PRODUCT_KIND.FarmPro:
      return FarmLands.farm as unknown as BrowserPromoLand[];
    default:
      return [];
  }
};
