import React, { ReactNode } from 'react';

import { Modal } from './modal';

type ModalInfoProps = {
  isOpen: boolean;
  onClose: () => void;
  title: string | ReactNode;
  description: string | ReactNode;
  icon: ReactNode;
  footer?: ReactNode;
};

export const ModalInfo = ({ isOpen, onClose, title, description, icon, footer }: ModalInfoProps) => {
  return (
    <Modal.Layout isOpen={isOpen} onClose={onClose}>
      <Modal.Content>
        <Modal.Body>
          <Modal.Icon icon={icon} />
          <Modal.TitleDescription title={title} description={description} />
        </Modal.Body>
        {footer && <Modal.Footer>{footer}</Modal.Footer>}
      </Modal.Content>
    </Modal.Layout>
  );
};
