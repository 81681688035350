import cls from 'classnames';

import { configSocials } from '@shared/Socials/config';

import styles from './Socials.module.scss';

export default function Socials() {
  return (
    <div className={styles.container}>
      {configSocials.map((s) => (
        <a key={s.id} href={s.link} target="_blank" className={cls([styles.item, styles[`${s.id}`]])} rel="noreferrer">
          {s.Icon}
        </a>
      ))}
    </div>
  );
}
