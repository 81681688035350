import { FbSvg, TgSvg, TwSvg, YtSvg, DsSvg, InstSvg } from '@assets/colored';

export const configSocials = [
  {
    id: 'tg',
    Icon: <TgSvg />,
    link: 'https://t.me/CryptoTabChannel',
  },
  {
    id: 'inst',
    Icon: <InstSvg />,
    link: 'https://www.instagram.com/cryptotabme/',
  },
  {
    id: 'tw',
    Icon: <TwSvg />,
    link: 'https://twitter.com/CryptoTabnet',
  },
  {
    id: 'fb',
    Icon: <FbSvg />,
    link: 'https://www.facebook.com/CryptoTabFamilyOfficial',
  },
  {
    id: 'ds',
    Icon: <DsSvg />,
    link: 'https://discord.com/invite/cryptotab-community-997079413013352529',
  },
  {
    id: 'yt',
    Icon: <YtSvg />,
    link: 'https://www.youtube.com/c/CryptoTabBrowser',
  },
];
