export const parseFileData = (base64String: string) => {
  if (!base64String.includes('base64')) {
    return '';
  }

  return base64String.split('base64,')[1];
};

export const readFile = (file: File): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;

    reader.readAsDataURL(file);
  });
};
