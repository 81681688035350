import cn from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Color, getTransactionColor } from '@utils/transactionStatus';

import { mapStatus } from '@pages/BrowserBalance/view/components/mapStatus';

import styles from './TransactionStatus.module.scss';

type TransactionStatusProps = {
  state: number;
};

export const TransactionStatus = ({ state }: TransactionStatusProps) => {
  const { t } = useTranslation();
  const [statusLabel] = useMemo(() => mapStatus(state, t), [state, t]);
  const theme = getTransactionColor(state);
  const mods = {
    [styles.default]: Color.DEFAULT === theme,
    [styles.blue]: Color.BLUE === theme,
    [styles.red]: Color.RED === theme,
    [styles.gold]: Color.GOLD === theme,
    [styles.gray]: Color.GRAY === theme,
    [styles.success]: Color.SUCCESS === theme,
  };
  return <div className={cn([styles.chip, mods])}>{statusLabel}</div>;
};
