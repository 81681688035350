import { useTranslation } from 'react-i18next';

import { NoHistoryIcon } from '@assets/colorless';

import styles from './History.module.scss';

export const EmptyHistory = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.history__empty}>
      <NoHistoryIcon className={styles.empty__svg} />
      <h2 className={styles.empty__title}>{t('Payment_history.Title')}</h2>
      <span className={styles.empty__text}>{t('Payment_history.Descr')}</span>
    </div>
  );
};
