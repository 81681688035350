import cls from 'classnames';
import { PropsWithChildren, ReactNode } from 'react';

import styles from './PromoSection.module.scss';

export type PromoSectionProps = PropsWithChildren & {
  title?: string;
  text?: string;
  offsetBottom?: number;
  overflowHide?: boolean;
  id?: string;
  Footer?: ReactNode;
  Switch?: ReactNode;
  isFooterOpacity?: boolean;
};

export function PromoSection({
  title,
  text,
  children,
  offsetBottom,
  Footer,
  overflowHide,
  Switch,
  id,
  isFooterOpacity,
}: PromoSectionProps) {
  return (
    <section
      className={cls([
        styles.section,
        overflowHide && styles.section__hide,
        { [styles.isFooterOpacity]: isFooterOpacity },
        { [styles.with_footer]: !!Footer },
      ])}
      id={id}
    >
      <div className={styles.container} style={{ paddingBottom: offsetBottom }}>
        {(title || Switch) && (
          <div className={styles.top}>
            <h2 className={styles.title}>{title}</h2>
            {Switch}
          </div>
        )}
        <div className={styles.body}>
          {text && <p className={styles.text}>{text}</p>}
          {children}
        </div>

        {/* TODO: Переделать позже */}
        {/* {!isFooterOpacity && Footer} */}
      </div>
      {Footer && <div className={styles.footer}>{Footer}</div>}
    </section>
  );
}
