import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { PRODUCT_KIND } from '@utils/checkPathname';
import { product } from '@utils/productName';

import PoolSubheader from '@pages/headersComponents/PoolSubheader';

import { useGeneratePostModal } from '@hooks/useGeneratePostModal';

import getBalances from '@service/actions/getBalances';
import { getPoolLandings } from '@service/actions/getPoolLandings';
import getPoolStatsLinks from '@service/actions/getPoolStatsLinks';
import { Root } from '@service/reducers';
import { selectBalance, selectPoolLandings } from '@service/selectors/selectors';

import { HelmetHead } from '../headersComponents/HelmetHead';
import { PoolLinksView } from './view/PoolLinksView';

export const PoolLinks = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const balance = useSelector((store: Root) => selectBalance(store));
  const store = useSelector((store: Root) => store.browserGraph);
  const poolLandings = useSelector((store: Root) => selectPoolLandings(store));

  useEffect(() => {
    !balance.get_balance && dispatch(getBalances());
  }, [dispatch, balance.get_balance]);

  useEffect(() => {
    !poolLandings.poolLandsGet && dispatch(getPoolLandings());
  }, [dispatch, poolLandings.poolLandsGet]);
  useEffect(() => {
    dispatch(getPoolStatsLinks(store.period_start, store.period_end));
  }, [dispatch, store.period_start, store.period_end]);
  const { handleGeneratePostClick } = useGeneratePostModal(PRODUCT_KIND.Pool, { timeoutDelay: 2000 });

  return (
    <>
      <HelmetHead title={t('Links')} product={product.ct} />
      <PoolSubheader />
      <PoolLinksView onLinkCreate={handleGeneratePostClick} />
    </>
  );
};
