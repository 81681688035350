import { Withdrawal } from '@backend/JsonRpcApi';

import {
  GET_WITHDRAWAL_ERROR,
  GET_WITHDRAWAL_REQUEST,
  GET_WITHDRAWAL_RESET,
  GET_WITHDRAWAL_SUCCESS,
} from '../constant';

export type WithdrawalState = {
  withdrawal: Withdrawal | null;
  isLoading: boolean;
  hasRequested: boolean;
};

const initialState: WithdrawalState = {
  withdrawal: null,
  isLoading: false,
  hasRequested: false,
};

type ActionType = {
  type: string;
  withdrawal: Withdrawal;
  isLoading: boolean;
  hasRequested: boolean;
};

export const withdrawalReducer = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case GET_WITHDRAWAL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_WITHDRAWAL_SUCCESS:
      return {
        ...state,
        withdrawal: action.withdrawal,
        isLoading: false,
        hasRequested: true,
      };
    case GET_WITHDRAWAL_ERROR:
      return {
        ...state,
        hasRequested: true,
        isLoading: false,
      };
    case GET_WITHDRAWAL_RESET:
      return {
        ...state,
        hasRequested: false,
        isLoading: false,
        withdrawal: null,
      };
    default:
      return state;
  }
};
