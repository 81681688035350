import { FC, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import FarmAffiliateSvg from '@assets/colored/farm-affiliate.svg?react';

import { externalLinks } from '@utils/externalLinks';

import { PromoType } from '@service/reducers/promoReducer';

import { FarmPromoTabs } from '@shared/FarmPromoCodes/FarmPromoCodesTabs';
import { FarmPromoCodes, Button, ButtonVariant } from '@shared/index';

import { FarmCodeResultSteps } from './FarmCodeResultSteps';

import styles from './FarmCodeResult.module.scss';

type FarmCodeResultProps = {
  promos: PromoType[];
};

export const FarmCodeResult: FC<FarmCodeResultProps> = (props) => {
  const { promos } = props;
  const { t } = useTranslation();
  const [tab, setTab] = useState(FarmPromoTabs.Usually);
  return (
    <div className={styles.farmCodeResult}>
      <FarmAffiliateSvg />
      <h1 className={styles.farmCodeResult__title}>{t('CryptoTab Farm Discount Code')}</h1>
      <p className={styles.farmCodeResult__description}>
        <Trans
          i18nKey={t(
            'Generate and use a special code to invite friends and followers to join %(link)sCryptoTab Farm%(link_end)s. By using it, they receive <b>up to 15%% off</b> all in-app purchases, and you — a <b>bonus equal to 15%%</b> of their farm income! Use your referral’s code to also get the discount: <b>come to success working together</b>!'
          )}
          components={{
            b: <b />,
            a: <a href="https://cryptotab.farm/" target={'_blank'} rel="noreferrer" />,
          }}
        />
      </p>
      <FarmPromoCodes promos={promos} setAdditionalTab={setTab} />
      <Button
        type={'link'}
        to={tab === FarmPromoTabs.Usually ? externalLinks.deepFarm : externalLinks.deepFarmPro}
        external
        target={'_blank'}
        variant={ButtonVariant.Primary}
        className={styles.farmCodeResult__btn}
      >
        {t('OPEN CRYPTOTAB FARM')}
      </Button>
      <FarmCodeResultSteps />
    </div>
  );
};

export default FarmCodeResult;
