import React, { FC } from 'react';
import CountUp from 'react-countup';
import { useTranslation } from 'react-i18next';

import useIntlFormatter from '@utils/useIntlFormatter';

import { INCOME_PER_USER } from './CalcResult';

import styles from './CalcResultItem.module.scss';

export type CalcResultItemProps = {
  level: number;
  rate: number;
  percent: number;
  friends: number;
  friendsOfFriends: number;
};

function getOrdinalKey(n: number) {
  if (n >= 4) return `${n}th`;

  switch (n) {
    case 1:
      return `${n}st`;
    case 2:
      return `${n}nd`;
    case 3:
      return `${n}rd`;
    default:
      return '1st';
  }
}

export const CalcResultItem: FC<CalcResultItemProps> = (props) => {
  const { level, percent, friends, friendsOfFriends } = props;
  const { t } = useTranslation();
  const { intFormatter } = useIntlFormatter();
  const roundedFriends = friends * Math.pow(friendsOfFriends, level);
  const income = Math.floor(((roundedFriends * INCOME_PER_USER) / 100) * percent);

  return (
    <div className={styles.calcResultItem}>
      <div className={styles.calcResultItem__cell__wide}>
        <p className={styles.calcResultItem__value}>
          <CountUp start={0} end={roundedFriends} separator={' '} />
        </p>
      </div>
      <div className={styles.calcResultItem__cell}>
        <p className={styles.calcResultItem__value__small}>
          <b>{t(getOrdinalKey(level + 1))}</b> {t('level of referrals')}
          <span>
            {t('Commission')}: <b>{intFormatter(percent)}%</b>
          </span>
        </p>
      </div>
      <div className={styles.calcResultItem__cell__wide}>
        <p className={styles.calcResultItem__value}>
          $<CountUp start={0} end={income} separator={' '} />
        </p>
      </div>
    </div>
  );
};
