import { rpc } from '@backend/Rpc';

import { AppDispatch } from 'src/main.tsx';

import { GET_WITHDRAWALS_ADDRESSES, GET_WITHDRAWALS_REQUEST } from '../constant';

export default function getWithdrawalsLastAddresses() {
  return async function (dispatch: AppDispatch) {
    try {
      dispatch({ type: GET_WITHDRAWALS_REQUEST });

      const res = await rpc.transmit('ncw.last_ncw_addresses', {});

      if (res.length) {
        const updateRes = res.map((item) => ({
          address: ('email' in item && item.email) || ('account_id' in item && item.account_id) || '',
          is_ncw: true,
        }));
        dispatch({ type: GET_WITHDRAWALS_ADDRESSES, withdrawalsAddresses: updateRes });
      }
    } catch (error) {
      console.error('Error fetching withdrawals addresses:', error);
    }
  };
}
