import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { HelmetHead } from '@pages/headersComponents/HelmetHead';

import { USER_UPDATE } from '@service/constant';
import { Root } from '@service/reducers';

import { UsersMeSetEmailError } from '@backend/JsonRpcApi';

import { EmailAlreadyConfirmed } from './EmailAlreadyConfirmed';
import { EmailConfirmationForm } from './EmailConfirmationForm';
import { EmailConfirmationSent } from './EmailConfirmationSent';

import styles from './EmailConfirmation.module.scss';

const EmailConfirmationContent = () => {
  const user = useSelector((store: Root) => store.user);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [email, setEmail] = useState(user.email);
  const [isConfirmationSent, setIsConfirmationSent] = useState(false);

  const onSubmitted = (email: string) => {
    setEmail(email);
    setIsConfirmationSent(true);
  };

  const onError = (code: number) => {
    switch (code) {
      case UsersMeSetEmailError.EmailAlreadyConfirmed:
        dispatch({
          type: USER_UPDATE,
          email_verified: true,
        });
        break;
      case UsersMeSetEmailError.Throttled:
        toast.error(t('TooManyRequests'));
        break;
      default:
        toast.error(t('Something Went Wrong'));
        break;
    }
  };

  if (user.email_verified) return <EmailAlreadyConfirmed email={email} />;
  if (isConfirmationSent) return <EmailConfirmationSent email={email} />;

  return <EmailConfirmationForm email={email} onSubmitted={onSubmitted} onError={onError} />;
};

export const EmailConfirmation = () => {
  const { t } = useTranslation();
  return (
    <>
      <HelmetHead title={t('Aff.Verify.Title')} />
      <div className={styles.pageContainer}>
        <EmailConfirmationContent />
      </div>
    </>
  );
};
