import {
  FARM_PROMO,
  BROWSER_PROMO,
  POOL_PROMO,
  BROWSER_DASH,
  FARM_DASH,
  POOL_DASH,
  BROWSER_ABOUT,
  FARM_ABOUT,
  POOL_ABOUT,
  MAIN_URL,
  MAIN_ABOUT_URL,
  MAIN_PROMO_URL,
  MAIN_STATISTIC_URL,
  BROWSER_STATISTIC,
  FARM_STATISTIC,
  POOL_STATISTIC,
  MAIN_LINK_URL,
  BROWSER_LINKS,
  FARM_LINK_INFO,
  POOL_LINKS,
} from '@utils/links';

export type TabsConfigTags = 'promo' | 'dashboard' | 'about' | 'statistic' | 'links';
export const tabsConfig = {
  promo: {
    all: MAIN_PROMO_URL,
    farm: FARM_PROMO,
    browser: BROWSER_PROMO,
    pool: POOL_PROMO,
  },
  dashboard: {
    all: MAIN_URL,
    browser: BROWSER_DASH,
    farm: FARM_DASH,
    pool: POOL_DASH,
  },
  about: {
    all: MAIN_ABOUT_URL,
    browser: BROWSER_ABOUT,
    farm: FARM_ABOUT,
    pool: POOL_ABOUT,
  },
  statistic: {
    all: MAIN_STATISTIC_URL,
    browser: BROWSER_STATISTIC,
    farm: FARM_STATISTIC,
    pool: POOL_STATISTIC,
  },
  links: {
    all: MAIN_LINK_URL,
    browser: BROWSER_LINKS,
    farm: FARM_LINK_INFO,
    pool: POOL_LINKS,
  },
};
