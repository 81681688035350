import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { EmailSvg, SupportSvg } from '@assets/colorless';

import { SUPPORT } from '@utils/links';

import { Button, ButtonVariant } from '@shared/Button/Button';

import { EmailAlreadyVerifiedIcon } from './assets';

import styles from './EmailConfirmation.module.scss';

type EmailAlreadyConfirmedProps = {
  email: string;
};
export const EmailAlreadyConfirmed = ({ email }: EmailAlreadyConfirmedProps) => {
  const { t } = useTranslation();

  const onClickSupport = () => {
    window.open(SUPPORT);
  };

  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <div className={styles.iconContainer}>
          <EmailAlreadyVerifiedIcon />
        </div>
        <div className={styles.innerContainer}>
          <h1 className={styles.title}>{t('Aff.Verify.Title')}</h1>
          <p className={styles.description}>
            <Trans i18nKey="Aff.Verify.Already" components={{ b: <b /> }} values={{ email }} />
          </p>
          <div className={styles.inputContainer}>
            <EmailSvg />
            <input value={email} disabled placeholder={'Enter Email'} />
          </div>
        </div>
      </div>

      <div className={styles.footer}>
        <div className={styles.buttonsGroup}>
          <Button className={styles.button} type={'button'} variant={ButtonVariant.Primary} onClick={onClickSupport}>
            <SupportSvg /> {t('Support')}
          </Button>
        </div>
      </div>
    </div>
  );
};
