import React, { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import FarmLogoSmSvg from '@assets/colored/farm-logo-sm.svg?react';
import FarmProLogoSmSvg from '@assets/colored/farm-pro-logo-sm.svg?react';

import { FARM_ABOUT, FARM_ABOUT_PRO } from '@utils/links';

import styles from './FarmTabs.module.scss';

type FarmTabsProps = HTMLAttributes<HTMLDivElement> & {
  farmUrl?: string;
  farmProUrl?: string;
};

export function FarmTabs(props: FarmTabsProps) {
  const { farmUrl, farmProUrl, ...rest } = props;
  const { t } = useTranslation();

  return (
    <div className={styles.tabs} {...rest}>
      <p className={styles.tabs__title}>{t('Product Version:')}</p>
      <div className={styles.tabs__switch}>
        <NavLink
          to={farmUrl || FARM_ABOUT}
          activeClassName={styles.tabs__switch_item_active}
          className={styles.tabs__switch_item}
        >
          <FarmLogoSmSvg />
          CT Farm
        </NavLink>

        <NavLink
          to={farmProUrl || FARM_ABOUT_PRO}
          activeClassName={styles.tabs__switch_item_active}
          className={styles.tabs__switch_item}
        >
          <FarmProLogoSmSvg />
          CT Farm PRO
        </NavLink>
      </div>
    </div>
  );
}
