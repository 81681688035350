import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { BROWSER_DASH } from '@utils/links';
import { product } from '@utils/productName';

import { BrowserSubheader } from '@pages/headersComponents/BrowserSubheader';

import getBalances from '@service/actions/getBalances';
import { rootBalanceType } from '@service/reducers/balanceReducer';

import { rpc } from '@backend/Rpc';

import { HelmetHead } from '../headersComponents/HelmetHead';
import { LinkEditView } from './view/LinkEditView';

export default function LinkEdit() {
  const dispatch = useDispatch();
  const balance = useSelector((store: { balance: rootBalanceType }) => store.balance);
  useEffect(() => {
    !balance.get_balance && !balance.balance_request && !balance.balance_error && dispatch(getBalances());
  }, [dispatch, balance.get_balance, balance.balance_request, balance.balance_error]);
  const match = useRouteMatch<{ id: string }>();
  const history = useHistory();
  const link = useMemo(
    () => balance.links.find((item) => item.id === +match.params.id),
    [balance.links, match.params.id]
  );
  const onBack = useCallback(() => history.goBack(), [history]);
  const onSave = useCallback(
    async (name: string, comment: string) => {
      await rpc.transmit('users.me.update_link', {
        link_id: parseFloat(match.params.id),
        name: name,
        comment: comment,
      });
      onBack();
      dispatch(getBalances());
    },
    [onBack, match.params.id, dispatch]
  );
  const { t } = useTranslation();
  if (link?.type === 'cb_click') {
    window.open('https://cb.click/');
    history.replace({ pathname: BROWSER_DASH });
    return null;
  }
  return (
    <>
      <HelmetHead title={t('Edit Link ID')} product={product.ct} />
      <BrowserSubheader />
      <LinkEditView linkName={link?.name} linkComment={link?.comment} onBack={onBack} onSave={onSave} />
    </>
  );
}
