import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { BrowserSubheader } from '@pages/headersComponents/BrowserSubheader';
import { HelmetHead } from '@pages/headersComponents/HelmetHead';

import { Root } from '@service/reducers';

import suspendConfig from './suspendConfig.json';
import { TotalSuspendView } from './view/TotalSuspendView';

export const TotalSuspend = () => {
  const { t } = useTranslation();
  const user = useSelector((store: Root) => store.user);
  if (!user.tags.includes('total_suspend')) {
    return <Redirect to={'/app'} />;
  }
  return (
    <>
      <HelmetHead title={t('Support')} />
      <BrowserSubheader />
      <TotalSuspendView suspendConfig={JSON.parse(JSON.stringify(suspendConfig))} />
    </>
  );
};
