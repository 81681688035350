import { useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import srcMob from '@assets/banners/farm/mob.png';
import srcMob2x from '@assets/banners/farm/mob@2x.png';
import srcPc from '@assets/banners/farm/pc.png';
import srcPc2x from '@assets/banners/farm/pc@2x.png';
import promoMob from '@assets/promo-materials/farm-pro/mob.png';
import promoMob2x from '@assets/promo-materials/farm-pro/mob@2x.png';
import promoPc from '@assets/promo-materials/farm-pro/pc.png';
import promoPc2x from '@assets/promo-materials/farm-pro/pc@2x.png';

import { PRODUCT_KIND } from '@utils/checkPathname';
import land from '@utils/FarmLand.json';
import { FARM_PROMO, FARM_PROMO_PRO } from '@utils/links';

import CBClickBanner from '@pages/FarmPromo/view/components/CBClickBanner/CBClickBanner';
import VideoBlock from '@pages/FarmPromo/view/components/VideoBlock/VideoBlock';

import { FarmTabs } from '@shared/FarmTabs/FarmTabs';
import { GenerateBanners } from '@shared/GenerateBanners/GenerateBanners';
import { Container, TextBlock } from '@shared/index';
import { PromoImagesSection } from '@shared/PromoImagesSection/PromoImagesSection';
import { PromoTop } from '@shared/PromoTop/PromoTop';

import { farmText } from '@constants/promo/farmTexts';

import BrandBlock from './components/BrandBlock/BrandBlock';
import EmptyFarmBanners from './components/EmptyFarmBanners/EmptyFarmBanners';
import LandingsBlock from './components/LandingsBlock/LandingsBlock';
import TabBanner from './components/TabBanners/TabBanners';
export type FarmPromoViewProps = {
  list: string[];
};

export const FarmPromoView = ({ list }: FarmPromoViewProps) => {
  const { t } = useTranslation();
  const tags = useMemo(
    () => [
      { name: t('No text'), code: 'farm' },
      { name: t('With text'), code: 'farm_text' },
    ],
    [t]
  );
  const replaceContent = useCallback((text: string) => {
    return text.replaceAll('Farm', 'Farm Pro');
  }, []);
  return (
    <Container vertical={20}>
      <FarmTabs farmProUrl={FARM_PROMO_PRO} farmUrl={FARM_PROMO} />
      <PromoTop
        imageDesktop={promoPc}
        imageDesktop2x={promoPc2x}
        imageMob={promoMob}
        imageMob2x={promoMob2x}
        Description={
          <Trans
            i18nKey="Ready_Promo_Farm_First_Block.Description"
            components={{
              b: <b />,
            }}
          />
        }
      />
      <CBClickBanner />
      <BrandBlock />
      <LandingsBlock />
      <GenerateBanners
        srcMob={srcMob}
        srcPc={srcPc}
        srcSetMob={srcMob2x}
        srcSetPc={srcPc2x}
        Tabs={TabBanner}
        title={t('Promo code')}
        tags={tags}
        list={list}
        land={land.farmPro}
        EmptyBlock={EmptyFarmBanners({})}
      />
      <PromoImagesSection productKind={PRODUCT_KIND.FarmPro} />
      <TextBlock texts={farmText} replaceContent={replaceContent} />
      <VideoBlock />
    </Container>
  );
};
