import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Plus from '@assets/colorless/plus.svg?react';
import Arrows from '@assets/icon-arrows-down.svg?react';

import { BROWSER_FEEDBACK } from '@utils/links';

import { Root } from '@service/reducers';
import { PromoType } from '@service/reducers/promoReducer';

import { Button, Card, FarmAboutCode, FarmPromoCodes } from '@shared/index';
import { LoadSpinner } from '@shared/Loader';

import styles from './Promo.module.scss';

export type PromoProps = {
  promo: PromoType[];
  createCode: () => void;
  isNeedRequest?: boolean;
};

export function Promo({ promo, createCode, isNeedRequest = true }: PromoProps) {
  const { t } = useTranslation();
  const promoLoad = useSelector((store: Root) => store.promo.promo_request);

  return (
    <Card
      TitleElement={
        <>
          <span className={styles.title}>
            {t('Your_Codes_Block.Title')}
            <span className={styles.count}>{promo.length}</span>
          </span>
        </>
      }
      id="promo"
    >
      <div className={`${styles.body} ${promo.length > 0 && styles.body__adapt} ${styles.top}`}>
        {promoLoad ? (
          <LoadSpinner />
        ) : promo.length > 0 ? (
          <>
            <p className={styles.body__title}>
              <Trans
                i18nKey="Farm_Code_Description"
                components={{
                  b: <b />,
                }}
              />
            </p>
            <FarmPromoCodes dash promos={promo} />
          </>
        ) : (
          <FarmAboutCode isNeedRequest={isNeedRequest} xsVariant style={{ padding: 0 }} />
        )}
        <div className={`${styles.body} ${promo.length > 0 && styles.body__adapt}`}></div>{' '}
        {promo.length > 0 && (
          <div className={styles.add}>
            {promo.length < 2 && (
              <BottomText
                title={t('Do you have a various traffic sources?')}
                text={t(
                  'Create additional personal link to track different traffic sources with different metrics to understand conversion rates.'
                )}
              />
            )}
            {promo.length < 5 && promo.length > 0 ? (
              <Button type="button" Icon={<Plus />} onClick={createCode} size="medium" className={styles.add__btn}>
                {t('GENERATE CODE')}
              </Button>
            ) : (
              <>
                <BottomText title={t('Code_Limit_Title')} text={t('Code_Limit_Description')} />
                <Button type="link" to={BROWSER_FEEDBACK} size="medium" className={styles.add__btn}>
                  {t('Code_Limit_Button')}
                </Button>
              </>
            )}
          </div>
        )}
      </div>
    </Card>
  );
}

type BottomTextProps = { title: string; text: string };

const BottomText = ({ title, text }: BottomTextProps) => {
  return (
    <>
      <Arrows />
      <div className={styles.add__title}>{title}</div>
      <div className={styles.add__text}>{text}</div>
    </>
  );
};
