import i18next from 'i18next';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import getNews from '@service/actions/getNews';
import { Root } from '@service/reducers';

export const useNewsLoad = () => {
  const news = useSelector((store: Root) => store.news);
  const dispatch = useDispatch();
  useEffect(() => {
    (!news.newsRequeted || news.lang !== i18next.language.split('-')[0]) &&
      dispatch(getNews(i18next.language === 'en-GB' ? 'en' : i18next.language));
  }, [dispatch, i18next.language, news.lang, news.newsRequeted]);
};
