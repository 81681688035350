import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import CheckSVG from '@assets/colorless/check.svg?react';

import { Button, ButtonVariant } from '@shared/index';

import styles from './LinkEditCard.module.scss';

export type LinkEditCardProps = {
  linkName?: string | null;
  linkComment?: string | null;
  onBack: () => void;
  onSave: (name: string, comment: string) => void;
};

export function LinkEditCard({ linkName, linkComment, onSave }: LinkEditCardProps) {
  const { t } = useTranslation();
  const [name, setName] = useState(linkName || '');
  const [comment, setComment] = useState(linkComment || '');
  return (
    <div className={styles.card}>
      <div className={styles.title}>{t('Edit Link ID')}</div>
      <div className={styles.body}>
        <div className={styles.text}>
          {t(
            'Create additional personal link to track different traffic sources with different metrics to understand conversion rates.'
          )}
        </div>
        <div className={styles.group}>
          <label className={styles.label} htmlFor="name">
            {t('Name')}
          </label>
          <input type="text" placeholder={t('Name')} id="name" value={name} onChange={(e) => setName(e.target.value)} />
          <span className={styles.group__subtitle}>{t('Your Link ID')}</span>
        </div>
        <div className={styles.group}>
          <label className={styles.label} htmlFor="comment">
            {t('Comment')}
          </label>
          <input
            id="comment"
            type="text"
            placeholder={t('Comment')}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </div>
      </div>
      <div className={styles.buttons}>
        <Button
          type="button"
          Icon={<CheckSVG color="#fff" />}
          variant={ButtonVariant.Primary}
          size="medium"
          onClick={() => onSave(name, comment)}
        >
          {t('Save')}
        </Button>
      </div>
    </div>
  );
}
