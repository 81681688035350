import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import FarmLands from '@utils/FarmLand.json';

import { usePromoShowOthers } from '@hooks/usePromoShowOthers';

import { Root } from '@service/reducers';

import FooterPromoSection from '@shared/FooterPromoSection/FooterPromoSection';
import { LandingCard, PromoSection } from '@shared/index';

import EmptyFarmBanners from '../EmptyFarmBanners/EmptyFarmBanners';

import styles from './LandingsBlock.module.scss';

export default function LandingsBlock() {
  const { t } = useTranslation();
  const { promos } = useSelector((store: Root) => store.promo);
  const { visible, onOther, showAll, isHide } = usePromoShowOthers(6, FarmLands.farmPro.length);

  return (
    <PromoSection
      title={t('Additional landing pages')}
      text={t(
        'You can use additional landing pages of different styles and sizes to invite people to your mining network. Select the most appropriate landing page to best suit your promotional content and type of traffic.'
      )}
      id="landings"
      Footer={!isHide && <FooterPromoSection onOtherClick={onOther} onAllClick={showAll} isHide={isHide} />}
    >
      {promos.length === 0 && <EmptyFarmBanners style={{ marginBottom: 40 }} />}
      <div className={styles.container}>
        {FarmLands.farmPro.slice(visible.from, visible.to).map((land) => (
          <LandingCard
            key={land.id}
            src={land.image.replace('LANG', i18next.language.split('-')[0])}
            disabledLink
            href={land.url}
            listTitle={t('Promo code')}
            list={promos.map((_) => _.code)}
          />
        ))}
      </div>
    </PromoSection>
  );
}
