import BalanceInfo from '@pages/CommonDashboard/view/components/BalanceInfo/BalanceInfo';
import Banner from '@pages/CommonDashboard/view/components/Banner/Banner';
import EarningsGraph from '@pages/CommonDashboard/view/components/EarningsGraph/EarningsGraph';
import Top, { CommonDashboardTopProps } from '@pages/CommonDashboard/view/components/Top/Top';

import { AllLinks, AllLinksProps } from '@shared/AllLinks/AllLinks';
import { FebruaryPromoBanner } from '@shared/BannerVariants/FebruaryPromoBanner';
import { Container } from '@shared/Container/Container';
import { News } from '@shared/News';

type CommonDashboardViewProps = CommonDashboardTopProps & AllLinksProps;
export default function CommonDashboardView(props: CommonDashboardViewProps) {
  return (
    <>
      <BalanceInfo />
      <Container>
        <FebruaryPromoBanner offsetBottom={20} />
        <Top {...props} />
        <AllLinks {...props} />
        <EarningsGraph />
        <Banner />
        <News />
      </Container>
    </>
  );
}
