import { useAnimation } from '@hooks/useAnimation';
import { useBreakpoints } from '@hooks/useBreakpoints';

import { CommonProductLinks } from '@shared/CommonProduct/CommonProduct';
import { Container } from '@shared/Container/Container';

import Banner from './components/Banner/Banner';
import How from './components/How/How';
import ProjectSection, { ProjectSectionVariant } from './components/ProjectSection/ProjectSection';
import Shared from './components/Shared/Shared';
import Top from './components/Top/Top';

type CommonAboutViewProps = CommonProductLinks & { isAuth: boolean };
export default function CommonAboutView(props: CommonAboutViewProps) {
  const { isMobile } = useBreakpoints();
  useAnimation([isMobile]);
  return (
    <Container vertical={20}>
      <Top {...props} isMobile={isMobile} />
      <Banner />
      <How />
      <ProjectSection variant={ProjectSectionVariant.CT} />
      <ProjectSection variant={ProjectSectionVariant.Farm} />
      <ProjectSection variant={ProjectSectionVariant.FarmPro} />
      <ProjectSection variant={ProjectSectionVariant.Pool} />
      <Shared />
    </Container>
  );
}
