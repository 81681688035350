import React, { CSSProperties, useEffect, useRef } from 'react';

export type LazyImageProps = {
  src: string;
  srcSet?: string;
  className?: string;
  style?: CSSProperties;
};

export function LazyImage({ src, srcSet, className, style }: LazyImageProps) {
  const ref = useRef<HTMLImageElement>(null);
  const [loaded, setLoaded] = React.useState(false);
  useEffect(() => {
    if (loaded && !!ref.current) {
      ref.current.src = src;
      if (srcSet) {
        ref.current.srcset = srcSet;
      } else {
        ref.current.srcset = src;
      }
    }
  }, [src, ref.current, loaded, srcSet]);
  useEffect(() => {
    const options = {
      threshold: 0.2,
    };
    const callback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const target = entry.target as Element & {
            src: string;
            srcset: string;
            dataset: { src: string; srcset: string };
          };
          target.src = target.dataset.src!;
          if (target.dataset.srcset) {
            target.srcset = `${target.dataset.srcset}`;
          }
          setLoaded(true);
          observer.disconnect();
          ref.current && observer.unobserve(ref.current);
        }
      });
    };
    const observer = new IntersectionObserver(callback, options);
    ref.current && observer.observe(ref.current);
    return () => {
      observer.disconnect();
      ref.current && observer.unobserve(ref.current);
    };
  }, []);

  return (
    <img data-src={src} data-srcset={srcSet || src} style={style} className={`${className} lazy`} ref={ref} alt="img" />
  );
}
