import classNames from 'classnames';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import AffiliateSvg from '@assets/colored/affiliate.svg?react';
import RectsSvg from '@assets/colorless/rects.svg?react';
import InfoIcon from '@assets/info-icon.svg?react';

import { useBreakpoints } from '@hooks/useBreakpoints';

import { Card } from '@shared/Card/Card';
import { ParallaxBackground } from '@shared/index';

import { AffiliateFeatures, Feature } from './AffiliateFeatures';
import { Button, ButtonVariant } from '../Button/Button';

import styles from './AffiliateIntroduction.module.scss';

type AffiliateIntroductionProps = {
  containerClassName?: string;

  Title: string | React.ReactNode;
  Description?: string | JSX.Element;
  MainBlock?: React.ReactNode;
  Icon?: React.ReactNode;
  dashboardUrl?: string;
  extraButtonUrl?: string;

  firstFeature?: string;
  secondFeature?: string;
  thirdFeature?: string;
  forthFeature?: string;
  fifthFeature?: string;
  features?: Feature[];

  withParallax?: boolean;

  dark?: boolean;
  descriptionClass?: string;
  titleClass?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  video?: any;
};

export const AffiliateIntroduction: FC<AffiliateIntroductionProps> = (props) => {
  const {
    dashboardUrl,
    extraButtonUrl,
    containerClassName,
    MainBlock,
    Title,
    Description,
    Icon,
    withParallax,
    features,
    descriptionClass,
    titleClass,
  } = props;

  const { t } = useTranslation();
  const { isMobile } = useBreakpoints();

  const renderFeatures = () => <AffiliateFeatures features={features} />;
  const renderIcon = () => (
    <div className={styles.icon}>
      {Icon ? Icon : <AffiliateSvg className="animate__animated" data-anim="fadeInDown" />}
    </div>
  );

  return (
    <>
      <Card className={classNames(styles.card, containerClassName)}>
        {withParallax && <ParallaxBackground className={styles.parallax} />}
        {renderIcon()}

        <h1 className={classNames([styles.title, 'animate__animated', titleClass])} data-anim="fadeIn">
          {Title}
        </h1>

        {Description && (
          <p className={classNames([styles.description, 'animate__animated', descriptionClass])} data-anim="fadeIn">
            {Description}
          </p>
        )}

        {dashboardUrl && (
          <Button
            type={'link'}
            to={dashboardUrl}
            Icon={<RectsSvg color="#ffffff" />}
            variant={ButtonVariant.Primary}
            className={styles.btn}
          >
            {t('Open Dashboard')}
          </Button>
        )}

        {extraButtonUrl && (
          <Button
            className={classNames(styles.btn, styles.btn_extra)}
            type={'link'}
            to={extraButtonUrl}
            Icon={<InfoIcon width={20} height={20} />}
            variant={ButtonVariant.Default}
            target="_blank"
            external
          >
            {t('Product Details')}
          </Button>
        )}

        {MainBlock && MainBlock}
        {!isMobile && renderFeatures()}
      </Card>
      {isMobile && renderFeatures()}
    </>
  );
};
