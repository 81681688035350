import React, { FC, PropsWithChildren } from 'react';

import { CheckRoundedColoredSvg } from '@assets/colored';

import { Card } from '@shared/Card/Card';

import styles from './AffiliateBenefits.module.scss';

type AffiliateBenefitsComponent = FC<PropsWithChildren> & {
  Item: FC<PropsWithChildren<AffiliateBenefitItemProps>>;
};

export const AffiliateBenefits: AffiliateBenefitsComponent = ({ children }) => {
  return (
    <Card className={styles.card}>
      <div className={styles.affiliateBenefits}>
        <div className={styles.affiliateBenefits__benefits}>{children}</div>
      </div>
    </Card>
  );
};

type AffiliateBenefitItemProps = {
  title: string;
};

const AffiliateBenefitItem: FC<AffiliateBenefitItemProps> = (props) => {
  const { title } = props;

  return (
    <div className={styles.affiliateBenefits__benefit}>
      <CheckRoundedColoredSvg className={styles.affiliateBenefits__benefit_image} />
      <h5 className={styles.affiliateBenefits__benefit_title}>{title}</h5>
    </div>
  );
};

AffiliateBenefits.Item = AffiliateBenefitItem;
