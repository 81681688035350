import { Steps } from '../Steps/Steps';
import CrowdSvg from './icons/crowd.svg?react';
import LaptopSvg from './icons/laptop.svg?react';
import MoneyBagSvg from './icons/money-bag.svg?react';
import StarCardSvg from './icons/star-card.svg?react';

export type StepsGroupProps = {
  titles: (string | JSX.Element)[];
  images?: React.FC<React.SVGProps<SVGSVGElement>>[];
};

export function StepsGroup({ titles, images }: StepsGroupProps) {
  return (
    <Steps>
      <Steps.Item
        Icon={images?.[0] || StarCardSvg}
        title={titles[0] as string | (string & JSX.Element)}
        withArrow={false}
      />
      <Steps.Item Icon={images?.[1] || CrowdSvg} title={titles[1] as string | (string & JSX.Element)} />
      <Steps.Item Icon={images?.[2] || LaptopSvg} title={titles[2] as string | (string & JSX.Element)} />
      <Steps.Item Icon={images?.[3] || MoneyBagSvg} title={titles[3] as string | (string & JSX.Element)} />
    </Steps>
  );
}
