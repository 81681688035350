import { CSSProperties, useCallback, useRef, useState } from 'react';

import useClickOutside from '@hooks/useClickOutside';

import styles from './SmallList.module.scss';

export type SmallListProps = {
  Arrow?: React.ReactNode;
  className?: string;
  listClassName?: string;
  listStyles?: CSSProperties;
  title: string;
  List: React.ReactNode;
  listWidth?: number;
  hideArrow?: boolean;
  containerCb?: () => void;
};

export function SmallList({
  title,
  List,
  listWidth,
  Arrow,
  className,
  listClassName,
  listStyles,
  containerCb,
  hideArrow,
}: SmallListProps) {
  const [show, setShow] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const handleClose = useCallback(() => setShow(false), []);
  const handleCloseWithCb = useCallback(() => {
    !hideArrow && setShow(!show);
    containerCb && containerCb();
  }, [containerCb, hideArrow, show]);
  useClickOutside(ref, handleClose, show);
  return (
    <div className={`${styles.container} ${className}`} onClick={handleCloseWithCb} ref={ref}>
      <div className={styles.title}>
        <span>{title}</span>
        {!hideArrow &&
          (Arrow || (
            <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 1L5 5L1 1" stroke="#4285F4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          ))}
      </div>
      {show && (
        <div
          className={`${styles.list} ${listClassName}`}
          style={{ width: listWidth, maxWidth: '100%', ...listStyles }}
        >
          {List}
        </div>
      )}
    </div>
  );
}
