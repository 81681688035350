import i18next from 'i18next';
import { useRef } from 'react';
import { useSelector } from 'react-redux';

import { PRODUCT_KIND } from '@utils/checkPathname';

import { Root } from '@service/reducers';
import { selectProductLandings, selectUser } from '@service/selectors/selectors';

import { AllProductTabs } from '@shared/CommonProduct/CommonProduct';
import { farmLinks } from '@shared/FarmPromoCodes/FarmPromoCodes';
import { FarmPromoTabs } from '@shared/FarmPromoCodes/FarmPromoCodesTabs';
import { GeneratePostModal } from '@shared/GeneratePostModal';

import { useModalContext } from '@context/ModalContext';

type GeneratePostModalOptions = {
  timeoutDelay?: number;
  initialLink?: number;
  initialTab?: AllProductTabs;
  showTabs?: boolean;
};

export const useGeneratePostModal = (productKind: PRODUCT_KIND, options?: GeneratePostModalOptions) => {
  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const { createModalContent, openModal, closeModal } = useModalContext();
  const landings = useSelector((store: Root) => selectProductLandings(store, productKind)).map((l) => ({
    ...l,
    image: l.image.replace('LANG', i18next.language.split('-')[0]),
    image2x: l.image.replace('LANG', i18next.language.split('-')[0]),
  }));

  const user = useSelector((store: Root) => selectUser(store));
  let links = useSelector((store: Root) => store.balance.links);
  if (productKind === PRODUCT_KIND.Farm || productKind === PRODUCT_KIND.FarmPro) {
    links = [
      {
        id: user.id,
        name: null,
        comment: null,
        is_banned: false,
        type: null,
        url: farmLinks[productKind === PRODUCT_KIND.Farm ? FarmPromoTabs.Usually : FarmPromoTabs.Pro],
      },
    ];
  }
  const codes = useSelector((store: Root) => store.promo.promos);

  const handleGeneratePostClick = () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    const openModalContent = () => {
      closeModal();
      createModalContent(
        <GeneratePostModal
          productKind={productKind}
          landings={landings}
          links={links}
          codes={codes}
          initialLink={options?.initialLink}
          initialTab={options?.initialTab}
          showTabs={options?.showTabs}
          onClose={closeModal}
        />
      );
      openModal();
    };

    if (options && options.timeoutDelay) {
      timeout.current = setTimeout(() => {
        openModalContent();
      }, options.timeoutDelay);
    } else {
      openModalContent();
    }
  };

  return { handleGeneratePostClick };
};
