import { useTranslation } from 'react-i18next';

import { ArrowRightShort } from '@assets/colorless';
import fb from '@assets/fb.svg';
import apple from '@assets/icon_apple.svg';
import google from '@assets/icon_google.png';
import tg from '@assets/tg.svg';
import vk from '@assets/vk.svg';
import tw from '@assets/x.svg';

import styles from './SettingsView.module.scss';

export function SettingsLogin() {
  const { t } = useTranslation();

  return (
    <>
      <p className={styles.change}>{t('Change to')}</p>
      <div className={styles.login}>
        <div className={styles.login__container}>
          <a href={`/login/google-oauth2/?next=/app/settings/`} className={styles.login__item}>
            <img src={google} alt="icon" />
            <span>{t('SIGN IN WITH')} Google</span>
            <ArrowRightShort />
          </a>
          <a href="/login/apple-id/?next=/app/settings/" className={styles.login__item}>
            <img src={apple} alt="icon" />
            <span>{t('SIGN IN WITH')} Apple</span>
            <ArrowRightShort />
          </a>
          <a href={`/login/facebook/?next=/app/settings/`} className={styles.login__item}>
            <img src={fb} alt="icon" />
            <span>{t('SIGN IN WITH')} Facebook</span>
            <ArrowRightShort />
          </a>
          <a href="/login/twitter/?next=/app/settings/" className={styles.login__item}>
            <img src={tw} alt="icon" />
            <span>{t('SIGN IN WITH')} X</span>
            <ArrowRightShort />
          </a>
          <a href="/login/?s=tg&next=/app/settings/" className={styles.login__item}>
            <img src={tg} alt="icon" />
            <span>{t('SIGN IN WITH')} TELEGRAM</span>
            <ArrowRightShort />
          </a>
          <a href="/login/vk-oauth2/?next=/app/settings/" className={styles.login__item}>
            <img src={vk} alt="icon" />
            <span>{t('SIGN IN WITH')} VK</span>
            <ArrowRightShort />
          </a>
        </div>
      </div>
    </>
  );
}
