import { rpc } from '@backend/Rpc';

import { AppDispatch } from 'src/main.tsx';

import { BROWSER_LAND_ERROR, BROWSER_LAND_REQUEST, BROWSER_LAND_SUCCESS } from '../constant';

export default function getBrowserLand() {
  return async function (dispatch: AppDispatch) {
    try {
      dispatch({ type: BROWSER_LAND_REQUEST });
      const resp = await rpc.transmit('promo.get_landings', {});
      const fixedResp = resp.map((item) => ({
        ...item,
        url: item.url.replace(/{USER_ID}\//gi, ''),
      }));

      dispatch({ type: BROWSER_LAND_SUCCESS, land: fixedResp });
    } catch (e) {
      dispatch({ type: BROWSER_LAND_ERROR });
      console.log(e);
    }
  };
}
