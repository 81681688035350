import cn from 'classnames';

import { nextPurchaseType } from '@service/reducers/balanceReducer';
import { WithdrawAddresses } from '@service/reducers/withdrawalsReducer';

import { LoadSpinner } from '@shared/Loader';

import { Currency } from '@backend/JsonRpcApi';

import NcwBanner from './NcwBanner/NcwBanner';
import { WithdrawalSuspended } from './WithdrawalSuspended/WithdrawalSuspend';
import { WithdrawalSwitchTabs } from './WithdrawalSwitchTabs';
import { WithdrawalModalState } from './WithdrawalView';
import { WithdrawalViewNotMoney } from './WithdrawalViewNotMoney';
import { WithdrawalViewWithMoney, WithdrawError } from './WithdrawalViewWithMoney';

import styles from './WithdrawalView.module.scss';

export type WithdrawalBodyProps = {
  balance: number;
  balanceHSH: number;
  balancePendingHSH: number;
  balanceAccruedHSH: number;
  nextPaymentHSH: nextPurchaseType;
  lastAddressHSH: string;
  currencies: Currency[];
  minAmount: number;
  minHSH: number;
  openChangeModal: () => void;
  error?: WithdrawError;
  amount: string;
  modal: WithdrawalModalState;
  isNcw: boolean;
  setAmount: (val: string) => void;
  address: WithdrawAddresses | null | undefined;
  onAddressChange: (val: WithdrawAddresses | string, skip?: boolean) => void;
  isBitcoin: boolean;
  isLoading: boolean;
  hasRequested: boolean;
  setIsBitcoin: (val: boolean) => void;
  setError: (val?: WithdrawError) => void;
  isSuspend: boolean;
  enoughMoney: boolean;
};

export default function WithdrawalBody({
  balance,
  balanceHSH,
  balancePendingHSH,
  balanceAccruedHSH,
  nextPaymentHSH,
  lastAddressHSH,
  currencies,
  minAmount,
  minHSH,
  error,
  modal,
  amount,
  openChangeModal,
  setAmount,
  address,
  isBitcoin,
  setIsBitcoin,
  onAddressChange,
  setError,
  isLoading,
  isNcw,
  hasRequested,
  isSuspend,
  enoughMoney,
}: WithdrawalBodyProps) {
  if (isSuspend) {
    return (
      <div className={cn(styles.card__body, !hasRequested && styles[`card__body--loader`])}>
        <WithdrawalSuspended />
      </div>
    );
  }

  return (
    <div className={cn(styles.card__body, !hasRequested && styles[`card__body--loader`])}>
      <div className={cn(styles.group, styles.group_mob)}>
        <WithdrawalSwitchTabs isBitcoin={isBitcoin} setIsBitcoin={setIsBitcoin} />
      </div>
      {!hasRequested ? (
        <div className={styles.group__loader}>
          <LoadSpinner />
        </div>
      ) : (
        <>
          {enoughMoney ? (
            <>
              <WithdrawalViewWithMoney
                isBitcoin={isBitcoin}
                balanceHSH={'' + balanceHSH}
                pendingBalance={balancePendingHSH}
                accruedBalance={balanceAccruedHSH}
                nextPaymentHSH={nextPaymentHSH}
                balance={'' + balance}
                amount={amount}
                modalState={modal}
                setAmount={setAmount}
                isNcw={isNcw}
                address={address}
                setAddress={onAddressChange}
                error={error}
                currencies={currencies}
                min={minAmount}
                openChangeModal={openChangeModal}
                setError={setError}
                isLockAddress={!isBitcoin && !lastAddressHSH && !isLoading}
                isLoading={isLoading}
              />
              {!isNcw && !isLoading && (
                <div className={styles.group_last}>
                  <NcwBanner isBitcoin={isBitcoin} />
                </div>
              )}
            </>
          ) : (
            <WithdrawalViewNotMoney minAmount={minAmount} minHSH={minHSH} isBitcoin={isBitcoin} />
          )}
        </>
      )}
    </div>
  );
}
