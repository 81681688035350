import cls from 'classnames';
import React from 'react';

import styles from './ListItem.module.scss';
export type BadgeProps = {
  Icon: string;
  text: string;
  theme: 'orange' | 'violet' | 'green';
};
export default function Badge({ Icon, text, theme }: BadgeProps) {
  const badgeClass = cls(styles.badge, {
    [styles.orange]: theme === 'orange',
    [styles.violet]: theme === 'violet',
    [styles.green]: theme === 'green',
  });
  return (
    <div className={badgeClass}>
      <div className={styles.badge__img}>
        <img src={Icon} alt="" />
      </div>
      <span>{text}</span>
    </div>
  );
}
