import cn from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import {
  CheckSvg,
  CircleArrowSvg,
  CopySvg,
  EmailSvg,
  HistorySvg,
  LoginSvg,
  LogoutSvg,
  SettingsSvg,
} from '@assets/colorless';

import { BROWSER_BALANCE, EMAIL_VERIFY, SETTINGS } from '@utils/links';

import { Root } from '@service/reducers';

import { Button, ButtonVariant } from '@shared/Button/Button';

import styles from './Account.module.scss';

const handleLogoutClick = () => {
  localStorage.removeItem('tokens');
  window.location.href = `/logout/?next=/login/`;
};

const handleLoginClick = () => (window.location.href = '/login/?next=/app/');
export default function Account() {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const switchShow = () => setShow((prev) => !prev);
  const user = useSelector((store: Root) => store.user);
  const navigation = useHistory();

  const [copy, setCopy] = useState(false);

  const onCopy = () => {
    window.navigator.clipboard.writeText('' + user.id);
    setCopy(true);
    setTimeout(() => setCopy(false), 2000);
  };

  const handleVerifyEmailClick = () => {
    navigation.push(EMAIL_VERIFY);
  };

  return (
    <div className={cn([styles.container, show && styles.container__active])}>
      <div className={styles.top}>
        <LoginSvg className={styles.user__icon} />
        <span className={styles.account__copy} onClick={onCopy}>
          {t('Account').toUpperCase()}
          {!!user.id && (
            <>
              {' / '}
              {user.id}
              {copy ? <CheckSvg /> : <CopySvg />}
            </>
          )}
        </span>
        {user.hasRequested && <CircleArrowSvg className={styles.arrow} onClick={switchShow} />}
      </div>
      <div className={styles.body}>
        {user.hasRequested ? (
          <>
            {user.email ? (
              <div className={styles.email}>{user.email}</div>
            ) : (
              <div className={cn(styles.email, styles.email__required)} onClick={handleVerifyEmailClick}>
                <EmailSvg width={20} />
                {t('VerifyYourEmail')}
              </div>
            )}
            <div className={cn(styles.list, { [styles.open]: !show })}>
              <div>
                <Link to={SETTINGS} className={styles.list__item}>
                  <SettingsSvg />
                  <span>{t('Login settings')}</span>
                </Link>
                <Link to={BROWSER_BALANCE} className={styles.list__item}>
                  <HistorySvg />
                  <span>{t('Payment History')}</span>
                </Link>
                <a
                  href="#"
                  onClick={async (e) => {
                    e.preventDefault();
                    await handleLogoutClick();
                  }}
                  className={styles.list__item}
                >
                  <LogoutSvg />
                  <span>{t('Logout')}</span>
                </a>
              </div>
            </div>
          </>
        ) : (
          <Button size="medium" type="button" variant={ButtonVariant.Primary} onClick={handleLoginClick}>
            {t('Login')}
          </Button>
        )}
      </div>
    </div>
  );
}
