import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ArrowRightSmSvg } from '@assets/colorless';

import { jsColors } from '@utils/jsColors';

import { useBreakpoints } from '@hooks/useBreakpoints';

import { Root } from '@service/reducers';
import { NewsElement } from '@service/reducers/newsReducer';

import { Button } from '@shared/Button/Button';
import { Card } from '@shared/Card/Card';
import Socials from '@shared/Socials/Socials';

import { LoadSpinner } from '../Loader';
import { NewsItem } from './NewsItem';

import styles from './DashboardNews.module.scss';

export function News() {
  const { t } = useTranslation();
  const { isMobile } = useBreakpoints();
  const store = useSelector((store: Root) => store.news);

  return (
    <div className={styles.wrapper}>
      <Card
        className={styles.card}
        headerClass={styles.header}
        TitleElement={
          <div className={styles.dashboardNewsTitle}>
            <a href="https://cryptobrowser.site/news/" target="_blank" rel="noreferrer" className={styles.title}>
              <span>
                <Trans i18nKey="CryptoTab News Center" components={{ b: <b /> }} />
              </span>
              <Button type="button" Icon={<ArrowRightSmSvg color={jsColors.blue} />} onlyIcon />
            </a>
            <Socials />
          </div>
        }
      >
        <div className={styles.container}>
          {isMobile && (
            <div className={styles.dashboardSocials}>
              <Socials />
            </div>
          )}
          {!store.newsRequeted ? (
            <LoadSpinner />
          ) : (
            <div className={styles.dashboardNewsWrapper}>
              <div className={styles.dashboardNewsInner}>
                {isMobile
                  ? store.news.slice(0, 3).map((item: NewsElement) => <NewsItem key={item.id} item={item} />)
                  : store.news.slice(0, 6).map((item: NewsElement) => <NewsItem key={item.id} item={item} />)}
              </div>
            </div>
          )}
        </div>
      </Card>
      <div className={styles.buttons}>
        <Button
          type="link"
          to={'https://cryptobrowser.site/news/'}
          size="medium"
          external
          iconPosition="right"
          Icon={<ArrowRightSmSvg />}
        >
          {t('View all')}
        </Button>
      </div>
    </div>
  );
}
