import cls from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './Loader.module.scss';
export default function Loader() {
  const { t } = useTranslation();
  const [err, setErr] = useState(false);
  const refresh = useCallback(() => {
    window.location.reload();
  }, []);
  useEffect(() => {
    const timeout = setTimeout(() => setErr(true), 10000);
    return () => clearTimeout(timeout);
  }, []);
  return (
    <>
      <div className={`${styles.loader_dash} ${err && styles.hide}`}>
        <LoadSpinner />
      </div>
      <div className={`${styles.loader_error} ${err && styles.show}`}>
        <p>{t('Something Went Wrong')}...</p>
        <button className={styles.loader_error__btn} onClick={refresh}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M21.0659 12C21.0659 7.19892 17.133 3.26718 12.333 3.26718V0L7.99277 4.39147L12.333 8.73174V5.46346C15.9586 5.46346 18.8696 8.37441 18.8696 12C18.8696 13.1243 18.6147 14.1451 18.1037 15.0646L19.6867 16.6475C20.5549 15.3195 21.0659 13.6854 21.0659 12ZM12.3334 15.2683V18.5355C8.70781 18.5355 5.79687 15.6246 5.79687 11.9989C5.79687 10.8747 6.05179 9.85387 6.56273 8.9344L4.9798 7.35147C4.11153 8.67947 3.60059 10.3136 3.60059 11.9989C3.60059 16.8 7.53341 20.7318 12.3334 20.7318V24L16.6737 19.6086L12.3334 15.2683Z"
              fill="#fff"
            />
          </svg>
          {t('Refresh')}
        </button>
      </div>
    </>
  );
}

export const LoadSpinner = ({ className }: { className?: string }) => {
  return (
    <div className={cls(styles.loader_dash_spinner, className)}>
      <i></i>
      <i></i>
      <i></i>
      <i></i>
    </div>
  );
};
