import { Trans } from 'react-i18next';

import { Button } from '@shared/Button/Button';
import { Modal } from '@shared/Modal';

import { ContentPage } from '@backend/JsonRpcApi';

import ArrowIcon from '../../assets/colorless/arrow-right-sm.svg?react';

import styles from './PromoModal.module.scss';

type TPromoModalProps = {
  opened: boolean;
  onClose: () => void;
  modal: ContentPage;
};

const TEN_HOURS_IN_MS = 36000000;

export const PromoModal = ({ onClose: modalClose, opened, modal }: TPromoModalProps) => {
  const { title, image, body, meta, actions } = modal;

  const setLocalStorage = (id: string) => {
    const recordedData = localStorage.getItem('viewedOffers') || '{}';
    const viewedOffers = JSON.parse(recordedData);

    viewedOffers[id] = { showTime: Date.now() };
    localStorage.setItem('viewedOffers', JSON.stringify(viewedOffers));
  };

  const handleClose = () => {
    setLocalStorage(`viewedModal_${modal.id}`);
    const throttleTime = Date.now() + TEN_HOURS_IN_MS;
    localStorage.setItem('modalsThrottle', throttleTime.toString());
    modalClose();
  };

  const onClose = (url: string) => {
    window.open(url, '_blank');
    handleClose();
  };

  return (
    <Modal.Layout onClose={handleClose} isOpen={opened}>
      <Modal.Content>
        <Modal.Body>
          <Modal.Image>
            <div className={styles.imageWrapper}>
              <picture>
                {meta && meta.image_mob && <source srcSet={meta.image_mob} media="(max-width: 767px)" />}
                <img src={image} alt={title} className={styles.image} />
              </picture>
            </div>
          </Modal.Image>
          <Modal.TitleDescription title={title} description={<Trans i18nKey={body} components={{ b: <b /> }} />} />
        </Modal.Body>
        {actions && actions.length > 0 && (
          <Modal.Footer>
            {actions.map((item) => (
              <Button
                type="button"
                key={item.url}
                color="blue"
                onClick={() => onClose(item.url)}
                Icon={<ArrowIcon />}
                iconPosition="right"
              >
                {item.title}
              </Button>
            ))}
          </Modal.Footer>
        )}
      </Modal.Content>
    </Modal.Layout>
  );
};
