import cls from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import AppStore from '@assets/colored/app-store.svg';
import GooglePlay from '@assets/colored/google-play.svg';
import OpenLinkSvg from '@assets/colorless/open-link.svg?react';

import { useCWA } from '@hooks/useCWA';

import Badge from './Badge';

import styles from './ListItem.module.scss';
type LocalizedText = {
  en: string;
  ru: string;
  fr: string;
  it: string;
  de: string;
  es: string;
  pt: string;
};

type Image = {
  '1x': string;
  '2x': string;
};

type Badge = {
  icon: string;
  text: LocalizedText;
  theme: 'orange' | 'violet' | 'green';
};

export enum ETypeMainLink {
  WithUtmContent,
}

export enum ETypeWebLink {
  WithUtmContentRef,
}

export type listItem = {
  appleLink?: string;
  googleLink?: string;
  webLink?: string;
  className?: string;
  topIcon: string;
  title: string;
  link: string;
  text: LocalizedText;
  badge: Badge;
  img: Image;
  typeMainLink?: ETypeMainLink;
  typeWebLink?: ETypeWebLink;
};
export type ListItemProps = {
  item: listItem;
  className?: string;
};
export default function ListItem({ item, className }: ListItemProps) {
  const { t, i18n } = useTranslation();
  const currentLocale = i18n.language;
  const { addReferrerToDeepLink } = useCWA();

  return (
    <div className={cls([styles.item, className])}>
      <div className={styles.top}>
        <a href={item.link} target="_blank" rel="noreferrer">
          <span>
            <img width={32} height={32} src={item.topIcon} alt={item.title} />
          </span>
          {item.title} <OpenLinkSvg className={styles.copy} />
        </a>
      </div>
      <div className={styles.center}>
        <p>{item.text[currentLocale as keyof LocalizedText]}</p>
        <div className={styles.centerContent}>
          {item.badge && (
            <Badge
              Icon={item.badge.icon}
              text={item.badge.text[currentLocale as keyof LocalizedText]}
              theme={item.badge.theme}
            />
          )}
          <img src={item.img['1x']} srcSet={`${item.img['2x']} 2x`} alt={item.title} />
        </div>
      </div>
      <div className={cls([styles.bottom, { [styles.emptyIosFallback]: !item.webLink && !item.appleLink }])}>
        {item.googleLink && (
          <>
            <a
              className={cls([styles.link, styles.android])}
              target="_blank"
              href={addReferrerToDeepLink(item.googleLink)}
              rel="noreferrer"
            >
              <img width={20} height={20} loading="lazy" src={GooglePlay} alt="Google Play" />
              Google Play
            </a>
            <a href={addReferrerToDeepLink(item.googleLink)} className={styles.androidForIos}>
              {t('MoreBTC.AvailableAndroid')}
            </a>
          </>
        )}
        {item.appleLink && (
          <a className={cls([styles.link, styles.ios])} target="_blank" href={item.appleLink} rel="noreferrer">
            <img width={20} height={20} loading="lazy" src={AppStore} alt="AppStore" />
            App Store
          </a>
        )}
        {item.webLink && (
          <a className={cls([styles.link, styles.web])} target="_blank" href={item.webLink} rel="noreferrer">
            {/* <img width={20} height={20} loading="lazy" src={Web} alt="web" /> */}
            Web
          </a>
        )}
      </div>
    </div>
  );
}
