import cn from 'classnames';
import { debounce } from 'lodash';
import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchIcon, Close } from '@assets/colorless';

import { BannerType } from '@service/reducers/bannerReducer';

import styles from './Search.module.scss';

interface SearchProps {
  handleChangeSearch: (val: string) => void;
  setSearchInput: (val: string) => void;
  results: BannerType[];
  setSearchResults: (val: BannerType[]) => void;
  value: string;
}

const Search: React.FC<SearchProps> = ({ handleChangeSearch, setSearchResults, setSearchInput, results, value }) => {
  const [isFocused, setIsFocused] = useState(false);

  const debouncedSearch = useCallback(
    debounce((searchTerm: string) => {
      if (searchTerm.length > 0) {
        handleChangeSearch(searchTerm);
      } else {
        setSearchResults([]);
      }
    }, 200),
    [handleChangeSearch, setSearchResults]
  );

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();
    setSearchInput(value);
    debouncedSearch(value);
  };

  const setSelectedValue = (value: BannerType) => {
    setSearchInput(`${value.id}`);
    handleChangeSearch(`${value.id}`);
  };

  const clearInput = () => {
    setSearchInput('');
    setSearchResults([]);
  };

  return (
    <div className={cn(styles.container)}>
      <div className={cn(styles.search, isFocused && styles.search__focused)}>
        <div className={styles.wrapper}>
          <SearchInput
            value={value}
            onChange={onChange}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            clearInput={clearInput}
          />
          <div className={styles.searchIcon}>
            <SearchIcon />
          </div>
        </div>
      </div>
      {isFocused && results.length > 0 && <Dropdown results={results} setSelectedValue={setSelectedValue} />}
    </div>
  );
};

const SearchInput: React.FC<{
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus: () => void;
  onBlur: () => void;
  clearInput: () => void;
}> = ({ value, onChange, onFocus, onBlur, clearInput }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.searchInput}>
      <input
        type="text"
        placeholder={t('search_hint')}
        className={styles.input}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      <Close className={cn(styles.close, value.length > 0 && styles.close__active)} onClick={clearInput} />
    </div>
  );
};

const Dropdown: React.FC<{
  results: BannerType[];
  setSelectedValue: (val: BannerType) => void;
}> = ({ results, setSelectedValue }) => (
  <div className={styles.dropdown}>
    {results.map((item) => (
      <Item key={item.id} banner={item} setSelectedValue={setSelectedValue} />
    ))}
  </div>
);

const Item: React.FC<{ banner: BannerType; setSelectedValue: (val: BannerType) => void }> = ({
  banner,
  setSelectedValue,
}) => {
  const handleClick = () => {
    setSelectedValue(banner);
  };
  return (
    <div className={styles.dropdown__item} onMouseDown={handleClick}>
      {banner.id}
    </div>
  );
};

export default Search;
