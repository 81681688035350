import { combineReducers } from 'redux';

import poolStatisticReducer, { rootPoolStatisticState } from '@service/reducers/poolStatisticReducer';
import systemReducer, { rootSystem } from '@service/reducers/systemReducer';

import { balanceReducer, rootBalanceType } from './balanceReducer';
import bannerReducer, { rootBanner } from './bannerReducer';
import browserPromoReducer, { rootBrowserPromo } from './browserPromoReducer';
import browserStateReducer, { rootBrowserGraph } from './browserStatsReducer';
import farmGraphReducers, { rootFarmGraph } from './farmGraphReducer';
import mainStatsReducer, { mainStatsGraph } from './mainStatsReducer';
import networkAlertReducers, { rootNetworkAlert } from './networkAlertReducer';
import { newsReducer, rootNews } from './newsReducer';
import nftPromoReducer, { rootNFTPromo } from './nftPromoReaducer';
import nftStatsReducer, { rootNftStatsType } from './nftStatsReducer';
import poolLandingsReducer, { PoolLandsState } from './poolLandingsReducer';
import promoReducer, { rootPromoType } from './promoReducer';
import rateReducer, { rootRateType } from './rateReducer';
import userReducer, { rootUser } from './userReducer';
import { withdrawalReducer, WithdrawalState as rootWithdrawal } from './withdrawalReducer';
import { withdrawalsReducer, WithdrawalsState as rootWithdrawals } from './withdrawalsReducer';

export const rootReducer = combineReducers({
  balance: balanceReducer,
  rate: rateReducer,
  promo: promoReducer,
  poolLandings: poolLandingsReducer,
  farmGraph: farmGraphReducers,
  user: userReducer,
  banners: bannerReducer,
  browserGraph: browserStateReducer,
  withdrawals: withdrawalsReducer,
  withdrawal: withdrawalReducer,
  browserPromo: browserPromoReducer,
  news: newsReducer,
  nftPromo: nftPromoReducer,
  nftStats: nftStatsReducer,
  networkAlert: networkAlertReducers,
  poolStatistic: poolStatisticReducer,
  system: systemReducer,
  mainStats: mainStatsReducer,
});

export type Root = {
  balance: rootBalanceType;
  rate: rootRateType;
  promo: rootPromoType;
  poolLandings: PoolLandsState;
  farmGraph: rootFarmGraph;
  user: rootUser;
  banners: rootBanner;
  browserGraph: rootBrowserGraph;
  withdrawals: rootWithdrawals;
  withdrawal: rootWithdrawal;
  browserPromo: rootBrowserPromo;
  news: rootNews;
  nftPromo: rootNFTPromo;
  nftStats: rootNftStatsType;
  networkAlert: rootNetworkAlert;
  poolStatistic: rootPoolStatisticState;
  system: rootSystem;
  mainStats: mainStatsGraph;
};
