import { FormEvent, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { BROWSER_DASH } from '@utils/links';
import { product } from '@utils/productName';

import { BrowserSubheader } from '@pages/headersComponents/BrowserSubheader';

import getBalances from '@service/actions/getBalances';
import { Root } from '@service/reducers';

import { rpc } from '@backend/Rpc';

import { LinksCreateCard } from './view/LinksCreateCard';
import { HelmetHead } from '../headersComponents/HelmetHead';

export default function LinksCreate() {
  const dispatch = useDispatch();
  const balance = useSelector((store: Root) => store.balance);
  const history = useHistory();
  useEffect(() => {
    !balance.get_balance && !balance.balance_request && dispatch(getBalances());
  }, [dispatch, balance.get_balance, balance.balance_request]);
  const handleSubmit = useCallback(
    async (e: FormEvent, comment: string) => {
      e.preventDefault();
      await rpc.transmit('users.me.create_link', {
        comment: comment,
      });
      dispatch(getBalances());
      history.replace({ pathname: BROWSER_DASH });
    },
    [dispatch, history]
  );
  const { t } = useTranslation();
  return (
    <>
      <HelmetHead title={t('Create Link ID')} product={product.ct} />
      <BrowserSubheader />
      <LinksCreateCard length={balance.links.length} onSubmit={handleSubmit} />
    </>
  );
}
