import React, { createContext, PropsWithChildren, ReactElement, useContext, useState } from 'react';

import { useModal } from '@shared/Modal';

interface ModalContextValue {
  opened: boolean;
  openModal: () => void;
  closeModal: () => void;
  createModalContent: (content: ReactElement, className?: string) => void;
  clearModalContent: () => void;
}

const ModalContext = createContext<ModalContextValue | undefined>(undefined);
export const ModalProvider = ({ children }: PropsWithChildren) => {
  const { opened, onOpen, onClose } = useModal();
  const [modalContent, setModalContent] = useState<ReactElement | null>(null);

  const closeModal = () => {
    onClose();
    clearModalContent();
  };

  const clearModalContent = () => {
    setModalContent(null);
  };

  const createModalContent = (content: ReactElement) => {
    setModalContent(content);
    onOpen();
  };

  return (
    <ModalContext.Provider
      value={{
        opened,
        openModal: onOpen,
        closeModal,
        createModalContent,
        clearModalContent,
      }}
    >
      {children}
      {modalContent}
    </ModalContext.Provider>
  );
};

export const useModalContext = (): ModalContextValue => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('add ModalProvider');
  }
  return context;
};
