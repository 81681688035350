import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { product } from '@utils/productName';

import { HelmetHead } from '@pages/headersComponents/HelmetHead';
import PoolSubheader from '@pages/headersComponents/PoolSubheader';
import PoolDashboardView from '@pages/PoolDashboard/view/PoolDashboardView';

import { useNewsLoad } from '@hooks/useNewsLoad';

import getPoolDashboardInfo from '@service/actions/getPoolDashboardInfo';
import { Root } from '@service/reducers';

export default function PoolDashboard() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const store = useSelector((store: Root) => store.poolStatistic);
  const { period_start, period_end } = useSelector((store: Root) => store.browserGraph);
  const isFirst = useRef(true);

  useEffect(() => {
    !isFirst.current && dispatch(getPoolDashboardInfo(period_start, period_end, store.earningsDiscreteness, false));
  }, [store.earningsDiscreteness]);
  useEffect(() => {
    if (isFirst.current) {
      isFirst.current = false;
    }
    dispatch(getPoolDashboardInfo(period_start, period_end, store.earningsDiscreteness, true));
  }, [period_start, period_end]);
  useNewsLoad();
  return (
    <>
      <HelmetHead title={t('Dashboard')} product={product.pool} />
      <PoolSubheader />
      <PoolDashboardView />
    </>
  );
}
