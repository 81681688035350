import cn from 'classnames';
import { ReactNode, useEffect } from 'react';
import { createPortal } from 'react-dom';

import { CrossSvg } from '@assets/colorless';

import { ModalContextProvider, useModalContext } from './modal.context';

import styles from './modal.module.scss';

interface ModalLayoutProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
}

const Layout: React.FC<ModalLayoutProps> = ({ isOpen, onClose, children }) => {
  useEffect(() => {
    const handleEsc = (e: KeyboardEvent) => {
      if (e.key === 'Escape') onClose();
    };
    document.addEventListener('keydown', handleEsc);
    return () => document.removeEventListener('keydown', handleEsc);
  }, [onClose]);

  if (!isOpen) return null;

  return createPortal(
    <ModalContextProvider value={{ onClose }}>
      <div className={styles.overlay} onClick={onClose}>
        <div className={styles.container}>{children}</div>
      </div>
    </ModalContextProvider>,
    document.body
  );
};

interface ContentProps {
  children: ReactNode;
  className?: string;
}

const Content: React.FC<ContentProps> = ({ children, className }) => {
  return (
    <div className={cn(styles.content, className)} onClick={(e) => e.stopPropagation()}>
      {children}
    </div>
  );
};

interface IconProps {
  icon: ReactNode;
}

const Icon: React.FC<IconProps> = ({ icon }: IconProps) => {
  return <div className={styles.icon}>{icon}</div>;
};

type ImageProps = {
  children?: ReactNode;
};

const Image: React.FC<ImageProps> = ({ children }) => {
  return <div className={styles.image}>{children}</div>;
};

interface BodyProps {
  children: ReactNode;
  containerClassName?: string;
}

const Body: React.FC<BodyProps> = ({ children, containerClassName }) => {
  const { onClose } = useModalContext();

  return (
    <div className={cn(styles.body, containerClassName)}>
      <button onClick={onClose} className={styles.closeButton}>
        <CrossSvg />
      </button>
      {children}
    </div>
  );
};

interface InnerContent {
  children: ReactNode;
}

const InnerContent: React.FC<InnerContent> = ({ children }) => {
  return <div className={styles.innerContent}>{children}</div>;
};

interface TitleDescriptionProps {
  title: string | ReactNode;
  description: string | ReactNode;
  containerClassName?: string;
}

const TitleDescription: React.FC<TitleDescriptionProps> = ({ title, description, containerClassName }) => {
  return (
    <div className={cn(styles.titleDescription, styles.innerContent, containerClassName)}>
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.description}>{description}</p>
    </div>
  );
};

interface FooterProps {
  children: ReactNode;
  containerClassName?: string;
}

const Footer: React.FC<FooterProps> = ({ children, containerClassName }) => {
  return <div className={cn(styles.footer, containerClassName)}>{children}</div>;
};

export const Modal = {
  Layout,
  Body,
  Footer,
  Content,
  TitleDescription,
  Image,
  Icon,
  InnerContent,
};
