import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Root } from '@service/reducers';
import { BrowserMinerResp, BrowserResp, BrowserShortResp } from '@service/reducers/browserStatsReducer';

export default function useFilteredBrowserLinks(id?: number | string) {
  const store = useSelector((store: Root) => store.browserGraph);
  return useMemo(() => {
    const resp = {
      open: 0,
      install: 0,
      revenue: 0,
      active: 0,
    };
    function filterOnId<T>(arr: T[], arrId: string | number) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return arr?.filter((item) => (item as any).account_id === Number(arrId));
    }
    let opens = store.tableOpens;
    let installs = store.tableInstalls;
    let miners = store.tableMiners;
    if (id && id !== 'All Link IDs') {
      miners = filterOnId<BrowserMinerResp>(miners, id);
      opens = filterOnId<BrowserShortResp>(opens, id);
      installs = filterOnId<BrowserResp>(installs, id);
    }

    resp.open = opens?.reduce((acc, item) => (acc += item.value), 0);
    resp.install = installs?.reduce((acc, item) => (item.lvl === 1 ? (acc += item.value) : acc), 0);
    resp.active = miners?.reduce((acc, item) => (acc += item.value), 0);
    resp.revenue = miners?.reduce((acc, item) => (acc += parseFloat(item.amount.toFixed(12))), 0);
    return resp;
  }, [store.tableOpens, store.tableInstalls, store.tableMiners, id]);
}
