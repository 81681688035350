import dayjs, { ManipulateType } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Bar, BarChart, Brush, Legend, Rectangle, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { defaultXformat, MOB_VISIBLE_BARS, VISIBLE_BARS } from '@utils/graph';

import IdList from '@pages/BrowserDashboard/view/components/IdList/IdList';

import { useBreakpoints } from '@hooks/useBreakpoints';

import { MAIN_CHANGE_DISCREETNESS_MINER, SET_BROWSER_PERIOD } from '@service/constant';
import { Root } from '@service/reducers';
import { BrowserMinerResp, BrowserMiners, rootBrowserGraph } from '@service/reducers/browserStatsReducer';

import { CustomTooltip } from '@shared/Graph';
import { GraphLevelsColors, mapForEmptyValue } from '@shared/Graph/Const';
import GraphNoData from '@shared/Graph/GraphNoData/GraphNoData';
import Tabs, { Span } from '@shared/Graph/Tabs';
import { Card, DatePicker } from '@shared/index';

import styles from './MinersGraph.module.scss';
dayjs.extend(isBetween);

export type MinersGraphProps = {
  id?: number;
};

interface BrowserMinersExtended extends BrowserMiners {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const MinersGraph = ({ id }: MinersGraphProps) => {
  const { t } = useTranslation();
  const [active, setActive] = useState(`${id ? id : 'All Link IDs'}`);
  const graphContainerRef = useRef<HTMLDivElement>(null);
  const [showShadowLeft, setShowShadowLeft] = useState(false);
  const [showShadowRight, setShowShadowRight] = useState(false);
  const dispatch = useDispatch();
  const [width, setWidth] = useState<number | string>('100%');
  const graph = useSelector((store: Root) => store.mainStats.miners);
  const span = useSelector((store: Root) => store.mainStats.minersDiscreteness);
  const period = useSelector((store: { browserGraph: rootBrowserGraph }) => ({
    start: store.browserGraph.period_start,
    end: store.browserGraph.period_end,
  }));

  const onPeriodChange = useCallback(
    (period: { period_start: string; period_end: string }) => {
      dispatch({
        type: SET_BROWSER_PERIOD,
        ...period,
      });
    },
    [dispatch]
  );

  const upgradeData: BrowserMinersExtended[] = useMemo(() => {
    return formatData(graph, span as Span);
  }, [graph, span]);

  const data = useMemo(() => {
    if (active === 'All Link IDs') {
      return upgradeData;
    }
    return upgradeData.filter((item: BrowserMinersExtended) => item.link_id === +active);
  }, [active, upgradeData]);

  const total = useMemo(() => {
    return data.reduce((acc: number, item: BrowserMiners) => acc + item.value, 0);
  }, [data]);
  const handleChangeSpan = useCallback(
    (span: Span) => {
      dispatch({
        type: MAIN_CHANGE_DISCREETNESS_MINER,
        span,
      });
    },
    [dispatch]
  );

  const { isMobile } = useBreakpoints();
  const visibleBars = useMemo(() => (isMobile ? MOB_VISIBLE_BARS : VISIBLE_BARS), [isMobile]);
  const updateShadowVisibility = debounce(() => {
    const container = graphContainerRef.current;
    if (container) {
      const maxScrollLeft = container.scrollWidth - container.clientWidth;

      setShowShadowLeft(container.scrollLeft > 0);
      setShowShadowRight(container.scrollLeft < maxScrollLeft);
    }
  }, 100);

  useEffect(() => {
    const container = graphContainerRef.current;
    if (container) {
      container.scrollLeft = container.scrollWidth;
      updateShadowVisibility();
      if (isMobile) {
        const _width = container?.clientWidth < data.length * 60 ? data.length * 60 : '100%';
        setWidth(_width);
      }
      container.addEventListener('scroll', updateShadowVisibility);
      return () => container.removeEventListener('scroll', updateShadowVisibility);
    }
  }, [data, graphContainerRef.current]);
  return (
    <Card
      TitleElement={
        <span className={styles.title}>
          <span className={styles.title}>
            {t('Active Miners')}
            {/* <Question />
            <div className={styles.tooltip}>{t('ActiveMiners.tooltip')}</div> */}
          </span>
          <b className={styles.total_mob}>{total}</b>
        </span>
      }
      Switch={
        <div className={styles.header}>
          <IdList id={!!id && id + ''} active={t(active)} containerClass={styles.top__ids} setActive={setActive} />
          <div className={styles.date}>
            <DatePicker onPeriodChange={onPeriodChange} period={period} />
          </div>
        </div>
      }
      headerClass={styles.cardHeader}
      id="miner"
    >
      <div className={styles.top}>
        <Tabs span={span as Span} onSpanChanged={handleChangeSpan} />
        <b className={styles.total_pc}>{total}</b>
      </div>
      <div className={styles.container}>
        {data.length > 0 ? (
          <>
            <div className={styles.graphContainer}>
              <div className={`${styles.shadowOverlayLeft} ${showShadowLeft ? styles.visible : ''}`} />
              <div className={`${styles.shadowOverlayRight} ${showShadowRight ? styles.visible : ''}`} />
              <div className={styles.scrollable} ref={graphContainerRef}>
                <ResponsiveContainer width={width} height={300}>
                  <BarChart data={data} margin={{ top: 30, right: 30, left: 20, bottom: 5 }}>
                    <XAxis
                      dataKey="timestamp"
                      axisLine={{ stroke: '#D6D6D6' }}
                      tickLine={false}
                      tickFormatter={defaultXformat(span)}
                      tick={{ fill: '#757575', fontSize: 11 }}
                    />
                    {!isMobile && <YAxis axisLine={false} tickLine={false} tick={{ fill: '#757575', fontSize: 11 }} />}
                    <Tooltip
                      cursor={false}
                      content={(props) => <CustomTooltip {...props} span={span} title={t('Active Miners')} />}
                      isAnimationActive={false}
                    />
                    {data.length > visibleBars && !isMobile && (
                      <Brush
                        height={7}
                        stroke="#F7931A"
                        className={styles.brush}
                        travellerWidth={0}
                        rx={4}
                        startIndex={data.length - visibleBars}
                        data={data}
                        color="red"
                        radius={4}
                      />
                    )}
                    {!isMobile && <Legend content={RenderLegend} />}
                    {GraphLevelsColors.map((color, i) => (
                      <Bar
                        key={i + 1}
                        dataKey={`level_${i + 1}`}
                        stackId="bar"
                        fill={color}
                        isAnimationActive={false}
                        maxBarSize={48}
                        radius={i === 0 ? [0, 0, 4, 4] : 0}
                        shape={
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          (props: any) => {
                            const lvl = getLastLevelWithData(props);
                            if (lvl) props.radius = [4, 4, 0, 0];
                            return <Rectangle {...props} />;
                          }
                        }
                      />
                    ))}
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
            {isMobile && <RenderLegend style={{ padding: '0 15px 15px 15px' }} />}
          </>
        ) : (
          <GraphNoData />
        )}
      </div>
    </Card>
  );
};

const getLastLevelWithData = (data: BrowserMinersExtended) => {
  let lastLevel = 0;
  for (let i = 1; i <= 10; i++) {
    if (data[`level_${i}`] > 0) {
      lastLevel = i;
    }
  }
  if (data.dataKey !== `level_${lastLevel}`) return undefined;
  return lastLevel;
};

const RenderLegend = ({ style }: { style?: React.CSSProperties }) => {
  return (
    <div className={styles.legendContainer} style={style}>
      <div>Levels</div>
      <ul className={styles.legend}>
        {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          GraphLevelsColors.map((entry: any, index: number) => (
            <li
              key={`item-${index}`}
              style={{ '--colorLvl': entry } as React.CSSProperties}
              className={styles.legend__item}
            >
              <i></i>
              <p>
                <b>{index + 1}</b>
              </p>
            </li>
          ))
        }
      </ul>
    </div>
  );
};

const formatData = (graph: BrowserMinerResp[], periodSpan: Span) => {
  if (graph.length) {
    const tempGraphData = graph.map((item) => ({
      ...item,
      timestamp: dayjs(item.timestamp)
        .startOf(mapForEmptyValue[periodSpan] as ManipulateType)
        .valueOf(),
    }));

    const groupedData: { [key: string]: BrowserMinersExtended } = {};

    tempGraphData.forEach((item) => {
      const key = `${item.account_id}-${item.timestamp}`;

      if (!groupedData[key]) {
        groupedData[key] = {
          timestamp: item.timestamp,
          link_id: item.account_id,
          level_1: 0,
          level_2: 0,
          level_3: 0,
          level_4: 0,
          level_5: 0,
          level_6: 0,
          level_7: 0,
          level_8: 0,
          level_9: 0,
          level_10: 0,
          value: 0,
        };
      }

      groupedData[key][`level_${item.lvl}`] += item.value;
      groupedData[key].value += item.value;
    });

    const groupedArray = Object.values(groupedData);

    const filledGraphData: Array<BrowserMiners> = [];
    let i = dayjs(groupedArray[0]?.timestamp);
    while (i.valueOf() <= dayjs(groupedArray[groupedArray.length - 1]?.timestamp).valueOf()) {
      filledGraphData.push({
        timestamp: i.valueOf(),
        link_id: 1,
        level_1: 0,
        level_2: 0,
        level_3: 0,
        level_4: 0,
        level_5: 0,
        level_6: 0,
        level_7: 0,
        level_8: 0,
        level_9: 0,
        level_10: 0,
        value: 0,
      });
      i = i.add(1, mapForEmptyValue[periodSpan] as ManipulateType);
    }

    if (i.valueOf() <= dayjs(groupedArray[groupedArray.length - 1]?.timestamp).valueOf()) {
      return [];
    }

    return filledGraphData.map((item: BrowserMiners) => {
      const find = groupedArray.find((ob) => ob.timestamp === item.timestamp);
      return find || item;
    });
  } else {
    return [];
  }
};

export default MinersGraph;
