import cn from 'classnames';
import { useCallback, useEffect, useState } from 'react';

import { ArrowRightSvg } from '@assets/colored';
import { ArrowLeftSmSvg } from '@assets/colorless';

import { useBreakpoints } from '@hooks/useBreakpoints';

import { Button, ButtonVariant } from '@shared/Button/Button';

import styles from './Pagination.module.scss';

export type PaginationProps = {
  itemsCount?: number;
  index: number;
  perPage?: number;
  totalPage: number;
  isNextWithdrawalsLoading?: boolean;
  handleChangePage: (page: number) => void;
  paginationButtonsMax: number;
  isBitcoin?: boolean;
  limit?: number;
};

export const Pagination = (props: PaginationProps) => {
  const { index, totalPage, handleChangePage, paginationButtonsMax, isNextWithdrawalsLoading, isBitcoin } = props;

  const [step, setStep] = useState(index > 0 ? index : 1);
  const [pages, setPages] = useState<(string | number)[]>([]);
  const { isMobile } = useBreakpoints();

  useEffect(() => {
    setStep(index > 0 ? index : 1);
  }, [index]);

  const range = useCallback((start: number, end: number) => {
    const length = end - start + 1;
    return Array.from({ length }, (_, idx) => idx + start);
  }, []);

  const getPagination = useCallback(
    (currentPage: number, totalPageCount: number) => {
      const firstPageIndex = 1;
      const lastPageIndex = totalPageCount;

      const leftSiblingIndex = Math.max(currentPage - 1, 1);
      const rightSiblingIndex = Math.min(currentPage + 1, totalPageCount);

      const shouldShowLeftDots = leftSiblingIndex > 1;
      const shouldShowRightDots = rightSiblingIndex < totalPageCount;

      if (paginationButtonsMax >= totalPageCount) {
        return range(1, totalPageCount);
      }

      if (isMobile && currentPage === 1) {
        const leftRange = range(1, paginationButtonsMax);
        return [...leftRange, '...', lastPageIndex];
      }

      if (isMobile && currentPage === totalPageCount) {
        const rightRange = range(totalPageCount - paginationButtonsMax + 1, totalPageCount);
        return [firstPageIndex, '...', ...rightRange];
      }

      if (isMobile && currentPage > 1 && currentPage < totalPageCount) {
        return [firstPageIndex, '...', currentPage, '...', lastPageIndex];
      }

      if (!isMobile) {
        if (!shouldShowLeftDots && shouldShowRightDots) {
          const leftItemCount = 3;
          const leftRange = range(1, leftItemCount);
          return [...leftRange, '...', totalPageCount];
        }

        if (shouldShowLeftDots && !shouldShowRightDots) {
          const rightItemCount = 3;
          const rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount);
          return [firstPageIndex, '...', ...rightRange];
        }

        if (shouldShowLeftDots && shouldShowRightDots) {
          const middleRange = range(leftSiblingIndex, rightSiblingIndex);
          return [firstPageIndex, '...', ...middleRange, '...', lastPageIndex];
        }
      }

      return [];
    },
    [paginationButtonsMax, isMobile]
  );

  useEffect(() => {
    setPages(getPagination(step, totalPage));
  }, [step, totalPage, getPagination, isBitcoin]);

  const handleClick = useCallback(
    (idx: number) => {
      setStep(idx);
      handleChangePage(idx);
    },
    [handleChangePage]
  );

  const handlePrevClick = useCallback(() => {
    handleClick(Math.max(step - 1, 1));
  }, [handleClick, step]);

  const handleNextClick = useCallback(() => {
    handleClick(Math.min(step + 1, totalPage));
  }, [handleClick, step, totalPage]);

  const handlePageClick = useCallback(
    (page: number) => {
      handleClick(page);
    },
    [handleClick]
  );

  return totalPage && totalPage >= 1 ? (
    <div className={styles.pagination}>
      <Button
        type="button"
        Icon={<ArrowLeftSmSvg />}
        data-disabled={isNextWithdrawalsLoading}
        size="medium"
        disabled={step === 1}
        className={cn(styles.pagination__item, styles.pagination__item_svg)}
        onClick={handlePrevClick}
      />
      {pages.map((e, idx) => {
        return (
          <div key={idx} className={styles.pagination__item_wrap}>
            {typeof e === 'string' ? (
              <Button type="button" size="medium" variant={ButtonVariant.Text} className={styles.pagination__item_dot}>
                {e}
              </Button>
            ) : (
              <Button
                type="button"
                size="medium"
                data-disabled={isNextWithdrawalsLoading}
                variant={step === e ? ButtonVariant.Primary : ButtonVariant.Default}
                className={cn(styles.pagination__item, step === e && styles[`pagination__item--active`])}
                onClick={() => handlePageClick(+e)}
              >
                {+e}
              </Button>
            )}
          </div>
        );
      })}
      <Button
        type="button"
        Icon={<ArrowRightSvg />}
        size="medium"
        data-disabled={isNextWithdrawalsLoading}
        disabled={step === totalPage}
        className={cn(styles.pagination__item, styles.pagination__item_svg)}
        onClick={handleNextClick}
      />
    </div>
  ) : null;
};
