import React, { FC } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import { BROWSER_FEEDBACK } from '@utils/links';

import { Container } from '@shared/index';

import { getKeys } from '../utils';
import { FeedbackCard } from './FeedbackCard/FeedbackCard';

import styles from './BrowserFeedbackView.module.scss';

type BrowserFeedbackViewProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  feedbackConfig: Record<string, any>;
};

export const BrowserFeedbackView: FC<BrowserFeedbackViewProps> = (props) => {
  const { feedbackConfig } = props;
  const { path } = useRouteMatch();
  const routes = new Set(getKeys(feedbackConfig));
  return (
    <div className={styles.feedback}>
      <div className={styles.feedback__decoration}></div>
      <Container>
        <Switch>
          {Array.from(routes).map((route, index) => {
            return (
              <Route path={`${path}${route}`} exact key={route + index}>
                <FeedbackCard route={route} feedbackConfig={feedbackConfig} />
              </Route>
            );
          })}
          <Route exact path="*">
            <Redirect to={BROWSER_FEEDBACK} />
          </Route>
        </Switch>
      </Container>
    </div>
  );
};
