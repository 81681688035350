import i18next from 'i18next';
import { FormEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ArrowBottomSmSvg } from '@assets/colorless';

import { bannerSizes, bannerLang } from '@utils/bannerInfo';

import getBanners from '@service/actions/getBanners';

import { Choice, Select } from '@shared/Select/Select';

import styles from './GenerateBanners.module.scss';

export type GenerateFormProps = {
  title: string;
  list: Choice[];
  tags: Choice[];
  handleSelectedSize: (val: Choice) => void;
  currentSize: Choice;
  onGenerateForm: () => void;
};

export const renderIdChoise = (choice: { value: string }) => {
  return (
    <div className={styles.link}>
      <div>{choice.value}</div>
    </div>
  );
};
export const renderThemedChoise = (choice: { label: string }) => {
  return (
    <div className={styles.link}>
      <div>{choice.label}</div>
    </div>
  );
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const renderLangChoise = (choice: any) => {
  return (
    <div className={styles.link}>
      {choice.Icon && <>{choice.Icon}</>}
      <div>{choice.label}</div>
    </div>
  );
};
const selectedLang = (lang: string) => {
  return bannerLang.find((item) => item.value === lang) || bannerLang[0];
};
export function GenerateForm({
  title,
  list,
  tags,
  currentSize,
  handleSelectedSize,
  onGenerateForm,
}: GenerateFormProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedId, setSelectedId] = useState<Choice>(list?.[0]);
  const [currentLang, setSelectedLang] = useState<Choice>(selectedLang(i18next.language));
  const [currentTag, setSelectedTag] = useState<Choice>(tags[0]);
  const handleSelectedTag = useCallback((val: Choice) => setSelectedTag(val), []);
  const handleSelectedLang = useCallback((val: Choice) => setSelectedLang(val), []);
  const handleChangeId = useCallback((id: Choice) => setSelectedId(id), []);
  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      onGenerateForm();
      dispatch(getBanners(selectedId.value, currentTag.value, currentSize.value, currentLang.value));
    },
    [dispatch, currentSize, selectedId, currentLang, currentTag]
  );

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <div className={styles.select}>
        <div className={styles.select__label}>{title}</div>
        <Select
          listClassName={styles.choices}
          name={'links'}
          choices={list}
          current={selectedId}
          onChange={handleChangeId}
          renderChoise={renderIdChoise}
        />
      </div>
      <div className={styles.select}>
        <div className={styles.select__label}>{t('Theme')}</div>
        <ArrowBottomSmSvg className={styles.select__icon} />

        <Select
          listClassName={styles.choices}
          name={'tags'}
          choices={tags}
          current={currentTag}
          onChange={handleSelectedTag}
          renderChoise={renderThemedChoise}
        />
      </div>
      <div className={styles.select}>
        <div className={styles.select__label}>{t('Banner Size')}</div>
        <Select
          listClassName={styles.choices}
          name={'size'}
          choices={bannerSizes}
          current={currentSize}
          onChange={handleSelectedSize}
          renderChoise={renderIdChoise}
        />
      </div>
      <div className={`${styles.select} ${styles.select__lang}`}>
        <div className={styles.select__label}>{t('Language')}</div>

        <Select
          listClassName={styles.choices}
          name={'lang'}
          choices={bannerLang}
          current={currentLang}
          onChange={handleSelectedLang}
          renderChoise={renderLangChoise}
        />
      </div>
      <button type="submit" className={styles.submit}>
        {t('GENERATE')}
      </button>
    </form>
  );
}
