import { useCWA } from '@hooks/useCWA';

import { data } from './data';
import ListItem, { ETypeMainLink, ETypeWebLink, listItem } from '../ListItem/ListItem';

import styles from './List.module.scss';

export default function List() {
  const items = data.items as listItem[];
  const { addUtmCWAParamWithoutUtmContent, addUtmCWAParamWithUtmContent } = useCWA();
  return (
    <>
      {items.length === 0 ? (
        <div style={{ textAlign: 'center', marginTop: '20px', marginBottom: '20px' }}>No items available</div>
      ) : (
        <div className={styles.list}>
          {items.map((item, index) => {
            const itemInfo = { ...item };
            if (item.typeMainLink === ETypeMainLink.WithUtmContent) {
              itemInfo.link = addUtmCWAParamWithUtmContent(itemInfo.link);
            } else {
              itemInfo.link = addUtmCWAParamWithoutUtmContent(itemInfo.link);
            }

            if (itemInfo.webLink) {
              if (item.typeWebLink === ETypeWebLink.WithUtmContentRef) {
                itemInfo.webLink = addUtmCWAParamWithUtmContent(itemInfo.link, true);
              } else {
                itemInfo.webLink = addUtmCWAParamWithoutUtmContent(itemInfo.link);
              }
            }

            return <ListItem className={styles.item} key={index} item={itemInfo} />;
          })}
        </div>
      )}
    </>
  );
}
