import { ChangeEvent, FC, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Arrow from '@assets/colorless/arrowLeft.svg?react';

import SupportInput, { SupportInputVariant } from '@pages/Support/view/component/SupportInput';

import { Root } from '@service/reducers';

import { Button, ButtonVariant } from '@shared/index';

import { QuestionViewType } from './types';
import { validateEmail, Validator } from '../services/utils';

import styles from './SuspendCard.module.scss';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const extractValues = (obj: Record<string, any>) => {
  let email = '';
  let message = '';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Object.values(obj).forEach((value: any) => {
    if (value.email) {
      email = value.email;
    }
    if (value.message) {
      message = value.message;
    }
  });

  return { email, message };
};
type SuspendCardProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  suspendConfig: Record<string, any>;
  openDispute: (email: string, message: string) => void;
  isLoading: boolean;
};

export const SuspendCard: FC<SuspendCardProps> = ({ suspendConfig, openDispute, isLoading }) => {
  const [currentStep, setCurrentStep] = useState<string>('1');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [formState, setFormState] = useState<Record<string, any>>({});
  const [error, setError] = useState<string | null>(null);
  const user = useSelector((store: Root) => store.user);
  const currentConfig = suspendConfig.steps[currentStep];

  useEffect(() => {
    const savedState = localStorage.getItem('suspendCardFormState');
    if (savedState) {
      const parsedState = JSON.parse(savedState);
      setFormState(parsedState.formState || {});
      setCurrentStep(parsedState.currentStep || '1');
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(
      'suspendCardFormState',
      JSON.stringify({
        formState,
        currentStep,
      })
    );
  }, [formState, currentStep]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    setFormState((prev) => ({
      ...prev,
      [currentStep]: {
        ...prev[currentStep],
        [name]: value,
      },
    }));
  };

  const handleChoiceClick = (id: string) => {
    setFormState((prev) => ({
      ...prev,
      [currentStep]: { choice: id },
    }));
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const validateStep = (stepData: any): boolean => {
    let validationError: string | null = Validator.validate(
      stepData?.message || stepData?.choice || '',
      currentConfig?.validation || {}
    );

    if (currentConfig.type === QuestionViewType.TEXT_EMAIL) {
      validationError = validateEmail(stepData?.email) ? null : 'Invalid email address.';
    }

    if (validationError) {
      setError(validationError);
      return false;
    }

    setError(null);
    return true;
  };
  const handleSubmit = () => {
    const { email, message } = extractValues(formState);
    openDispute(email || user.email, message);
  };
  const handleNextStep = () => {
    const currentData = formState[currentStep];

    if (!validateStep(currentData)) return;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const nextStep = currentConfig.next?.find((n: any) => n.id === currentData?.choice)?.to;
    if (nextStep) {
      setCurrentStep(nextStep);
    }
    if (currentConfig.isLast) {
      handleSubmit();
    }
  };

  const handlePrevStep = () => {
    if (currentConfig.prev) {
      setCurrentStep(currentConfig.prev);
    }
  };

  useEffect(() => {
    if (formState[currentStep]) {
      validateStep(formState[currentStep]);
    }
  }, [formState, currentStep]);

  return (
    <>
      <div className={styles.wrapper}>
        {currentConfig.title && <h3 dangerouslySetInnerHTML={{ __html: currentConfig.title }} />}
        {currentConfig.description && (
          <p
            className={`${styles.suspendCard__description} ${styles.description}`}
            dangerouslySetInnerHTML={{ __html: currentConfig.description }}
          />
        )}
        {currentConfig.type === QuestionViewType.CHECKBOX_MULTIPLE && currentConfig.choices && (
          <div>
            {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              currentConfig.choices.map((choice: any) => (
                <label key={choice.id} className={styles.checkbox}>
                  <input
                    type="radio"
                    name="choice"
                    checked={formState[currentStep]?.choice === choice.id}
                    onChange={() => handleChoiceClick(choice.id)}
                  />
                  {choice.label}
                </label>
              ))
            }
          </div>
        )}
        {[QuestionViewType.TEXT_LONG, QuestionViewType.TEXT_EMAIL].includes(currentConfig.type) && (
          <SupportInput
            label={currentConfig.label}
            variant={
              currentConfig.type === QuestionViewType.TEXT_LONG ? SupportInputVariant.Text : SupportInputVariant.Email
            }
            name={currentConfig.type === QuestionViewType.TEXT_LONG ? 'message' : 'email'}
            value={
              formState[currentStep]?.[currentConfig.type === QuestionViewType.TEXT_LONG ? 'message' : 'email'] || ''
            }
            maxLength={2000}
            onChange={handleInputChange}
          />
        )}
      </div>
      {currentConfig.urls && (
        <div className={styles.wrapper} style={{ marginBottom: '20px' }}>
          <div className={styles.urls}>
            {currentConfig.urls.map((url: string, idx: number) => (
              <a href={url} target="_blank" rel="noopener noreferrer" key={idx}>
                {url}
              </a>
            ))}
          </div>
        </div>
      )}
      {error && (
        <div className={styles.wrapper} style={{ marginBottom: '20px' }}>
          <p style={{ color: 'red' }}>{error}</p>
        </div>
      )}
      <div className={styles.suspendCard__controls}>
        <Button type="button" size="medium" onClick={handlePrevStep} Icon={<Arrow />} disabled={!currentConfig.prev}>
          Back
        </Button>

        <Button
          type="button"
          size="medium"
          onClick={handleNextStep}
          variant={ButtonVariant.Primary}
          disabled={(!currentConfig.next && !currentConfig.isLast) || isLoading}
        >
          Next
        </Button>
      </div>
    </>
  );
};
