import cn from 'classnames';
import React, { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import NCW from '@assets/colored/ncwallet-logo.svg?react';
import { CircleCancelSvg } from '@assets/colorless';
import ArrowBack from '@assets/colorless/arrow-right-sm.svg?react';
import { BtcDecorImg, BtcDecorRetinaImg } from '@assets/img';

import { getAddress } from '@utils/getAddress';
import { getTransactionLink } from '@utils/getTransactionLink';
import { BROWSER_BALANCE } from '@utils/links';
import { Color, getTransactionColor } from '@utils/transactionStatus';
import useIntlFormatter from '@utils/useIntlFormatter';

import { Button, ButtonVariant } from '@shared/index';
import { TransactionStatus } from '@shared/TransactionStatus/TransactionStatus';

import { Withdrawal } from '@backend/JsonRpcApi';

import { WithdrawalNote } from './WithdrawalNote/WithdrawalNote';
import { mapStatus } from '../../BrowserBalance/view/components/mapStatus';

import styles from './WithdrawalDetailsView.module.scss';

type WithdrawalDetailsViewProps = {
  withdrawal: Withdrawal;
  onWithdrawalConfirm: (id: string) => Promise<void>;
  onWithdrawalCancel: (id: string) => void;
  isBitcoin: boolean;
};

export const WithdrawalDetailsView = (props: WithdrawalDetailsViewProps) => {
  const { withdrawal, onWithdrawalCancel, onWithdrawalConfirm, isBitcoin } = props;
  const { t } = useTranslation();
  const { intFormatter } = useIntlFormatter();
  const [statusLabel] = mapStatus(withdrawal.state, t);
  const transactionLink = getTransactionLink(withdrawal.payment_service, withdrawal.transaction_id);

  const theme = getTransactionColor(withdrawal.state);
  const mods = {
    [styles.default]: Color.DEFAULT === theme,
    [styles.blue]: Color.BLUE === theme,
    [styles.red]: Color.RED === theme,
    [styles.gray]: Color.GRAY === theme,
    [styles.success]: Color.SUCCESS === theme,
  };

  return (
    <div className={styles.withdrawal}>
      <img src={BtcDecorImg} srcSet={BtcDecorRetinaImg + ' 2x'} className={styles.decor} alt="btc" />
      <div className={styles.withdrawal__card}>
        <div className={styles.withdrawal__card__header}>
          <h1 className={styles.withdrawal__card__header__title}>{t('Payment Details')}</h1>
          <TransactionStatus state={withdrawal.state} />
        </div>
        <div className={styles.withdrawal__card__body}>
          {withdrawal.state === 11 && (
            <>
              <div className={styles.withdrawal__card__warning}>
                <Trans
                  i18nKey="Due to increased cases of fraud activity, please review with care and confirm bitcoin addresses for your withdrawals is correct to proceed the operation. In case if you find your address is different than expected, please cancel your withdrawal and %(link)scontact our support team.%(link_end)s"
                  components={{
                    a: <a href="/app/support" />,
                  }}
                />
              </div>
            </>
          )}
          <div className={cn([styles.withdrawal__transaction, mods])}>
            <div className={styles.withdrawal__transaction__row}>
              <b>{t('Amount')}: </b>
              <span>
                {isBitcoin
                  ? `${intFormatter(Number(withdrawal.amount).toFixed(8))} BTC`
                  : `${intFormatter(withdrawal.amount, { minimumFractionDigits: 0, maximumFractionDigits: 8 })} HSH`}
              </span>
            </div>
            <div className={styles.withdrawal__transaction__row} style={{ paddingRight: 10 }}>
              <b>{t('Address')}: </b>
              <span>{getAddress(withdrawal)}</span>
              {withdrawal.payment_service === 'ncw' && <NCW className={styles.label__logo} />}
            </div>
            {withdrawal.state === 9 || withdrawal.state === 7 ? (
              <div className={styles.withdrawal__transaction__row}>
                <b>{t('Status')}: </b>
                {withdrawal.payment_service === 'ncw' &&
                  withdrawal.state === 9 &&
                  `${t('withdrowDetails.priorityPayment')} `}
                <a href={transactionLink} target={'_blank'} rel="noreferrer">
                  {statusLabel}
                </a>
              </div>
            ) : (
              <div className={styles.withdrawal__transaction__row}>
                <b>{t('Status')}: </b>
                <span>{statusLabel}</span>
              </div>
            )}
          </div>
          <div className={styles.withdrawal__note}>
            <WithdrawalNote status={withdrawal.state} />
          </div>
        </div>
        <div className={styles.withdrawal__card__footer}>
          <WithdrawalActions
            status={withdrawal.state}
            onConfirm={() => onWithdrawalConfirm(withdrawal.id)}
            onCancel={() => onWithdrawalCancel(withdrawal.id)}
          />
        </div>
      </div>
    </div>
  );
};

const WithdrawalActions = ({
  status,
  onCancel,
  onConfirm,
}: {
  status: number;
  onCancel: () => void;
  onConfirm: () => Promise<void>;
}) => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const handleGoBackToCurrentPage = useCallback((e: React.SyntheticEvent) => {
    e.preventDefault();
    const prevPage = (history.location.state as { currentPage: number })?.currentPage || 1;

    history.push({
      pathname: BROWSER_BALANCE,
      state: { currentPage: prevPage },
    });
  }, []);

  switch (status) {
    case 1:
      return (
        <>
          <Button
            type={'link'}
            size="medium"
            to={BROWSER_BALANCE}
            className={styles.withdrawal__btn}
            Icon={<ArrowBack />}
          >
            {t('Back')}
          </Button>
          <Button
            type={'button'}
            onClick={onCancel}
            size="medium"
            Icon={<CircleCancelSvg />}
            className={styles.withdrawal__btn}
            variant={ButtonVariant.Primary}
          >
            {t('Cancel')}
          </Button>
        </>
      );
    case 12:
      return (
        <>
          <Button
            type={'link'}
            size="medium"
            to={BROWSER_BALANCE}
            className={styles.withdrawal__btn}
            Icon={<ArrowBack />}
          >
            {t('Back')}
          </Button>
          {/* <Button
            type={'button'}
            onClick={onCancel}
            size="medium"
            Icon={<CircleCancelSvg />}
            className={styles.withdrawal__btn}
            variant={ButtonVariant.Primary}
          >
            {t('Cancel')}
          </Button> */}
        </>
      );
    case 11:
      return (
        <>
          <Button
            type={'link'}
            size="medium"
            to={BROWSER_BALANCE}
            className={styles.withdrawal__btn}
            Icon={<ArrowBack />}
          >
            {t('Back')}
          </Button>
          <Button
            type={'button'}
            size="medium"
            disabled={isLoading}
            onClick={() => {
              setLoading(true);
              onConfirm().finally(() => setLoading(false));
            }}
            className={styles.withdrawal__btn}
          >
            {t('Confirm')}
          </Button>
        </>
      );
    default:
      return (
        <Link
          type={'link'}
          to={BROWSER_BALANCE}
          className={cn(styles.withdrawal__btn, styles.withdrawal__btn__back)}
          onClick={(e) => handleGoBackToCurrentPage(e)}
        >
          <ArrowBack />
          {t('Back')}
        </Link>
      );
  }
};
