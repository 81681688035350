import cn from 'classnames';
import { useEffect, useState } from 'react';

import { useBreakpoints } from '@hooks/useBreakpoints';

import { FarmTabs } from '@shared/FarmTabs/FarmTabs';

import styles from './StickyFarmTabs.module.scss';

export function StickyFarmTabs() {
  const [show, setShow] = useState(false);
  const [className, setClassName] = useState('');

  const { isMobile } = useBreakpoints();

  const controlNavbar = () => {
    let timeout;

    if (window.scrollY > 150 && !show) {
      setShow(true);
      setClassName(styles.fadeInUp);
      clearTimeout(timeout);
    } else if (window.scrollY <= 150 && show) {
      setClassName(styles.fadeOutDown);

      timeout = setTimeout(() => {
        setShow(false);
      }, 300);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', controlNavbar);
    return () => {
      window.removeEventListener('scroll', controlNavbar);
    };
  }, [show]);

  return (
    <>
      {show && !isMobile && (
        <div className={cn(styles.tabsWrapper, className)}>
          <FarmTabs />
        </div>
      )}
    </>
  );
}
