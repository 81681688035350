import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ArrowDownSvg, LevelSvg } from '@assets/colored';

import { Root } from '@service/reducers';

import { Card } from '@shared/index';
import { Picture } from '@shared/Picture/Picture';

import styles from './Develop.module.scss';

export const Develop = () => {
  const { t } = useTranslation();
  const { isDarkTheme } = useSelector((store: Root) => store.system);

  return (
    <Card className={styles.card}>
      <div className={styles.imageWrapper}>
        <div className={styles.tableInfo}>
          <div className={styles.levelInfo}>
            <LevelSvg />
            <p className={styles.level}>{t('YourPersonalLink')}</p>
            <ArrowDownSvg className={styles.arrow} />
          </div>
          <div className={styles.titleWrapper}>
            <h2 className={styles.title}>{t("Let's assemble a huge mining pool together!")}</h2>
            <p className={styles.description}>
              <Trans
                i18nKey={
                  'You will get percentage of the revenue from every active user of CryptoTab browser that followed your link to register. Your affiliate network will spread to encompass up to ten levels of friends.'
                }
                components={{
                  strong: <strong />,
                }}
              />
            </p>
          </div>
        </div>
        <div className={styles.tableImage}>
          <Picture
            imageDesktop={
              isDarkTheme
                ? 'https://cdn.cryptobrowser.site/images/affiliate-redesign/ct-browser/table-dark.png'
                : 'https://cdn.cryptobrowser.site/images/affiliate-redesign/ct-browser/table.png'
            }
            imageDesktop2x={
              isDarkTheme
                ? 'https://cdn.cryptobrowser.site/images/affiliate-redesign/ct-browser/table-dark@2x.png'
                : 'https://cdn.cryptobrowser.site/images/affiliate-redesign/ct-browser/table@2x.png'
            }
            imageMob={
              isDarkTheme
                ? 'https://cdn.cryptobrowser.site/images/affiliate-redesign/ct-browser/table-dark-mob.png'
                : 'https://cdn.cryptobrowser.site/images/affiliate-redesign/ct-browser/table-mob-upd.png'
            }
            imageMob2x={
              isDarkTheme
                ? 'https://cdn.cryptobrowser.site/images/affiliate-redesign/ct-browser/table-dark-mob@2x.png'
                : 'https://cdn.cryptobrowser.site/images/affiliate-redesign/ct-browser/table-mob-upd@2x.png'
            }
            breakPoint={'640px'}
          ></Picture>
        </div>

        <p className={styles.description_note}>...{t('and we a going to pay you %% of all their minings.')}</p>
      </div>
    </Card>
  );
};
