import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';

import { BROWSER_BALANCE } from '@utils/links';

import { AllSubheader } from '@pages/headersComponents/AllSubheader';
import { CancelWithdrawalSucсessModal } from '@pages/WithdrawalDetails/view/CancelWithdrawalSucсessModal';

import { getWithdrawal, resetWithdrawal } from '@service/actions/getWithdrawal';
import { GET_WITHDRAWALS_REQUEST } from '@service/constant';
import { WithdrawalState } from '@service/reducers/withdrawalReducer';

import Loader from '@shared/Loader';
import { useModal } from '@shared/Modal';

import { rpc } from '@backend/Rpc';

import { HelmetHead } from '../headersComponents/HelmetHead';
import { WithdrawalCancelModal } from './view/WithdrawalCancelModal';
import { WithdrawalDetailsView } from './view/WithdrawalDetailsView';

export type WithdrawalDetailsProps = RouteComponentProps<{ id: string; isBitcoin: string }>;

export const WithdrawalDetails = (props: WithdrawalDetailsProps) => {
  const history = useHistory();
  const { id, isBitcoin } = props.match.params;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const withdrawalState = useSelector((state: { withdrawal: WithdrawalState }) => state.withdrawal);
  const cancelWithdrawalModal = useModal();
  const cancelWithdrawalSuccessModal = useModal();
  const [isLoading, setIsLoading] = useState(false);

  const handleCanelWithdrawalSuccessClose = useCallback(() => {
    cancelWithdrawalSuccessModal.onClose();
    history.replace(BROWSER_BALANCE);
    dispatch({ type: GET_WITHDRAWALS_REQUEST });
  }, []);

  const handleWithdrawalConfirm = useCallback(
    async (id: string) => {
      setIsLoading(true);

      try {
        try {
          await rpc.transmit('withdrawals.reconfirm_withdrawal', { id });
        } catch (error) {
          console.error('Error reconfirm withdrawal:', error);
        }

        history.replace(BROWSER_BALANCE);
        dispatch({ type: GET_WITHDRAWALS_REQUEST });
      } finally {
        setIsLoading(false);
      }
    },
    [history, dispatch]
  );

  const handleWithdrawalCancel = useCallback(async () => {
    setIsLoading(true);
    try {
      if (isBitcoin === 'true') {
        await rpc.transmit('withdrawals.delete_withdrawal', {
          id: withdrawalState.withdrawal!.id,
        });
      } else {
        await rpc.transmit('wallets.withdrawals.cancel', {
          withdrawal_id: withdrawalState.withdrawal!.id,
        });
      }
      cancelWithdrawalSuccessModal.onOpen();
    } finally {
      setIsLoading(false);
    }
  }, [withdrawalState.withdrawal, history, dispatch]);

  useEffect(() => {
    dispatch(getWithdrawal(id, isBitcoin === 'true'));

    return () => {
      dispatch(resetWithdrawal());
    };
  }, [dispatch, id, isBitcoin]);

  if (!withdrawalState.withdrawal || !withdrawalState.hasRequested || withdrawalState.isLoading) {
    return <Loader />;
  }

  return (
    <>
      <HelmetHead title={t('Payment History')} />
      <AllSubheader />
      <WithdrawalDetailsView
        withdrawal={withdrawalState.withdrawal}
        onWithdrawalConfirm={handleWithdrawalConfirm}
        onWithdrawalCancel={() => cancelWithdrawalModal.onOpen()}
        isBitcoin={isBitcoin === 'true'}
      />
      <WithdrawalCancelModal
        withdrawal={withdrawalState.withdrawal}
        isVisible={cancelWithdrawalModal.opened}
        onClose={() => cancelWithdrawalModal.onClose()}
        onCancel={handleWithdrawalCancel}
        isBitcoin={isBitcoin === 'true'}
        isLoading={isLoading}
      />
      <CancelWithdrawalSucсessModal
        isVisible={cancelWithdrawalSuccessModal.opened}
        onClose={handleCanelWithdrawalSuccessClose}
        isBitcoin={isBitcoin === 'true'}
        withdrawal={withdrawalState.withdrawal}
      />
    </>
  );
};
