import Lottie from 'lottie-react';
import { CSSProperties } from 'react';

import { arrow } from '@utils/animation';

import styles from './Arrow.module.scss';

export type ArrowProps = {
  style?: CSSProperties;
  variant?: ArrowVariant;
  className?: string;
};

export enum ArrowVariant {
  Left,
  Right,
  Top,
  Down,
}

export default function Arrow({ style, variant = ArrowVariant.Down, className }: ArrowProps) {
  return (
    <Lottie
      animationData={arrow}
      loop={true}
      className={`${styles.arrow} ${className} ${variant === ArrowVariant.Left && styles.arrow__left} ${
        variant === ArrowVariant.Right && styles.arrow__right
      } ${variant === ArrowVariant.Top && styles.arrow__top}`}
      style={style}
    />
  );
}
