import { apiClient } from '../../api/apiClient';
import { getAuthTokensPathname } from '../../constants/url';
import { AppDispatch } from '../../main.tsx';
import { BROWSER_BANNED } from '../../utils/links';
import { watchLocalStorage } from '../../utils/storage';
import { USER_TOKENS_SUCCESS } from '../constant';

export const getAuthTokens = (withoutRedirect = false, navigate?: () => void) => {
  return async function (dispatch: AppDispatch) {
    const tokens_ts = localStorage.getItem('tokens_loading');
    if (!tokens_ts || isNaN(parseInt(tokens_ts)) || Date.now() - parseInt(tokens_ts) > 10000) {
      localStorage.setItem('tokens_loading', '' + Date.now());
      try {
        const did = localStorage.getItem('did');
        const urlToken = getAuthTokensPathname(did);
        const tokens: {
          id: string;
          access_token: string;
          refresh_token: string;
        } = await new apiClient().post(urlToken);
        localStorage.setItem('tokens', JSON.stringify(tokens));
        dispatch({
          type: USER_TOKENS_SUCCESS,
          tokens: {
            accessToken: tokens.access_token,
            refreshToken: tokens.refresh_token,
          },
        });
      } catch (er: unknown) {
        if (!withoutRedirect) {
          if (navigate) {
            navigate();
            return;
          }
          if (er instanceof Response) {
            const reason = await er.json();
            if (reason.code === 'account_banned') {
              window.location.href = BROWSER_BANNED;
              return;
            }
          }
          window.location.href = '/login/?next=/app/';
        }
      } finally {
        localStorage.removeItem('tokens_loading');
      }
    } else {
      try {
        const storage_tokens = await watchLocalStorage('tokens', 3000);
        if (storage_tokens) {
          const tokens = JSON.parse(storage_tokens);
          dispatch({
            type: USER_TOKENS_SUCCESS,
            tokens: {
              accessToken: tokens.access_token,
              refreshToken: tokens.refresh_token,
            },
          });
        }
      } catch (e) {
        console.log(e);
      }
    }
  };
};
