import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CopyButton from './CopyButton';

import styles from './PromoCard.module.scss';

export type InputPromoProps = {
  label: string;
  link: string;
  code: string;
  divider?: boolean;
  withTab?: boolean;
};

type Pref = 'apply/' | 'go/';

export default function InputPromo({ label, link, code, withTab, divider }: InputPromoProps) {
  const { t } = useTranslation();
  const [pref, setPref] = useState<Pref | ''>('');
  const value = useMemo(() => `${link}${pref}${code}`, [link, pref, code]);
  return (
    <div className={styles.input}>
      {divider && <div className={styles.input__divider}>{t('or')}</div>}
      <div className={styles.input__label}>{label}</div>
      <div className={styles.input__row}>
        <div className={styles.input__area}>{value.replace(/https:\/\/|http:\/\//gi, '')}</div>
        <CopyButton copyText={value} />
      </div>
      {withTab && <Tab setAll={setPref} />}
    </div>
  );
}

const Tab = ({ setAll }: { setAll: (val: Pref) => void }) => {
  const { t } = useTranslation();
  const [active, setActive] = useState<'apply' | 'go'>('apply');
  useEffect(() => {
    setAll((active + '/') as Pref);
  }, []);
  const switchTab = useCallback(
    (pref: 'apply' | 'go') => {
      setActive(pref);
      setAll((pref + '/') as Pref);
    },
    [setAll]
  );
  return (
    <div className={styles.tabs}>
      <div className={`${styles.tab} ${active === 'apply' && styles.tab__active}`} onClick={() => switchTab('apply')}>
        {t('application')}
      </div>
      <div className={`${styles.tab} ${active === 'go' && styles.tab__active}`} onClick={() => switchTab('go')}>
        {t('landing')}
      </div>
    </div>
  );
};
