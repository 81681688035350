import { DependencyList, useEffect } from 'react';

export const useAnimation = (dependencies: DependencyList = []) => {
  useEffect(() => {
    const animElements = document.querySelectorAll('.animate__animated');
    const options = {
      root: null,
      rootMargin: '0px',
      thresholds: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const element = entry.target as HTMLElement;
          element.classList.add(`animate__${element.dataset.anim}`);
          element.classList.add('animate__animated--finish');
        }
      });
    }, options);

    animElements.forEach((item) => observer.observe(item));

    return () => {
      observer.disconnect();
    };
  }, dependencies);
};
