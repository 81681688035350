import React, { useCallback, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { IconWalletSvg } from '@assets/colorless';

import { externalLinks } from '@utils/externalLinks';
import { formatAddress } from '@utils/formatAddress';

import { useBreakpoints } from '@hooks/useBreakpoints';

import { useModal } from '@shared/Modal';
import ModalHSHLayout from '@shared/ModalHSHLayout';

interface ModalNcwErrorProps {
  isShow: boolean;
  email: string;
  changeIsShow: () => void;
}

export default function ModalNcwError({ isShow, changeIsShow, email }: ModalNcwErrorProps) {
  const { opened, onOpen, onClose } = useModal(isShow);
  const { t } = useTranslation();
  const { isMobile } = useBreakpoints();

  useEffect(() => {
    if (isShow) onOpen();
  }, [isShow]);

  const handleClick = useCallback(() => {
    window.open(externalLinks.ncwTry);
  }, []);

  const handleClose = useCallback(() => {
    changeIsShow();
    onClose();
  }, []);

  return (
    <ModalHSHLayout
      opened={opened}
      onClose={handleClose}
      title={t('Connect_NCW.Not_found.Title')}
      text={
        <Trans
          defaults="Connect_NCW.Not_found.Description"
          values={{ email: formatAddress(email, isMobile) }}
          components={{ b: <b /> }}
        />
      }
      isBackButton={true}
      button={{
        type: 'button',
        text: t('Connect_NCW.Create.Btn'),
        icon: <IconWalletSvg />,
        onClick: handleClick,
      }}
      isNCW={true}
    />
  );
}
