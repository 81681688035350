/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useState } from 'react';

import SupportInput, { SupportInputVariant } from '@pages/Support/view/component/SupportInput';

import { SupportError } from './SuspendController';

import styles from './suspendCard.module.scss';

type SuspendCardProps = {
  question: string;
  checkboxes?: { [key: string]: string }[];
  field?: { [key: string]: string | any };
  setIsNext: Dispatch<SetStateAction<boolean>>;
  page: number;
  setNotRead: Dispatch<SetStateAction<boolean>>;
  urls: string[];
  notRead: boolean;
  formState: { [key: string]: string }[];
  setFormState: Dispatch<SetStateAction<{ [key: string]: string }[]>>;
  error: SupportError;
};

export const SuspendCard: FC<SuspendCardProps> = (props) => {
  const { question, checkboxes, field, setIsNext, page, setNotRead, urls, notRead, formState, setFormState, error } =
    props;

  const [textContent, setTextContent] = useState<string>(formState[page]?.[field?.name] || '');
  const [isFiled, setIsFiled] = useState<boolean>(false);
  const [isCheck, setIsCheck] = useState<string[]>([]);
  const [subFieldName, setSubFieldName] = useState<string>('');
  const [isSubField, setIsSubField] = useState<boolean>(false);
  const [isOrderField, setIsOrderField] = useState<boolean>(false);
  const limitField = isOrderField ? 10 : 30;

  const handleCheckboxClick = (id: string, text: string, checked: boolean) => {
    if (checked) {
      setFormState((prevFormState: { [key: string]: string }[]) => handleFillForm(prevFormState, id, text));
    } else {
      isCheck.length === 1 && setIsNext(false);
    }
  };

  const handleFillForm = (prevFormState: { [key: string]: string }[], id: string, text: string) => {
    const idx = prevFormState.findIndex((_, index) => index === page);

    if (idx === -1) {
      const newPage = { [id]: text };
      const newFormState = [...prevFormState];
      newFormState[page] = newPage;
      return newFormState;
    } else {
      const updatedFormPage = { ...prevFormState[idx], [id]: text };
      const newFormState = [...prevFormState];
      newFormState[idx] = updatedFormPage;
      return newFormState;
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormState((prevFormState: { [key: string]: string }[]) => {
      const updatedFormState = handleFillForm(prevFormState, name, value);
      return updatedFormState;
    });
    setTextContent(value);
  };

  const handleClick = (e: any, text: string) => {
    const { id, checked } = e.target;

    setIsCheck((prevIsCheck) => {
      const updatedIsCheck = checked ? [...prevIsCheck, id] : prevIsCheck.filter((it) => it !== id);
      return updatedIsCheck;
    });

    handleCheckboxClick(id, text, checked);
  };

  const handleRadioClick = (e: any, text: string) => {
    const { id, checked } = e.target;

    setIsCheck((prevIsCheck) => [...prevIsCheck.filter((it) => it.replace(/\d+$/, '') !== id.replace(/\d+$/, '')), id]);

    handleCheckboxClick(id, text, checked);

    if (page === 0 && id === 'Read2') {
      setNotRead(true);
    }
  };

  useEffect(() => {
    if (field?.type === 'text' && textContent.length < limitField) {
      setIsFiled(false);
    } else {
      setIsFiled(true);
    }
  }, [textContent, field, limitField]);

  useEffect(() => {
    setIsCheck([]);
    setIsFiled(false);
    setTextContent('');
    setSubFieldName('');
    setIsSubField(false);
  }, [page, notRead]);

  useEffect(() => {
    if (formState[page]?.[subFieldName] !== undefined) {
      setIsNext(formState[page][subFieldName].length > limitField);
    }
  }, [formState, page, subFieldName, limitField, setIsNext]);

  useEffect(() => {
    if (field && !checkboxes) {
      setIsNext(isFiled);
    } else if (!field && checkboxes && !isSubField) {
      setIsNext(isCheck.length !== 0);
    } else if (field && checkboxes && !isSubField) {
      setIsNext(isCheck.length !== 0 && isFiled);
    }
  }, [isCheck, isFiled, field, checkboxes, isSubField, setIsNext]);

  const fieldMap: { [key: string]: SupportInputVariant } = {
    text: SupportInputVariant.Text,
    email: SupportInputVariant.Email,
    url: SupportInputVariant.Url,
    order: SupportInputVariant.Default,
  };

  return (
    <>
      {question && (
        <h5 className={notRead ? styles.suspendCard__subtitle__sub : styles.suspendCard__subtitle}>{question}</h5>
      )}
      {checkboxes &&
        checkboxes.map((checkbox: any, idx: number) => (
          <div key={idx}>
            <label htmlFor={checkbox.id} className={styles.suspendCard__checkbox}>
              <input
                type={checkbox.type ? checkbox.type : 'checkbox'}
                id={checkbox.id}
                onChange={(e) => {
                  if (checkbox.field) {
                    setSubFieldName(checkbox.field.name);
                    setIsSubField(true);
                    if (checkbox.field.type === 'order') {
                      setIsOrderField(true);
                    }
                  }
                  checkbox.type === 'radio' ? handleRadioClick(e, checkbox.text) : handleClick(e, checkbox.text);
                }}
                checked={isCheck.includes(checkbox.id)}
                name={checkbox.name}
              />
              {checkbox.text}
            </label>
            {isCheck.includes(checkbox.id) && checkbox.sub && (
              <div className={styles.suspendCard__container__sub}>
                <h5 className={styles.suspendCard__subtitle__sub}>{checkbox.sub.question}</h5>
                {checkbox.sub.checkboxes.map((sub: any, subIdx: number) => (
                  <label htmlFor={sub.id} key={subIdx} className={styles.suspendCard__checkbox__sub}>
                    <input
                      type={sub.type ? sub.type : 'checkbox'}
                      id={sub.id}
                      onChange={(e) =>
                        sub.type === 'radio' ? handleRadioClick(e, sub.text) : handleClick(e, sub.text)
                      }
                      checked={isCheck.includes(sub.id)}
                      name={sub.name}
                    />
                    {sub.text}
                  </label>
                ))}
              </div>
            )}
            {isCheck.includes(checkbox.id) && checkbox.field && (
              <SupportInput
                label={checkbox.field.label}
                variant={fieldMap[checkbox.field.type]}
                name={checkbox.field.name}
                value={formState[page]?.[checkbox.field.name] || ''}
                onChange={handleChange}
                error={error}
              />
            )}
          </div>
        ))}
      {field && (
        <SupportInput
          label={field.label}
          variant={fieldMap[field.type]}
          name={field.name}
          value={textContent}
          onChange={handleChange}
          error={error}
        />
      )}
      {urls &&
        urls.map((url: string, idx: number) => (
          <a href={url} className={styles.suspendCard__url} target="_blank" rel="noopener noreferrer" key={idx}>
            {url}
          </a>
        ))}
    </>
  );
};
