import { rpc } from '@backend/Rpc';

import { AppDispatch } from 'src/main.tsx';

import { NFT_PROMO_ERROR, NFT_PROMO_REQUEST, NFT_PROMO_SUCCESS } from '../constant';

export default function getNFTPromo() {
  return async function (dispatch: AppDispatch) {
    try {
      dispatch({ type: NFT_PROMO_REQUEST });
      const resp = await rpc.transmit('nft.promo-codes', {});
      const amountResp = await rpc.transmit('nft.get_payments_statistics', {});
      const nftPromo = resp.map((item) => ({
        ...item,
        amount: amountResp.find((_) => _.promo_code === item.code)?.amount || 0,
      }));
      dispatch({ type: NFT_PROMO_SUCCESS, promo: nftPromo });
    } catch (e) {
      dispatch({ type: NFT_PROMO_ERROR });
      console.log(e);
    }
  };
}
