import { useLocation } from 'react-router-dom';

import { MAIN_URL } from '@utils/links';

export const useCWA = () => {
  const location = useLocation();
  const uid = sessionStorage.getItem('uid') || '';
  const cid = sessionStorage.getItem('cid') || '';
  window.utmHelperInstance.updateUtm({ utm_content: `${cid}:${uid}` });

  const updateUtmMedium = () => {
    // eslint-disable-next-line no-useless-escape
    const transformedUrl = location.pathname.replace(`${MAIN_URL}/`, '').replace(/[\/-]/g, '_');
    window.utmHelperInstance.updateUtm({ utm_medium: transformedUrl });
  };

  const addReferrerToDeepLink = (link: string): string => {
    const arr = [
      ['utm_cid', cid],
      ['utm_uid', uid],
    ];

    const deeplinkPararmLink = new URL(link).searchParams.get('link');
    if (!deeplinkPararmLink) return link;

    const newParamLink = window.utmHelperInstance.updateUrlWithUtm({
      targetUrl: deeplinkPararmLink,
      utmParams: arr,
      targetUpdateParam: 'referrer',
    });
    const newUrlLink = new URL(link);
    newUrlLink.searchParams.set('link', newParamLink);
    return newUrlLink.toString();
  };

  const addUtmCWAParamWithUtmContent = (link: string, withRef?: boolean): string => {
    const arr = Object.entries(window.utmHelperInstance.getUtm());

    return window.utmHelperInstance.updateUrlWithUtm({
      targetUrl: link,
      utmParams: arr,
      targetUpdateParam: withRef && 'ref',
    });
  };

  const addUtmCWAParamWithoutUtmContent = (link: string): string => {
    const arr = [
      ['utm_cid', cid],
      ['utm_uid', uid],
    ];

    return window.utmHelperInstance.updateUrlWithUtm({ targetUrl: link, utmParams: arr });
  };

  return {
    uid,
    cid,
    addReferrerToDeepLink,
    addUtmCWAParamWithUtmContent,
    addUtmCWAParamWithoutUtmContent,
    updateUtmMedium,
  };
};
