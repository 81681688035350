import { CommonLinksInfo } from '@pages/CommonLinks/CommonLinks';

import { AllLinks } from '@shared/AllLinks/AllLinks';
import { Container } from '@shared/Container/Container';

type CommonLinksViewProps = {
  linksInfo: CommonLinksInfo;
  onLinkCreate: () => void;
  handleRefresh: () => void;
  isLoading?: boolean;
};
export default function CommonLinksView(props: CommonLinksViewProps) {
  return (
    <Container vertical={20}>
      <AllLinks
        hideViewAllBtn
        {...props}
        onLinkCreate={props.onLinkCreate}
        isLoading={props.isLoading}
        handleRefresh={props.handleRefresh}
      />
    </Container>
  );
}
