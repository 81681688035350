import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import ArrowDownSvg from '@assets/arrow-list.svg?react';
import ArrowLeftSVG from '@assets/colorless/arrowLeft.svg?react';

import { formatDate } from '@utils/formatDate';

import useClickOutside from '@hooks/useClickOutside';

import { Withdrawal } from '../../../../../backend/JsonRpcApi';
import WithdrawalTag, { tagConfig } from '../WithdrawalTag/WithdrawalTag';

import styles from './WithdrawalSelect.module.scss';

type WithdrawalSelectProps = {
  selectedWithdrawalId: string;
  withdrawals: Withdrawal[];
  onSelect: (id: string) => void;
};

export const WithdrawalSelect: FC<WithdrawalSelectProps> = (props) => {
  const { selectedWithdrawalId, withdrawals, onSelect } = props;
  const selectRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);
  const selectedWithdrawal = useMemo(() => {
    return withdrawals.find((it) => it.id === selectedWithdrawalId);
  }, [withdrawals, selectedWithdrawalId]);

  useEffect(() => {
    document.body.classList.toggle('no-layout', isOpen);
  }, [isOpen]);

  useEffect(() => {
    return () => {
      document.body.classList.remove('no-layout');
    };
  }, []);

  useClickOutside(selectRef, handleOpen, isOpen);

  return (
    <>
      {isOpen && (
        <div className={styles.withdrawalSelect__list__header_mobile} onClick={() => setIsOpen(false)}>
          <ArrowLeftSVG />
          <span>Choose Withdrawal</span>
        </div>
      )}
      <div className={styles.withdrawalSelect} onClick={handleOpen} ref={selectRef}>
        {selectedWithdrawal ? (
          <div className={styles.withdrawalSelect__selected}>
            <div className={styles.withdrawalSelect__selected__date}>
              {formatDate(selectedWithdrawal.request_date, 'D MMMM YYYY')}
            </div>
            <div className={styles.withdrawalSelect__selected__amount}>
              {Number(selectedWithdrawal.amount).toFixed(8)} BTC
            </div>
            <div className={styles.withdrawalSelect__selected__status}>
              <WithdrawalTag state={selectedWithdrawal.state as keyof typeof tagConfig} />
            </div>
          </div>
        ) : (
          <div className={styles.withdrawalSelect__title}>
            Choose withdrawal <ArrowDownSvg />
          </div>
        )}

        {isOpen && (
          <div className={styles.withdrawalSelect__list__wrapper}>
            <div className={styles.withdrawalSelect__list__header}>
              {withdrawals.length > 0 ? (
                <>
                  <span>Date</span>
                  <span>Amount</span>
                  <span>Status</span>
                </>
              ) : (
                <span style={{ textAlign: 'left' }}>No withdrawals</span>
              )}
            </div>

            {withdrawals.length > 0 && (
              <div className={styles.withdrawalSelect__list}>
                {withdrawals.map((it) => {
                  return (
                    <div className={styles.withdrawalSelect__item} key={it.id} onClick={() => onSelect(it.id)}>
                      <div className={styles.withdrawalSelect__item__date}>
                        {formatDate(it.request_date, 'D MMMM YYYY')}
                      </div>
                      <div className={styles.withdrawalSelect__item__amount}>{Number(it.amount).toFixed(8)} BTC</div>
                      <div className={styles.withdrawalSelect__item__status}>
                        <WithdrawalTag state={it.state as keyof typeof tagConfig} />
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
