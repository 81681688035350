import { useSelector } from 'react-redux';

import { Root } from '@service/reducers';

import { ModalIncreasingHSH } from './view/ModalIncreasingHSH';
import { ModalInfoHSH } from './view/ModalInfoHSH';

export type ModalsHSHType = {
  balance: number | string;
};

export default function ModalHSH() {
  const { hsh_balance, get_balance } = useSelector((store: Root) => store.balance);

  return (
    <>
      {get_balance && (
        <>
          <ModalInfoHSH balance={+hsh_balance.accrued} />
          <ModalIncreasingHSH balance={+hsh_balance.current} />
        </>
      )}
    </>
  );
}
