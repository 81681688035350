import { BROWSER_PROMO } from '@utils/links';

import BannerDashboard from '@shared/BannerDashboard/BannerDashboard';
import { BrowserLinks, Container, News, BrowserMined, ProductPromoBanner } from '@shared/index';

import { EarningsGraph } from './components/EarningsGraph/EarningsGraph';
import Balance, { BalanceVariant } from '../../../components/Balance';

export const BrowserDashboardView = () => {
  return (
    <>
      <Container vertical={20}>
        <Balance variant={BalanceVariant.Affiliate} />
        <BannerDashboard />
        <BrowserLinks />
        <BrowserMined />
        <EarningsGraph />
        <ProductPromoBanner
          cookie="browserbanner=hide"
          link={BROWSER_PROMO}
          image={{
            srcMob: 'https://cdn.cryptobrowser.site/images/affiliate-redesign/ct-browser/browser-promo-mob.png',
            srcSetMob: 'https://cdn.cryptobrowser.site/images/affiliate-redesign/ct-browser/browser-promo-mob@2x.png',
            src: 'https://cdn.cryptobrowser.site/images/affiliate-redesign/ct-browser/browser-promo.png',
            srcSet: 'https://cdn.cryptobrowser.site/images/affiliate-redesign/ct-browser/browser-promo@2x.png',
          }}
        />
        <News />
      </Container>
    </>
  );
};
