import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import apple from '@assets/icon_apple.svg';
import fb from '@assets/icon_facebook.svg';
import google from '@assets/icon_google.png';
import telegram from '@assets/icon_telegram.svg';
import tw from '@assets/icon_twitter.png';
import vk from '@assets/icon_vk.png';

import getSocial from '@service/actions/getSocial';

import { Button } from '@shared/index';

import { SocialAuth } from '../../../backend/JsonRpcApi';
import { rpc } from '../../../backend/Rpc';

import styles from './SettingsView.module.scss';

const icon: Record<SocialAuth['provider'], string> = {
  'facebook': fb,
  'google-oauth2': google,
  'apple-id': apple,
  'vk-oauth2': vk,
  'twitter': tw,
  'telegram': telegram,
};

const name: Record<SocialAuth['provider'], string> = {
  'facebook': 'Facebook',
  'google-oauth2': 'Google',
  'apple-id': 'Apple',
  'vk-oauth2': 'VK',
  'twitter': 'Twitter',
  'telegram': 'Telegram',
};

export type SettingsItemProps = {
  provider: SocialAuth['provider'];
  id: number;
  uid: string;
  canDelete?: boolean;
};

export function SettingsItem({ provider, uid, canDelete, id }: SettingsItemProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleDelete = useCallback(async () => {
    await rpc.transmit('users.me.delete_social_auth', { id });
    dispatch(getSocial());
  }, [dispatch, id]);

  return (
    <div className={styles.item}>
      <div className={styles.inner}>
        <img src={icon[provider as keyof typeof icon]} alt="icon" />
        <div className={styles.item__info}>
          <h2>{name[provider as keyof typeof name]}</h2>
          {provider === 'google-oauth2' && <p>{uid}</p>}
        </div>
        {canDelete && (
          <Button type="button" size="small" onClick={handleDelete}>
            {t('Disconnect')}
          </Button>
        )}
      </div>

      {
        // привязать NC wallet по FB акку невозможно
        // https://yt.wcode.team/issue/CTAF-473/Ne-otobrazhaetsya-podklyuchennyj-NC-Wallet-na-ekrane-Account-esli-avtorizaciya-proshla-cherez-Facebook#focus=Comments-4-56528.0-0
      }
      {/* {provider !== 'facebook' && provider !== 'telegram' && <NCWCheck isCheck={isAnyNCW} showPromo={showPromo} />} */}
    </div>
  );
}
