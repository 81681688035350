import quickObjectSort from '@utils/quickObjectSort';

import { useFarmRespInfo } from '@hooks/useFarmRespInfo';

import { Span } from '@shared/Graph/Tabs';

import { rpc } from '@backend/Rpc';

import { AppDispatch } from 'src/main.tsx';

import { GET_FARM_EARNINGS_DATA } from '../constant';
import { FarmDataType } from '../reducers/farmGraphReducer';

type Resp = {
  timestamp: number;
};

type FarmEarnings = {
  value: number | string;
  timestamp: number;
};

export default function getFarmEarnings(period: Span, userId: number, period_start: string, period_end: string) {
  return async function (dispatch: AppDispatch) {
    const respInfo = useFarmRespInfo(period);
    const resp = await rpc.transmit('farm.get_payments_statistics', {
      farm_ids: [userId],
      start_time: `${period_start}`,
      end_time: `${period_end}`,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      discreteness: `${respInfo.discreteness}` as any,
      payment_type_ids: [3],
    });

    let sortData: FarmEarnings[] = [];
    if (resp.items.length > 0) {
      sortData = quickObjectSort(resp.items, 'value');
    }
    if (Number(sortData[0]?.value) > 0.00000001) {
      sortData = resp.items.map((item: FarmDataType) => ({
        value: Number(item.value),
        timestamp: item.timestamp * 1000,
      }));
    } else {
      sortData = resp.items.map((item: FarmDataType) => ({
        value: (Number(item.value) * 100000000).toFixed(4),
        timestamp: item.timestamp * 1000,
      }));
    }
    dispatch({
      type: GET_FARM_EARNINGS_DATA,
      earning: sortData.sort((a: Resp, b: Resp) => a.timestamp - b.timestamp),
    });
  };
}
