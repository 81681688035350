import React, { ChangeEvent } from 'react';

import SupportInput, { SupportInputVariant } from '@pages/Support/view/component/SupportInput';

import { Question as QuestionType, AnswerItem, ValidationKey, QuestionViewType } from './types';

import styles from './SuspendCard.module.scss';

type QuestionProps = {
  question: QuestionType;
  onChange: (id: string, answer: AnswerItem) => void;
  answer?: AnswerItem;
};

const Question: React.FC<QuestionProps> = ({ question, onChange, answer }) => {
  const { id, type, choices, validation } = question;
  const [_, setInput] = React.useState<string | number>('');

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (type === QuestionViewType.CHECKBOX_SINGLE) {
      onChange(id, {
        ...answer,
        choices: [value],
        other: Boolean(choices?.find((choice) => choice.id === value)?.other),
      });
    } else {
      const updatedChoices = answer?.choices?.includes(value)
        ? answer.choices.filter((c: string) => c !== value)
        : [...(answer?.choices || []), value];

      // const currentIsOther = updatedChoices.some(
      //   (choiceId) => choices?.find((choice) => choice.id === choiceId)?.other
      // );

      onChange(id, { ...answer, choices: updatedChoices, other: false });
    }
  };

  const handleOtherChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const otherText = e.target.value;
    onChange(id, { ...answer, text: otherText, other: true });
  };
  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target.value;
    setInput(value);
  };

  const getMinMaxValue = (value: number | null | undefined): number | undefined => {
    return value ?? undefined;
  };
  console.log('QuestionViewType.TEXT_SHORT', question);
  return (
    <div className={`${styles.wrapper} ${styles.question}`}>
      {question.label && <h3>{question.label}</h3>}
      {question.description && <p className={styles.suspendCard__description}>{question.description}</p>}
      {type === QuestionViewType.TEXT_SHORT || type === QuestionViewType.TEXT_EMAIL ? (
        <label className={styles.checkbox}>
          <input
            type="text"
            value={answer?.text || ''}
            onChange={handleInputChange}
            required={validation?.rq}
            minLength={getMinMaxValue(validation?.mnc)}
            maxLength={getMinMaxValue(validation?.mxc)}
          />
        </label>
      ) : type === QuestionViewType.TEXT_LONG ? (
        <SupportInput
          label={''}
          variant={SupportInputVariant.Text}
          name={'id'}
          placeholder="Type here"
          value={answer?.text || ''}
          onChange={handleOtherChange}
          required={validation?.rq}
          minLength={getMinMaxValue(validation?.mnc)}
          maxLength={getMinMaxValue(validation?.mxc)}
        />
      ) : type === QuestionViewType.CHECKBOX_MULTIPLE || type === QuestionViewType.CHECKBOX_SINGLE ? (
        choices?.map((choice) => (
          <div key={choice.id}>
            <label className={styles.checkbox}>
              <input
                type={type === QuestionViewType.CHECKBOX_MULTIPLE ? 'checkbox' : 'radio'}
                name={id}
                value={choice.id}
                checked={answer?.choices?.includes(choice.id)}
                onChange={handleCheckboxChange}
              />

              {choice.label}
            </label>
            {choice.other && answer?.choices?.includes(choice.id) && (
              <SupportInput
                label={''}
                placeholder="Type here"
                variant={SupportInputVariant.Text}
                name={'id'}
                value={answer?.text || ''}
                onChange={handleOtherChange}
              />
            )}
          </div>
        ))
      ) : type === QuestionViewType.TEXT_SHORT && validation?.[ValidationKey.MXN] ? (
        <label className={styles.checkbox}>
          <input
            type="number"
            value={answer?.text || ''}
            onChange={handleInputChange}
            required={validation?.rq}
            min={validation?.[ValidationKey.MXN]}
          />{' '}
        </label>
      ) : null}
    </div>
  );
};

export default Question;
