import { AppDispatch } from 'src/main.tsx';

import { apiClient } from '../../api/apiClient';
import { apiLinkV1 } from '../../api/apiLInk';
import { BANNER_ERROR, BANNER_REQUEST, BANNER_SUCCESS } from '../constant';

export default function getBanners(id: string, theme: string, size: string, lang: string) {
  let url = apiLinkV1 + 'promo/banner/suitable/';
  return async function (dispatch: AppDispatch) {
    url += `?t=${theme}&size=${size}${lang !== '' ? `&lang=${lang}` : ''}`;
    try {
      dispatch({ type: BANNER_REQUEST });
      const resp = await new apiClient().get(url);
      dispatch({ type: BANNER_SUCCESS, banners: resp.results });
    } catch {
      dispatch({ type: BANNER_ERROR });
    }
  };
}
