import { ETypeMainLink, ETypeWebLink } from '../ListItem/ListItem';

export const data = {
  items: [
    {
      topIcon: 'https://cdn.cryptobrowser.site/images/cta-promo/cryptotab-browser-pro.svg',
      title: 'CryptoTab Browser Pro',
      link: 'https://cryptobrowser.site/',
      text: {
        en: 'Mining is available on your smartphone too! Use CTB Pro and reach fantastic mining speed with Cloud.Boost feature: multiply your hashrate as many times as you want and don’t limit your speeds!',
        ru: 'Майнинг доступен и на вашем смартфоне! Используйте CTB Pro и достигайте фантастической скорости майнинга благодаря функции Cloud.Boost: умножайте хешрейт любое количество раз и не ограничивайте себя в скоростях!',
        fr: 'Le minage est également disponible sur votre smartphone ! Utilisez CTB Pro et atteignez une vitesse de minage fantastique avec la fonction Cloud.Boost : multipliez votre hashrate autant de fois que vous le souhaitez et ne limitez pas vos vitesses !',
        it: 'Il mining è disponibile anche sul tuo smartphone! Usa CTB Pro e raggiungi una fantastica velocità di mining con la funzione Cloud.Boost: moltiplica il tuo hashrate quante volte vuoi e non limitare la tua velocità!',
        de: 'Mining ist auch auf Ihrem Smartphone möglich! Verwenden Sie CTB Pro und erreichen Sie eine fantastische Mining-Geschwindigkeit mit der Cloud.Boost-Funktion: Multiplizieren Sie Ihre Hashrate so oft Sie wollen und schränken Sie Ihre Geschwindigkeit nicht ein!',
        es: '¡La minería también está disponible en tu smartphone! Usa CTB Pro y alcanza una velocidad de minado fantástica con la función Cloud.Boost: ¡multiplica tu hashrate tantas veces como quieras y no limites tus velocidades!',
        pt: 'A mineração também está disponível em seu smartphone! Use o CTB Pro e alcance uma velocidade de mineração fantástica com o recurso Cloud.Boost: multiplique seu hashrate quantas vezes quiser e não limite suas velocidades!',
      },
      badge: {
        icon: 'https://cdn.cryptobrowser.site/images/cta-promo/cloudboost.svg',
        text: {
          en: 'Gift with every Cloud.Boost!',
          ru: 'Подарок с каждым Cloud.Boost!',
          fr: 'Cadeau avec chaque Cloud.Boost !',
          it: 'Regalo con ogni Cloud.Boost!',
          de: 'Geschenk mit jedem Cloud.Boost!',
          es: '¡Regalo con cada Cloud.Boost!',
          pt: 'Presente com cada Cloud.Boost!',
        },
        theme: 'orange',
      },
      img: {
        '1x': 'https://cdn.cryptobrowser.site/images/cta-promo/Product-CT-Browser-Pro.png',
        '2x': 'https://cdn.cryptobrowser.site/images/cta-promo/Product-CT-Browser-Pro@2x.png',
      },
      // appleLink: 'https://itunes.apple.com/app/cryptotab-browser-pro/id1524974223/',
      googleLink:
        'https://play.app.goo.gl/?link=https%3A//play.google.com/store/apps/details%3Fid%3Dpro.cryptotab.android',
    },
    {
      topIcon: 'https://cdn.cryptobrowser.site/images/cta-promo/CryptoTab-Browser-Max.svg',
      title: 'CryptoTab Browser Max',
      link: 'https://cryptobrowser.site/',
      text: {
        en: 'You do not need a PC for effective mining! With just a smartphone and a Cloud.Boost feature you could multiply your mining speed hundreds of times.',
        ru: 'Для эффективного майнинга не нужен компьютер! С помощью смартфона и функции Cloud.Boost вы можете увеличить скорость майнинга в сотни раз.',
        fr: "Vous n'avez pas besoin d'un PC pour miner efficacement ! Avec juste un smartphone et une fonction Cloud.Boost, vous pourriez multiplier votre vitesse de minage des centaines de fois.",
        it: 'Non è necessario un PC per un mining efficace! Basta uno smartphone e la funzione Cloud.Boost per moltiplicare la velocità di mining di centinaia di volte.',
        de: 'Sie brauchen keinen PC für effektives Mining! Mit nur einem Smartphone und einer Cloud.Boost-Funktion können Sie Ihre Mining-Geschwindigkeit um das Hundertfache erhöhen.',
        es: '¡No necesitas un PC para una minería efectiva! Con solo un teléfono inteligente y una función Cloud.Boost, podría multiplicar su velocidad de minería cientos de veces.',
        pt: 'Você não precisa de um PC para uma mineração eficaz! Com apenas um smartphone e o recurso Cloud.Boost, você pode multiplicar sua velocidade de mineração centenas de vezes.',
      },
      badge: {
        icon: 'https://cdn.cryptobrowser.site/images/cta-promo/mining-speed.svg',
        text: {
          en: 'Bonus speed with every purchase!',
          ru: 'Бонусная скорость с каждой покупкой!',
          fr: 'Bonus vitesse pour tout achat !',
          it: 'Velocità bonus con ogni acquisto!',
          de: 'Bonusgeschwindigkeit bei jedem Kauf!',
          es: 'Velocidad extra con cada compra',
          pt: 'Velocidade de bônus em cada compra!',
        },
        theme: 'violet',
      },
      img: {
        '1x': 'https://cdn.cryptobrowser.site/images/cta-promo/Product-CT-Browser-Max.png',
        '2x': 'https://cdn.cryptobrowser.site/images/cta-promo/Product-CT-Browser-Max@2x.png',
      },

      googleLink: 'https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=max.cryptotab.android',
    },
    {
      topIcon: 'https://cdn.cryptobrowser.site/images/cta-promo/CryptoTab-Farm.svg',
      title: 'CryptoTab Farm',
      link: 'https://cryptotab.farm/',
      text: {
        en: 'Your best instrument for stable and high speed mining with or even without PCs. Connect your own hardware or use Pool Miners to generate as much BTC as you want.',
        ru: 'Ваш лучший инструмент для стабильного и высокоскоростного майнинга с ПК или даже без него. Подключайте собственное оборудование или используйте Пул Майнеры, чтобы генерировать столько BTC, сколько хотите.',
        fr: 'Votre meilleur instrument pour un minage stable et à grande vitesse avec ou même sans PC. Connectez votre propre matériel ou utilisez des Pool Miners pour générer autant de BTC que vous le souhaitez.',
        it: 'Il vostro miglior strumento per un mining stabile e ad alta velocità con o senza PC. Collegate il vostro hardware o utilizzate i Pool Miner per generare tutti i BTC che volete.',
        de: 'Ihr bestes Instrument für stabiles und schnelles Mining mit oder sogar ohne PC. Schließen Sie Ihre eigene Hardware an oder nutzen Sie Pool-Miner, um so viel BTC zu generieren, wie Sie wollen.',
        es: 'Su mejor instrumento para la minería estable y de alta velocidad con o incluso sin PC. Conecte su propio hardware o utilice Pool Miners para generar tanto BTC como desee.',
        pt: 'Seu melhor instrumento para mineração estável e de alta velocidade com ou mesmo sem PCs. Conecte seu próprio hardware ou use os Pool Miners para gerar a quantidade de BTC que desejar.',
      },
      badge: {
        icon: 'https://cdn.cryptobrowser.site/images/cta-promo/gifts.svg',
        text: {
          en: '-72% off and gifts!',
          ru: 'Скидка -72% и подарки!',
          fr: '-72% de réduction et des cadeaux !',
          it: '-72% di sconto e regali!',
          de: '-72% Rabatt und Geschenke!',
          es: '-¡72% de descuento y regalos!',
          pt: '-72% de desconto e brindes!',
        },
        theme: 'green',
      },
      img: {
        '1x': 'https://cdn.cryptobrowser.site/images/cta-promo/Product-CT-Farm.png',
        '2x': 'https://cdn.cryptobrowser.site/images/cta-promo/Product-CT-Farm@2x.png',
      },

      googleLink: 'https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.cryptofarm',
      webLink: 'https://app.cryptotab.farm/',
      typeWebLink: ETypeWebLink.WithUtmContentRef,
    },
    {
      topIcon: 'https://cdn.cryptobrowser.site/images/cta-promo/CryptoTab-Farm-Pro.svg',
      title: 'CryptoTab Farm Pro',
      link: 'https://cryptotabfarm.com/',
      text: {
        en: 'Pro mining tool for pro miners. Use it with or without your own hardware: estimate your mining results in demo mode, boost speed with the Worker feature and get used to Bitcoins in your wallet.',
        ru: 'Про майнинг-инструмент для про-фермеров. Используйте его с собственным оборудованием или без него: оцените результаты добычи в демо-режиме, увеличьте скорость с помощью функции Воркер и привыкайте к Биткоинам в своем кошельке.',
        fr: 'Outil de minage professionnel pour les mineurs professionnels. Utilisez-le avec ou sans votre propre matériel: estimez vos résultats de minage en mode démo, augmentez la vitesse avec la fonction Worker et habituez-vous aux Bitcoins dans votre portefeuille.',
        it: 'Strumento di mining professionale per minatori professionisti. Utilizzatelo con o senza il vostro hardware: valutate i vostri risultati di mining in modalità demo, aumentate la velocità con la funzione Worker e abituatevi ai Bitcoin nel vostro portafoglio.',
        de: 'Profi-Mining-Tool für Profi-Miner. Verwenden Sie es mit oder ohne Ihre eigene Hardware: Schätzen Sie Ihre Mining-Ergebnisse im Demo-Modus, steigern Sie die Geschwindigkeit mit der Worker-Funktion und gewöhnen Sie sich an Bitcoins in Ihrer Wallet.',
        es: 'Herramienta de minería profesional para mineros profesionales. Úsalo con o sin tu propio hardware: estima tus resultados de minería en modo demo, aumenta la velocidad con la función Worker y acostúmbrate a los Bitcoins en tu billetera.',
        pt: 'Ferramenta de mineração profissional para mineradores profissionais. Use-a com ou sem seu próprio hardware: estime seus resultados de mineração no modo de demonstração, aumente a velocidade com o recurso Worker e acostume-se com os Bitcoins em sua carteira.',
      },
      badge: {
        icon: 'https://cdn.cryptobrowser.site/images/cta-promo/misc.svg',
        text: {
          en: 'Seasonal offer!',
          ru: 'Сезонное предложение!',
          fr: 'Offre saisonnière !',
          it: 'Offerta stagionale!',
          de: 'Saisonales Angebot!',
          es: '¡Oferta de temporada!',
          pt: 'Oferta sazonal!',
        },
        theme: 'violet',
      },
      img: {
        '1x': 'https://cdn.cryptobrowser.site/images/cta-promo/Product-CT-Farm-PRO.png',
        '2x': 'https://cdn.cryptobrowser.site/images/cta-promo/Product-CT-Farm-PRO@2x.png',
      },
      appleLink: 'https://apps.apple.com/app/ct-farm/id1633191121',
      googleLink: 'https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.ctfarm',
    },
    {
      topIcon: 'https://cdn.cryptobrowser.site/images/cta-promo/CryptoTab-Pool.svg',
      title: 'CryptoTab Pool',
      link: 'https://ctpool.net/',
      text: {
        en: 'Now incredible Pool Mining technology is available in any browser, Android app and even on Telegram! Tens of millions H/s are waiting to boost your mining of any major currency!',
        ru: 'Теперь невероятная технология Пул Майнинга доступна в любом браузере, приложении для Android и даже в Telegram! Десятки миллионов H/s ждут вас, чтобы ускорить ваш майнинг любой валюты!',
        fr: "L'incroyable technologie du Pool Mining est désormais disponible dans n'importe quel navigateur, application Android et même sur Telegram ! Des dizaines de millions de H/s vous attendent pour booster votre minage de n'importe quelle devise majeure !",
        it: "Ora l'incredibile tecnologia Pool Mining è disponibile in qualsiasi browser, app Android e persino su Telegram! Decine di milioni di H/s sono in attesa di incrementare il vostro mining di qualsiasi valuta principale!",
        de: 'Jetzt ist die unglaubliche Pool-Mining-Technologie in jedem Browser, jeder Android-App und sogar auf Telegram verfügbar! Zig Millionen H/s warten darauf, Ihr Mining in jeder wichtigen Währung anzukurbeln!',
        es: '¡Ahora la increíble tecnología Pool Mining está disponible en cualquier navegador, aplicación Android e incluso en Telegram! ¡Decenas de millones de H/s están esperando para impulsar su minería de cualquier moneda!',
        pt: 'Agora a incrível tecnologia Pool Mining está disponível em qualquer navegador, aplicativo para Android e até mesmo no Telegram! Dezenas de milhões de H/s estão esperando para impulsionar sua mineração de qualquer moeda importante!',
      },
      badge: {
        icon: 'https://cdn.cryptobrowser.site/images/cta-promo/gift-box.svg',
        text: {
          en: 'Gift with every purchase!',
          ru: 'Подарок с каждой покупкой!',
          fr: 'Cadeau à chaque achat !',
          it: 'Regalo con ogni acquisto!',
          de: 'Geschenk bei jedem Einkauf!',
          es: '¡Regala con cada compra!',
          pt: 'Presenteie em cada compra!',
        },
        theme: 'green',
      },
      img: {
        '1x': 'https://cdn.cryptobrowser.site/images/cta-promo/Product-CT-Pool.png',
        '2x': 'https://cdn.cryptobrowser.site/images/cta-promo/Product-CT-Pool@2x.png',
      },

      googleLink: 'https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=net.ctpool',
      webLink: 'https://app.ctpool.net/',
      typeMainLink: ETypeMainLink.WithUtmContent,
    },
    {
      topIcon: 'https://cdn.cryptobrowser.site/images/cta-promo/NC-Wallet.svg',
      title: 'NC Wallet',
      link: 'https://ncwallet.net/try/',
      text: {
        en: "Improve your crypto experience with the world's first commission-free wallet! Enjoy security, plenty of currencies and priority withdrawals across all ecosystem products!",
        ru: 'Улучшите опыт работы с криптовалютой с первым кошельком без комиссий! Безопасность, любимые монеты и приоритетные выводы во всех продуктах экосистемы ждут вас!',
        fr: "Améliorez votre expérience crypto avec le premier wallet sans commission au monde ! Profitez de sa sécurité, des nombreuses devises et des retraits prioritaires pour tous les produits de l'écosystème!",
        it: "Migliora la tua esperienza crypto con il primo wallet senza commissioni al mondo! Massima sicurezza, ampia scelta di valute e prelievi prioritari per tutti i prodotti dell'ecosistema!",
        de: 'Verbessere dein Krypto-Erlebnis mit dem weltweit ersten kommissionsfreien Wallet! Genieße über alle Produkte des Ökosystems hinweg Sicherheit, eine Vielzahl von Währungen sowie priorisierte Abhebungen!',
        es: 'Mejora tu experiencia cripto con el primer monedero del mundo sin comisión. Disfruta de seguridad, múltiples monedas y retiradas prioritarias en todos los productos del ecosistema.',
        pt: 'Melhore sua experiência com criptomoedas com a primeira carteira sem comissão do mundo! Desfrute de segurança, muitas moedas e saques prioritários em todos os produtos do ecossistema!',
      },
      img: {
        '1x': 'https://cdn.cryptobrowser.site/images/cta-promo/Product-NC-Wallet.png',
        '2x': 'https://cdn.cryptobrowser.site/images/cta-promo/Product-NC-Wallet@2x.png',
      },
      appleLink: 'https://apps.apple.com/app/nc-wallet-crypto-without-fees/id1615381976',
      googleLink: 'https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.ncwallet',
      typeMainLink: ETypeMainLink.WithUtmContent,
    },
  ],
};
