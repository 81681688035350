import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { ArrowRightSvg } from '@assets/colored';
import { CircleQuestionSvg, HistorySvg, WithdrawSvg } from '@assets/colorless';

import { BROWSER_BALANCE, SUPPORT } from '@utils/links';

import { Button, ButtonVariant } from '@shared/Button/Button';

import styles from './WithdrawalView.module.scss';

export type WithdrawalViewFooterProps = {
  balance: number;
  balanceHSH: number;
  isBitcoin: boolean;
  disabled: boolean;
  isLoading: boolean;
  setLoading: (v: boolean) => void;
  handleBack: () => void;
  handleSubmit: () => Promise<null | undefined>;
  isSuspend: boolean;
};

export default function WithdrawalViewFooter(props: WithdrawalViewFooterProps) {
  const { t } = useTranslation();
  const { balance, balanceHSH, isBitcoin, handleSubmit, handleBack, isLoading, setLoading, disabled, isSuspend } =
    props;

  if (isSuspend) {
    return (
      <div className={cn(styles.card__footer, styles[`card__footer--suspend`])}>
        <Button
          type="link"
          to={SUPPORT}
          variant={ButtonVariant.Primary}
          className={styles.suspend_btn}
          size="medium"
          Icon={<CircleQuestionSvg className={styles.suspend_icon} />}
        >
          {t('Support')}
        </Button>
      </div>
    );
  }

  return (
    <div className={cn(styles.card__footer, 'opacityFooterButtons')}>
      <Button
        type="link"
        to={`${BROWSER_BALANCE}/${isBitcoin ? 'BTC' : 'HSH'}`}
        variant={ButtonVariant.Text}
        className={styles.history_btn}
        size="small"
        Icon={<HistorySvg />}
      >
        {t('Payment History')}
      </Button>
      {(isBitcoin && +balance !== 0) || (!isBitcoin && balanceHSH !== 0) ? (
        <Button
          type="button"
          variant={ButtonVariant.Primary}
          Icon={<WithdrawSvg />}
          size="medium"
          disabled={disabled}
          isLoading={isLoading}
          onClick={() => {
            setLoading(true);
            handleSubmit().finally(() => setLoading(false));
          }}
        >
          {t('Withdraw')}
        </Button>
      ) : (
        <Button
          type="button"
          size="medium"
          onClick={handleBack}
          className={styles.back}
          Icon={<ArrowRightSvg className={styles.arrow} />}
        >
          {t('Back')}
        </Button>
      )}
    </div>
  );
}
