import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import AffiliateSvg from '@assets/colored/affiliate-plus.svg?react';
import BtcSvg from '@assets/colored/circle-btc.svg?react';
import LightSvg from '@assets/colored/circle-light.svg?react';
import ShareSvg from '@assets/colored/circle-share.svg?react';
import Icon from '@assets/colored/icon-link-lg.svg?react';

import { Card, StepsGroup } from '@shared/index';
import { Picture } from '@shared/Picture/Picture';

import styles from './PoolAboutGenerate.module.scss';

const stepsImages = [ShareSvg, AffiliateSvg, LightSvg, BtcSvg];

export default function PoolAboutGenerate() {
  const { t } = useTranslation();
  const titles = useMemo(
    () => [
      t('Invite new users via your personal link, using CT Pool promo materials'),
      t('If a user you invite invites another users, they also become part of your referral network'),
      t('The more powerful pool miners your referrals use, the higher your income will be'),
      t('You receive commission earnings from pool mining of all your referrals'),
    ],
    [t]
  );
  return (
    <Card className={styles.card}>
      <div className={styles.container}>
        <Icon className={styles.icon} />
        <h2 className={styles.title}>{t('Start right away!')}</h2>
        <p className={styles.text}>
          <Trans
            i18nKey="CT_Pool.About.Block_2.Description"
            components={{
              a: <a href="https://ctnft.net/" target="_blank" rel="noreferrer" />,
              b: <b />,
            }}
          />
        </p>
        <StepsGroup titles={titles} images={stepsImages} />

        <div className={styles.pool__image}>
          <Picture
            imageDesktop={'https://cdn.cryptobrowser.site/images/affiliate-redesign/ct-pool/promo-pool.png'}
            imageDesktop2x={'https://cdn.cryptobrowser.site/images/affiliate-redesign/ct-pool/promo-pool@2x.png'}
            imageMob={'https://cdn.cryptobrowser.site/images/affiliate-redesign/ct-pool/promo-pool-mob-new.png'}
            imageMob2x={'https://cdn.cryptobrowser.site/images/affiliate-redesign/ct-pool/promo-pool-mob-new@2x.png'}
            breakPoint={'600px'}
            className={styles.ui}
          ></Picture>
        </div>
      </div>
    </Card>
  );
}
