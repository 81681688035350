import { useTranslation } from 'react-i18next';

import useFarmVideo from '@hooks/useFarmVideos';

import { LandingCard, PromoSection } from '@shared/index';

import styles from '../LandingsBlock/LandingsBlock.module.scss';

export default function VideoBlock() {
  const { t } = useTranslation();
  const videos = useFarmVideo();
  return (
    <PromoSection
      title={t('Promo post videos')}
      text={t('With these videos, you can draw more attention to your promo-posts and increase your mining network.')}
      id="video"
    >
      <div className={styles.container}>
        {videos.map((video) => (
          <LandingCard
            src={video.src}
            disabledLink
            key={video.id}
            className={styles.sm_image}
            href={video.link}
            linkStyle={styles.linkStyle}
          />
        ))}
      </div>
    </PromoSection>
  );
}
