import cn from 'classnames';
import { ReactNode, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { CtpLogoSvg } from '@assets/colored';
import { ArrowRightSmSvg } from '@assets/colorless';
import { CtSmLogoSvg, FarmSmLogoSvg } from '@assets/index';
import Refresh from '@assets/refresh.svg?react';

import { PRODUCT_KIND } from '@utils/checkPathname';
import {
  BROWSER_DASH,
  BROWSER_LINKS,
  FARM_DASH,
  FARM_LINK_INFO,
  MAIN_LINK_URL,
  POOL_DASH,
  POOL_LINKS,
} from '@utils/links';

import { CommonLinksInfo } from '@pages/CommonLinks/CommonLinks';

import useFilteredBrowserLinks from '@hooks/useFilteredBrowserLinks';
import useFilteredFarmLinks from '@hooks/useFilteredFarmLinks';
import useFilteredPoolLinks from '@hooks/useFilteredPoolLinks';

import { SET_BROWSER_PERIOD } from '@service/constant';
import { Root } from '@service/reducers';
import { LinkAcc } from '@service/reducers/balanceReducer';
import { selectBrowserGraph } from '@service/selectors/selectors';

import { AddLinkButton } from '@shared/AddLinkButton/AddLinkButton';
import { BannerAddLinks } from '@shared/BannerAddLinks/BannerAddLinks';
import { Button } from '@shared/Button/Button';
import { Card } from '@shared/Card/Card';
import { DatePicker } from '@shared/DatePicker/DatePicker';
import { farmLinks } from '@shared/FarmPromoCodes/FarmPromoCodes';
import { FarmPromoTabs } from '@shared/FarmPromoCodes/FarmPromoCodesTabs';
import LinksRow from '@shared/LinksView/LinksRow';
import LinksTotal, { LinksTotalInfo } from '@shared/LinksView/LinksTotal';
import { LinkTableHeader } from '@shared/LinksView/LinksView';

import styles from '../LinksView/LinksView.module.scss';
export type AllLinksProps = {
  handleRefresh: () => void;
  isLoading?: boolean;
  linksInfo: CommonLinksInfo;
  onLinkCreate: () => void;
  hideViewAllBtn?: boolean;
};

export function AllLinks({ linksInfo, onLinkCreate, isLoading, handleRefresh, hideViewAllBtn }: AllLinksProps) {
  const { farm, ct_pool, browser, count } = linksInfo;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const totalInfoBrowser = useFilteredBrowserLinks();
  const totalInfoFarm = useFilteredFarmLinks();
  const totalInfoPool = useFilteredPoolLinks();

  const total = useMemo<LinksTotalInfo>(() => {
    const keysTotal = Object.keys(totalInfoBrowser) as Array<keyof LinksTotalInfo>;
    const totalInfoAll: LinksTotalInfo = {} as LinksTotalInfo;

    keysTotal.forEach((value) => {
      totalInfoAll[value] = totalInfoBrowser[value] + totalInfoFarm[value] + totalInfoPool[value];
    });

    return totalInfoAll;
  }, [totalInfoBrowser, totalInfoFarm, totalInfoPool]);

  const onPeriodChange = useCallback(
    (period: { period_start: string; period_end: string }) => {
      dispatch({
        type: SET_BROWSER_PERIOD,
        ...period,
      });
    },
    [dispatch]
  );
  const browserGraph = useSelector((store: Root) => selectBrowserGraph(store));
  const period = {
    start: browserGraph.period_start,
    end: browserGraph.period_end,
  };

  return (
    <>
      <div className={styles.wrapper}>
        <Card
          className={styles.card}
          headerClass={styles.card__header}
          TitleElement={
            <div className={styles.title}>
              <span className={styles.title__link}>
                <span>{t('menu.LinksAndCodes')}</span>
              </span>
              <span className={styles.title__count}>{count}</span>
              <Button
                type="button"
                onClick={handleRefresh}
                Icon={<Refresh />}
                onlyIcon
                className={cn(styles.refresh, { [styles.loading]: isLoading })}
              />
            </div>
          }
          Switch={<DatePicker period={period} onPeriodChange={onPeriodChange} />}
        >
          <div className={styles.body}>
            <div className={styles.table}>
              <LinkTableHeader />
              <Section
                Icon={<CtSmLogoSvg width={24} height={24} />}
                title={'CT Browser'}
                url={BROWSER_DASH}
                link={BROWSER_LINKS}
                links={browser}
                projectType={PRODUCT_KIND.Browser}
                landLink={'https://cryptotabbrowser.com/'}
                onPeriodChange={onPeriodChange}
              />
              <Section
                Icon={<CtpLogoSvg width={24} height={24} />}
                title={'CT Pool'}
                projectType={PRODUCT_KIND.Pool}
                landLink={'https://pool.cryptobrowser.site/landing/?aid='}
                link={POOL_LINKS}
                url={POOL_DASH}
                links={ct_pool}
              />
              <Section
                Icon={<FarmSmLogoSvg width={24} height={24} />}
                title={'CT Farm'}
                landLink={farmLinks[FarmPromoTabs.Usually]}
                projectType={PRODUCT_KIND.Farm}
                url={FARM_DASH}
                link={FARM_LINK_INFO}
                links={farm}
              />
              <LinksTotal info={total} />
            </div>
          </div>
        </Card>
        <div className={cn(styles.footer, { [styles.footer_show_viewall_btn]: !hideViewAllBtn })}>
          <AddLinkButton btnClassName={styles.link_btn} onLinkCreate={onLinkCreate} />
          {!hideViewAllBtn && (
            <Button
              className={cn(styles.all_link_btn, styles.link_btn)}
              type="link"
              to={MAIN_LINK_URL}
              size="medium"
              external
              iconPosition="right"
              Icon={<ArrowRightSmSvg />}
            >
              {t('View all')}
            </Button>
          )}
        </div>
      </div>

      <Card>
        <BannerAddLinks onLinkCreate={onLinkCreate} />
      </Card>
    </>
  );
}

type SectionProps = {
  title?: string;
  Icon?: ReactNode;
  url?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  links?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  projectType?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  link?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  landLink?: any;
  onPeriodChange?: (period: { period_start: string; period_end: string }) => void;
};

type Period = { start: string; end: string };
export type LinksViewProps = {
  count: number;
  handleRefresh: () => void;
  projectType: string;
  link: string;
  landLink: string;
  createLink?: string;
  period: Period;
  totalInfo: LinksTotalInfo;
  onPeriodChange: (period: { period_start: string; period_end: string }) => void;
};

function Section({ title, url, Icon, links, projectType, link, landLink }: SectionProps) {
  return (
    <>
      <Link className={styles.sectionHeader} to={{ pathname: url }}>
        {Icon}
        <span>{title}</span>
      </Link>

      {links?.map(
        (linkAcc: LinkAcc) =>
          linkAcc.type !== 'farm' && (
            <LinksRow {...linkAcc} projectType={projectType} link={link} landLink={landLink} key={linkAcc.id} />
          )
      )}
    </>
  );
}
