import cn from 'classnames';
import { useCallback, useState, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import Check from '@assets/colorless/check.svg?react';
import Copy from '@assets/colorless/copy.svg?react';
import Scrap from '@assets/colorless/scrap.svg?react';

import checkE from '@utils/checkE';
import useIntlFormatter from '@utils/useIntlFormatter';

import { useBreakpoints } from '@hooks/useBreakpoints';
import useFilteredBrowserLinks from '@hooks/useFilteredBrowserLinks';
import useFilteredFarmLinks from '@hooks/useFilteredFarmLinks';
import useFilteredPoolLinks from '@hooks/useFilteredPoolLinks';

import { LinkAcc } from '@service/reducers/balanceReducer';

import { ProjectType } from '@shared/LinksView/LinksView';

import styles from './LinksView.module.scss';

type Info = { open: number; install: number; revenue: number; active: number };

type LinksRowProps = LinkAcc & {
  landLink: string;
  link: string;
  projectType: ProjectType;
};

type LinksEmptyRowProps = {
  id: string | number;
  code?: string;
  to: string;
  offCopy?: boolean;
  offApprox?: boolean;
  landLink: string;
  comment: string;
  revenue?: number;
  open?: number;
  install?: number;
  active?: number;
  onClick?: () => void;
  className?: string;
  name?: string | null;
};

export function LinksEmptyRow(props: LinksEmptyRowProps) {
  const { t } = useTranslation();
  const { intFormatter } = useIntlFormatter();
  return (
    <Link to={{ pathname: props.to }} onClick={props.onClick} className={cn(styles.row, props.className)}>
      <div className={styles.row__name}>
        {props.name && <div className={styles.row__nameText}>{props.name}</div>}
        <div className={styles.row__name_id}>{props.id}</div>
      </div>
      <div className={styles.row__copy}>
        {!props.offCopy && (
          <>
            <CopyElem text={`${props.landLink}${props.id}`} />
            <p className={styles.row__copy_tooltip}>{t('copyLink')}</p>
          </>
        )}
      </div>
      <div className={`${styles.row__comm} ${props.offCopy && styles.row__comm_cb}`}>{props.comment}</div>
      <div className={styles.row__num}>{props.open || 0}</div>
      <div className={styles.row__num}>{props.install || 0}</div>
      <div className={styles.row__num}>{props.active || 0}</div>
      <div className={styles.row__btc}>
        <div className={styles.row__btc_balance}>
          <span>
            {props.revenue
              ? intFormatter(checkE(props.revenue))
              : intFormatter(0, { minimumFractionDigits: 8, maximumFractionDigits: 8 })}
          </span>{' '}
          BTC
        </div>
      </div>
    </Link>
  );
}
export function LinksEmptyRowMobile(props: LinksEmptyRowProps) {
  const { t } = useTranslation();
  const { intFormatter } = useIntlFormatter();
  return (
    <Link to={{ pathname: props.to }} onClick={props.onClick} className={cn(styles.row_mob, props.className)}>
      <div className={styles.top}>
        <div className={styles.row__name}>
          <Scrap />
          <div className={styles.row__name_id}>{props.id}</div>
        </div>

        {!props.offCopy && (
          <div className={styles.row__copy}>
            <CopyElem text={`${props.landLink}${props.id}`} />
            <p className={styles.row__copy_tooltip}>{t('copyLink')}</p>
          </div>
        )}
      </div>
      <div className={styles.content}>
        <div className={styles.line}>
          <div className={styles.heading}>{t('Comment')}:</div>
          <div className={`${styles.row__comm} ${props.offCopy && styles.row__comm_cb}`}>{props.comment}</div>
        </div>
        <div className={styles.line}>
          <div className={styles.heading}>{t('Opens')}:</div>
          <div className={styles.row__num}>{props.open || 0}</div>
        </div>
        <div className={styles.line}>
          <div className={styles.heading}>{t('Referrals')}:</div>
          <div className={styles.row__num}>{props.install || 0}</div>
        </div>
        <div className={styles.line}>
          <div className={styles.heading}>{t('Active Miners')}:</div>
          <div className={styles.row__num}>{props.active || 0}</div>
        </div>
        <div className={styles.line}>
          <div className={styles.heading}>{t('Earnings')}:</div>
          <div className={styles.row__btc}>
            <div className={styles.row__btc_balance}>
              <span>
                {props.revenue
                  ? intFormatter(checkE(props.revenue))
                  : intFormatter(0, { minimumFractionDigits: 8, maximumFractionDigits: 8 })}
              </span>{' '}
              BTC
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}
export default function LinksRow(props: LinksRowProps) {
  const { t } = useTranslation();
  const { isMobile, isTable } = useBreakpoints();
  const _browserLinks = useFilteredBrowserLinks(props.id);
  const _poolLinks = useFilteredPoolLinks(props.id);
  const _farmLinks = useFilteredFarmLinks(props.id);
  const location = useLocation();
  const filteredLinks: Record<ProjectType, Info> = {
    browser: _browserLinks,
    pool: _poolLinks,
    farm: _farmLinks,
  };
  const { install, active, revenue, open } = filteredLinks[props.projectType];

  const to =
    props.type === 'cb_click'
      ? location.pathname
      : props.projectType === 'farm'
      ? props.link
      : props.link + `/${props.id}`;
  const onClick = () => {
    props.type === 'cb_click' && window.open('https://cb.click/');
  };
  const offCopy = props.type === 'cb_click';
  const id = props.type === 'cb_click' ? 'CB.Click' : props.id;
  const comment =
    props.type === 'cb_click' ? t('This link associated with your CB.click account') : props.comment || '';

  if (isTable || isMobile) {
    return (
      <LinksEmptyRowMobile
        to={to}
        onClick={onClick}
        landLink={props.landLink}
        offCopy={offCopy}
        id={id}
        comment={comment}
        offApprox
        open={open}
        install={install}
        active={active}
        revenue={revenue}
        name={props.name}
      />
    );
  }

  return (
    <LinksEmptyRow
      to={to}
      onClick={onClick}
      landLink={props.landLink}
      offCopy={offCopy}
      id={id}
      comment={comment}
      offApprox
      open={open}
      install={install}
      active={active}
      revenue={revenue}
      name={props.name}
    />
  );
}

const CopyElem = ({ text }: { text: string }) => {
  const [isCopy, setIsCopy] = useState(false);
  const onCopy = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      window.navigator.clipboard.writeText(text);
      setIsCopy(true);
      setTimeout(() => {
        setIsCopy(false);
      }, 2000);
    },
    [text]
  );
  return (
    <div className={styles.row__copy_area} onClick={onCopy}>
      {!isCopy ? <Copy color="#4285F4" /> : <Check color="#37CC33" />}
    </div>
  );
};
