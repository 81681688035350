import { rpc } from '@backend/Rpc';

import { AppDispatch } from '../../main.tsx';
import { NETWORK_ALERT_ERROR, NETWORK_ALERT_REQUEST, NETWORK_ALERT_SUCCESS } from '../constant';

export default function getNetworkAlert() {
  return async function (dispatch: AppDispatch) {
    try {
      dispatch({ type: NETWORK_ALERT_REQUEST });
      const resp = await rpc.transmit('get_system_settings', {});
      dispatch({
        type: NETWORK_ALERT_SUCCESS,
        overload: resp.blockchain_overload,
      });
    } catch (e) {
      dispatch({ type: NETWORK_ALERT_ERROR });
      console.log(e);
    }
  };
}
