import i18next from 'i18next';
import { useMemo } from 'react';

import video1 from '@assets/ct-farm_overview.jpg';
import video4 from '@assets/ct-farm_pool-miner.jpg';
import video3 from '@assets/ct-farm_qr-code.jpg';

type videos = {
  en: string;
  ru: string;
  de: string;
  fr: string;
  it: string;
  es: string;
  pt: string;
};

export const video = {
  first: {
    en: 'https://vimeo.com/734664649',
    ru: 'https://vimeo.com/734718051',
    de: 'https://vimeo.com/734704278',
    fr: 'https://vimeo.com/734708135',
    it: 'https://vimeo.com/734711724',
    es: 'https://vimeo.com/734732865',
    pt: 'https://vimeo.com/734737932',
  },
  second: {
    en: 'https://vimeo.com/734671239',
    ru: 'https://vimeo.com/736799793',
    de: 'https://vimeo.com/734740489',
    fr: 'https://vimeo.com/736797137',
    it: 'https://vimeo.com/736798020',
    es: 'https://vimeo.com/736801549',
    pt: 'https://vimeo.com/736802938',
  },
  third: {
    en: 'https://vimeo.com/734697094',
    ru: 'https://vimeo.com/736809794',
    de: 'https://vimeo.com/736804597',
    fr: 'https://vimeo.com/736805634',
    it: 'https://vimeo.com/736807765',
    es: 'https://vimeo.com/736812063',
    pt: 'https://vimeo.com/736814727 ',
  },
};

export default function useFarmVideo() {
  return useMemo(() => {
    const lang = i18next.language;
    return [
      {
        id: 1,
        link: video.first[lang as keyof videos],
        src: video1,
      },
      {
        id: 2,
        link: video.second[lang as keyof videos],
        src: video3,
      },
      {
        id: 3,
        link: video.third[lang as keyof videos],
        src: video4,
      },
    ];
  }, [i18next.language]);
}
