import { useFarmRespInfo } from '@hooks/useFarmRespInfo';

import { Span } from '@shared/Graph/Tabs';

import { rpc } from '@backend/Rpc';

import { AppDispatch } from 'src/main.tsx';

import { GET_FARM_ACTIVATION_DATA } from '../constant';

type Resp = {
  timestamp: number;
};

export default function getFarmActivations(period: Span, promo: string, period_start: string, period_end: string) {
  return async function (dispatch: AppDispatch) {
    const respInfo = useFarmRespInfo(period);

    const resp = await rpc.transmit('farm.get_accounts_statistics', {
      promo_code: `${promo}`,
      start_time: `${period_start}`,
      end_time: `${period_end}`,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      discreteness: `${respInfo.discreteness}` as any,
    });
    dispatch({
      type: GET_FARM_ACTIVATION_DATA,
      activation: resp.items
        .map((item) => ({
          ...item,
          timestamp: item.timestamp * 1000,
        }))
        .sort((a: Resp, b: Resp) => a.timestamp - b.timestamp),
    });
  };
}
