import { rpc } from '@backend/Rpc';

import { AppDispatch } from 'src/main.tsx';

import { BROWSER_VIDEO_SUCCESS } from '../constant';

const images = {
  '1': 'https://cdn.cryptobrowser.site/images/aff-browser-video-preview/ctb_promo_1.jpg',
  '2': 'https://cdn.cryptobrowser.site/images/aff-browser-video-preview/ctb_promo_2.jpg',
  '3': 'https://cdn.cryptobrowser.site/images/aff-browser-video-preview/ctb_promo_3.jpg',
  '4': 'https://cdn.cryptobrowser.site/images/aff-browser-video-preview/ctb_promo_4.jpg',
  '5': 'https://cdn.cryptobrowser.site/images/aff-browser-video-preview/ctb_promo_5.jpg',
  '6': 'https://cdn.cryptobrowser.site/images/aff-browser-video-preview/ctb_promo_6.jpg',
  'bear': 'https://cdn.cryptobrowser.site/images/aff-browser-video-preview/ctb_promo_bear.jpg',
  'cryptobot': 'https://cdn.cryptobrowser.site/images/aff-browser-video-preview/ctb_promo_cryptobot.jpg',
  'guy': 'https://cdn.cryptobrowser.site/images/aff-browser-video-preview/ctb_promo_guy.jpg',
  'income': 'https://cdn.cryptobrowser.site/images/aff-browser-video-preview/ctb_promo_income.jpg',
  'overview_initial': 'https://cdn.cryptobrowser.site/images/aff-browser-video-preview/ctb_promo_initial.jpg',
};

export default function getBrowserVideo(lang: string) {
  return async function (dispatch: AppDispatch) {
    try {
      const resp = await rpc.transmit('promo.get_videos', { lang });
      const videos = Object.keys(resp).map((item) => {
        switch (item) {
          case 'guy': {
            return {
              image: images['bear'],
              id: resp['bear'],
            };
          }
          case 'bear': {
            return {
              image: images['guy'],
              id: resp['guy'],
            };
          }
          default: {
            return {
              image: images[item as keyof typeof images],
              id: resp[item],
            };
          }
        }
      });
      dispatch({ type: BROWSER_VIDEO_SUCCESS, video: videos.reverse() });
    } catch (e) {
      console.log(e);
    }
  };
}
