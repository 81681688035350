import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { FARM_DASH, FARM_PROMO } from '@utils/links';

import { useAnimation } from '@hooks/useAnimation';

import { AffilateVideo } from '@shared/AffilateVideo/AffilateVideo';
import { AffiliatePromoAccess } from '@shared/AffiliatePromoAccess/AffiliatePromoAccess';
import { farmLinks } from '@shared/FarmPromoCodes/FarmPromoCodes';
import { FarmPromoTabs } from '@shared/FarmPromoCodes/FarmPromoCodesTabs';
import { FarmTabs } from '@shared/FarmTabs/FarmTabs';
import {
  AffiliateBenefits,
  AffiliateIntroduction,
  MineOnDevice,
  FarmAboutCode,
  Container,
  NftIntroduction,
} from '@shared/index';
import { StickyFarmTabs } from '@shared/StickyFarmTabs/StickyFarmTabs';

import styles from './FarmAboutPro.module.scss';

export const FarmAboutProView = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const onGeneratePress = useCallback(() => {
    history.replace({ pathname: FARM_DASH });
  }, [history]);

  useAnimation();

  return (
    <Container vertical={20}>
      <FarmTabs />
      <AffiliateIntroduction
        MainBlock={
          <AffilateVideo
            player="youtube"
            previewSrc={
              'https://cdn.cryptobrowser.site/images/affiliate-redesign/ctfarm-pro/ctfarm-pro-video-preview.png'
            }
            previewRetinaSrc={
              'https://cdn.cryptobrowser.site/images/affiliate-redesign/ctfarm-pro/ctfarm-pro-video-preview@2x.png'
            }
            decorationImage={'https://cdn.cryptobrowser.site/images/affiliate-redesign/general/video-coins.png'}
            rootClassname={styles.previewImg}
            videos={{
              en: 'wChtUuCSsSM',
              ru: 'mAN1vSuUlWE',
              fr: 'mju-MtKdpkc',
              de: 'b-RQGp4UD3o',
              it: 'LUbv3oZdi-8',
              pt: 's8BcgmsChik',
              es: 'Rurrore1s-4',
            }}
          />
        }
        Title={t('Farm_About_First_Block.Title')}
        Description={
          <Trans
            i18nKey="Farm_About_First_Block.Description"
            components={{
              b: <b />,
            }}
          />
        }
        withParallax
        dashboardUrl={FARM_DASH}
        extraButtonUrl={farmLinks[FarmPromoTabs.Pro]}
      />
      <FarmAboutCode
        onCodeGeneratePress={onGeneratePress}
        stepsCss={styles.steps}
        isNeedRequest={false}
        Title={t('Generate a discount code for CryptoTab Farm Pro')}
        Description={
          <Trans
            i18nKey="Generate and use a special code to invite friends and followers to join %(link)sCryptoTab Farm%(link_end)s. By using it, they receive <b>up to 15%% off</b> all in-app purchases, and you — a <b>bonus equal to 15%%</b> of their farm income! Use your referral’s code to also get the discount: <b>come to success working together</b>!"
            components={{
              a: <a href="https://cryptotabfarm.com/" target="_blank" rel="noreferrer" />,
              b: <b />,
            }}
          />
        }
        imageDesktop={'https://cdn.cryptobrowser.site/images/affiliate-redesign/ctfarm-pro/ctfarm-pro-generate.png'}
        imageDesktop2x={
          'https://cdn.cryptobrowser.site/images/affiliate-redesign/ctfarm-pro/ctfarm-pro-generate@2x.png'
        }
        imageMob={'https://cdn.cryptobrowser.site/images/affiliate-redesign/ctfarm-pro/ctfarm-pro-generate-mob.png'}
        imageMob2x={
          'https://cdn.cryptobrowser.site/images/affiliate-redesign/ctfarm-pro/ctfarm-pro-generate-mob@2x.png'
        }
      />
      <AffiliateBenefits>
        <AffiliateBenefits.Item title={t('Farm_Affilate_Features_Blok_1')} />
        <AffiliateBenefits.Item title={t('Farm_Affilate_Features_Blok_2')} />
        <AffiliateBenefits.Item title={t('Farm_Affilate_Features_Blok_3')} />
        <AffiliateBenefits.Item title={t('Farm_Affilate_Features_Blok_4')} />
        <AffiliateBenefits.Item title={t('Fast withdrawals with no limits')} />
        <AffiliateBenefits.Item title={t('Farm_Affilate_Features_Blok_6')} />
      </AffiliateBenefits>
      <AffiliatePromoAccess
        promoUrl={FARM_PROMO}
        src={'https://cdn.cryptobrowser.site/images/affiliate-redesign/ctfarm-pro/ctfarm-pro-about-promo@2x.png'}
        srcMob={'https://cdn.cryptobrowser.site/images/affiliate-redesign/ctfarm-pro/ctfarm-pro-about-promo-mob@2x.png'}
      />
      <MineOnDevice />
      <NftIntroduction />
      <StickyFarmTabs />
    </Container>
  );
};
