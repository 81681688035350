import { FC, PropsWithChildren } from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router-dom';

import { TOTAL_SUSPEND } from '@utils/links';

import { BrowserSuspend } from '@pages/BrowserSuspend/BrowserSuspend';

import { useAuth } from '@hooks/useAuth';
import { useCWA } from '@hooks/useCWA';

import Loader from '@shared/Loader';

import { BaseLayout } from '../BaseLayout/BaseLayout';

interface LayoutRouteProps extends RouteProps {
  navigate?: () => void;
  isProtected?: boolean;
  isGetUser?: boolean;
  hideFooter?: boolean;
}

export const LayoutRoute: FC<PropsWithChildren<LayoutRouteProps>> = ({
  children,
  component: Component,
  isProtected = false,
  isGetUser = false,
  hideFooter = false,
  ...rest
}) => {
  const user = useAuth(isGetUser, isProtected);
  const isSuspend =
    !user.tags.includes('withdraw_access') && ['test_suspend', 'form_suspend'].some((tag) => user.tags.includes(tag));
  const { updateUtmMedium } = useCWA();

  const isTotalSuspend = user.tags.includes('total_suspend');
  if (isProtected && !user.hasRequested) {
    return <Loader />;
  }
  if (isProtected && isTotalSuspend && rest.location?.pathname !== TOTAL_SUSPEND) {
    return <Route {...rest} render={() => <Redirect to={TOTAL_SUSPEND} />} />;
  }
  if (isProtected && isSuspend) {
    return (
      <Route {...rest}>
        <BrowserSuspend />
      </Route>
    );
  }

  updateUtmMedium();
  return (
    <Route
      {...rest}
      render={(routeProps: RouteComponentProps) => (
        <BaseLayout component={Component} {...routeProps} hideFooter={hideFooter}>
          {children}
        </BaseLayout>
      )}
    />
  );
};

export default LayoutRoute;
