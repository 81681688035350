import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { product } from '@utils/productName';

import { createPromoCode } from '@service/actions/createPromoCode';
import getPromos from '@service/actions/getPromos';
import { rootPromoType } from '@service/reducers/promoReducer';

import Loader from '@shared/Loader';

import { FarmGenerateCodeView } from './view/FarmGenerateCodeView';
import { FarmSubheader } from '../headersComponents/FarmSubheader';
import { HelmetHead } from '../headersComponents/HelmetHead';

export const FarmGenerateCode = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const promo = useSelector((store: { promo: rootPromoType }) => store.promo);
  const isPromoLoading = useSelector((store: { promo: rootPromoType }) => store.promo.promo_request);

  useEffect(() => {
    if (!promo.promo_get && !promo.promo_request) {
      dispatch(getPromos());
    }
  }, [promo.promo_get, dispatch]);

  const handleGenerateCodeClick = useCallback(() => {
    if (promo.promo_get) {
      dispatch(createPromoCode());
    }
  }, [promo, dispatch]);

  if (isPromoLoading || !promo.promo_get) {
    return <Loader />;
  }

  return (
    <>
      <HelmetHead title={t('Generate a discount code for CryptoTab Farm')} product={product.farm} />
      <FarmSubheader />
      <FarmGenerateCodeView onGenerateCodeClick={handleGenerateCodeClick} promos={promo.promos} />
    </>
  );
};
