import cn from 'classnames';
import { ReactNode } from 'react';
import toast, { Toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { ToastCrossSvg, ToastErrorSvg, ToastInfoSvg, ToastSuccessSvg, ToastWarningSvg } from '@assets/colored';

import ToastCircle from './ToastCircle';

import styles from './Toast.module.scss';

export enum ToastKind {
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
}

type ToastProps = Toast & {
  text: string;
  kind: ToastKind;
};

const IconMap: Record<ToastKind, ReactNode> = {
  [ToastKind.Info]: <ToastInfoSvg />,
  [ToastKind.Success]: <ToastSuccessSvg />,
  [ToastKind.Warning]: <ToastWarningSvg />,
  [ToastKind.Error]: <ToastErrorSvg />,
};
function CustomToast(props: ToastProps) {
  const { text, kind, id } = props;
  const { t } = useTranslation();
  return (
    <div className={cn(styles.container, styles[kind])}>
      {IconMap[kind]}
      <div className={styles.text}>{t(text)}</div>
      <div className={styles.cross} onClick={() => toast.dismiss(id)}>
        <ToastCrossSvg />
        <ToastCircle />
      </div>
    </div>
  );
}

export const showToast = (text: string, kind = ToastKind.Info, id?: string) => {
  return toast.custom((t) => <CustomToast {...t} text={text} kind={kind} />, {
    duration: 10000,
    id,
  });
};
