import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Fail from '@assets/withdraw-fail.svg?react';

import { WithdrawalsState } from '@service/reducers/withdrawalsReducer';

import { Button, ButtonVariant } from '@shared/index';

import { Modal } from '../../../../shared/Modal';

type ModalErrorProps = {
  onClose: () => void;
};

export function ModalError({ onClose }: ModalErrorProps) {
  const { t } = useTranslation();
  const error = useSelector((store: { withdrawals: WithdrawalsState }) => store.withdrawals.errorWithdrawal);
  return (
    <Modal.Content>
      <Modal.Body>
        <Modal.Icon icon={<Fail />} />
        <Modal.TitleDescription title={t('WithdrawalConfirmError.Title')} description={t(error)} />
      </Modal.Body>
      <Modal.Footer>
        <Button type="button" size="medium" onClick={onClose} variant={ButtonVariant.Primary}>
          {t('Ok')}
        </Button>
      </Modal.Footer>
    </Modal.Content>
  );
}
