import cls from 'classnames';
import React, { ChangeEvent, ReactNode, useEffect, useState } from 'react';

import { Close, CopySuccess, EditSvg } from '@assets/colorless';

import styles from './BoxWithLabel.module.scss';

interface BoxWithLabelProps {
  text: string;
  label: string;
  icon?: ReactNode;
  isBlue?: boolean;
  isEditable?: boolean;
  onSave?: (value: string) => void;
  className?: string;
  textClassName?: string;
}

export default function BoxWithLabel(props: BoxWithLabelProps) {
  const { label, text, icon, isBlue, isEditable, onSave, className, textClassName } = props;
  const [isEdit, setEdit] = useState(false);
  const [value, setValue] = useState(text || '');
  useEffect(() => {
    setValue(text);
  }, [text]);
  const onEdit = () => {
    if (isEditable) {
      setEdit(true);
    }
  };

  const cancelEdit = () => {
    setEdit(false);
    setValue(text);
  };

  const saveEdit = () => {
    if (onSave) {
      onSave(value);
    }

    setEdit(false);
  };

  const EditText = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  if (!isEditable) {
    return (
      <div className={cls([styles.box, isBlue && styles.box_blue, className])}>
        <div className={styles.label}>{label}</div>
        <div className={cls(styles.text, textClassName)}>{text}</div>
        <div className={styles.icon}>{icon}</div>
      </div>
    );
  }

  return (
    <label className={cls([styles.box, isBlue && styles.box_blue, isEdit && styles.box_edit, className])}>
      <div className={styles.label}>{label}</div>

      {!isEdit ? (
        <>
          <div className={cls(styles.text, textClassName)}>{text}</div>
          <div onClick={onEdit} className={styles.icon}>
            <EditSvg />
          </div>
        </>
      ) : (
        <>
          <input type="text" className={styles.input} value={value} onChange={EditText} />
          <div className={styles.icon}>
            <div className={styles.save} onClick={saveEdit}>
              <CopySuccess />
            </div>
            <div className={styles.close} onClick={cancelEdit}>
              <Close />
            </div>
          </div>
        </>
      )}
    </label>
  );
}
