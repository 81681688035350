import { BROWSER_BANNED } from '@utils/links';

import { getAuthTokensPathname } from '@constants/url';

import { apiClient } from '../../api/apiClient';
import { rpc } from '../../backend/Rpc';
import { store } from '../../main.tsx';
import { USER_ERROR, USER_REQUEST, USER_SUCCESS, USER_TOKENS_SUCCESS } from '../constant';

export default function refreshUser() {
  const dispatch = store.dispatch;

  (async function () {
    try {
      const tokens_ts = localStorage.getItem('tokens_loading');
      if (!tokens_ts || isNaN(parseInt(tokens_ts)) || Date.now() - parseInt(tokens_ts) > 10000) {
        localStorage.setItem('tokens_loading', '' + Date.now());
        try {
          const did = localStorage.getItem('did');
          const urlToken = getAuthTokensPathname(did);
          const tokens: {
            id: string;
            access_token: string;
            refresh_token: string;
          } = await new apiClient().post(urlToken);
          localStorage.setItem('tokens', JSON.stringify(tokens));
          dispatch({
            type: USER_TOKENS_SUCCESS,
            tokens: {
              accessToken: tokens.access_token,
              refreshToken: tokens.refresh_token,
            },
          });
        } catch (er: unknown) {
          if (er instanceof Response) {
            const reason = await er.json();
            if (reason.code === 'account_banned') {
              window.location.href = BROWSER_BANNED;
              return;
            }
          }
          window.location.href = '/login/?next=/app/';
        } finally {
          localStorage.removeItem('tokens_loading');
        }
      }
      dispatch({ type: USER_REQUEST });
      const storage_tokens = localStorage.getItem('tokens');
      const tokens = JSON.parse(storage_tokens!);
      await rpc.transmit('auth', { access_token: tokens.access_token });
      const resp = await rpc.transmit('users.me', {});

      dispatch({
        type: USER_SUCCESS,
        id: resp.id,
        email: resp.email,
        tags: resp.tags,
        minWithdraw: resp.min_withdrawal_amount,
        ncwMinWithdraw: resp.ncw_min_withdrawal_amount,
        verified: resp.email_verified,
        is_banned: resp.is_banned,
      });
    } catch {
      dispatch({ type: USER_ERROR });
    }
  })();
}
