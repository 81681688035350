import {
  MAIN_CHANGE_DISCREETNESS_CLICKS,
  MAIN_CHANGE_DISCREETNESS_EARNING,
  MAIN_CLICKS_SUCCESS,
  MAIN_EARNING_SUCCESS,
  MAIN_CHANGE_DISCREETNESS_MINER,
  MAIN_MINERS_SUCCESS,
  MAIN_CHANGE_EARNING_TOTAL,
} from '@service/constant';

import { Span } from '@shared/Graph/Tabs';

import { Discreteness } from '@backend/JsonRpcApi';

const initialState = {
  clicksDiscreteness: Span.Day,
  earningsDiscreteness: Span.Day,
  minersDiscreteness: Span.Day,
  clicks: [],
  earnings: [],
  miners: [],
  earningTotal: 0,
  levels: {},
  get_levels: false,
};

type ActionType = {
  type: string;
  period_start?: string;
  period_end?: string;
  clicks?: MainStatsMinerResp[];
  earnings?: EarningsStatisticsResponse[];
  miners?: MainStatsMinerResp[];
  span: Discreteness;
  earningTotal?: number;
};

export type MainStatsResp = {
  timestamp?: number;
  account_id: number;
  lvl: number;
  value: number;
};

export type MainStatsShortResp = {
  timestamp: number;
  account_id: number;
  value: number;
};

export type MainStatsMinerResp = MainStatsResp & { amount: number };

export type MainStatsLevels = MainStatsLevelsItem[];

export type MainStatsLevelsItem = {
  ref: number;
  mined: number;
};

export type MainStatsMiners = {
  link_id: number;
  timestamp: number;
  level_1: number;
  level_2: number;
  level_3: number;
  level_4: number;
  level_5: number;
  level_6: number;
  level_7: number;
  level_8: number;
  level_9: number;
  level_10: number;
  value: number;
};

export type mainStatsGraph = Omit<typeof initialState, 'clicks' | 'earnings' | 'levels'> & {
  clicks: MainStatsResp[];
  earnings: EarningsStatisticsResponse[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  levels: any;
  earningTotal: number;
};
export type EarningsStatisticsResponse = {
  timestamp: number | undefined;
  value: number;
};
export default function mainStatsReducer(state = initialState, action: ActionType) {
  switch (action.type) {
    case MAIN_CLICKS_SUCCESS: {
      return {
        ...state,
        clicks: action.clicks,
      };
    }
    case MAIN_EARNING_SUCCESS: {
      return {
        ...state,
        earnings: action.earnings,
      };
    }
    case MAIN_MINERS_SUCCESS: {
      return {
        ...state,
        miners: action.miners,
      };
    }

    case MAIN_CHANGE_DISCREETNESS_CLICKS: {
      return {
        ...state,
        clicksDiscreteness: action.span,
      };
    }
    case MAIN_CHANGE_DISCREETNESS_EARNING: {
      return {
        ...state,
        earningsDiscreteness: action.span,
      };
    }
    case MAIN_CHANGE_DISCREETNESS_MINER: {
      return {
        ...state,
        minersDiscreteness: action.span,
      };
    }
    case MAIN_CHANGE_EARNING_TOTAL: {
      return {
        ...state,
        earningTotal: action.earningTotal,
      };
    }

    default:
      return state;
  }
}
