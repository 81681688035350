import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { GeneratePost } from '@assets/colorless';

import BoxWithLabel from '@shared/BoxWithLabel/BoxWithLabel';
import { CopyText } from '@shared/CopyText/CopyText';
import { farmLinks } from '@shared/FarmPromoCodes/FarmPromoCodes';
import { FarmPromoCodesTabs, FarmPromoTabs } from '@shared/FarmPromoCodes/FarmPromoCodesTabs';
import { Button } from '@shared/index';
import CopyButton from '@shared/PromoCard/CopyButton';

import styles from './LinkCard.module.scss';

export type LinkCardProps = {
  id: number;
  onGeneratePost: () => void;
  topChildren?: React.ReactNode;
};

export function LinkCard({ id, onGeneratePost }: LinkCardProps) {
  const { t } = useTranslation();
  const [tab, setTab] = useState(FarmPromoTabs.Usually);
  const [url, setUrl] = useState(farmLinks[FarmPromoTabs.Usually]);
  const setTabHandler = useCallback((tab: FarmPromoTabs) => {
    setTab(tab);
    setUrl(farmLinks[tab]);
  }, []);
  return (
    <>
      <FarmPromoCodesTabs active={tab} set={setTabHandler} />
      <div className={styles.container}>
        <div className={styles.top}>
          <div className={styles.label}>{t('Link ID / Code')}</div>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.center}>
            <div className={styles.id}>{id}</div>
            <div className={styles.buttons}>
              <CopyText text={`${url}${id}`} isBtn={true} />
              <Button
                onClick={onGeneratePost}
                type="button"
                className={styles.btn}
                size="medium"
                Icon={<GeneratePost />}
              >
                {t('GeneratePost.button')}
              </Button>
            </div>
          </div>
          <div className={styles.bottom}>
            <BoxWithLabel
              text={`${url}${id}`}
              label={t('Default Address')}
              icon={<CopyButton copyText={`${url}${id}`} noBackground />}
              isBlue
            />
          </div>
        </div>
      </div>
    </>
  );
}
