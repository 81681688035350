import cls from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { MoonSvg, NewsSvg, QuestionSvg } from '@assets/colorless';

import { externalLinks } from '@utils/externalLinks';
import { BROWSER_FEEDBACK } from '@utils/links';

import { SET_DARK_THEME } from '@service/constant';
import { Root } from '@service/reducers';

import Account from './components/Account';
import Background from './components/Background';
import Footer from './components/Footer';
import Language from './components/Languages';
import SimpleLink from './components/SimpleLink';
import SimpleSwitch from './components/SimpleSwitch';

import styles from './Sidebar.module.scss';
export type SidebarProps = {
  show: boolean;
};

export default function Sidebar(props: SidebarProps) {
  const { show } = props;
  const { t } = useTranslation();
  const { isDarkTheme } = useSelector((store: Root) => store.system);
  const dispatch = useDispatch();
  const [switchClass, setSwitch] = useState(false);
  useEffect(() => {
    const scroll = () => {
      if (window.pageYOffset > 60) {
        setSwitch(true);
      } else {
        setSwitch(false);
      }
    };
    document.addEventListener('scroll', scroll);
    return () => document.removeEventListener('scroll', scroll);
  }, [switchClass]);
  const toggleTheme = useCallback(() => {
    dispatch({ type: SET_DARK_THEME, value: !isDarkTheme });
  }, [dispatch, isDarkTheme]);
  return (
    <div className={cls([styles.sidebar__panel, show && styles.sidebar_active, switchClass && styles.sidebar__switch])}>
      <Account />
      <SimpleLink Icon={<NewsSvg />} title={t('News')} url={externalLinks.news} />
      <Background />
      <SimpleSwitch title={t('menu.darkTheme')} Icon={<MoonSvg />} isActive={isDarkTheme} onToggle={toggleTheme} />
      <SimpleLink Icon={<QuestionSvg width={20} height={20} />} inApp title={t('Support')} url={BROWSER_FEEDBACK} />
      <Language />
      <Footer />
    </div>
  );
}
