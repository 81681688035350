import cls from 'classnames';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { BtcSvg, LightCircleOrangeSvg, LinkCircleOrangeSvg, UserCircleOrangeSvg } from '@assets/colored';
import { StatisticSvg } from '@assets/colorless';
import Refresh from '@assets/refresh.svg?react';

import checkE from '@utils/checkE';
import useIntlFormatter from '@utils/useIntlFormatter';

import IdList from '@pages/BrowserDashboard/view/components/IdList/IdList';

import { ScrollToSection, useScrollToSection } from '@hooks/useScrollToSection';

import { BROWSER_LINKS_REQUEST, SET_BROWSER_PERIOD } from '@service/constant';
import { Root } from '@service/reducers';

import { Button, ButtonVariant } from '@shared/Button/Button';
import { Card } from '@shared/Card/Card';
import { DatePicker } from '@shared/DatePicker/DatePicker';
import { LoadSpinner } from '@shared/Loader';
import { MinedCard } from '@shared/MinedView/MinedCard';
import MinedLevels, { MindedLevels, MinedLevelsItem } from '@shared/MinedView/MinedLevels';

import styles from './MinedView.module.scss';

export type MinedViewLinksInfo = {
  open: number;
  install: number;
  revenue: number;
  active: number;
};

export type MinedViewProps = {
  id?: number;
  info: MinedViewLinksInfo;
  isLoaded: boolean;
  active: string;
  setActive: (v: string) => void;
  isShowListId?: boolean;
  start: string;
  end: string;
  total?: MinedLevelsItem;
  data?: MindedLevels;
  isOpensLink?: string;
  isInstallLink?: string;
  isMinerLink?: string;
  buttonLink?: string;
  isShowButtons?: boolean;
};

const refresh = () => window.location.reload();

export default function MinedView(props: MinedViewProps) {
  const { t } = useTranslation();
  const {
    info,
    isLoaded,
    active,
    setActive,
    id,
    isShowListId = true,
    start,
    end,
    total,
    data,
    isOpensLink = '#open',
    isInstallLink = '#install',
    isMinerLink = '#miner',
    buttonLink,
    isShowButtons = true,
  } = props;
  useScrollToSection({ section: ScrollToSection.MINING_NETWORK });
  const rate = useSelector((store: Root) => store.rate.btc2usd);
  const { intFormatter } = useIntlFormatter();
  const period = { start, end };
  const dispatch = useDispatch();
  const onPeriodChange = useCallback(
    (period: { period_start: string; period_end: string }) => {
      dispatch({
        type: SET_BROWSER_PERIOD,
        ...period,
      });
      dispatch({
        type: BROWSER_LINKS_REQUEST,
      });
    },
    [dispatch]
  );
  return (
    <div className={styles.container}>
      <Card
        className={styles.cardContent}
        headerClass={styles.card__header}
        TitleElement={
          <>
            <div className={styles.title} id={ScrollToSection.MINING_NETWORK}>
              <span>{t('Mining Network')}</span>
              <Button type="button" onClick={refresh} Icon={<Refresh />} onlyIcon className={styles.refresh} />
            </div>
            <div className={styles.desc}>{t('MiningNetwork.Description')}</div>
          </>
        }
        Switch={
          <div className={styles.switch}>
            <DatePicker period={period} onPeriodChange={onPeriodChange} />
            {isShowListId && (
              <IdList
                listClass={styles.id_list}
                containerClass={styles.id_list}
                active={active}
                id={!!id && id + ''}
                setActive={setActive}
              />
            )}
          </div>
        }
      >
        {isLoaded ? (
          <div className={cls({ [styles.body]: true, [styles.borderRadius]: !data })}>
            <div className={styles.card__container}>
              <MinedCard
                Icon={<LinkCircleOrangeSvg />}
                label={t('Link Opens')}
                number={info.open}
                href={isOpensLink}
                className={styles.row}
                external
              />
              <MinedCard
                Icon={<UserCircleOrangeSvg />}
                label={t('Referrals')}
                number={info.install}
                href={isInstallLink}
                className={styles.row}
                external
              />
              <MinedCard
                Icon={<LightCircleOrangeSvg />}
                label={t('Active Miners')}
                number={info.active}
                href={isMinerLink}
                className={styles.row}
                external
              />
              <MinedCard
                Icon={<BtcSvg />}
                label={t('Earnings')}
                subtitle={`${t('Approx.')} <b>$${intFormatter((info.revenue * rate).toFixed(4))} USD</b>`}
                number={intFormatter(checkE(info.revenue)) + ' <i>BTC</i>'}
                className={styles.total}
              />
            </div>
          </div>
        ) : (
          <div className={styles.load}>
            <LoadSpinner />
          </div>
        )}
        {data && total && <MinedLevels total={total} data={data} />}
      </Card>
      {isShowButtons && (
        <div className={styles.buttons}>
          <Button
            type="link"
            Icon={<StatisticSvg />}
            iconPosition={'left'}
            size="medium"
            to={{ pathname: buttonLink }}
            className={styles.link_btn}
            variant={ButtonVariant.Inverted}
          >
            <span>{t('VIEW DETAILS')}</span>
          </Button>
        </div>
      )}
    </div>
  );
}
