import React, { FC, useEffect, useMemo } from 'react';

import { LoadSpinner } from '@shared/Loader';

import { Withdrawal } from '../../../../../backend/JsonRpcApi';
import { SupportError, SupportErrorVariant } from '../../../SupportError';
import { WithdrawalSelect } from '../WithdrawalSelect/WithdrawalSelect';

import styles from './SupportWithdrawals.module.scss';

type SupportWithdrawalsProps = {
  withdrawals: Withdrawal[];
  getWithdrawals: () => void;
  selectedWithdrawalId: string | null;
  onWithdrawalSelect: (id: string) => void;
  error: SupportError;
  hasRequested: boolean;
};

export const SupportWithdrawals: FC<SupportWithdrawalsProps> = (props) => {
  const { withdrawals, getWithdrawals, onWithdrawalSelect, selectedWithdrawalId, hasRequested, error } = props;

  useEffect(() => {
    getWithdrawals();
  }, [getWithdrawals]);

  const filteredWithdrawals = useMemo(() => {
    return withdrawals.filter(
      (it) =>
        it.state === 1 ||
        it.state === 2 ||
        it.state === 3 ||
        it.state === 7 ||
        it.state === 9 ||
        it.state === 11 ||
        it.state === 12
    );
  }, [withdrawals]);

  return (
    <div className={styles.withdrawals}>
      {hasRequested ? (
        <WithdrawalSelect
          withdrawals={filteredWithdrawals}
          selectedWithdrawalId={selectedWithdrawalId!}
          onSelect={onWithdrawalSelect}
        />
      ) : (
        <LoadSpinner />
      )}
      {error.variant === SupportErrorVariant.SelectedWithdrawal && (
        <div className={styles.withdrawals__error}>This field is required.</div>
      )}
    </div>
  );
};
