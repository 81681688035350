import { POOL_LANDINGS_ERROR, POOL_LANDINGS_REQUEST, POOL_LANDINGS_SUCCESS } from '@service/constant';

import { BrowserPromoLand } from './browserPromoReducer';

export type PoolLandsState = {
  poolLandsRequest: boolean;
  poolLandsGet: boolean;
  lands: BrowserPromoLand[];
};
const initialState: PoolLandsState = {
  poolLandsRequest: false,
  poolLandsGet: false,
  lands: [],
};

type Action = {
  type: string;
  lands: BrowserPromoLand[];
};

export default function poolLandingsReducer(state = initialState, action: Action) {
  switch (action.type) {
    case POOL_LANDINGS_REQUEST: {
      return {
        ...state,
        poolLandsRequest: true,
      };
    }
    case POOL_LANDINGS_SUCCESS: {
      return {
        ...state,
        lands: action.lands,
        poolLandsRequest: false,
        poolLandsGet: true,
      };
    }
    case POOL_LANDINGS_ERROR: {
      return {
        ...state,
        poolLandsRequest: false,
        poolLandsGet: false,
      };
    }
    default: {
      return state;
    }
  }
}
