import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import cn from 'classnames';
import de from 'date-fns/locale/de';
import enGB from 'date-fns/locale/en-GB';
import es from 'date-fns/locale/es';
import fr from 'date-fns/locale/fr';
import it from 'date-fns/locale/it';
import ptBR from 'date-fns/locale/pt-BR';
import ru from 'date-fns/locale/ru';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/ru';
import 'dayjs/locale/fr';
import 'dayjs/locale/de';
import 'dayjs/locale/es';
import 'dayjs/locale/it';
import 'dayjs/locale/pt';
import i18next from 'i18next';
import { useCallback, useMemo, useRef, useState } from 'react';
import { DateRangePicker, RangeKeyDict } from 'react-date-range';
import { useTranslation } from 'react-i18next';

import { CalendarSvg } from '@assets/colorless';
import ArrowDownSvg from '@assets/colorless/arrow-list.svg?react';

import { jsColors } from '@utils/jsColors';
import useIntlFormatter from '@utils/useIntlFormatter';

import useClickOutside from '@hooks/useClickOutside';

import { Button, ButtonVariant } from '../Button/Button';

import styles from './DatePicker.module.scss';

export type DateRange = { period_start: string; period_end: string };
export type DatePickerProps = {
  left?: boolean;
  onPeriodChange: (period: { period_start: string; period_end: string }) => void;
  period: {
    start: string;
    end: string;
  };
};

const langMap = {
  en: enGB,
  ru: ru,
  fr: fr,
  de: de,
  es: es,
  it: it,
  pt: ptBR,
};

export function DatePicker({ left, period, onPeriodChange }: DatePickerProps) {
  const [show, setShow] = useState(false);
  const { dateFormatter } = useIntlFormatter();
  const [time, setTime] = useState({
    startDate: dayjs(period.start).toDate(),
    endDate: dayjs(period.end).toDate(),
  });

  const handleClick = useCallback(() => {
    if (
      dayjs(time.startDate).format('YYYY-MM-DD') !== dayjs(period.start).format('YYYY-MM-DD') ||
      dayjs(time.endDate).format('YYYY-MM-DD') !== dayjs(period.end).format('YYYY-MM-DD')
    ) {
      onPeriodChange({
        period_start: dayjs(time.startDate).format('YYYY-MM-DD'),
        period_end: dayjs(time.endDate).format('YYYY-MM-DD'),
      });
    }
  }, [time.startDate, time.endDate, period.start, period.end, onPeriodChange]);
  const ref = useRef<HTMLDivElement>(null);
  const handleClose = useCallback(() => {
    handleClick();
    setShow(false);
  }, [handleClick]);
  useClickOutside(ref, handleClose, show);

  const handleChange = useCallback((r: RangeKeyDict) => {
    setTime({
      startDate: dayjs(r.range1.startDate).toDate(),
      endDate: dayjs(r.range1.endDate).toDate(),
    });
  }, []);

  const onToggleDatePicker = () => setShow(!show);
  const staticRanges = useStaticRange();
  return (
    <div className={cn(styles.container, show && styles[`container--open`])} ref={ref}>
      <div className={styles.title} onClick={onToggleDatePicker}>
        <CalendarSvg />
      </div>
      <div className={styles.select} onClick={onToggleDatePicker}>
        <span>
          {dateFormatter(period.start, { month: 'short', day: '2-digit', year: 'numeric' })} -{' '}
          {dateFormatter(period.end, { month: 'short', day: '2-digit', year: 'numeric' })}
        </span>
        {/* <img src={arrow} alt="arrow" /> */}
        <ArrowDownSvg className={styles.arrow} />
      </div>
      {show && (
        <div className={styles.list} style={left ? { right: 'auto', left: 0 } : {}}>
          <DateRangePicker
            locale={langMap[i18next.language as keyof typeof langMap]}
            ranges={[
              {
                startDate: time.startDate,
                endDate: time.endDate,
                color: jsColors.orange,
              },
            ]}
            // Я не понимаю почему но в потругальском кривые аббревиатуры не отображаются
            weekdayDisplayFormat={i18next.language === 'pt' ? 'EEEEEE' : undefined}
            onChange={handleChange}
            rangeColors={[jsColors.orange]}
            maxDate={dayjs().toDate()}
            minDate={dayjs(1514754000000).toDate()}
            inputRanges={[]}
            staticRanges={staticRanges}
            className={styles.datePicker}
          />
          <Button
            type="button"
            variant={ButtonVariant.Primary}
            onClick={handleClose}
            size="medium"
            className={styles.list__btn}
          >
            OK
          </Button>
        </div>
      )}
    </div>
  );
}

const useStaticRange = () => {
  const { t } = useTranslation();

  return useMemo(() => {
    const isSelected = () => false;
    const today = dayjs().toDate();
    return [
      {
        isSelected,
        range: () => ({ startDate: dayjs().toDate(), endDate: dayjs().toDate() }),
        label: t('Calander.Today'),
      },
      {
        isSelected,
        range: () => ({
          endDate: dayjs().subtract(1, 'days').toDate(),
          startDate: dayjs().subtract(1, 'days').toDate(),
        }),
        label: t('Calander.Yesterday'),
      },
      {
        isSelected,
        range: () => {
          const startOfWeek = dayjs().startOf('week').toDate();
          const endOfWeek = new Date(Math.min(dayjs().endOf('week').valueOf(), today.valueOf()));
          return {
            startDate: startOfWeek,
            endDate: endOfWeek,
          };
        },
        label: t('Calander.This_week'),
      },
      {
        isSelected,
        range: () => ({
          startDate: dayjs().subtract(1, 'week').startOf('week').toDate(),
          endDate: dayjs().subtract(1, 'week').endOf('week').toDate(),
        }),
        label: t('Calander.Last_week'),
      },
      {
        isSelected,
        range: () => {
          const start = dayjs().startOf('month').toDate();
          const end = new Date(Math.min(dayjs().endOf('month').valueOf(), today.valueOf()));
          return {
            startDate: start,
            endDate: end,
          };
        },
        label: t('Calander.This_month'),
      },
      {
        isSelected,
        range: () => ({
          startDate: dayjs().subtract(1, 'month').startOf('month').toDate(),
          endDate: dayjs().subtract(1, 'month').endOf('month').toDate(),
        }),
        label: t('Calander.Last_month'),
      },
      {
        isSelected,
        range: () => ({
          endDate: dayjs().toDate(),
          startDate: dayjs().subtract(29, 'days').toDate(),
        }),
        label: t('Calander.Last_30_day'),
      },
      {
        isSelected,
        range: () => ({
          endDate: dayjs().toDate(),
          startDate: dayjs().subtract(90, 'days').toDate(),
        }),
        label: t('Calander.Last_90_days'),
      },
      {
        isSelected,
        range: () => ({
          endDate: dayjs().toDate(),
          startDate: dayjs().subtract(6, 'months').toDate(),
        }),
        label: t('Calander.Last_6_month'),
      },
    ];
  }, [t]);
};
