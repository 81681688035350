import classNames from 'classnames';
import { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import CoinSvg from '@assets/coin.svg?react';
import { HshSvg } from '@assets/colored';
import ArrowSvg from '@assets/colorless/arrow-right-sm.svg?react';
import NcwSvg from '@assets/ncw.svg?react';
import WalletSvg from '@assets/wallet.svg?react';

import checkE from '@utils/checkE';
import { formatAddress } from '@utils/formatAddress';
import { getAddress } from '@utils/getAddress';
import { BROWSER_BALANCE_ITEM } from '@utils/links';
import useIntlFormatter from '@utils/useIntlFormatter';

import { useBreakpoints } from '@hooks/useBreakpoints';

import { Withdrawal } from '@backend/JsonRpcApi';

import { mapStatus } from '../mapStatus';
import { EmptyHistory } from './EmptyHistory';

import styles from './History.module.scss';

export type HistoryProps = HTMLAttributes<HTMLDivElement> & {
  history: Withdrawal[];
  perPage: number;
  isBitcoin: boolean;
  isEmpty: boolean;
  currentPage: number;
};

const getTransactionBorderColor = (status: number | undefined) => {
  if (status === 3 || status === 4 || status === 8) return 'red';
  if (status === 1 || status === 11) return 'gray';
  if (status === 2 || status === 5 || status === 6 || status === 7 || status === 10 || status === 12) return 'blue';
  if (status === 9) return 'green';
};

export const History: FC<HistoryProps> = (props) => {
  const { history, isBitcoin, isEmpty, currentPage } = props;
  const { t } = useTranslation();
  const { intFormatter } = useIntlFormatter();
  const { isMobile } = useBreakpoints();

  if (isEmpty) {
    return <EmptyHistory />;
  }

  return (
    <div className={styles.history}>
      <div className={styles.history__list}>
        {history.map((it, index) => {
          const [statusLabel, message] = mapStatus(it.state, t);
          const address = getAddress(it);

          return (
            <Link
              to={{
                pathname: `${BROWSER_BALANCE_ITEM}/id=${it.id} isBitcoin=${isBitcoin}`,
                state: { currentPage, selectedItemId: it.id },
              }}
              className={styles.history__list__item}
              key={`${it.id}-${index}`}
              id={it.id}
            >
              <div className={styles.history__list__item__cell}>
                {it.payment_service === 'ncw' ? (
                  <NcwSvg width={20} height={20} />
                ) : (
                  <WalletSvg width={20} height={20} />
                )}
                <div className={styles.history__list__item__wrap}>
                  <span className={styles.addr}>{formatAddress(address, isMobile)}</span>
                </div>
              </div>
              <div className={styles.history__list__item__cell}>
                <div
                  className={classNames(
                    styles.history__chip,
                    styles[`history__chip__${getTransactionBorderColor(it.state)}`]
                  )}
                >
                  {statusLabel}
                  <div className={styles.history__chip_info}>{message}</div>
                </div>
              </div>
              <div className={classNames(styles.history__list__item__cell, styles.history__list__item__cell_amount)}>
                {it.type === 'hsh' ? (
                  <HshSvg width={20} height={20} className={styles.history__list__item__cell__icon} />
                ) : (
                  <CoinSvg width={20} height={20} className={styles.history__list__item__cell__icon} />
                )}
                <b>
                  {it.type === 'hsh'
                    ? `${intFormatter(it.amount, { minimumFractionDigits: 0, maximumFractionDigits: 8 })} HSH`
                    : `${intFormatter(checkE(+it.amount))} BTC`}
                </b>
                <ArrowSvg />
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};
