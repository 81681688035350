import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { BtcSvg, HshSvg } from '@assets/colored';

import { convertBigValueToLocale } from '@utils/convertBigValueToLocale';
import useIntlFormatter from '@utils/useIntlFormatter';

import styles from './WithdrawHeader.module.scss';

interface WithdrawHeaderProps {
  balance: number;
  balanceHSH: number;
  isBitcoin: boolean;
  isSuspend: boolean;
}

export default function WithdrawHeader({ balance, balanceHSH, isBitcoin, isSuspend }: WithdrawHeaderProps) {
  const { t } = useTranslation();
  const { intFormatter } = useIntlFormatter();

  return (
    <div className={cn(styles.wrapper, isSuspend && styles[`wrapper--suspend`])}>
      <div className={styles.wrapper__title}>{t('Withdraw')}</div>
      {!isSuspend && (
        <div className={cn(styles.wrapper__balance, styles.balance)}>
          {isBitcoin ? <BtcSvg width={40} height={40} /> : <HshSvg width={40} height={40} />}
          <div>
            <div className={styles.balance__subtitle}>{t('Your balance')}</div>
            <div className={styles.balance__title}>
              {isBitcoin ? (
                <>
                  <b>{intFormatter(balance) || Number(0).toFixed(8)}</b> BTC
                </>
              ) : (
                <>
                  <b>{convertBigValueToLocale(balanceHSH) || Number(0).toFixed(2)}</b> HSH
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
