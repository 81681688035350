import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { CircleRedCloseSvg } from '@assets/colored';
import { CheckSvg, CrossCircleSvg } from '@assets/colorless';

import checkE from '@utils/checkE';
import { convertBigValueToLocale } from '@utils/convertBigValueToLocale';

import { Button, ButtonVariant } from '@shared/index';
import { Modal } from '@shared/Modal';

import { Withdrawal } from '@backend/JsonRpcApi';

type WithdrawalCancelModalProps = {
  withdrawal: Withdrawal;
  isVisible: boolean;
  onClose: () => void;
  onCancel: () => void;
  isBitcoin: boolean;
  isLoading?: boolean;
};

export const WithdrawalCancelModal: FC<WithdrawalCancelModalProps> = (props) => {
  const { t } = useTranslation();
  const { withdrawal, isVisible, onClose, onCancel, isBitcoin, isLoading } = props;

  return (
    <Modal.Layout isOpen={isVisible} onClose={onClose}>
      <Modal.Content>
        <Modal.Body>
          <Modal.Icon icon={<CircleRedCloseSvg />} />
          <Modal.TitleDescription
            title={t('Cancel Withdrawal')}
            description={
              <Trans
                i18nKey={
                  'If you’d like to cancel this %(amount)s %(coin)s withdrawal please confirm your action by clicking the button below.'
                }
                components={{ b: <b /> }}
                values={{
                  amount: isBitcoin ? checkE(+withdrawal.amount) : convertBigValueToLocale(+withdrawal.amount),
                  coin: isBitcoin ? 'BTC' : 'HSH',
                }}
              />
            }
          />
        </Modal.Body>
        <Modal.Footer>
          <Button type={'button'} Icon={<CrossCircleSvg />} size="medium" onClick={onClose} isDisabledDark={isLoading}>
            {t('Cancel')}
          </Button>
          <Button
            type={'button'}
            Icon={<CheckSvg />}
            onClick={onCancel}
            size="medium"
            variant={ButtonVariant.Danger}
            isLoading={isLoading}
          >
            {t('Confirm')}
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Layout>
  );
};
