import cls from 'classnames';
import { ReactNode, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from '@shared/Modal';

import { Button, ButtonVariant } from '..';

import styles from './CommonLinkModal.module.scss';

const COMMENT_CHARS_LIMIT = 40;

type CommonLinkModalProps = {
  isOpen: boolean;
  onClose: () => void;
  icon: ReactNode;
  btnIcon?: ReactNode;
  title: string;
  description: string;
  btnText: string;
  onSubmit: (value: string) => Promise<void> | (() => void);
  withComment?: boolean;
};

export const CommonLinkModal = (props: CommonLinkModalProps) => {
  const { icon, title, description, onSubmit, withComment = false, btnIcon, btnText, onClose, isOpen } = props;

  const [value, setValue] = useState('');
  const { t } = useTranslation();

  const countClassnames = cls(styles.length, {
    [styles.empty]: value.length === 0,
    [styles.positive]:
      value.length !== 0 && COMMENT_CHARS_LIMIT - value.length > 0 && value.length < COMMENT_CHARS_LIMIT,
    [styles.equals]: COMMENT_CHARS_LIMIT - value.length === 0,
    [styles.negative]: COMMENT_CHARS_LIMIT - value.length < 0,
  });

  const handleSubmit = useCallback(() => {
    onSubmit(value);
  }, [onSubmit, value]);

  return (
    <Modal.Layout isOpen={isOpen} onClose={onClose}>
      <Modal.Content>
        <Modal.Body>
          <Modal.Icon icon={icon} />
          <Modal.TitleDescription title={title} description={description} />
          {withComment && (
            <div className={styles.comment}>
              <label htmlFor="comment">{t('Comment')}</label>
              <textarea
                className={COMMENT_CHARS_LIMIT - value.length < 0 ? styles.error : undefined}
                placeholder={t('Comment')}
                name="comment"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
              <span className={countClassnames}>{COMMENT_CHARS_LIMIT - value.length}</span>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={COMMENT_CHARS_LIMIT - value.length < 0}
            type={'button'}
            variant={ButtonVariant.Primary}
            onClick={handleSubmit}
            Icon={btnIcon}
          >
            {btnText}
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Layout>
  );
};
