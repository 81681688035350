import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { CtpLogoSvg } from '@assets/colored';

import { BackButton } from '@shared/BackButton/BackButton';

import { config } from './config';
import Subheader from '../../components/Subheader';

import styles from '../../components/Subheader/Subheader.module.scss';

export default function PoolSubheader({ isShowBackButton }: { isShowBackButton?: boolean }) {
  const { t } = useTranslation();
  return (
    <Subheader Icon={<CtpLogoSvg />}>
      {isShowBackButton && <BackButton />}

      {config.pool.map((c) => (
        <NavLink key={c.url} to={{ pathname: c.url }} activeClassName={styles.active}>
          {c.Icon}
          {t(c.text)}
        </NavLink>
      ))}
    </Subheader>
  );
}
