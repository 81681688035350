import { SupportError, SupportErrorVariant } from '../Support/SupportError';

// eslint-disable-next-line no-useless-escape
const emailRegexp = new RegExp(/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/);

const urlRegexp = new RegExp(
  /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i
);

type ValidateResult = {
  isValid: boolean;
  error?: SupportError;
};

export const validateEmail = (email: string): ValidateResult => {
  if (email === '') {
    return {
      isValid: false,
      error: {
        error: 'This field is required.',
        variant: SupportErrorVariant.Email,
      },
    };
  }

  if (!emailRegexp.test(email)) {
    return {
      isValid: false,
      error: {
        error: 'Enter correct email.',
        variant: SupportErrorVariant.Email,
      },
    };
  }
  return { isValid: true };
};

export const validateUrl = (url: string): ValidateResult => {
  if (url === '') {
    return {
      isValid: false,
      error: {
        error: 'This field is required',
        variant: SupportErrorVariant.Url,
      },
    };
  }

  if (!urlRegexp.test(url)) {
    return {
      isValid: false,
      error: {
        error: 'Enter correct url address.',
        variant: SupportErrorVariant.Url,
      },
    };
  }
  return { isValid: true };
};
