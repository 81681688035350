import checkResponse from './checkResponse';

export class apiClient {
  public get(url: string, header?: RequestInit) {
    return fetch(url, {
      ...header,
    }).then((r) => checkResponse(r));
  }

  public post(url: string, headers?: HeadersInit, body = {}) {
    return fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
      body: JSON.stringify(body),
    }).then((r) => checkResponse(r));
  }

  public delete(url: string, headers?: HeadersInit, body = {}) {
    return fetch(url, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
      body: JSON.stringify(body),
    }).then((r) => checkResponse(r));
  }
}
