import checkE from '@utils/checkE';

import { MAIN_CHANGE_EARNING_TOTAL, MAIN_EARNING_SUCCESS, MAIN_MINERS_SUCCESS } from '@service/constant';

import { Span } from '@shared/Graph/Tabs';

import { AffiliateGroup, Discreteness, ReferralPaymentsItem } from '@backend/JsonRpcApi';
import { rpc } from '@backend/Rpc';

import { AppDispatch } from 'src/main.tsx';

export const getMainPaymentsStats = (
  period_start: string,
  period_end: string,
  discretenessMiners: Discreteness = Span.Day,
  discretenessEarnings: Discreteness = Span.Day,
  isDiscretenessMinersChange: boolean,
  isDateChange: boolean
) => {
  const params = (group: AffiliateGroup, discreteness: Discreteness) => ({
    start_date: period_start,
    end_date: period_end,
    group,
    discreteness,
  });

  const handleDispatch = (
    dispatch: AppDispatch,
    data: ReferralPaymentsItem[],
    isMiners: boolean,
    isEarnings: boolean
  ) => {
    const minersData = data
      .map((item) => ({
        ...item,
        timestamp: item.timestamp! * 1000,
        value: item.users,
      }))
      .sort((a, b) => a.timestamp - b.timestamp);
    if (isMiners) {
      dispatch({ type: MAIN_MINERS_SUCCESS, miners: minersData });
    }
    if (isEarnings) {
      const earningsData = data.map((item) => ({
        timestamp: item.timestamp ? item.timestamp * 1000 : undefined,
        value: +checkE(item.amount),
      }));
      const earningTotal = checkE(data.reduce((acc, item) => acc + item.amount, 0));
      dispatch({ type: MAIN_CHANGE_EARNING_TOTAL, earningTotal: earningTotal });
      dispatch({ type: MAIN_EARNING_SUCCESS, earnings: earningsData });
    }
  };

  return async (dispatch: AppDispatch) => {
    try {
      let data: ReferralPaymentsItem[] = [];
      if (isDateChange) {
        if (discretenessMiners === discretenessEarnings) {
          const [data1, data2, data3] = await Promise.all([
            rpc.transmit('statistics.referrals.payments', params(AffiliateGroup.Browser, discretenessMiners)),
            rpc.transmit('statistics.referrals.payments', params(AffiliateGroup.Farm, discretenessMiners)),
            rpc.transmit('statistics.referrals.payments', params(AffiliateGroup.Pool, discretenessMiners)),
          ]);
          data = [...data1.items, ...data2.items, ...data3.items];

          handleDispatch(dispatch, data, true, true);
        } else {
          const [data1, data2, data3, data4, data5, data6] = await Promise.all([
            rpc.transmit('statistics.referrals.payments', params(AffiliateGroup.Browser, discretenessMiners)),
            rpc.transmit('statistics.referrals.payments', params(AffiliateGroup.Farm, discretenessMiners)),
            rpc.transmit('statistics.referrals.payments', params(AffiliateGroup.Pool, discretenessMiners)),
            rpc.transmit('statistics.referrals.payments', params(AffiliateGroup.Browser, discretenessEarnings)),
            rpc.transmit('statistics.referrals.payments', params(AffiliateGroup.Farm, discretenessEarnings)),
            rpc.transmit('statistics.referrals.payments', params(AffiliateGroup.Pool, discretenessEarnings)),
          ]);
          const dataMiners = [...data1.items, ...data2.items, ...data3.items];
          const dataEarnings = [...data4.items, ...data5.items, ...data6.items];
          handleDispatch(dispatch, dataMiners, true, false);
          handleDispatch(dispatch, dataEarnings, false, true);
        }
      } else {
        const discreteness = isDiscretenessMinersChange ? discretenessMiners : discretenessEarnings;
        const [data1, data2, data3] = await Promise.all([
          rpc.transmit('statistics.referrals.payments', params(AffiliateGroup.Browser, discreteness)),
          rpc.transmit('statistics.referrals.payments', params(AffiliateGroup.Farm, discreteness)),
          rpc.transmit('statistics.referrals.payments', params(AffiliateGroup.Pool, discreteness)),
        ]);
        data = [...data1.items, ...data2.items, ...data3.items];
        if (isDiscretenessMinersChange) {
          handleDispatch(dispatch, data, true, false);
        } else {
          handleDispatch(dispatch, data, false, true);
        }
      }
    } catch (e) {
      console.error('Ошибка при получении данных по платежам браузера:', e);
    }
  };
};
