import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import AffiliateLinkSvg from '@assets/colored/affiliate-link.svg?react';
import { PlusSvg } from '@assets/colorless';
import LinkSvg from '@assets/colorless/link.svg?react';

import getBalances from '@service/actions/getBalances';

import { Button, ButtonVariant } from '@shared/Button/Button';
import { CommonLinkModal } from '@shared/CommonLinkModal/CommonLinkModal';
import { useModal } from '@shared/Modal';

import { rpc } from '@backend/Rpc';

import { showToast, ToastKind } from '../../components/Toast/Toast';

type AddLinkButtonProps = {
  onLinkCreate: () => void;
  btnClassName?: string;
  buttonVariant?: ButtonVariant;
};
export const AddLinkButton = (props: AddLinkButtonProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { opened, onOpen, onClose } = useModal();

  const handleLinkCreate = async (comment: string) => {
    try {
      await rpc.transmit('users.me.create_link', {
        comment,
      });
      dispatch(getBalances());
      onClose();
      props.onLinkCreate();
      showToast('Link/Code successfully added!', ToastKind.Success);
    } catch {
      showToast('error.code', ToastKind.Error);
    }
  };

  return (
    <>
      <Button
        type="button"
        onClick={onOpen}
        variant={props.buttonVariant}
        className={props.btnClassName}
        size="medium"
        Icon={<PlusSvg />}
      >
        {t('Add link')}
      </Button>
      <CommonLinkModal
        isOpen={opened}
        onClose={onClose}
        title={t('Create Link ID')}
        description={t(
          'Create additional personal link to track different traffic sources with different metrics to understand conversion rates.'
        )}
        btnText={t('Create')}
        withComment={true}
        onSubmit={handleLinkCreate}
        icon={<AffiliateLinkSvg />}
        btnIcon={<LinkSvg />}
      />
    </>
  );
};
