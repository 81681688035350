import React, { FC, ReactNode, useCallback, useState } from 'react';
import { Trans } from 'react-i18next';

import CloseSVG from '@assets/colorless/cross.svg?react';

import { Button as CommonButton } from '../Button/Button';

import styles from './Banner.module.scss';

export type BannerProps = {
  Media?: ReactNode;
  title?: string;
  description: string;
  actionTitle?: string;
  onAction?: () => void;
  isNew?: boolean;
  offsetBottom?: number;
  offsetTop?: number;
  Button?: ReactNode;
};

export const Banner: FC<BannerProps> = (props) => {
  const { title, description, actionTitle, onAction, Media, Button } = props;
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = useCallback(() => setIsVisible((prev) => !prev), []);

  return isVisible ? (
    <div className={styles.banner} style={{ marginBottom: props.offsetBottom, marginTop: props.offsetTop }}>
      <div className={styles.banner__body}>
        <>
          {' '}
          <button className={styles.banner__close} onClick={handleClose}>
            <CloseSVG color={'#B3B3B3'} />
          </button>
          {Media && <div className={styles.banner__media}>{Media}</div>}
          <div className={styles.banner__content}>
            {title && <h3 className={styles.banner__content_title}>{title}</h3>}
            <p className={styles.banner__content_description}>
              <Trans i18nKey={description} components={{ b: <b /> }} />
            </p>
          </div>
          {Button ? (
            <>{Button}</>
          ) : (
            onAction && (
              <CommonButton type={'button'} onClick={onAction} size="medium" className={styles.banner__action}>
                {actionTitle}
              </CommonButton>
            )
          )}
        </>
      </div>
    </div>
  ) : null;
};
