import { doEmptyLevels, Item, searchItem } from '@utils/browserLevelsUtils';
import checkE from '@utils/checkE';

import { PoolStatisticsResponse } from '@service/reducers/poolStatisticReducer';

import { Span } from '@shared/Graph/Tabs';

import { AffiliateGroup, Discreteness, JsonRpcApi, Product } from '@backend/JsonRpcApi';
import { rpc } from '@backend/Rpc';

import { AppDispatch } from 'src/main.tsx';

import {
  GET_POOL_LEVELS,
  POOL_CHANGE_EARNINGS_TOTAL,
  POOL_INSTALLS_SUCCESS,
  POOL_LINKS_ERROR,
  POOL_LINKS_REQUEST,
  POOL_LINKS_SUCCESS,
  POOL_MINERS_SUCCESS,
  POOL_OPENS_SUCCESS,
  POOL_STATISTIC_UPDATE,
} from '../constant';
const updateTimestamp = <T extends { timestamp: number }>(arr: T[]): T[] =>
  arr.map((item) => ({ ...item, timestamp: item.timestamp * 1000 }));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fetchAndProcessData = async (rpcMethod: keyof JsonRpcApi, params: object, mapFunc: (item: any) => any) => {
  const response = await rpc.transmit(rpcMethod, params);
  // @ts-expect-error TODO: fix this
  const data = response.items ? response.items : response;
  const modifiedItems = updateTimestamp(data)
    .map(mapFunc)
    .sort((a, b) => a.timestamp - b.timestamp);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const unmodifiedItems: any[] = updateTimestamp(data).sort((a, b) => a.timestamp - b.timestamp);
  return { modifiedItems, unmodifiedItems };
};
export default function getPoolLinkDetailInfo(
  period_start: string,
  period_end: string,
  linksDiscreteness: Discreteness,
  registrationDiscreteness: Discreteness,
  earningsDiscreteness: Discreteness,
  updateDate: boolean,
  updateOpensDiscreteness: boolean,
  updateInstallsDiscreteness: boolean,
  updateEarningsDiscreteness: boolean
) {
  return async function (dispatch: AppDispatch) {
    const mockLevels = new Array(10).fill({ ref: 0, mined: 0 });
    const update = (dataType: string, data: PoolStatisticsResponse[]) => {
      dispatch({
        type: POOL_STATISTIC_UPDATE,
        dataType,
        data,
      });
    };
    try {
      if (updateDate) {
        dispatch({ type: POOL_LINKS_REQUEST });

        // ---------- opens ----------
        const opensData = await fetchAndProcessData(
          'statistics.v2.clicks',
          { start_date: period_start, end_date: period_end, discreteness: Span.Day, product_ids: [Product.Pool] },
          (item) => ({ timestamp: item.timestamp, account_id: +item.advert_id, value: item.value })
        );

        let graphOpens = opensData;
        if (linksDiscreteness !== Span.Day) {
          graphOpens = await fetchAndProcessData(
            'statistics.v2.clicks',
            {
              start_date: period_start,
              end_date: period_end,
              discreteness: linksDiscreteness,
              product_ids: [Product.Pool],
            },
            (item) => ({ timestamp: item.timestamp, account_id: +item.advert_id, value: item.value })
          );
        }

        dispatch({ type: POOL_OPENS_SUCCESS, opens: graphOpens.modifiedItems });
        update('link', opensData.modifiedItems);

        // ---------- installs ----------

        const installsData = await fetchAndProcessData(
          'statistics.referrals',
          { start_date: period_start, end_date: period_end, discreteness: Span.Day },
          (item) => ({ ...item, timestamp: item.timestamp })
        );
        let graphInstalls = installsData;
        if (registrationDiscreteness !== Span.Day) {
          graphInstalls = await fetchAndProcessData(
            'statistics.referrals',
            { start_date: period_start, end_date: period_end, discreteness: registrationDiscreteness },
            (item) => ({ ...item, timestamp: item.timestamp })
          );
        }

        dispatch({ type: POOL_INSTALLS_SUCCESS, installs: installsData.modifiedItems });
        update('registration', graphInstalls.modifiedItems);

        // ---------- miners ----------

        const minersData = await fetchAndProcessData(
          'statistics.referrals.payments',
          { start_date: period_start, end_date: period_end, group: AffiliateGroup.Pool, discreteness: Span.Day },
          (item) => ({ ...item, timestamp: item.timestamp, value: item.users })
        );
        const graphMiners = minersData;

        dispatch({ type: POOL_MINERS_SUCCESS, miners: minersData.modifiedItems });

        // ---------- earnings ----------

        let graphEarnings = graphMiners;
        if (earningsDiscreteness !== Span.Day) {
          graphEarnings = await fetchAndProcessData(
            'statistics.referrals.payments',
            {
              start_date: period_start,
              end_date: period_end,
              group: AffiliateGroup.Pool,
              discreteness: earningsDiscreteness,
            },
            (item) => ({ timestamp: item.timestamp ? item.timestamp : undefined, value: +checkE(item.amount) })
          );
        }
        const earningTotal = checkE(graphEarnings.unmodifiedItems.reduce((acc, item) => acc + item.amount, 0));
        dispatch({ type: POOL_CHANGE_EARNINGS_TOTAL, earningsTotal: earningTotal });
        update('earnings', graphEarnings.modifiedItems);
        const levels: Record<string, Item[]> = {};
        const total: Item[] = [...mockLevels];

        installsData.modifiedItems.forEach((item) => {
          const levelIndex = Math.max(1, Math.min(item.lvl, 10)) - 1;
          if (total[levelIndex]) total[levelIndex].ref += item.value;
        });

        minersData.modifiedItems.forEach((item) => {
          const levelIndex = Math.max(1, Math.min(item.lvl, 10)) - 1;
          if (total[levelIndex]) total[levelIndex].mined += item.value;
        });

        doEmptyLevels(installsData.modifiedItems, levels);
        doEmptyLevels(minersData.modifiedItems, levels);

        Object.keys(levels).forEach((item) => {
          for (let lvl = 1; lvl <= 10; lvl++) {
            levels[item][lvl - 1] = {
              ref: searchItem(item, lvl, installsData.modifiedItems),
              mined: searchItem(item, lvl, minersData.modifiedItems),
            };
          }
        });
        levels.total = total;

        dispatch({ type: POOL_LINKS_SUCCESS });
        dispatch({ type: GET_POOL_LEVELS, levels });
      } else {
        // Если updateDate false, обрабатываем только те части, которые требуют обновления
        if (updateOpensDiscreteness) {
          const opensData = await fetchAndProcessData(
            'statistics.v2.clicks',
            {
              start_date: period_start,
              end_date: period_end,
              discreteness: linksDiscreteness,
              product_ids: [Product.Pool],
            },
            (item) => ({ timestamp: item.timestamp, account_id: +item.advert_id, value: item.value })
          );
          update('link', opensData.modifiedItems);
        }
        if (updateInstallsDiscreteness) {
          const installsData = await fetchAndProcessData(
            'statistics.referrals',
            { start_date: period_start, end_date: period_end, discreteness: registrationDiscreteness },
            (item) => ({ ...item, timestamp: item.timestamp })
          );
          update('registration', installsData.modifiedItems);
        }

        if (updateEarningsDiscreteness) {
          const earningsData = await fetchAndProcessData(
            'statistics.referrals.payments',
            {
              start_date: period_start,
              end_date: period_end,
              group: AffiliateGroup.Pool,
              discreteness: earningsDiscreteness,
            },
            (item) => ({ timestamp: item.timestamp ? item.timestamp : undefined, value: +checkE(item.amount) })
          );
          const earningTotal = checkE(earningsData.unmodifiedItems.reduce((acc, item) => acc + item.amount, 0));
          dispatch({ type: POOL_CHANGE_EARNINGS_TOTAL, earningsTotal: earningTotal });
          update('earnings', earningsData.modifiedItems);
        }
      }
    } catch (e) {
      dispatch({ type: POOL_LINKS_ERROR });
      console.error(e);
    }
  };
}
