import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { GraphDateConfig } from '@utils/graph';

import IdList from '@pages/BrowserDashboard/view/components/IdList/IdList';

import { MAIN_CHANGE_DISCREETNESS_CLICKS, SET_BROWSER_PERIOD } from '@service/constant';
import { Root } from '@service/reducers';
import { rootBrowserGraph } from '@service/reducers/browserStatsReducer';
import { MainStatsResp, MainStatsShortResp } from '@service/reducers/mainStatsReducer';

import GraphComponent from '@shared/Graph/GraphComponent/GraphComponent';
import { Span } from '@shared/Graph/Tabs';

import styles from './ClicksGraph.module.scss';

const DEFAULT_ID = 'All Link IDs';

export type MainStatsGraphProps = {
  id?: number;
};

const convertToShortResp = (data: (MainStatsResp | MainStatsShortResp)[]): MainStatsShortResp[] => {
  return data
    .filter((item): item is MainStatsShortResp => item.timestamp !== undefined)
    .map((item) => ({
      ...item,
      timestamp: item.timestamp as number,
    }));
};

function filterData<P>(setGraphData: (val: P[]) => void, data: P[], id: string) {
  if (id === DEFAULT_ID) {
    setGraphData(data);
  } else {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newData = data?.filter((item: any) => item.account_id === id);
    setGraphData(newData);
  }
}

export default function ClicksGraph({ id }: MainStatsGraphProps) {
  const { t } = useTranslation();
  const data = useSelector((store: Root) => store.mainStats.clicks);

  const graphPeriod = useSelector((store: { browserGraph: rootBrowserGraph }) => ({
    start: store.browserGraph.period_start,
    end: store.browserGraph.period_end,
  }));
  const period = useSelector((store: Root) => store.mainStats.clicksDiscreteness as Span);
  const dispatch = useDispatch();
  const onPeriodChange = useCallback(
    (period: { period_start: string; period_end: string }) => {
      dispatch({
        type: SET_BROWSER_PERIOD,
        ...period,
      });
    },
    [dispatch]
  );

  const [active, setActive] = useState(`${id ? id : DEFAULT_ID}`);
  const [graphData, setGraphData] = useState<(MainStatsResp | MainStatsShortResp)[]>([]);

  const handleChangePeriod = useCallback(
    (span: Span) => {
      dispatch({
        type: MAIN_CHANGE_DISCREETNESS_CLICKS,
        span: span,
      });
    },
    [dispatch]
  );

  const handleChangeId = useCallback(
    (val: string) => {
      setActive(val);
      filterData(setGraphData, graphData, val);
    },
    [graphData]
  );

  useEffect(() => {
    setGraphData(data);
    const temp = convertToShortResp(data);
    filterData(setGraphData, temp, active);
  }, [active, data, period]);

  const total = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const res = graphData.reduce((acc: number, item: any) => acc + item.value, 0);
    return res;
  }, [graphData]);

  return (
    <GraphComponent
      SwitchElem={
        <IdList
          id={!!id && id + ''}
          active={t(active)}
          containerClass={styles.list__container}
          setActive={handleChangeId}
        />
      }
      total={total}
      onPeriodChange={onPeriodChange}
      period={graphPeriod}
      type={'value'}
      onSpanChanged={handleChangePeriod}
      span={period}
      title={t('Clicks.Title')}
      xFormat={GraphDateConfig[period].format}
      data={graphData}
      tooltip={t('Linkclicks.tooltip')}
    />
  );
}
