export enum PRODUCT_KIND {
  All = 'all',
  Farm = 'farm',
  FarmPro = 'farm_pro',
  Pool = 'pool',
  Browser = 'browser',
  Nft = 'nft',
}

export default function checkPathname(path: string): PRODUCT_KIND | null {
  path = path.replace(/\/$/, '');

  if (path.match('app/farm/')) {
    return PRODUCT_KIND.Farm;
  }

  if (path.match('app/farm-pro/')) {
    return PRODUCT_KIND.FarmPro;
  }

  if (path.match('app/pool/')) {
    return PRODUCT_KIND.Pool;
  }

  if (path.match('app/browser/')) {
    return PRODUCT_KIND.Browser;
  }

  return PRODUCT_KIND.All;
}
