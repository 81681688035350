import cn from 'classnames';
import {
  Dispatch,
  HTMLAttributes,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { NavLink } from 'react-router-dom';

import { ArrowRightSmSvg, ChevronDownSvg } from '@assets/colorless';

import useClickOutside from '@hooks/useClickOutside';

import CopyButton from '@shared/PromoCard/CopyButton';
import ShareBtn from '@shared/ShareBtn/ShareBtn';

import styles from './CommonProduct.module.scss';

export enum AllProductTabs {
  Link = 'link',
  Promo = 'promo',
}
type ProductState = {
  link: string;
  promo: string;
};

export type CommonProductLinks = {
  farmLinks: string[];
  farmPromos: string[];
  poolLinks: string[];
  poolPromos: string[];
  browserPromos: string[];
  browserLinks: string[];
  farmProLinks: string[];
};

export type CommonProductState = Record<string, ProductState>;

export type CommonProductProps = {
  Icon: ReactNode;
  active: AllProductTabs;
  state: CommonProductState;
  setState: Dispatch<SetStateAction<CommonProductState>>;
  title: string;
  url: string;
  text: string;
  links: string[];
  index: string;
  promoCodes: string[];
  Footer?: ReactNode;
  isShowLink?: boolean;
};
export function CommonProduct(props: CommonProductProps & HTMLAttributes<HTMLDivElement>) {
  const {
    Footer,
    state,
    setState,
    index,
    links,
    active,
    promoCodes,
    Icon,
    text,
    title,
    url,
    className,
    isShowLink = true,
    ...rest
  } = props;
  const [show, setShow] = useState(false);
  useEffect(() => {
    setState((prev) => {
      return { ...prev, [index]: { link: links[0], promo: promoCodes[0] } };
    });
  }, [links, promoCodes, index, setState]);
  const list = useMemo(() => {
    const _list = active === AllProductTabs.Promo ? promoCodes : links;
    return _list.map((l) => ({
      value: l,
      label: l,
    }));
  }, [active, links, promoCodes]);

  const inputContainerRef = useRef<HTMLDivElement>(null);
  useClickOutside(
    inputContainerRef,
    () => {
      setShow(false);
    },
    show
  );

  const computeUrl = useMemo(() => {
    if (!state[index]) {
      return '';
    }
    const { link = '', promo = '' } = state[index];
    return link + promo;
  }, [state, index]);

  const onListItemClick = useCallback(
    (i: string) => {
      setState((prev) => {
        return { ...prev, [index]: { ...prev[index], [active]: i } };
      });
    },
    [active, index, setState]
  );

  return (
    <div className={cn(styles.container, className)} {...rest}>
      <div className={styles.header}>
        {Icon}
        <span>{title}</span>
        <NavLink className={styles.header__link} to={{ pathname: url }}>
          <ArrowRightSmSvg />
        </NavLink>
      </div>
      <div className={styles.body}>
        <p className={styles.text}>{text}</p>
        {isShowLink && (
          <div className={styles.content}>
            <div className={styles.input} ref={inputContainerRef} onClick={() => setShow((prev) => !prev)}>
              <div className={styles.input__label}>
                {active === AllProductTabs.Link && 'Reflink'}
                {active === AllProductTabs.Promo && 'Promo'}
              </div>

              <div className={styles.input__active}>
                <span>
                  {state[index]?.link}
                  <strong>{state[index]?.promo}</strong>
                </span>
                <ChevronDownSvg />
              </div>
              {show && (
                <div className={styles.list}>
                  {list.map((i) => (
                    <div className={styles.list__item} key={i.label} onClick={() => onListItemClick(i.label)}>
                      {i.label.split('?')[0]}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <CopyButton copyText={computeUrl} />
            <ShareBtn shareUrl={computeUrl} />
          </div>
        )}
        {Footer}
      </div>
    </div>
  );
}
