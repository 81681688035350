import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import checkPathname, { PRODUCT_KIND } from '@utils/checkPathname';

import { Root } from '@service/reducers';

import { productURLsMap } from '@constants/productURLsMap';

import { TextCard } from '../../shared/TextCard/TextCard';

export type PromoTextCardProps = {
  text: string;
  replaceContent?: (text: string) => string;
};

export function PromoTextCard({ text, replaceContent }: PromoTextCardProps) {
  const { t } = useTranslation();
  const currentLinkId = useSelector((store: Root) => store.user.id);
  const productKind = productURLsMap[checkPathname(window.location.pathname) ?? PRODUCT_KIND.All];
  const cleanedText = t(text).replace(/<a\b[^>]*>(.*?)<\/a>/gi, '$1');

  const formattedUrl =
    productKind === 'https://pool.cryptobrowser.site/'
      ? `${productKind}landing/?aid=${currentLinkId}`
      : `${productKind}${currentLinkId}`;

  const formatTextForCopy = useCallback(() => {
    const finalText = replaceContent ? replaceContent(cleanedText) : cleanedText;
    return `${finalText} ${formattedUrl}`;
  }, [cleanedText, productKind, currentLinkId, replaceContent]);

  const handleCopy = useCallback(
    (e: React.ClipboardEvent<HTMLDivElement>) => {
      e.preventDefault();
      const formattedText = formatTextForCopy();
      e.clipboardData.setData('text/plain', formattedText);
    },
    [formatTextForCopy]
  );

  return (
    <TextCard
      text={text}
      currentLinkId={currentLinkId}
      productKind={productKind}
      handleCopy={handleCopy}
      url={formattedUrl}
      textForCopy={formatTextForCopy()}
      replaceContent={replaceContent}
    />
  );
}
