/* eslint-disable @typescript-eslint/no-explicit-any */
// Check if a value is a plain object
const isObject = (x: unknown): x is Record<string, any> => Object.prototype.toString.call(x) === '[object Object]';

export const getKeys = (obj: Record<string, any>, prefix = ''): string[] => {
  const keys = Object.keys(obj);
  const newPrefix = prefix ? prefix + '/' : '/';
  let result: string[] = [];

  for (const key of keys) {
    if (isObject(obj[key])) {
      result = result.concat(getKeys(obj[key], newPrefix + key));
    } else {
      result.push(newPrefix);
    }
  }
  // Remove "sections" from the generated paths
  return result.map((it) =>
    it
      .split('/')
      .filter((part) => part !== 'sections')
      .join('/')
  );
};

export function findByPath(obj: Record<string, any>, path: string): any {
  const paths = path.split('/').filter((it) => it);
  return traverse(obj, paths, 0);
}

export function traverse(list: Record<string, any>, paths: string[], level: number): any {
  if (!paths.length) return list;

  // Ensure the list has a "sections" property and it is an object
  const sections = list.sections;
  if (!sections || typeof sections !== 'object') {
    return undefined;
  }
  // Find the matching entry by key in the sections object
  const nodeEntry = Object.entries(sections).find(([k]) => k === paths[level]);
  if (!nodeEntry) return undefined;

  const node = nodeEntry[1];
  // If we're at the end of the path or there's no further "sections", return the node
  // @ts-expect-error TODO: fix this
  if (level === paths.length - 1 || !('sections' in node)) {
    return node;
  } else {
    return traverse(node, paths, level + 1);
  }
}
