import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Arrow from '@assets/colorless/arrow-right-sm.svg?react';

import { Card } from '@shared/Card/Card';
import { Picture } from '@shared/Picture/Picture';

import { Button, ButtonVariant } from '../Button/Button';
import { Pallet } from '../Pallet/Pallet';

import styles from './AffiliatePromoAccess.module.scss';

export type AffiliatePromoAccessProps = {
  promoUrl: string;
  src: string;
  src2x?: string;
  srcMob?: string;
  srcMob2x?: string;
  withDivider?: boolean;
};

export const AffiliatePromoAccess: FC<AffiliatePromoAccessProps> = (props) => {
  const { promoUrl, src, src2x, srcMob, srcMob2x, withDivider } = props;
  const { t } = useTranslation();

  return (
    <Card className={styles.card}>
      <div className={`${styles.affiliatePromoAccess} ${withDivider && styles.affiliatePromoAccess__border}`}>
        <Pallet />
        <h2 className={styles.affiliatePromoAccess__title}>
          {t('ct-affiliate_about.Group_9023284.get_promo.Get_access_to_high-q')}
        </h2>
        <p className={styles.affiliatePromoAccess__description}>
          {t('ct-affiliate_about.Group_9023284.get_promo.Easily_start_your_pr')}
        </p>
        <div className={styles.affiliatePromoAccess__btn_group}>
          <Button
            type={'link'}
            to={promoUrl}
            className={styles.affiliatePromoAccess__btn}
            variant={ButtonVariant.Primary}
            iconPosition="right"
            Icon={<Arrow color="#fff" />}
          >
            {t('View promo materials')}
          </Button>
        </div>
        <div className={styles.affiliatePromoAccess__image}>
          <Picture
            imageDesktop={src}
            imageDesktop2x={src2x}
            imageMob={srcMob}
            imageMob2x={srcMob2x}
            breakPoint={'600px'}
          ></Picture>
        </div>
      </div>
    </Card>
  );
};
