import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { BROWSER_BALANCE } from '@utils/links';

import { AllSubheader } from '@pages/headersComponents/AllSubheader';

import { useBreakpoints } from '@hooks/useBreakpoints';

import getNetworkAlert from '@service/actions/getNetworkAlert';
import { getNextWithdrawals, getWithdrawals } from '@service/actions/getWithdrawals';
import { Root } from '@service/reducers';
import { selectWithdrawals } from '@service/selectors/selectors';

import { showToast, ToastKind } from '../../components/Toast/Toast';
import { HelmetHead } from '../headersComponents/HelmetHead';
import { BrowserBalanceView } from './view/BrowserBalanceView';

export const BrowserBalance = () => {
  const withdrawals = useSelector((state: Root) => selectWithdrawals(state));
  const { isNextWithdrawalsLoading, withdrawalsToShow, hasRequested, limit, perPage, index, mob_withdrawals } =
    withdrawals;
  const { isBitcoin } = useSelector((state: Root) => state.balance);
  const dispatch = useDispatch();
  const { networkAlert } = useSelector((store: Root) => store);
  const { isMobile } = useBreakpoints();
  const match = useRouteMatch<{ BTCOrHSH: string }>();
  const history = useHistory();
  const { t } = useTranslation();

  const locationState = history.location.state as { currentPage?: number };
  const totalPage = useMemo(() => Math.ceil(limit / perPage), [perPage, limit]);

  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(true);
  const [currentPage, setCurrentPage] = useState(locationState?.currentPage || 1);
  const showModal = !/networkBtcAlert=hide/gi.test(document.cookie) && modal && networkAlert.overload;

  const handleCloseModal = useCallback(() => {
    document.cookie = 'networkBtcAlert=hide; max-age=43200;';
    setModal(false);
  }, []);

  useEffect(() => {
    if (match.params.BTCOrHSH != undefined) {
      dispatch({ type: 'CHANGE_IS_BITCOIN', isBitcoin: match.params.BTCOrHSH === 'BTC' });
      dispatch(getNextWithdrawals(currentPage));
    }

    // getWithdrawals(true)

    if (currentPage === 1) {
      dispatch(getWithdrawals(true));
    }
  }, [currentPage]);

  useEffect(() => {
    if (currentPage >= totalPage && totalPage > 0) {
      setCurrentPage(totalPage);
    }
  }, [currentPage, totalPage]);

  useEffect(() => {
    dispatch(getNetworkAlert());
  }, [dispatch]);

  useEffect(() => {
    if (withdrawals.isAddedWithdrawalHSH) {
      showToast(t('HSH.Withdr_success.Toast'), ToastKind.Success);
    }
  }, []);

  const setIsBitcoin = useCallback(
    (value: boolean) => {
      dispatch({ type: 'CHANGE_IS_BITCOIN', isBitcoin: value });
      history.push(`${BROWSER_BALANCE}/${value ? 'BTC' : 'HSH'}`);
      dispatch(getWithdrawals(true));

      const filteredMobWithdrawals = mob_withdrawals.filter((transaction) =>
        value ? transaction.type === 'regular' : transaction.type !== 'regular'
      );

      dispatch({
        type: 'UPDATE_MOB_WITHDRAWALS',
        withdrawalsToShow: filteredMobWithdrawals,
        index: 0,
      });
    },
    [dispatch, history, mob_withdrawals]
  );

  const filteredHistory = useMemo(() => {
    if (isMobile) {
      return isBitcoin
        ? mob_withdrawals.filter((transaction) => transaction.type === 'regular')
        : mob_withdrawals.filter((transaction) => transaction.type === 'hsh');
    }
    return withdrawalsToShow;
  }, [isMobile, isBitcoin, mob_withdrawals, withdrawalsToShow]);

  const isEmpty = withdrawalsToShow.length === 0;

  const handlePageChange = useCallback(
    (idx: number) => {
      window.scrollTo(0, 0);
      setCurrentPage(idx);
      dispatch(getNextWithdrawals(idx));
    },
    [dispatch]
  );

  const loadMoreData = useCallback(async () => {
    if (currentPage < totalPage) {
      setIsLoading(true);
      const nextPage = currentPage + 1;

      try {
        await dispatch(getNextWithdrawals(nextPage, perPage, true));
        setCurrentPage(nextPage);
      } catch (error) {
        console.error('Error', error);
      }
      setIsLoading(false);
    }
  }, [currentPage, totalPage]);

  useEffect(() => {
    let isFetching = false;

    if (isMobile && limit > perPage) {
      const handleScroll = async () => {
        if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 200) {
          if (currentPage === totalPage || isFetching) {
            return;
          }

          isFetching = true;
          try {
            await loadMoreData();
          } catch (error) {
            console.error('Error while loading data:', error);
          }
          isFetching = false;
        }
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [isMobile, currentPage, loadMoreData, totalPage]);

  useEffect(() => {
    if (isMobile) {
      dispatch(getNextWithdrawals(1, perPage, true));
      setCurrentPage(1);
    }
  }, [isMobile, isBitcoin]);

  return (
    <div>
      <HelmetHead title={t('Payment History')} />
      <AllSubheader />
      <BrowserBalanceView
        items={filteredHistory}
        isNextWithdrawalsLoading={isNextWithdrawalsLoading}
        limit={limit}
        perPage={perPage}
        index={index}
        isBitcoin={isBitcoin}
        setIsBitcoin={setIsBitcoin}
        hasRequested={hasRequested}
        handlePageChange={handlePageChange}
        currentPage={currentPage}
        totalPage={totalPage}
        isLoading={isLoading}
        handleCloseModal={handleCloseModal}
        showModal={showModal}
        isEmpty={isEmpty}
      />
    </div>
  );
};
