import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { CtSmLogoSvg } from '@assets/index';

import { config } from '@pages/headersComponents/config';

import { BackButton } from '@shared/BackButton/BackButton';

import Subheader from '../../components/Subheader';

import styles from '../../components/Subheader/Subheader.module.scss';

export type TBrowserSubheaderProps = {
  isShowBackButton?: boolean;
};

export const BrowserSubheader: FC<TBrowserSubheaderProps> = ({ isShowBackButton }) => {
  const { t } = useTranslation();

  return (
    <Subheader Icon={<CtSmLogoSvg />}>
      {isShowBackButton && <BackButton />}

      {config.browser.map((b) => (
        <NavLink to={{ pathname: b.url }} key={b.url} activeClassName={styles.active}>
          {b.Icon}
          {t(b.text)}
        </NavLink>
      ))}
    </Subheader>
  );
};
