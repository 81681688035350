import { useFarmRespInfo } from '@hooks/useFarmRespInfo';

import { Span } from '@shared/Graph/Tabs';

import { paymentType } from '@backend/JsonRpcApi';
import { rpc } from '@backend/Rpc';

import { AppDispatch } from 'src/main.tsx';

import { GET_FARM_MINING_DATA } from '../constant';
type Resp = {
  timestamp: number;
};

export default function getFarmMiner(
  period: Span,
  userId: number,
  period_start: string,
  period_end: string,
  paymentType: paymentType = 3
) {
  return async function (dispatch: AppDispatch) {
    const respInfo = useFarmRespInfo(period);

    const resp = await rpc.transmit('farm.get_activity_statistics', {
      farm_ids: [userId],
      start_time: `${period_start}`,
      end_time: `${period_end}`,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      discreteness: `${respInfo.discreteness}` as any,
      payment_type_ids: [paymentType],
    });
    dispatch({
      type: GET_FARM_MINING_DATA,
      purchase: resp.items
        .map((item) => ({
          ...item,
          timestamp: item.timestamp * 1000,
        }))
        .sort((a: Resp, b: Resp) => a.timestamp - b.timestamp),
    });
  };
}
