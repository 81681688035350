import i18next from 'i18next';
import { useCallback, useEffect, useState } from 'react';

import { ContentPage, PageCategory } from '@backend/JsonRpcApi';
import { rpc } from '@backend/Rpc';

export const SHOW_PROMO_LOCAL_KEY = 'show_promo';
const TEN_MINS_IN_MS = 600000;

export const usePromoVariant = () => {
  const [promos, setPromos] = useState<{ banners: ContentPage[]; modals: ContentPage[] }>({ banners: [], modals: [] });
  const [notViewedPromos, setNotViewedPromos] = useState<{ banners: ContentPage[]; modals: ContentPage[] }>({
    banners: [],
    modals: [],
  });
  const fetchPromos = useCallback(async () => {
    try {
      const res = await rpc.transmit('content.category_pages', {
        categories: [PageCategory.Modals, PageCategory.Banners],
        language: i18next.language.split('-')[0],
        placing: ['affiliate-modal', 'affiliate-banner'],
      });

      if (res.items) {
        const banners = res.items.filter((item) => item.category === PageCategory.Banners);
        const modals = res.items.filter((item) => item.category === PageCategory.Modals);

        setPromos({ banners, modals });

        const recordedData = localStorage.getItem('viewedOffers');

        if (recordedData) {
          const currentIds = [...banners.map((b) => b.id), ...modals.map((m) => m.id)];
          const regex = /(?:viewedModal_|viewedBanner_)(\d+)/;
          let viewedOffers: { [key: string]: string } = {};
          try {
            viewedOffers = JSON.parse(recordedData) || {};
          } catch (e) {
            console.log('Ошибка при разборе JSON из localStorage:', e);
          }
          viewedOffers = Object.fromEntries(
            Object.entries(viewedOffers).filter(([key]) => {
              const match = key.match(regex);
              if (!match || !match[1]) {
                return false;
              }
              const id = parseInt(match[1], 10);
              return currentIds.includes(id);
            })
          );
          setNotViewedPromos({
            banners: banners.filter((b) => !viewedOffers[`viewedBanner_${b.id}`]),
            modals: modals.filter((m) => !viewedOffers[`viewedModal_${m.id}`]),
          });
          localStorage.setItem('viewedOffers', JSON.stringify(viewedOffers));
        }
      }
    } catch (e) {
      console.error('Error fetching promos:', e);
    }
  }, []);

  const checkLocalStorage = (name: string, throttle: number) => {
    const recordedData = localStorage.getItem('viewedOffers');
    if (!recordedData) return true;

    let viewedOffers;
    try {
      viewedOffers = JSON.parse(recordedData) || {};
    } catch (e) {
      console.log('Ошибка при разборе JSON из localStorage:', e);
    }
    const offer = viewedOffers[name];

    if (offer && offer.showTime && Date.now() - offer.showTime > throttle) {
      delete viewedOffers[name];
      localStorage.setItem('viewedOffers', JSON.stringify(viewedOffers));
      return true;
    }
    return !offer;
  };

  const filterPromos = (promos: ContentPage[]) =>
    promos
      .filter((p) =>
        checkLocalStorage(
          `${p.category === PageCategory.Banners ? 'viewedBanner_' : 'viewedModal_'}${p.id}`,
          p.options.throttle
        )
      )
      .sort((a, b) => b.options?.priority - a.options?.priority);

  // Подгружаем модалки с баннерами и устанавливаем повторный запрос раз в 10 минут
  useEffect(() => {
    fetchPromos();
    const intervalFetching = setInterval(() => fetchPromos(), TEN_MINS_IN_MS);
    return () => clearInterval(intervalFetching);
  }, [fetchPromos]);

  const { banners, modals } =
    notViewedPromos.modals.length > 0 || notViewedPromos.banners.length > 0 ? notViewedPromos : promos;

  const regainPromos = () => {
    return {
      banners: filterPromos(banners),
      modals: filterPromos(modals),
      regainPromos: regainPromos,
    };
  };

  return regainPromos();
};
