import { Currency } from '@backend/JsonRpcApi';

import {
  BALANCE_ERROR,
  BALANCE_REQUEST,
  BALANCE_SUCCESS,
  GET_AFF_BALANCE,
  GET_ALL_BALANCE,
  GET_NFT_BALANCE,
  GET_FARM_BALACNE,
  GET_POOL_BALANCE,
  GET_HSH_BALANCE,
  ADD_INCREASED_BALANCE,
  CHANGE_IS_BITCOIN,
  CHANGE_IS_SHOWING_HSH_INCOMING,
  GET_ALL_LINKS_INFO,
  GET_HSH_WELCOME_BONUS,
  GET_CURRENCIES,
} from '../constant';

const initialState = {
  aff_balance: {
    mined: 0,
    referral: 0,
    total: 0,
    balance: 0,
  },

  farm_balance: {
    mined: 0,
    referral: 0,
    total: 0,
    balance: 0,
  },

  pool_balance: {
    nft: 0,
    pool: 0,
    affiliate: 0,
    balance: 0,
  },

  all_balance: {
    mined: 0,
    referral: 0,
    total: 0,
    balance: 0,
    wihdraw_balance: 0,
  },

  nft_balance: {
    total: 0,
    balance: 0,
  },
  all_links_info: [],

  isBitcoin: true,

  currencies: [],

  hsh_balance: {
    current: 0,
    accrued: 0,
    income: 0,
    outgoing: 0,
    bonus: 0,
    nextPayment: {
      days: 0,
      hours: 0,
      mins: 0,
    },
  },

  increased_balance_hsh: {
    amount: 0,
    totalAmount: 0,
    message: '',
    isShowing: false,
  },

  links: [],
  linksMap: new Map<number, LinkAcc>(),
  balance_request: false,
  balance_error: false,
  get_balance: false,
};

export type rootBalanceType = Omit<typeof initialState, 'links' | 'all_links_info' | 'currencies' | 'linksMap'> & {
  links: LinkAcc[];
  all_links_info: LinkBalanceInfo[];
  currencies: Currency[];
  linksMap: ReadonlyMap<number, LinkAcc>;
};

export type LinkBalanceInfo = {
  ct?: LinkInfo;
  ct_pool?: LinkInfo;
  farm?: LinkInfo;
  pool_mobile?: LinkInfo;
  link_id: number;
};

export type LinkInfo = {
  earnings?: Earning;
  locked?: number;
  total?: number;
};

export type Earning = {
  mined?: number;
  ref?: number;
};

export type balanceType = {
  mined: number | string;
  referral: number | string;
  total: number | string;
  balance: number | string;
  nft?: number | string;
};

export type nftBalanceType = {
  total: number | string;
  balance: number | string;
};

export type LinkAcc = {
  id: number;
  name: string | null;
  comment: string | null;
  is_banned: boolean;
  type: string | null;
  url: string;
};

export type nextPurchaseType = {
  days: number;
  hours: number;
  mins: number;
} | null;

export type hshBalanceType = {
  current: number | string;
  total: number | string;
  group: string;
  accrued: number | string;
  income: number | string;
  outgoing: number | string;
  bonus?: number | string;
  nextPurchase: nextPurchaseType;
};

export type increasedBalanceHshType = {
  amount: number | string;
  totalAmount: number | string;
  message: string | null;
  isShowing: boolean;
};

type ActionType = {
  type: string;
  amount: number | string;
  balance?: balanceType;
  currencies?: Currency[];
  nftBalance?: Omit<balanceType, 'mined' | 'referral'>;
  isBitcoin?: boolean;
  hshBalance?: hshBalanceType;
  increasedBalanceHSH?: increasedBalanceHshType;
  links?: LinkAcc[];
  allLinksInfo?: LinkBalanceInfo[];
  linksMap?: ReadonlyMap<number, LinkAcc>;
};

export const balanceReducer = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case BALANCE_REQUEST: {
      return {
        ...state,
        get_balance: false,
        balance_request: true,
        balance_error: false,
      };
    }
    case BALANCE_ERROR: {
      return {
        ...state,
        balance_request: false,
        balance_error: true,
        get_balance: false,
      };
    }
    case BALANCE_SUCCESS: {
      return {
        ...state,
        balance_request: false,
        balance_error: false,
        get_balance: true,
        links: action.links,
      };
    }

    case GET_ALL_LINKS_INFO: {
      return {
        ...state,
        all_links_info: action.allLinksInfo,
        linksMap: action.linksMap,
      };
    }

    case GET_ALL_BALANCE: {
      return {
        ...state,
        all_balance: action.balance,
      };
    }

    case GET_AFF_BALANCE: {
      return {
        ...state,
        aff_balance: action.balance,
      };
    }

    case GET_FARM_BALACNE: {
      return {
        ...state,
        farm_balance: action.balance,
      };
    }

    case GET_NFT_BALANCE: {
      return {
        ...state,
        nft_balance: action.nftBalance,
      };
    }

    case GET_POOL_BALANCE: {
      return {
        ...state,
        pool_balance: action.balance,
      };
    }

    case GET_CURRENCIES: {
      return {
        ...state,
        currencies: action.currencies,
      };
    }

    case CHANGE_IS_BITCOIN: {
      return {
        ...state,
        isBitcoin: action.isBitcoin,
      };
    }

    case GET_HSH_BALANCE: {
      return {
        ...state,
        hsh_balance: action.hshBalance,
      };
    }

    case ADD_INCREASED_BALANCE: {
      return {
        ...state,
        increased_balance_hsh: {
          ...action.increasedBalanceHSH,
          totalAmount: +state.increased_balance_hsh.totalAmount + +(action.increasedBalanceHSH?.amount || 0),
          isShowing: true,
        },
      };
    }

    case CHANGE_IS_SHOWING_HSH_INCOMING: {
      return {
        ...state,
        increased_balance_hsh: {
          ...state.increased_balance_hsh,
          isShowing: false,
        },
      };
    }

    case GET_HSH_WELCOME_BONUS: {
      return {
        ...state,
        hsh_balance: {
          ...state.hsh_balance,
          bonus: action.amount,
        },
      };
    }

    default:
      return state;
  }
};
