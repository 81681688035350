import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { BtcSvg, LightCircleOrangeSvg, LinkCircleOrangeSvg } from '@assets/colored';

import checkE from '@utils/checkE';
import { FARM_STATISTIC } from '@utils/links';
import useIntlFormatter from '@utils/useIntlFormatter';

import { Root } from '@service/reducers';
import { PromoType } from '@service/reducers/promoReducer';

import { MinedCard } from '@shared/MinedView/MinedCard';

import { FarmPromoCodesTabs, FarmPromoTabs } from './FarmPromoCodesTabs';
import { PromoCard } from '../PromoCard/PromoCard';

import styles from './FarmPromoCodes.module.scss';

export type FarmPromoCodesProps = {
  promos: PromoType[];
  dash?: boolean;
  setAdditionalTab?: (v: FarmPromoTabs) => void;
};

export function FarmPromoCodes({ promos, dash, setAdditionalTab }: FarmPromoCodesProps) {
  const [tab, setTab] = useState(FarmPromoTabs.Usually);
  const { t } = useTranslation();
  const { intFormatter } = useIntlFormatter();
  const set = useCallback(
    (tab: FarmPromoTabs) => {
      setAdditionalTab?.(tab);
      setTab(tab);
    },
    [setAdditionalTab]
  );
  const rate = useSelector((store: Root) => store.rate.btc2usd);
  const sold = promos[0].sold_count;
  const allTimeIncome = promos[0].alltime_income;

  const active = promos[0].active_count;
  return (
    <>
      <div className={styles.stats}>
        <MinedCard
          Icon={<LinkCircleOrangeSvg />}
          label={t('Purchases')}
          number={sold}
          href={`${FARM_STATISTIC}#activations`}
          className={styles.row}
          external
        />
        <MinedCard
          Icon={<LightCircleOrangeSvg />}
          label={t('Active Miners')}
          number={active}
          href={`${FARM_STATISTIC}#miner`}
          className={styles.row}
          external
        />
        <MinedCard
          Icon={<BtcSvg />}
          label={t('Earnings')}
          subtitle={`${t('Approx.')} <b>$${intFormatter((allTimeIncome * rate).toFixed(4))} USD</b>`}
          number={intFormatter(checkE(+allTimeIncome)) + ' <i>BTC</i>'}
          className={styles.total}
          href={`${FARM_STATISTIC}#earnings`}
          external
        />
      </div>
      <FarmPromoCodesTabs active={tab} set={set} />
      <div className={styles.promos}>
        {promos.map((_) => (
          <PromoCard
            dash={dash}
            key={_.code}
            type={tab === FarmPromoTabs.Pro ? 'farmPro' : 'farm'}
            withSharedTabs
            link={farmLinks[tab]}
            code={_.code}
          />
        ))}
      </div>
    </>
  );
}

export const farmLinks = {
  [FarmPromoTabs.Usually]: 'https://cryptotab.farm/',
  [FarmPromoTabs.Pro]: 'https://cryptotabfarm.com/',
};
