import { useTranslation } from 'react-i18next';

import { product } from '@utils/productName';

import { FarmSubheader } from '@pages/headersComponents/FarmSubheader';

import { HelmetHead } from '../headersComponents/HelmetHead';
import FarmStatisticsView from './view/FarmStatisticsView';

export function FarmStatistics() {
  const { t } = useTranslation();

  return (
    <>
      <HelmetHead title={t('Statistics')} product={product.farm} />
      <FarmSubheader />
      <FarmStatisticsView />
    </>
  );
}
