import { useTranslation } from 'react-i18next';

import { ErrorClockSvg } from '@assets/colored';

import styles from './WithdrawalSuspendedView.module.scss';

export const WithdrawalSuspended = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <ErrorClockSvg className={styles.wrapper__icon} />
      <h3 className={styles.wrapper__title}>{t('Withdrawals Suspended')}</h3>
      <p className={styles.wrapper__description}>
        {t(
          'Your withdrawal function has been suspended as you’ve bent the terms of use. In case, it is a mistake, please, contact our support team.'
        )}
      </p>
    </div>
  );
};
