import cls from 'classnames';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { PaintSvg, CheckSvg, RandomSvg } from '@assets/colorless';

import { ColorMap, Colors, getRandomColor } from '@utils/colors';

import { SET_COLOR, SET_RANDOM } from '@service/constant';
import { Root } from '@service/reducers';

import Switch from '@shared/Switch/Switch';

import Toggle from './Toggle';

import styles from './Background.module.scss';

export default function Background() {
  const { backgroundColor, isRandomBg } = useSelector((root: Root) => root.system);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const setColor = useCallback(
    (color: Colors) => {
      dispatch({ type: SET_RANDOM, value: false });
      dispatch({ type: SET_COLOR, value: color });
      document.body.style.cssText = `--background-color: ${ColorMap[color]};`;
    },
    [dispatch]
  );
  const toggleRandom = useCallback(() => {
    dispatch({
      type: SET_COLOR,
      value: isRandomBg ? undefined : getRandomColor(),
    });
    dispatch({ type: SET_RANDOM, value: !isRandomBg });
  }, [isRandomBg, dispatch]);
  return (
    <Toggle Icon={<PaintSvg />} title={t('Background.Title')}>
      <div className={styles.container}>
        <div className={styles.colors}>
          <p className={styles.colors__text}>{t('Background.Description')}</p>
          <div className={styles.colors__kinds}>
            {Object.entries(ColorMap).map(([color, value]) => (
              <div
                style={{ background: value }}
                key={color}
                onClick={() => setColor(color as Colors)}
                className={cls([styles.item, backgroundColor === color && !isRandomBg && styles.item__active])}
              >
                <div className={styles.active}>
                  <CheckSvg />
                </div>
              </div>
            ))}
          </div>
          <div className={styles.switch}>
            <div className={styles.switch__text}>
              <RandomSvg />
              <span>{t('Background.Random')}</span>
            </div>
            <Switch isActive={isRandomBg} onToggle={toggleRandom} />
          </div>
        </div>
      </div>
    </Toggle>
  );
}
