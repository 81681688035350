import React, { useCallback, useState } from 'react';

import TransferIcon from '@assets/colorless/transfer.svg?react';

import { BROWSER_ABOUT } from '@utils/links';

import { Banner, Button, ButtonGroup, ButtonVariant, Container } from '@shared/index';

import styles from './Components.module.scss';

enum Period {
  Day,
  Week,
  Month,
}

export const Components = () => {
  const [selectedPeriod, setSelectedPeriod] = useState(Period.Day);
  const handleSelect = useCallback(
    (period: Period) => {
      setSelectedPeriod(period);
    },
    [setSelectedPeriod]
  );

  return (
    <Container className={styles.container}>
      <section className={styles.section}>
        <h1>Buttons:</h1>
        <div className={styles.row}>
          <Button type={'button'}>Default Button</Button>
          <Button type={'button'} variant={ButtonVariant.Primary}>
            Primary Button
          </Button>
          <Button type={'link'} variant={ButtonVariant.Primary} to={BROWSER_ABOUT}>
            Primary Button as Link
          </Button>
          <Button type={'button'} variant={ButtonVariant.Primary} size={'small'}>
            Primary Button small
          </Button>
          <div className={styles.row}>
            <Button type={'button'} variant={ButtonVariant.Text}>
              Text Button
            </Button>
            <Button type={'button'} variant={ButtonVariant.Text} size={'small'}>
              Text Button small
            </Button>
            <Button type={'link'} to={BROWSER_ABOUT} variant={ButtonVariant.Text}>
              Text Button as Link
            </Button>
            <Button type={'button'} variant={ButtonVariant.Text} Icon={<TransferIcon />}>
              Text Button with Icon
            </Button>
          </div>
        </div>
      </section>

      <section className={styles.section}>
        <h1>Button Group:</h1>
        <ButtonGroup>
          <Button
            type={'button'}
            size={'small'}
            variant={ButtonVariant.Default}
            isActive={selectedPeriod === Period.Day}
            onClick={() => handleSelect(Period.Day)}
          >
            Button 1
          </Button>
          <Button
            type={'button'}
            size={'small'}
            variant={ButtonVariant.Default}
            isActive={selectedPeriod === Period.Week}
            onClick={() => handleSelect(Period.Week)}
          >
            Button 2
          </Button>
          <Button
            type={'button'}
            size={'small'}
            variant={ButtonVariant.Default}
            isActive={selectedPeriod === Period.Month}
            onClick={() => handleSelect(Period.Month)}
          >
            Button 3
          </Button>
        </ButtonGroup>
      </section>

      <section className={styles.section}>
        <Banner
          Media={<BannerMedia />}
          title={'Use New Token — Earn Maximum BTC!'}
          description={
            'Get additional +100,000 H/s to the hashrate for each CryptoTab NFT Mining #02 and at the mining period finale exchange it for more valuable NFTs!'
          }
          actionTitle={'Learn More'}
          onAction={() => {}}
        />
      </section>

      <section className={styles.section}>
        <h1>Sentry:</h1>
        <button
          onClick={() => {
            // @ts-expect-error calling function that doesn't exist
            iDontExistAgain();
          }}
        >
          Break the world with scam function
        </button>
        <button
          onClick={() => {
            return new Promise((resolve, reject) => reject('No reason'));
          }}
        >
          Break the world with bad promise
        </button>
      </section>
    </Container>
  );
};

const BannerMedia = () => {
  return (
    <picture>
      <source
        srcSet="https://cdn.cryptobrowser.store/static/affiliate/images/bot-banner-img-mob.svg"
        media="(max-width: 768px)"
      />
      <source srcSet="https://cdn.cryptobrowser.store/static/affiliate/images/bot-banner-img.png, https://cdn.cryptobrowser.store/static/affiliate/images/bot-banner-img%402x.png 2x" />
      <img
        src="https://cdn.cryptobrowser.store/static/affiliate/images/bot-banner-img.png"
        srcSet="https://cdn.cryptobrowser.store/static/affiliate/images/bot-banner-img%402x.png 2x"
        alt="loaded"
      />
    </picture>
  );
};
