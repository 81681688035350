import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { product } from '@utils/productName';

import { BrowserSubheader } from '@pages/headersComponents/BrowserSubheader';

import getBalances from '@service/actions/getBalances';
import { getBrowserStatsInstalls, getBrowserStatsOpens } from '@service/actions/getBrowserStatsLinks';
import getBrowserStatsPayments from '@service/actions/getBrowserStatsPayments';
import { Root } from '@service/reducers';
import { rootBalanceType } from '@service/reducers/balanceReducer';

import BrowserStatisticsView from './view/BrowserStatisticsView';
import { HelmetHead } from '../headersComponents/HelmetHead';

export function BrowserStatistics() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const balance = useSelector((store: { balance: rootBalanceType }) => store.balance);
  const installsDiscreteness = useSelector((store: Root) => store.browserGraph.installsDiscreteness);
  const minersDiscreteness = useSelector((store: Root) => store.browserGraph.minersDiscreteness);
  const opensDiscreteness = useSelector((store: Root) => store.browserGraph.opensDiscreteness);
  const earningsDiscreteness = useSelector((store: Root) => store.browserGraph.earningsDiscreteness);
  const { period_start, period_end } = useSelector((store: Root) => store.browserGraph);
  const isFirstLoad = useRef(true);

  useEffect(() => {
    if (!balance.get_balance) dispatch(getBalances());
  }, [dispatch, balance.get_balance]);

  useEffect(() => {
    dispatch(getBrowserStatsInstalls(period_start, period_end, installsDiscreteness));
  }, [dispatch, period_start, period_end, installsDiscreteness]);

  useEffect(() => {
    dispatch(getBrowserStatsOpens(period_start, period_end, opensDiscreteness));
  }, [dispatch, period_start, period_end, opensDiscreteness]);
  // получение статистики по выплатам  и майнерам при первой загрузке
  useEffect(() => {
    if (!isFirstLoad.current) {
      dispatch(
        getBrowserStatsPayments(period_start, period_end, minersDiscreteness, earningsDiscreteness, false, false)
      );
    }
  }, [dispatch, earningsDiscreteness]);
  // получение статистики   майнерам при изменении  discreteness
  useEffect(() => {
    if (!isFirstLoad.current) {
      dispatch(
        getBrowserStatsPayments(period_start, period_end, minersDiscreteness, earningsDiscreteness, true, false)
      );
    }
  }, [dispatch, minersDiscreteness]);
  // получение статистики   выплатам при изменении  discreteness
  useEffect(() => {
    if (isFirstLoad.current) {
      isFirstLoad.current = false;
    }
    dispatch(getBrowserStatsPayments(period_start, period_end, minersDiscreteness, earningsDiscreteness, false, true));
  }, [dispatch, period_start, period_end]);

  return (
    <>
      <HelmetHead title={t('Statistic')} product={product.ct} />
      <BrowserSubheader />
      <BrowserStatisticsView />
    </>
  );
}
