import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { LinkSvg } from '@assets/colorless';

import { jsColors } from '@utils/jsColors';

import { Root } from '@service/reducers';
import { LinkAcc } from '@service/reducers/balanceReducer';

import CardSwitcher from '@shared/CardSwitcher/CardSwitcher';

import styles from './IdList.module.scss';

export type IdListProps = {
  active: string;
  setActive: (val: string) => void;
  listClass?: string;
  containerClass?: string;
  id?: string | false;
};

export default function IdList({ active, setActive, listClass, containerClass, id }: IdListProps) {
  const { t } = useTranslation();

  const links = useSelector((store: Root) => store.balance.links);
  const ids = useMemo(
    () =>
      Array.from(
        new Set(
          links
            .filter((link: LinkAcc) => link.type === '' || link.type === null)
            .map((item: LinkAcc) => ({ id: item.id, name: item.name }))
        )
      ),
    [links]
  );
  if (id) {
    return <></>;
  }
  return (
    <CardSwitcher
      containerClass={containerClass}
      Icon={<LinkSvg color={jsColors.blue} />}
      activeElement={t(active)}
      listClass={listClass}
      List={
        <>
          <div className={styles.item__all} onClick={() => setActive('All Link IDs')}>
            {t('All Link IDs')}
          </div>
          {ids.map(
            (item) =>
              item?.id !== null && (
                <div className={styles.item} key={item?.id} onClick={() => setActive(item?.id + '')}>
                  <span>
                    <b>{item?.id}</b>
                  </span>
                  <span>{item?.name}</span>
                </div>
              )
          )}
        </>
      }
    />
  );
}
