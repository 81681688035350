import { useTranslation } from 'react-i18next';

import { HelmetHead } from '@pages/headersComponents/HelmetHead';

import WithdrawalNcwWalletView from './view/WithdrawalNcwWalletView';
import Subheader from '../../components/Subheader';

export default function WithdrawalNcwWallet() {
  const { t } = useTranslation();

  return (
    <>
      <HelmetHead title={t('Connect NC Wallet')} />
      <Subheader />
      <WithdrawalNcwWalletView />
    </>
  );
}
