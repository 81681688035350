import { JsonRpcException } from './JsonRpcException';

export type InterceptorErrorListener = (error: JsonRpcException, callId: string) => void;

export class JsonRpcInterceptor {
  private _interceptorListeners: InterceptorErrorListener[] = [];

  public readonly interceptors = {
    error: {
      use: (fn: InterceptorErrorListener) => {
        this._interceptorListeners.push(fn);

        // Return a function that removes the listener
        return () => {
          this._interceptorListeners = this._interceptorListeners.filter((l) => l !== fn);
        };
      },
    },
  };

  protected _notifyErrorInterceptors(error: JsonRpcException, callId: string) {
    this._interceptorListeners.forEach((listener) => listener(error, callId));
  }
}
