import React, { FC } from 'react';

import { PromoType } from '@service/reducers/promoReducer';

import { Card, Container, FarmAboutCode } from '@shared/index';

import FarmCodeResult from './components/FarmCodeResult';

type FarmGenerateCodeViewProps = {
  promos: PromoType[];
  onGenerateCodeClick: () => void;
};

export const FarmGenerateCodeView: FC<FarmGenerateCodeViewProps> = (props) => {
  const { onGenerateCodeClick, promos } = props;
  return (
    <>
      <Container>
        <Card style={{ marginTop: '20px' }}>
          {' '}
          {promos.length > 0 ? (
            <FarmCodeResult promos={promos} />
          ) : (
            <FarmAboutCode onCodeGeneratePress={onGenerateCodeClick} />
          )}
        </Card>
      </Container>
    </>
  );
};
