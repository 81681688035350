interface DisputeResponse {
  project: string;
  project_user_id: string;
  status: RequestDisputStatus;
  contact_email: string;
  jira_issue_id: string;
  jira_issue_created_at: number;
  initial_form_uuid: string;
  initial_form_sent_at: number;
  proofs_sent_at: number;
}
export enum RequestDisputStatus {
  issue_created = 'issue_created',
  initial_form_sent = 'initial_form_sent',
  proofs_sent = 'proofs_sent',
  cancelled = 'cancelled',
  rejected = 'rejected',
  approved = 'approved',
}

export const fetchDisputeStatus = async (setLoading: (arg0: boolean) => void): Promise<DisputeResponse | null> => {
  setLoading(true);

  try {
    const response = await fetch(`${window.app_config.baseAPIURL}suspend/dispute`, {
      credentials: 'include',
    });
    if (response.status === 404) {
      return null;
    }
    if (!response.ok) {
      throw new Error(`HTTP Error: ${response.status}`);
    }

    let res = await response.json();

    if (res?.code === 'dispute_does_not_exist') {
      res = {};
    }

    return res;
  } catch (error) {
    console.error('Error fetching dispute status:', error);
    throw error;
  } finally {
    setLoading(false);
  }
};

export const createDispute = async (
  setLoading: (arg0: boolean) => void,
  data: { contact_email: string; situation_description: string }
): Promise<void> => {
  setLoading(true);

  try {
    const response = await fetch(window.app_config.baseAPIURL + `suspend/dispute`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      const errorBody = await response.json();
      const errorMessage = errorBody.code || 'Failed to submit answers';
      throw new Error(errorMessage);
    }
  } finally {
    setLoading(false);
  }
};
