import { SocialAuth } from '@backend/JsonRpcApi';

import {
  USER_ERROR,
  USER_REQUEST,
  USER_SOCIAL_ERROR,
  USER_SOCIAL_REQUEST,
  USER_SOCIAL_SUCCESS,
  USER_SUCCESS,
  USER_TOKENS_SUCCESS,
  USER_UPDATE,
} from '../constant';

type Tokens = { accessToken: string; refreshToken: string };
const initialState = {
  id: 0,
  email: '',
  request: false,
  hasRequested: false,
  error: false,
  social: [],
  social_request: false,
  social_error: false,
  tags: [],
  email_verified: false,
  min_withdrawal_amount: '0.00001',
  ncw_min_withdrawal_amount: '0.00001',
  tokens: null,
  is_banned: false,
};

type ActionType = {
  type: string;
  id?: number;
  email?: string;
  social?: UserSocial[];
  tags?: string[];
  minWithdraw?: string;
  ncwMinWithdraw?: string;
  verified?: boolean;
  tokens?: Tokens;
  is_banned: boolean;
};

export type rootUser = Omit<typeof initialState, 'social' | 'tags' | 'tokens'> & {
  social: UserSocial[];
  tags: string[];
  tokens: null | Tokens;
};

export const USER_SUSPEND_TAG = 'withdraw_suspend';
export const USER_ACCESS_TAG = 'withdraw_access';

export type UserSocial = {
  id: number;
  created: string;
  extra_data: string;
  modified: string;
  provider: SocialAuth['provider'];
  uid: string;
  user_id: string;
  tags: string[];
  is_ncw: boolean;
};

export default function userReducer(state = initialState, action: ActionType) {
  switch (action.type) {
    case USER_REQUEST: {
      return {
        ...state,
        request: true,
        error: false,
      };
    }
    case USER_ERROR: {
      return {
        ...state,
        error: true,
        request: false,
      };
    }

    case USER_SUCCESS: {
      return {
        ...state,
        error: false,
        request: false,
        hasRequested: true,
        id: action.id,
        email: action.email,
        tags: action.tags,
        min_withdrawal_amount: action.minWithdraw || state.min_withdrawal_amount,
        ncw_min_withdrawal_amount: action.ncwMinWithdraw || state.ncw_min_withdrawal_amount,
        email_verified: action.verified,
        is_banned: action.is_banned,
      };
    }
    case USER_UPDATE: {
      return {
        ...state,
        ...action,
      };
    }
    case USER_SOCIAL_REQUEST: {
      return {
        ...state,
        social_request: true,
        social_error: false,
      };
    }
    case USER_SOCIAL_ERROR: {
      return {
        ...state,
        social_request: false,
        social_error: true,
      };
    }
    case USER_SOCIAL_SUCCESS: {
      return {
        ...state,
        social_request: false,
        social_error: false,
        social: action.social,
      };
    }
    case USER_TOKENS_SUCCESS: {
      return {
        ...state,
        tokens: action.tokens,
      };
    }
    default:
      return state;
  }
}
