import cn from 'classnames';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ArrowRightSvg } from '@assets/colored';
import { DownloadIcon, EyeSvg, ArrowLeftSmSvg } from '@assets/colorless';

import { useBannerJS } from '@hooks/useBannerJS';
import { useBannerHtml } from '@hooks/useBannersHtml';

import { BannerType } from '@service/reducers/bannerReducer';

import { Button, ButtonVariant } from '@shared/index';
import { Modal } from '@shared/Modal';
import CopyButton from '@shared/PromoCard/CopyButton';
import { Choice, Select } from '@shared/Select/Select';
import TextArea from '@shared/TextArea/TextArea';

import { renderIdChoise } from '../GenerateForm';

import styles from './GenerateBannerModal.module.scss';

interface GenerateBannerModalProps {
  onClose: () => void;
  banner: BannerType;
  list: Choice[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  lands: any[];
  orientation: 'horizontal' | 'vertical';
  onNext: () => void;
  onPrevious: () => void;
  isNextDisabled: boolean;
  isPreviousDisabled: boolean;
}

export default function GenerateBannerModal(props: GenerateBannerModalProps) {
  const { list, banner, lands, orientation, isPreviousDisabled, isNextDisabled, onNext, onPrevious } = props;
  const { t } = useTranslation();
  const [selectedCurrentId, setSelectedId] = useState<Choice>(list[0]);
  const handleChangeId = useCallback((val: Choice) => setSelectedId(val), []);
  const handleChangeLand = useCallback((val: Choice) => setLand(val), []);
  const [land, setLand] = useState(lands[0]);
  const src = banner.images?.[''] || banner.default_images?.[''];
  const srcSet = (banner.images?.['2x'] || banner.default_images?.['2x']) + ' 2x';
  const link = useMemo(() => land.url + selectedCurrentId.value, [land, selectedCurrentId]);
  const html = useBannerHtml(link, src, srcSet);
  const js = useBannerJS(link, src, srcSet, banner.width, banner.height);

  return (
    <Modal.Layout isOpen onClose={props.onClose}>
      <Modal.Content>
        <Modal.Body containerClassName={styles.modalBody}>
          <div className={styles.container}>
            <div className={styles.header}>
              <div className={styles.item__label}>{t('previewTitle')}</div>
              <div className={styles.id}>
                <div className={styles.id__text}>{props.banner.id} </div>
                <CopyButton noBackground copyText={`${props.banner.id}`} className={styles.copy} />
              </div>
            </div>
            <div className={styles.body}>
              <p>{t('previewSubtitle')}</p>
              <div className={styles.selects}>
                {lands.length > 1 && (
                  <Select
                    listClassName={styles.choices}
                    name={'land'}
                    current={land}
                    choices={lands}
                    onChange={handleChangeLand}
                    renderChoise={renderIdChoise}
                    label={t('Landing ID')}
                  />
                )}
                {list.length > 0 && (
                  <Select
                    listClassName={styles.choices}
                    name={'list'}
                    current={selectedCurrentId}
                    choices={list}
                    onChange={handleChangeId}
                    renderChoise={renderIdChoise}
                    label={t('Link ID')}
                  />
                )}
              </div>
              <div className={cn([styles.image, { [styles.vertical]: orientation === 'vertical' }])}>
                <a
                  className={cn([styles.img])}
                  href={banner.images?.['2x'] || banner.default_images?.['2x'] || src}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={src} srcSet={srcSet || src} alt="img" />
                  <div className={styles.before}>
                    <EyeSvg /> {t('showFullSize')}
                  </div>
                </a>
              </div>
              <div className={styles.inputs}>
                <TextArea withCopy value={html} className={styles.textarea} readOnly label={t('HTML-CODE')} />
                <TextArea withCopy value={js} className={styles.textarea} readOnly label={t('JAVASCRIPT-CODE')} />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className={styles.footer}>
            <Button
              type="button"
              Icon={<ArrowLeftSmSvg />}
              size="medium"
              variant={ButtonVariant.Transparent}
              disabled={isPreviousDisabled}
              className={styles.button}
              onClick={onPrevious}
            />
            <Button
              type={'link'}
              variant={ButtonVariant.Primary}
              download
              target="_blank"
              rel="noreferrer"
              to={banner.images?.[''] || banner.default_images?.['']}
              external
              Icon={<DownloadIcon className={styles.icon} />}
            >
              {t('Download image')}
            </Button>
            <Button
              type="button"
              Icon={<ArrowRightSvg />}
              size="medium"
              variant={ButtonVariant.Transparent}
              disabled={isNextDisabled}
              className={styles.button}
              onClick={onNext}
            />
          </div>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Layout>
  );
}
