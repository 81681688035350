import { rpc } from '@backend/Rpc';

import { AppDispatch } from 'src/main.tsx';

import { USER_SOCIAL_ERROR, USER_SOCIAL_REQUEST, USER_SOCIAL_SUCCESS } from '../constant';

export default function getSocial() {
  return async function (dispatch: AppDispatch) {
    try {
      dispatch({ type: USER_SOCIAL_REQUEST });
      const resp = await rpc.transmit('users.me.social_auth', {});
      dispatch({ type: USER_SOCIAL_SUCCESS, social: resp });
    } catch (e) {
      dispatch({ type: USER_SOCIAL_ERROR });
      console.log(e);
    }
  };
}
