import { useBreakpoints } from '@hooks/useBreakpoints';

import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';

export default function Header() {
  const { isMobile, isTable } = useBreakpoints();

  if (isMobile || isTable) {
    return <MobileHeader />;
  }
  return <DesktopHeader />;
}
