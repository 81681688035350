import cls from 'classnames';
import { useCallback, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';

import CheckSvg from '@assets/colorless/check.svg?react';
import CopySvg from '@assets/colorless/copy.svg?react';

import styles from './LandingCard.module.scss';
export type LandingCardCopyProps = {
  text: string;
};

export function LandingCardCopy({ text }: LandingCardCopyProps) {
  const { t } = useTranslation();
  const [copy, setCopy] = useState(false);
  const handleClick = useCallback(() => {
    setCopy(true);
    setTimeout(() => setCopy(false), 2000);
  }, []);
  return (
    <CopyToClipboard text={text} onCopy={handleClick}>
      <div className={cls(styles.copy, copy && styles.copied)}>
        {copy ? <CheckSvg className={styles.copy__success} /> : <CopySvg />}
        <span>{t('Copy')}</span>
      </div>
    </CopyToClipboard>
  );
}
