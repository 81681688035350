import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import srcMob from '@assets/banners/pool/mob.png';
import srcMob2x from '@assets/banners/pool/mob@2x.png';
import srcPc from '@assets/banners/pool/pc.png';
import srcPc2x from '@assets/banners/pool/pc@2x.png';
import promoMob from '@assets/promo-materials/pool/mob.png';
import promoMob2x from '@assets/promo-materials/pool/mob@2x.png';
import promoPc from '@assets/promo-materials/pool/pc.png';
import promoPc2x from '@assets/promo-materials/pool/pc@2x.png';

import { PRODUCT_KIND } from '@utils/checkPathname';

import BrowserLandingsBlock from '@pages/BrowserPromo/view/components/BrowserLandingsBlock';
import PoolPromoBrand from '@pages/PoolPromo/view/components/PoolPromoBrand';

import { GenerateBanners } from '@shared/GenerateBanners/GenerateBanners';
import { Container } from '@shared/index';
import { PromoImagesSection } from '@shared/PromoImagesSection/PromoImagesSection';
import { PromoTop } from '@shared/PromoTop/PromoTop';
import { TextBlock } from '@shared/TextBlock/TextBlock';

import { poolTexts } from '@constants/promo/poolTexts';

import PoolTabBanners from './components/PoolTabBanners/PoolTabBanners';
export type PoolPromoViewProps = {
  list: string[];
  links?: {
    id: string | number;
    name?: string | null;
    comment?: string | null;
  }[];
};

export default function PoolPromoView({ list, links }: PoolPromoViewProps) {
  const { t } = useTranslation();
  const tags = useMemo(() => [{ name: t('No text'), code: 'pool' }], [t]);
  return (
    <Container vertical={20}>
      <PromoTop
        imageDesktop={promoPc}
        imageDesktop2x={promoPc2x}
        imageMob={promoMob}
        imageMob2x={promoMob2x}
        List={
          <>
            <a href="#landings">{t('Landings')}</a>
            <a href="#social">{t('Social')}</a>
            <a href="#banners">{t('Banners')}</a>
            <a href="#text">{t('Lost.Keys1')}</a>
          </>
        }
      />
      <PoolPromoBrand />
      <BrowserLandingsBlock list={list} links={links} type="pool" />
      <GenerateBanners
        srcMob={srcMob}
        srcPc={srcPc}
        srcSetMob={srcMob2x}
        srcSetPc={srcPc2x}
        Tabs={PoolTabBanners}
        title={t('Link ID')}
        list={list}
        tags={tags}
        land={[{ url: 'https://ctpool.net/?aid=' }]}
      />
      <PromoImagesSection productKind={PRODUCT_KIND.Pool} />

      <TextBlock texts={poolTexts} />
    </Container>
  );
}
