export const BALANCE_REQUEST = 'BALANCE_REQUEST';
export const BALANCE_ERROR = 'BALANCE_ERROR';
export const BALANCE_SUCCESS = 'BALANCE_SUCCESS';

export const GET_ALL_LINKS_INFO = 'GET_ALL_LINKS_INFO';
export const GET_ALL_BALANCE = 'GET_ALL_BALANCE';
export const GET_FARM_BALACNE = 'GET_FARM_BALANCE';
export const GET_NFT_BALANCE = 'GET_NFT_BALANCE';
export const GET_AFF_BALANCE = 'GET_AFF_BALANCE';
export const GET_POOL_BALANCE = 'GET_POOL_BALANCE';
export const GET_CURRENCIES = 'GET_CURRENCIES';
export const GET_HSH_BALANCE = 'GET_HSH_BALANCE';
export const ADD_INCREASED_BALANCE = 'ADD_INCREASED_BALANCE';
export const CHANGE_IS_SHOWING_HSH_INCOMING = ' CHANGE_IS_SHOWING_HSH_INCOMING';

export const RATE_REQUEST = 'RATE_REQUEST';
export const RATE_ERROR = 'RATE_ERROR';
export const RATE_SUCCESS = 'RATE_SUCCESS';

export const PROMO_REQUEST = 'PROMO_REQUEST';
export const PROMO_ERROR = 'PROMO_ERROR';
export const PROMO_SUCCESS = 'PROMO_SUCCESS';

export const GET_FARM_ACTIVATION_DATA = 'GET_FARM_ACTIVATION_DATA';
export const GET_FARM_MINING_DATA = 'GET_FARM_MINING_DATA';
export const GET_FARM_EARNINGS_DATA = 'GET_FARM_EARNINGS_DATA';
export const GET_FARM_OPENS_DATA = 'GET_FARM_OPENS_DATA';
export const GET_AGGREGATED_FARM_DATA = 'GET_AGGREGATED_FARM_DATA';

export const USER_REQUEST = 'USER_REQUEST';
export const USER_ERROR = 'USER_ERROR';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_SOCIAL_REQUEST = 'USER_SOCIAL_REQUEST';
export const USER_SOCIAL_ERROR = 'USER_SOCIAL_ERROR';
export const USER_SOCIAL_SUCCESS = 'USER_SOCIAL_SUCCESS';
export const USER_TOKENS_SUCCESS = 'USER_TOKENS_SUCCESS';
export const USER_UPDATE = 'USER_UPDATE';

export const BANNER_REQUEST = 'BANNER_REQUEST';
export const BANNER_ERROR = 'BANNER_ERROR';
export const BANNER_SUCCESS = 'BANNER_SUCCESS';
export const BANNER_CLEAR = 'BANNER_CLEAR';

export const BROWSER_GRAPH_REQUEST = 'BROWSER_GRAPH_REQUEST';
export const BROWSER_GRAPH_ERROR = 'BROWSER_GRAPH_ERROR';
export const BROWSER_GRAPH_SUCCESS = 'BROWSER_GRAPH_SUCCESS';
export const BROWSER_CHANGE_DISCREETNESS_OPENS = 'BROWSER_CHANGE_DISCREETNESS_OPENS';
export const BROWSER_CHANGE_DISCREETNESS_INSTALLS = 'BROWSER_CHANGE_DISCREETNESS_INSTALLS';
export const BROWSER_CHANGE_DISCREETNESS_MINERS = 'BROWSER_CHANGE_DISCREETNESS_MINERS';
export const BROWSER_CHANGE_DISCREETNESS_EARNING = 'BROWSER_CHANGE_DISCREETNESS_EARNING';
export const BROWSER_EARNING_SUCCESS = 'BROWSER_EARNING_SUCCESS';
export const BROWSER_CHANGE_TABLE_MINERS = 'BROWSER_CHANGE_TABLE_MINERS';
export const BROWSER_CHANGE_TABLE_INSTALLS = 'BROWSER_CHANGE_TABLE_INSTALLS';
export const BROWSER_CHANGE_TABLE_OPENS = 'BROWSER_CHANGE_TABLE_OPENS';
export const SET_BROWSER_PERIOD = 'SET_BROWSER_PERIOD';

export const BROWSER_LINKS_REQUEST = 'BROWSER_LINKS_REQUEST';
export const BROWSER_LINKS_ERROR = 'BROWSER_LINKS_ERROR';
export const BROWSER_LINKS_SUCCESS = 'BROWSER_LINKS_SUCCESS';

export const GET_BROWSER_LEVELS = 'GET_BROWSER_LEVELS';

//
export const BROWSER_MINERS_REQUEST = 'BROWSER_MINERS_REQUEST';
export const BROWSER_MINERS_ERROR = 'BROWSER_MINERS_ERROR';
//
export const BROWSER_MINERS_SUCCESS = 'BROWSER_MINERS_SUCCESS';
export const BROWSER_DOWNLOADS_SUCCESS = 'BROWSER_DOWNLOADS_SUCCESS';
export const BROWSER_OPENS_SUCCESS = 'BROWSER_OPENS_SUCCESS';
export const BROWSER_INSTALLS_SUCCESS = 'BROWSER_INSTALLS_SUCCESS';
export const BROWSER_EARNING_TOTAL = 'BROWSER_EARNING_TOTAL';

export const GET_WITHDRAWAL_REQUEST = 'GET_WITHDRAWAL_REQUEST';
export const GET_WITHDRAWAL_SUCCESS = 'GET_WITHDRAWAL_SUCCESS';
export const GET_WITHDRAWAL_ERROR = 'GET_WITHDRAWAL_ERROR';
export const GET_WITHDRAWAL_RESET = 'GET_WITHDRAWAL_RESET';

export const GET_WITHDRAWALS_REQUEST = 'GET_WITHDRAWALS_REQUEST';
export const GET_WITHDRAWALS_FORCE_REQUEST = 'GET_WITHDRAWALS_FORCE_REQUEST';
export const GET_WITHDRAWALS_SUCCESS = 'GET_WITHDRAWALS_SUCCESS';
export const GET_WITHDRAWALS_ERROR = 'GET_WITHDRAWALS_ERROR';
export const GET_WITHDRAWALS_NEXT_REQUEST = 'GET_WITHDRAWALS_NEXT_REQUEST';
export const GET_WITHDRAWALS_NEXT = 'GET_WITHDRAWALS_NEXT';
export const GET_WITHDRAWALS_ADDRESSES = 'GET_WITHDRAWALS_ADDRESSES';
export const GET_NCW_ADDRESSES = 'GET_NCW_ADDRESSES';
export const CHANGE_IS_BITCOIN = 'CHANGE_IS_BITCOIN';
export const GET_HSH_WELCOME_BONUS = 'GET_HSH_WELCOME_BONUS';
export const CHANGE_WITHDRAWAL_STATUS = 'CHANGE_WITHDRAWAL_STATUS';
export const UPDATE_WITHDRAWALS = 'UPDATE_WITHDRAWALS';

export const UPDATE_MOB_WITHDRAWALS = 'UPDATE_MOB_WITHDRAWALS';
export const ERROR_WITHDRAWAL = 'ERROR_WITHDRAWAL';
export const ADD_NEW_WITHDRAWAL = 'ADD_NEW_WITHDRAWAL';
export const ADD_HSH_WITHDRAWAL = 'ADD_HSH_WITHDRAWAL';
export const ADD_NEW_WITHDRAWAL_ADDRESS = 'ADD_NEW_WITHDRAWAL_ADDRESS';

export const BROWSER_LAND_REQUEST = 'BROWSER_LAND_REQUEST';
export const BROWSER_LAND_ERROR = 'BROWSER_LAND_ERROR';
export const BROWSER_LAND_SUCCESS = 'BROWSER_LAND_SUCCESS';
export const BROWSER_VIDEO_SUCCESS = 'BROWSER_VIDEO_SUCCESS';

export const NFT_PROMO_REQUEST = 'NFT_PROMO_REQUEST';
export const NFT_PROMO_ERROR = 'NFT_PROMO_ERROR';
export const NFT_PROMO_SUCCESS = 'NFT_PROMO_SUCCESS';

export const NFT_STATS_REQUEST = 'NFT_STATS_REQUEST';
export const NFT_STATS_ERROR = 'NFT_STATS_ERROR';
export const NFT_STATS_SUCCESS = 'NFT_STATS_SUCCESS';
export const NFT_STATS_GET_ACCOUNTS = 'NFT_STATS_GET_ACCOUNTS';

export const NEWS_REQUEST = 'NEWS_REQUEST';
export const NEWS_ERROR = 'NEWS_ERROR';
export const NEWS_SUCCESS = 'NEWS_SUCCESS';

export const NETWORK_ALERT_REQUEST = 'NETWORK_ALERT_REQUEST';
export const NETWORK_ALERT_ERROR = 'NETWORK_ALERT_ERROR';
export const NETWORK_ALERT_SUCCESS = 'NETWORK_ALERT_SUCCESS';

export const POOL_LANDINGS_REQUEST = 'POOL_LANDINGS_REQUEST';
export const POOL_LANDINGS_ERROR = 'POOL_LANDINGS_ERROR';
export const POOL_LANDINGS_SUCCESS = 'POOL_LANDINGS_SUCCESS';

export const POOL_STATISTIC_UPDATE = 'POOL_STATISTIC_UPDATE';
export const GET_POOL_LEVELS = 'GET_POOL_LEVELS';
export const SET_POOL_PERIOD = 'SET_POOL_PERIOD';
export const POOL_LINKS_REQUEST = 'POOL_LINKS_REQUEST';
export const POOL_LINKS_ERROR = 'POOL_LINKS_ERROR';
export const POOL_LINKS_SUCCESS = 'POOL_LINKS_SUCCESS';

export const POOL_INSTALLS_SUCCESS = 'POOL_INSTALLS_SUCCESS';
export const POOL_MINERS_SUCCESS = 'POOL_MINERS_SUCCESS';
export const POOL_OPENS_SUCCESS = 'POOL_OPENS_SUCCESS';
export const POOL_CHANGE_DISCREETNESS_LINKS = 'POOL_CHANGE_DISCREETNESS_LINKS';
export const POOL_CHANGE_DISCREETNESS_REGISTRATION = 'POOL_CHANGE_DISCREETNESS_REGISTRATION';
export const POOL_CHANGE_DISCREETNESS_PURCHASED = 'POOL_CHANGE_DISCREETNESS_PURCHASED';
export const POOL_CHANGE_DISCREETNESS_EARNINGS = 'POOL_CHANGE_DISCREETNESS_EARNINGS';
export const POOL_CHANGE_EARNINGS_TOTAL = 'POOL_CHANGE_EARNINGS_TOTAL';

export const SET_DARK_THEME = 'SET_DARK_THEME';
export const SET_RANDOM = 'SET_RANDOM';
export const SET_COLOR = 'SET_COLOR';

export const UPDATE_MOBILE = 'UPDATE_MOBILE';

export const INTERNAL_ERROR = 'INTERNAL_ERROR';

export const SET_TITLE_PAGE = 'SET_TITLE_PAGE';

export const MAIN_EARNING_SUCCESS = 'MAIN_EARNING_SUCCESS';
export const MAIN_MINERS_SUCCESS = 'MAIN_MINERS_SUCCESS';
export const MAIN_CHANGE_EARNING_TOTAL = 'MAIN_CHANGE_EARNING_TOTAL';
export const MAIN_LINKS_ERROR = 'MAIN_LINKS_ERROR';
export const MAIN_LINKS_REQUEST = 'MAIN_LINKS_REQUEST';
export const MAIN_LINKS_SUCCESS = 'MAIN_LINKS_SUCCESS';
export const MAIN_CLICKS_SUCCESS = 'MAIN_CLICKS_SUCCESS';
export const GET_MAIN_LEVELS = 'GET_MAIN_LEVELS';
export const SET_MAIN_PERIOD = 'SET_MAIN_PERIOD';
export const MAIN_CHANGE_DISCREETNESS_CLICKS = 'MAIN_CHANGE_DISCREETNESS_CLICKS';
export const MAIN_CHANGE_DISCREETNESS_EARNING = 'MAIN_CHANGE_DISCREETNESS_EARNING';
export const MAIN_CHANGE_DISCREETNESS_MINER = 'MAIN_CHANGE_DISCREETNESS_MINER';
