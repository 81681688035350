import React from 'react';

import Arrow from '@assets/colorless/arrowLeft.svg?react';

import { Button, ButtonVariant } from '@shared/index';

import styles from './SuspendCard.module.scss';
type FormNavigationProps = {
  currentIndex: number;
  total: number;
  onNext: () => void;
  onPrevious: () => void;
  onSubmit: () => void;
  isLoading: boolean;
};

const FormNavigation: React.FC<FormNavigationProps> = ({
  currentIndex,
  total,
  onNext,
  onPrevious,
  onSubmit,
  isLoading,
}) => {
  return (
    <div className={styles.suspendCard__controls}>
      <Button type={'button'} size="medium" onClick={onPrevious} Icon={<Arrow />} disabled={!(currentIndex > 0)}>
        Back
      </Button>

      {currentIndex < total - 1 && (
        <Button type={'button'} size="medium" onClick={onNext} variant={ButtonVariant.Primary} disabled={false}>
          Next
        </Button>
      )}
      {currentIndex === total - 1 && (
        <Button type={'button'} size="medium" onClick={onSubmit} variant={ButtonVariant.Primary} disabled={isLoading}>
          Submit
        </Button>
      )}
    </div>
  );
};

export default FormNavigation;
