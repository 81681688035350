import cn from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BtcSvg } from '@assets/colored';
import { CheckSvg, CrossCircleSvg, IconWalletSvg } from '@assets/colorless';

import { jsColors } from '@utils/jsColors';
import { BROWSER_WITHDRAWAL } from '@utils/links';

import { WithdrawalError } from '@pages/Withdrawal/view/WithdrawalError';
import { BTC_NCW_CONNECT } from '@pages/WithdrawalNcwWallet/view/WithdrawalNcwWalletView/WithdrawalNcwWalletView';

import { Button, ButtonVariant } from '@shared/Button/Button';
import { useModal, Modal } from '@shared/Modal';

import { rpc } from '../../../../../backend/Rpc';

import styles from './ModalBtcConnect.module.scss';

interface ModalBtcConnectProps {
  isShow: boolean;
  changeIsShow: () => void;
  BTCOrHSH: string;
}

export default function ModalBtcConnect({ isShow, changeIsShow, BTCOrHSH }: ModalBtcConnectProps) {
  const { opened, onOpen, onClose } = useModal(isShow);
  const [address, setAddress] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (isShow) onOpen();
  }, [isShow]);

  const handleClose = useCallback(() => {
    changeIsShow();
    onClose();
  }, []);

  const handleSubmit = useCallback(async (address: string) => {
    try {
      setIsLoading(true);
      const res = await rpc.transmit('wallets.check_address', { address });

      if (res) {
        let addressToLocal;
        if ('email' in res) {
          addressToLocal = { address: res.email || res.account_id, is_ncw: res.type === 'ncw' };
        } else {
          addressToLocal = { address, is_ncw: res.type === 'ncw' };
        }
        localStorage.setItem(BTC_NCW_CONNECT, JSON.stringify(addressToLocal));
        window.open(`${BROWSER_WITHDRAWAL}/${BTCOrHSH}`, '_self');
      }
    } catch {
      setError(true);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return (
    <Modal.Layout isOpen={opened} onClose={handleClose}>
      <Modal.Content>
        <Modal.Body>
          <Modal.Icon icon={<BtcSvg />} />
          <Modal.TitleDescription title={t('BTC_address.Title')} description={t('BTC_address.Descr')} />
          <Modal.InnerContent>
            <div className={cn(styles.address, error && styles.input_error)}>
              <IconWalletSvg color={jsColors.orange} className={styles.address__logo} />
              <input
                type="text"
                placeholder={t('Add New Address')}
                disabled={isLoading}
                id="address"
                autoFocus={true}
                value={address}
                onChange={(e) => {
                  setAddress(e.target.value);
                  setError(false);
                }}
                className={styles.address__input}
              />
            </div>
            {error && <WithdrawalError text={t('BTC_address.Error')} />}
          </Modal.InnerContent>
        </Modal.Body>
        <Modal.Footer>
          <Button type={'button'} onClick={handleClose} variant={ButtonVariant.Default} Icon={<CrossCircleSvg />}>
            {t('Cancel')}
          </Button>
          <Button
            type={'button'}
            variant={ButtonVariant.Primary}
            Icon={<CheckSvg />}
            isLoading={isLoading}
            disabled={!address || isLoading || error}
            onClick={() => handleSubmit(address)}
          >
            {t('Confirm')}
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Layout>
  );
}
