import { Trans, useTranslation } from 'react-i18next';

import Ques from '@assets/icon-circle-question.svg?react';

import { Card, Steps } from '@shared/index';

import Container from '../Container/Container';
import first from './stepsImg/first.svg?react';
import fourth from './stepsImg/fourth.svg?react';
import second from './stepsImg/second.svg?react';
import third from './stepsImg/third.svg?react';

import styles from './How.module.scss';

export default function How() {
  const { t } = useTranslation();
  return (
    <Card className={styles.root}>
      <Container
        Icon={<Ques className="animate__animated" data-anim="fadeInDown" />}
        dark
        innerTop={0}
        title={
          <span className="animate__animated" data-anim="fadeIn">
            {t('ct-affiliate_about_text-edits.steps.How_to_start_earning')}
          </span>
        }
        text={
          <span className="animate__animated" data-anim="fadeIn">
            <Trans
              i18nKey="ct-affiliate_about_text-edits.steps.Invite_as_many_new_u"
              components={{
                b: <b />,
              }}
            />
          </span>
        }
      >
        <Steps>
          <Steps.Item
            Icon={first}
            title={t('ct-affiliate_about_text-edits.steps.steps_scheme.All_people_invited_b.85')}
            withArrow={false}
            className="animate__animated steps-anim-1"
            dark
          />
          <Steps.Item
            Icon={second}
            title={t('ct-affiliate_about_text-edits.steps.steps_scheme.All_people_invited_b.49')}
            dark
            className="animate__animated steps-anim-2"
            arrowAnimClassName="animate__animated steps-anim-arrow-1"
          />
          <Steps.Item
            Icon={third}
            title={t('ct-affiliate_about_text-edits.steps.steps_scheme.All_people_invited_b')}
            dark
            className="animate__animated steps-anim-3"
            arrowAnimClassName="animate__animated steps-anim-arrow-2"
          />
          <Steps.Item
            Icon={fourth}
            title={t('ct-affiliate_about_text-edits.steps.steps_scheme.All_people_invited_b.44')}
            dark
            className="animate__animated steps-anim-4"
            arrowAnimClassName="animate__animated steps-anim-arrow-3"
          />
        </Steps>
      </Container>
    </Card>
  );
}
