import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import IntlFormatter from './IntlFormatter';

const useIntlFormatter = () => {
  const { i18n } = useTranslation();
  const currentLocale = i18n.language || 'en-US';
  type ISODateString = string | number;

  const intFormatter = useCallback(
    (value: string | number, options?: Intl.NumberFormatOptions) => {
      return IntlFormatter.formatNumber(value, currentLocale, options);
    },
    [currentLocale]
  );

  const dateFormatter = useCallback(
    (date: ISODateString | number, options?: Intl.DateTimeFormatOptions) => {
      return IntlFormatter.formatDate(date, currentLocale, options);
    },
    [currentLocale]
  );

  return { intFormatter, dateFormatter };
};

export default useIntlFormatter;
