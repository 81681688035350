export default function quickObjectSort<T extends Record<K, number | string>, K extends keyof T>(
  array: T[],
  params: K
): T[] {
  if (array.length <= 1) {
    return array;
  }

  const pivotIndex = Math.floor(array.length / 2);
  const pivot = array[pivotIndex];
  const less: T[] = [];
  const greater: T[] = [];

  for (let i = 0; i < array.length; i++) {
    if (i === pivotIndex) continue;
    if (array[i][params] > pivot[params]) {
      less.push(array[i]);
    } else {
      greater.push(array[i]);
    }
  }

  return [...quickObjectSort(less, params), pivot, ...quickObjectSort(greater, params)];
}
