import React from 'react';
import { useTranslation } from 'react-i18next';

import noData from '@assets/no-data.svg';

import styles from './GraphNoData.module.scss';
export default function GraphNoData() {
  const { t } = useTranslation();
  return (
    <div className={styles.noData}>
      <img src={noData} alt="no data" />
      <div className={styles.noData__title}>{t('No data')}</div>
    </div>
  );
}
