/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useRef } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import ArrowRight from '@assets/colorless/arrow-right-sm.svg?react';
import Arrow from '@assets/colorless/arrowLeft.svg?react';
import Refresh from '@assets/colorless/refresh.svg?react';

import { BROWSER_FEEDBACK, SUPPORT } from '@utils/links';

import { SupportFormKind } from '@pages/Support/SupportFormKind';

import { Button, ButtonVariant } from '@shared/index';

import { findByPath } from '../../utils';

import styles from './FeedbackCard.module.scss';

type FeedbackCardProps = {
  route: string;
  feedbackConfig: Record<string, any>;
  section?: Record<string, any>;
};

export const FeedbackCard: FC<FeedbackCardProps> = (props) => {
  const { goBack } = useHistory();
  const { route, feedbackConfig } = props;

  const { url } = useRouteMatch();
  const section: Record<string, any> = findByPath(feedbackConfig, route);
  const { title, description, question, sections, content } = section;
  const sectionRef = useRef<Record<string, any>>();
  return (
    <div className={styles.feedbackCard}>
      <h1 className={styles.feedbackCard__title}>{title}</h1>
      <div className={styles.feedbackCard__body}>
        {description && <p className={styles.feedbackCard__description}>{description}</p>}
        {question && <h5 className={styles.feedbackCard__subtitle}>{question}</h5>}
        <div className={styles.feedbackCard__content}>
          <div className={styles.feedbackCard__content_choices}>
            {sections &&
              Object.entries(sections).map(([k, _]) => {
                const section: Record<string, any> = findByPath(feedbackConfig, `${route}/${k}`);
                sectionRef.current = section;
                sectionRef.current.topic = k;

                const isFormNext = 'form' in section;
                const isExtended = 'extended' in section;
                const isBot = 'bot' in section;
                const summery = isFormNext ? section['summary'] : '';
                let kind = isExtended ? SupportFormKind.Extended : SupportFormKind.Default;
                kind = isBot ? SupportFormKind.Bot : kind;

                return (
                  !section['hidden'] && (
                    <Button
                      type={'link'}
                      key={k}
                      to={{
                        pathname: isFormNext ? `${SUPPORT}/${k}` : `${url}/${k}`,
                        state: {
                          projectId: projectIdMap[sectionRef?.current?.topic as keyof typeof projectIdMap],
                          summery,
                          kind,
                        },
                      }}
                      className={styles.feedbackCard__choice}
                    >
                      {section.alias || section.title}
                    </Button>
                  )
                );
              })}
          </div>

          {content && (
            <div className={styles.feedbackCard__content_inner} dangerouslySetInnerHTML={{ __html: content }} />
          )}
        </div>
      </div>
      {route !== '/' && (
        <div className={styles.feedbackCard__controls}>
          {route !== '/' && (
            <Button type={'button'} onClick={() => goBack()} size="medium" Icon={<Arrow />}>
              Back
            </Button>
          )}
          {sectionRef?.current?.next && (
            <Button
              type={'link'}
              size="medium"
              to={{
                pathname: `${SUPPORT}/${sectionRef?.current?.topic}`,
                state: {
                  projectId: projectIdMap[sectionRef?.current?.topic as keyof typeof projectIdMap],
                  summary: sectionRef?.current?.summary,
                  kind: sectionRef?.current?.bot ? SupportFormKind.Bot : SupportFormKind.Default,
                },
              }}
              iconPosition="right"
              Icon={<ArrowRight />}
            >
              Next
            </Button>
          )}
          {!sections && (
            <Button type={'link'} to={BROWSER_FEEDBACK} variant={ButtonVariant.Primary} Icon={<Refresh color="#fff" />}>
              Go to start
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export const projectIdMap = {
  'cryptotab-coin-feedback': 35,
  'cryptotab-nft-feedback-form': 34,
  'vipsupport': 36,
  'ios': 36,
  'android': 36,
};
