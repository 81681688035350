import { POOL_PROMO } from '@utils/links';

import { PoolEarningsGraph } from '@pages/PoolStatistic/view/components/PoolEarningsGraph';

import BannerDashboard from '@shared/BannerDashboard/BannerDashboard';
import { Container } from '@shared/Container/Container';
import { News } from '@shared/News';
import { PoolLinks } from '@shared/PoolLinks/PoolLinks';
import { PoolMined } from '@shared/PoolMined/PoolMined';
import { ProductPromoBanner } from '@shared/ProductPromoBanner/ProductPromoBanner';

import Balance, { BalanceVariant } from '../../../components/Balance';

export default function PoolDashboardView() {
  return (
    <Container vertical={20}>
      <Balance variant={BalanceVariant.POOL} />
      <BannerDashboard />
      <PoolLinks />
      <PoolMined />
      <PoolEarningsGraph />
      <ProductPromoBanner
        cookie="poolbanner=hide"
        link={POOL_PROMO}
        image={{
          srcMob: 'https://cdn.cryptobrowser.site/images/affiliate-redesign/ct-pool/pool-promo-mob.png',
          srcSetMob: 'https://cdn.cryptobrowser.site/images/affiliate-redesign/ct-pool/pool-promo-mob@2x.png',
          src: 'https://cdn.cryptobrowser.site/images/affiliate-redesign/ct-pool/pool-promo.png',
          srcSet: 'https://cdn.cryptobrowser.site/images/affiliate-redesign/ct-pool/pool-promo@2x.png',
        }}
      />
      <News />
    </Container>
  );
}
