import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { CheckSvg } from '@assets/colorless';

import checkE from '@utils/checkE';
import { convertBigValueToLocale } from '@utils/convertBigValueToLocale';

import { Button, ButtonVariant } from '@shared/index';
import { ModalInfo } from '@shared/Modal';

import { Withdrawal } from '@backend/JsonRpcApi';

import CheckGreenSvg from './assets/check-green.svg?react';

type CancelWithdrawalSuceessModalProps = {
  isVisible: boolean;
  onClose: () => void;
  withdrawal: Withdrawal;
  isBitcoin: boolean;
};

export const CancelWithdrawalSucсessModal: FC<CancelWithdrawalSuceessModalProps> = ({
  isVisible,
  onClose,
  withdrawal,
  isBitcoin,
}) => {
  const { t } = useTranslation();

  return (
    <ModalInfo
      isOpen={isVisible}
      onClose={onClose}
      icon={<CheckGreenSvg />}
      title={t('WithdrawalCancelSuccess.Title')}
      description={
        <Trans
          i18nKey="WithdrawalCancelSuccess.Description"
          components={{ b: <b /> }}
          values={{
            amount: isBitcoin ? checkE(+withdrawal.amount) : convertBigValueToLocale(+withdrawal.amount),
            coin: isBitcoin ? 'BTC' : 'HSH',
          }}
        />
      }
      footer={
        <Button type={'button'} Icon={<CheckSvg />} size="medium" variant={ButtonVariant.Primary} onClick={onClose}>
          {t('OK, GOT IT')}
        </Button>
      }
    />
  );
};
