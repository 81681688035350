import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { AllSubheader } from '@pages/headersComponents/AllSubheader';

import getBalances from '@service/actions/getBalances';
import { getBrowserStatsInstalls } from '@service/actions/getBrowserStatsLinks';
import { getMainPaymentsStats } from '@service/actions/getMainPaymentsStats';
import getPromos from '@service/actions/getPromos';
import getStatsClicks from '@service/actions/getStatsClicks';
import { Root } from '@service/reducers';

import CommonStatisticsView from './view/CommonStatisticView';
import { HelmetHead } from '../headersComponents/HelmetHead';

export function CommonStatistics() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    browserGraph: { period_start, period_end, installsDiscreteness },
    balance: { get_balance },
    mainStats: { clicksDiscreteness, earningsDiscreteness, minersDiscreteness },
    promo: { promo_get, promo_request },
  } = useSelector((state: Root) => state);
  const isFirstLoad = useRef(true);
  useEffect(() => {
    if (!get_balance) {
      dispatch(getBalances());
    }
  }, [dispatch, get_balance]);

  useEffect(() => {
    if (!promo_get && !promo_request) {
      dispatch(getPromos());
    }
  }, [promo_get, dispatch]);

  //stats
  useEffect(() => {
    dispatch(getStatsClicks(period_start, period_end, clicksDiscreteness));
  }, [dispatch, period_start, period_end, clicksDiscreteness]);

  useEffect(() => {
    dispatch(getBrowserStatsInstalls(period_start, period_end, installsDiscreteness));
  }, [dispatch, period_start, period_end, installsDiscreteness]);

  // получение статистики по выплатам  и майнерам при первой загрузке
  useEffect(() => {
    if (!isFirstLoad.current) {
      dispatch(getMainPaymentsStats(period_start, period_end, minersDiscreteness, earningsDiscreteness, false, false));
    }
  }, [dispatch, earningsDiscreteness]);
  // получение статистики   майнерам при изменении  discreteness
  useEffect(() => {
    if (!isFirstLoad.current) {
      dispatch(getMainPaymentsStats(period_start, period_end, minersDiscreteness, earningsDiscreteness, true, false));
    }
  }, [dispatch, minersDiscreteness]);
  // получение статистики   выплатам при изменении  discreteness
  useEffect(() => {
    if (isFirstLoad.current) {
      isFirstLoad.current = false;
    }
    dispatch(getMainPaymentsStats(period_start, period_end, minersDiscreteness, earningsDiscreteness, false, true));
  }, [dispatch, period_start, period_end]);

  return (
    <>
      <HelmetHead title={t('Statistic')} />
      <AllSubheader />
      <CommonStatisticsView />
    </>
  );
}
