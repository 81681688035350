import { useTranslation } from 'react-i18next';

import { BrowserSubheader } from '@pages/headersComponents/BrowserSubheader';
import { HelmetHead } from '@pages/headersComponents/HelmetHead';

import suspendConfig from './suspendConfig.json';
import { BrowserSuspendView } from './view/BrowserSuspendView';

export const BrowserSuspend = () => {
  const { t } = useTranslation();
  return (
    <>
      <HelmetHead title={t('Support')} />
      <BrowserSubheader />
      <BrowserSuspendView suspendConfig={JSON.parse(JSON.stringify(suspendConfig))} />
    </>
  );
};
