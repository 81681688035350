import { createContext, useContext } from 'react';

interface ModalContextProps {
  onClose: () => void;
}

const ModalContext = createContext<ModalContextProps | null>(null);

export const ModalContextProvider = ModalContext.Provider;

export const useModalContext = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModalContext must be used within a ModalContextProvider');
  }
  return context;
};
