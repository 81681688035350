import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { SUPPORT, SUPPORT_SUCCESS } from '@utils/links';

import { BrowserSubheader } from '@pages/headersComponents/BrowserSubheader';

import { getWithdrawals } from '@service/actions/getWithdrawals';
import { rootUser } from '@service/reducers/userReducer';
import { WithdrawalsState } from '@service/reducers/withdrawalsReducer';

import { rpc } from '@backend/Rpc';

import { FormStateDate } from './FormStateData';
import { SummeryKind } from './SummaryKind';
import { SupportFormKind } from './SupportFormKind';
import SupportView from './view/SupportView';
import { projectIdMap } from '../BrowserFeedback/view/FeedbackCard/FeedbackCard';
import { HelmetHead } from '../headersComponents/HelmetHead';

const getSupportKind = (pathname: string) => {
  const path = pathname.split('/');
  if (path[path.length - 1] === 'withdrawal-issue') return SupportFormKind.Bot;
  if (path[path.length - 1] === 'account-was-deleted' || path[path.length - 1] === 'account-was-reset')
    return SupportFormKind.Extended;

  return SupportFormKind.Default;
};

export default function Support() {
  const [isSubmitting, setSubmitting] = useState(false);
  const history = useHistory();
  const email = useSelector((store: { user: rootUser }) => store.user.email);
  const { state, pathname } = useLocation<{
    projectId?: number;
    summary: SummeryKind;
    kind: SupportFormKind;
  }>();

  const path = pathname.split('/')[pathname.split('/').length - 1];
  const _projectId = projectIdMap[path as keyof typeof projectIdMap];
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { hasRequested, withdrawals } = useSelector((state: { withdrawals: WithdrawalsState }) => state.withdrawals);

  const fetchWithdrawals = useCallback(() => {
    !hasRequested && dispatch(getWithdrawals(true));
  }, [hasRequested, dispatch]);

  const supportKind = getSupportKind(pathname);
  useEffect(() => {
    if (path === 'withdrawal-issue') {
      history.replace({ pathname: SUPPORT, state: { ...state } });
    }
  }, [history, path, state]);

  useEffect(() => {
    if (supportKind === SupportFormKind.Default && _projectId !== 36) {
      history.replace({ pathname: SUPPORT, state: { ...state } });
    }
  }, [history, supportKind, _projectId]);

  const handleSubmit = useCallback(
    async (formState: FormStateDate) => {
      setSubmitting(true);
      try {
        await rpc.transmit('support.create_issue', {
          ...formState,
          subject_id: _projectId ? _projectId : 'projectId' in state ? state.projectId : 0,
          summary: formState.message,
        });

        history.replace({ pathname: SUPPORT_SUCCESS });
      } catch (e) {
        console.log(e);
      } finally {
        setSubmitting(false);
      }
    },
    [_projectId, history, state]
  );

  const handleBotSubmit = useCallback(
    async (withdrawalId: string) => {
      setSubmitting(true);
      try {
        await rpc.transmit('withdrawals.create_issue', {
          id: withdrawalId,
          message: '',
        });

        history.replace({ pathname: SUPPORT_SUCCESS });
      } catch (e) {
        console.log(e);
      } finally {
        setSubmitting(false);
      }
    },
    [history]
  );

  const handleBack = useCallback(() => {
    history.goBack();
  }, [history]);

  // if (!email) {
  //   return <Loader />;
  // }

  return (
    <>
      <HelmetHead title={t('Support')} />
      <BrowserSubheader />
      <SupportView
        email={email}
        hasRequested={hasRequested}
        withdrawals={withdrawals}
        getWithdrawals={fetchWithdrawals}
        kind={supportKind}
        onBack={handleBack}
        onSubmit={handleSubmit}
        onBotSubmit={handleBotSubmit}
        isSubmitting={isSubmitting}
      />
    </>
  );
}
