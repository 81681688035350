import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PRODUCT_KIND } from '@utils/checkPathname';
import { getRandomPromoImage, getRandomPromoText, getRandomPromoTitles } from '@utils/randomPromo';

import { LinkAcc } from '@service/reducers/balanceReducer';
import { BrowserPromoLand } from '@service/reducers/browserPromoReducer';
import { PromoType } from '@service/reducers/promoReducer';

import { AllProductTabs } from '@shared/CommonProduct/CommonProduct';
import { Modal } from '@shared/Modal';

import { productURLsMap } from '@constants/productURLsMap';

import { GeneratePostModalBody } from './GeneratePostModalBody/GeneratePostModalBody';
import { GeneratePostModalFooter } from './GeneratePostModalFooter/GeneratePostModalFooter';
import { GeneratePostModalHeader } from './GeneratePostModalHeader/GeneratePostModalHeader';

import styles from './GeneratePostModal.module.scss';

export enum RefreshContent {
  All = 'all',
  Title = 'title',
  Text = 'text',
  Image = 'image',
}

type GeneratePostModalProps = {
  productKind: PRODUCT_KIND;
  codes: PromoType[];
  links: LinkAcc[];
  landings: BrowserPromoLand[];
  initialLink?: number;
  initialTab?: AllProductTabs;
  showTabs?: boolean;
  onClose: () => void;
};

export const GeneratePostModal = (props: GeneratePostModalProps) => {
  const { t } = useTranslation();
  const getText = useCallback(() => getRandomPromoText(props.productKind), [props.productKind]);
  const getTitle = useCallback(() => getRandomPromoTitles(props.productKind), [props.productKind]);
  const getImage = useCallback(() => getRandomPromoImage(props.productKind), [props.productKind]);

  const [currentTab, setCurrentTab] = useState(props.initialTab || AllProductTabs.Link);

  const [title, setTitle] = useState(getTitle());
  const [text, setText] = useState(getText());
  const [image, setImage] = useState(getImage());

  const handleRefresh = (evt: React.MouseEvent<HTMLButtonElement>) => {
    const refreshContent = evt.currentTarget.getAttribute('name') as RefreshContent | undefined;

    switch (refreshContent) {
      case RefreshContent.Title:
        setTitle(getTitle());
        break;
      case RefreshContent.Text:
        setText(getText());
        break;
      case RefreshContent.Image:
        setImage(getImage());
        break;
      case RefreshContent.All:
        setTitle(getTitle());
        setText(getText());
        setImage(getImage());
        break;
    }
  };

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const refreshContent = evt.currentTarget.getAttribute('name') as RefreshContent | undefined;

    switch (refreshContent) {
      case RefreshContent.Title:
        setTitle(evt.target.value);
        break;
      case RefreshContent.Text:
        setText(evt.target.value);
        break;
    }
  };

  const [currentLinkId, setCurrentLinkId] = useState<number>();

  const content = {
    title: t(title),
    // TODO: Remove after adding Portugese translation to all texts
    text: t(text) ? t(text).replace('{{ref_link}}', `${productURLsMap[props.productKind]}${currentLinkId}`) : getText(),
    image,
  };

  return (
    <Modal.Layout onClose={props.onClose} isOpen>
      <Modal.Content className={styles.modalContentContainer}>
        <Modal.Body containerClassName={styles.modalBody}>
          <GeneratePostModalHeader
            productKind={props.productKind}
            title={t('Share_block.Title')}
            onRefresh={handleRefresh}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            showTabs={props.showTabs}
          />
          <GeneratePostModalBody onRefresh={handleRefresh} onChange={handleChange} {...content} />
          <GeneratePostModalFooter
            productKind={props.productKind}
            entityType={currentTab}
            codes={props.codes}
            links={props.links}
            landings={props.landings}
            initialLink={props.initialLink}
            setCurrentLinkId={setCurrentLinkId}
            {...content}
          />
        </Modal.Body>
      </Modal.Content>
    </Modal.Layout>
  );
};
