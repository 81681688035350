import { useState } from 'react';

import { AnswerItem } from '../components/types';

export const useFormAPI = () => {
  const [loading, setLoading] = useState(false);
  const url = window.app_config.baseAPIURL;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fetchTemplate = async (): Promise<any> => {
    setLoading(true);
    try {
      const response = await fetch(url + `suspend/dispute/forms/templates/initial`, { credentials: 'include' });
      if (!response.ok) throw new Error('Failed to fetch form template');
      return response.json();
    } finally {
      setLoading(false);
    }
  };
  const submitAnswers = async (answers: Record<string, AnswerItem>): Promise<void> => {
    setLoading(true);
    try {
      const response = await fetch(url + 'suspend/dispute/forms/initial', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({
          answers,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to submit answers');
      }
    } finally {
      setLoading(false);
    }
  };

  return { fetchTemplate, submitAnswers, loading };
};
