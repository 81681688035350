import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { BrowserSubheader } from '@pages/headersComponents/BrowserSubheader';

import getSocial from '@service/actions/getSocial';

import { HelmetHead } from '../headersComponents/HelmetHead';
import { SettingsView } from './view/SettingsView';

export default function Settings() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSocial());
  }, [dispatch]);
  const { t } = useTranslation();
  return (
    <>
      <HelmetHead title={t('Login settings')} />
      <BrowserSubheader />
      <SettingsView />
    </>
  );
}
